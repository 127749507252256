import { NavLink } from "react-router-dom";

// Sidebar Data
import { sidebarData, sidebarIconData } from "./sidebar-data";

// Components
import SidebarMenu from "./sidebar-menu/sidebar-menu";

// Images

import Logo from "../../assets/logo/logo.png";
import collapsedIcon from "../../assets/logo/logo.png";

// SCSS
import "./sidebar.scss";

// Interfaces
import { ISIDEBARDATA, ISIDEBARICONS } from "./sidebar-interface";

// Custom Hook
import { useSidebar } from "./use-sidebar";
import { setMobDrawer } from "../../store/layout/layout-slice";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";
import { Avatar, Box, Drawer, Grid, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  MUIBORDERRADIUS24,
  MUIPRIMARYTEXTCOLOR,
} from "../../constants/mui-styles-constant";

// Component
export const SidebarMainLayout = () => {
  const [openNav, setOpenNav] = useState(false);
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenNav(open);
  };

  const {
    openDrawer,
    auth,
    handleImage,
    dispatch,
    personalUser,
    navigate,
  }: any = useSidebar();

  return (
    <>
      {/* Mobile Version Sidebar */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
          marginLeft: 1,
        }}
      >
        <MenuIcon sx={{ color: MUIPRIMARYTEXTCOLOR }} />
      </IconButton>
      <Drawer
        anchor="left"
        open={openNav}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: 300,
            borderEndEndRadius: MUIBORDERRADIUS24,
            borderTopRightRadius: MUIBORDERRADIUS24,
            top: 30,
          },
        }}
      >
        <IconButton
          disableRipple
          sx={{ mb: 2, display: "flex", mt: 1, justifyContent: "end" }}
          onClick={toggleDrawer(false)}
        >
          <CloseIcon sx={{ color: MUIPRIMARYTEXTCOLOR }} />
        </IconButton>
        <Grid
          className={" black-color border-radius-24 padding-bottom-3"}
          maxWidth="290px"
        >
          {sidebarData.map((item: ISIDEBARDATA) => {
            return (
              <div key={item.id} onClick={() => setOpenNav(false)}>
                <SidebarMenu item={item}  />
              </div>
            );
          })}
        </Grid>
      </Drawer>
      {/* Desktop Version Sidebar */}
      <Box
        sx={{ display: { xs: "none", md: "block" } }}
        className={
          "bg-card-white black-color border-radius-24 padding-top-2 padding-bottom-3"
        }
        style={{ maxWidth: "290px" }}
      >
        {sidebarData.map((item: ISIDEBARDATA) => {
          return <SidebarMenu item={item} key={item.id} />;
        })}
      </Box>
    </>
  );
};
