import { Grid, CircularProgress } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { useAppSelector } from "../../../../store/store";
import "./style.scss";
export const PieChartForMortgageBroker = () => {
  const { status, mortgageBrokerData } = useAppSelector((state: any) => state?.mortgageBrokerSlice)

  const eChartOption = {
    tooltip: {
      trigger: "item",
    },
    color: [" #48995D", "#FFBF00"],
    legend: {
      showInLegend: true,
      orient: "vertical",
      right: "right",
      top: "top",
      data: ["Completed Calls", "Pending Calls"],
      icon: "circle",
      padding: 5,
    },
    series: [
      {
        type: "pie",
        radius: "77%",
        avoidLabelOverlap: false,
        center: ["42%", "57%"],
        data: [
          { value: mortgageBrokerData?.completedAverage, name: "Completed Calls" },
          { value: mortgageBrokerData?.pendingStatus, name: "Pending Calls" },
        ],
        itemStyle: {
          normal: {
            label: {
              fontSize: 20,
              fontWeight: 600,
              color: "#ffff",
              show: true,
              position: "inner",
              formatter: function (params: { value: string }) {
                return params.value + "%\n";
              },
            },
            labelLine: {
              show: false,
            },
            borderWidth: 6,
            borderColor: "#fff",
          },
        },
        label: {
          show: false,
        },
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 height-100">
      <Grid padding={2} className="height-100">
        <div className="flex justify-space-between margin-bottom-1">
          <span className="font-weight-600 secondary-title"> Calls Stats </span>
        </div>
        {status === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
          </div>}
        {status === REQUESTSTATUS.SUCCEEDED ?
          <ReactECharts option={eChartOption} className="pie-chart" />
          :
          (status === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">Server Not Responding</span>
            </div>
          )
        }
      </Grid>
    </div>
  );
};
