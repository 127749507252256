import { createAsyncThunk } from "@reduxjs/toolkit";
import { QUOTEMANAGEMENTLISTAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { getDataAPI, patchDataAPI } from "../../lib/axios";

// Quote Management Home Page Get
export const getQuoteManagementListThunk = createAsyncThunk(
  "quote-management/get-list",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        QUOTEMANAGEMENTLISTAPI,
        axiosInstance,
        params
      );
      const responseData = {
        users: response?.data?.data?.users,
        meta: response?.data?.data?.meta,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Quote Management Form Get
export const getByIdQuoteManagementFormThunk = createAsyncThunk(
  "quote-management-form/get-list",
  async ({ axiosInstance, userId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${QUOTEMANAGEMENTLISTAPI}/${userId}`,
        axiosInstance
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Quote Management Form Patch
export const patchByIdQuoteManagementFormThunk = createAsyncThunk(
  "quote-management-form/patch-list",
  async (
    { axiosInstance, userId, newDataOfForm, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        QUOTEMANAGEMENTLISTAPI,
        axiosInstance,
        newDataOfForm
      );
      dispatch(getByIdQuoteManagementFormThunk({ axiosInstance, userId }));
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
