import { Tooltip } from "@mui/material";
import { TOOLTIP } from "./app-tooltip-mui-styles";

export const AppToolTip = (props: any) => {
  const {
    tooltipTitle,
    componentsProps,
    placement,
    children,
    showArrow,
    bgColor,
  } = props;

  return (
    <>
      <Tooltip
        title={tooltipTitle}
        arrow={showArrow ?? true}
        placement={placement || "top"}
        componentsProps={componentsProps || TOOLTIP?.(bgColor)}
      >
        {children}
      </Tooltip>
    </>
  );
};
