import { useState, useRef } from "react";
import { Grid, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { axiosInstance } from "../../../../lib/axios";

import { AppInputField } from "../../../../components/app-input-field/app-input-field";
import { FilterIcon } from "../../../../utils/svg-icon-utils";
import { dropdownOptionsForSellers } from "./dropdown-data";
import { AppButton } from "../../../../components/app-button/app-button";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { addSellerContractThunk } from "../../../../store/contracts/contracts-thunk-actions";
import ModalLayout from "../../../../components/modal-layout/modal-layout";
import { RenderPdf } from "../../../../components/render-pdf/render-pdf";
import AppLoader from "../../../../components/app-loader/app-loader";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";

const readFile = async (
  file: File | null,
  type: "text" | "dataURL" | "arrayBuffer"
) => {
  return new Promise<string | ArrayBuffer>((r) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (e) => {
      if (e && e.target && e.target.result && file !== null) {
        r(e.target.result);
      }
    });
    if (file !== null) {
      if (type === "text") {
        fileReader.readAsText(file);
      } else if (type === "dataURL") {
        fileReader.readAsDataURL(file);
      } else if (type === "arrayBuffer") {
        fileReader.readAsArrayBuffer(file);
      }
    }
  });
};

export const SellerDropDown = () => {
  const [caseStatus, setCaseStatus] = useState<string>("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [fileUrl, setFileurl] = useState<any>(null);
  const navigate = useNavigate();
  const { status } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const { caseId } = useParams();
  const { openAlert } = useAppAlert();
  const handleCaseStatus = (event: any) => {
    setCaseStatus(event.target.value as string);
    setFile(null);
  };

  const fileClickHand = () => {
    fileRef.current && fileRef.current.click();
  };
  const onFileUpload = (event: any) => {
    setFile(event.target.files[0]);
  };

  const uploadPdf = () => {
    const formData = new FormData();
    formData.append("contractType", "seller_contracts");
    formData.append("formType", caseStatus);
    formData.append("contractFile", file);
    dispatch(
      addSellerContractThunk({
        axiosInstance,
        formData,
        caseId,
        navigate,
        openAlert,
      })
    );
    setFile(null);
    setCaseStatus("");
  };

  const clearFile = () => {
    setFile(null);
  };

  const openPdf = async () => {
    const url = await readFile(file, "dataURL");
    setFileurl(url);
    setPreviewModalOpen(true);
  };
  return (
    <>
      {status === REQUESTSTATUS.PENDING ? (
        <AppLoader />
      ) : (
        <>
          <Grid container>
            <Grid item sm={12} container sx={{ marginBottom: "1.1rem" }}>
              <Grid item sm={4} xs={12}>
                <AppInputField
                  label="Form Type"
                  labelClassName="primary-text-color font-weight-500"
                  required={false}
                  select={true}
                  selectMenudata={dropdownOptionsForSellers}
                  value={caseStatus}
                  onChange={handleCaseStatus}
                />
              </Grid>
            </Grid>
            {file === null && caseStatus !== "" && (
              <>
                <Grid
                  container
                  onClick={fileClickHand}
                  sx={{
                    border: "1px dashed #000000",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                  }}
                >
                  <Grid
                    item
                    sm={12}
                    container
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <IconButton aria-label="delete">
                        <AttachFileIcon />
                      </IconButton>
                      <span>{file ? file?.name : null}</span>
                      <span>{!file && "Browse File"}</span>
                    </Grid>
                  </Grid>
                  <input
                    onChange={onFileUpload}
                    style={{ display: "none" }}
                    ref={fileRef}
                    type="file"
                    accept="application/pdf"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ marginTop: "1em" }}
                >
                  <AppButton
                    btnText="Upload"
                    className="btn-gray cursor-pointer tertiary-title add-item-btn "
                    btnFunction={() => {}}
                    btnIconRight={
                      <CloudUploadIcon
                        sx={{ color: "white", margin: "0 1em" }}
                      />
                    }
                  />
                </Grid>
              </>
            )}

            {file !== null && caseStatus !== "" && (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px dashed #000000",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                  }}
                >
                  <Grid
                    item
                    sm={12}
                    container
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid
                      item
                      sx={{
                        background:
                          "linear-gradient(0deg, rgba(54, 110, 194, 0.1), rgba(54, 110, 194, 0.1)), #FFFFFF",
                        position: "relative",
                        padding: "0.8em 0.5em",
                        borderRadius: "2px",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        sx={{
                          position: "absolute",
                          top: "0",
                          left: "100%",
                          backgroundColor: "#366EC2",
                          transform: "translate(-50%,-50%)",
                          width: "0.8em",
                          height: "0.8em",
                        }}
                        onClick={clearFile}
                      >
                        <CloseIcon
                          sx={{
                            color: "white",
                            width: "0.7em",
                            height: "0.7em",
                          }}
                        />
                      </IconButton>
                      <IconButton aria-label="delete">
                        <AttachFileIcon />
                      </IconButton>
                      {/* <span>{file ? file?.name : null}</span> */}
                      <span>
                        {file
                          ? file?.name.length > 10
                            ? `${file?.name.slice(0, 10).replace(".p", "")}...`
                            : file?.name
                          : null}
                      </span>
                      <span
                        onClick={openPdf}
                        style={{ marginLeft: "0.2em", color: "#48995D" }}
                      >
                        (Preview)
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ marginTop: "1em" }}
                >
                  <AppButton
                    btnText="Upload"
                    className="btn-blue cursor-pointer tertiary-title add-item-btn "
                    btnFunction={uploadPdf}
                    btnIconRight={
                      <CloudUploadIcon
                        sx={{ color: "white", margin: "0 1em" }}
                      />
                    }
                  />
                </Grid>
              </>
            )}
            {isPreviewModalOpen && fileUrl && (
              <ModalLayout
                isModalOpen={isPreviewModalOpen}
                setIsModalOpen={setPreviewModalOpen}
                leftMd={4}
                centerMd={3}
                rightMd={4}
              >
                <RenderPdf file={fileUrl} viewonly={true} />
              </ModalLayout>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
