import { Grid } from "@mui/material";
import { AppButton } from "../app-button/app-button";
import { EditMood } from "./edit-mood";
import { ReadOnlyMood } from "./read-only-mood";
import { APPTEXTEDITIORINTERFACE } from "./app-text-editor-interfaces";

export const AppTextEditior: React.FC<APPTEXTEDITIORINTERFACE> = ({
  states,
  editHan,
  updateBtnClickHan,
  updateHand,
}) => {
  return (
    <Grid container direction="column">
      <Grid item container sx={{ marginBottom: "0.5em" }}>
        <Grid item sm={6}></Grid>
        <Grid item sm={6} container justifyContent="flex-end">
          <Grid item>
            {states.editOrUpdate ? (
              <AppButton
                btnText="Edit"
                className="btn-blue cursor-pointer tertiary-title add-item-btn"
                btnFunction={editHan}
              />
            ) : (
              <AppButton
                btnText="Update"
                className="btn-blue cursor-pointer tertiary-title add-item-btn"
                btnFunction={updateBtnClickHan}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {states.editOrUpdate ? (
          <ReadOnlyMood value={states.value} />
        ) : (
          <EditMood value={states.value} updateHand={updateHand} />
        )}
      </Grid>
    </Grid>
  );
};
