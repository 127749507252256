import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import EChartsReact from "echarts-for-react";
import { useState, useEffect } from "react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { axiosInstance } from "../../../../lib/axios";
import { getEstateAgentYearDashboardDataThunk } from "../../../../store/dashboard/estate-agent-dashboard/estate-agent-dashboard-thunk";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

export const BarChartForEstateAgent = () => {
  const { yearStatus, estateAgentEarning } = useAppSelector((state: any) => state?.estateAgentSlice)

  const date = new Date();
  const year = date.getFullYear();
  const [yearData, setYearData] = useState<any>(year);

  const handleChange = (event: SelectChangeEvent) => {
    setYearData(event.target.value as string);
  };

  const dispatch = useAppDispatch()
  useEffect(() => {

    dispatch(getEstateAgentYearDashboardDataThunk({ yearData, axiosInstance }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData])

  const eChartOption = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: "6%",
      right: "3%",
      top: "10%",
    },
    height: "80%",
    xAxis: {
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },

    series: [
      {
        data: Object.values(estateAgentEarning),
        type: "bar",
        color: "#366EC2",
        label: {
          show: true,
          position: "top",
        },
        showBackground: true,
      },
    ],
  };

  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 height-100">
      <Grid padding={2}>
        <div className="flex justify-space-between">
          <span className="font-weight-600 secondary-title"> Earnings </span>

          <div className="margin-bottom-1 flex">
            <span className="tertiary-title font-weight-600 primary-text-color margin-right-1 padding-top-0">
              Year:
            </span>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 70 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={yearData}
                onChange={handleChange}
                sx={{ height: 25, fontSize: 12 }}
              >
                <MenuItem value={year}>{year}</MenuItem>
                <MenuItem value={year - 1}>{year - 1}</MenuItem>
                <MenuItem value={year - 2}>{year - 2}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Grid paddingTop={1}  >
          {yearStatus === REQUESTSTATUS.PENDING &&
            <div className="flex align-center justify-center width-100 padding-2">
              <CircularProgress size={35} />
              <span className="primary-color font-weight-600 center-text secondary-title padding-left-0">Loading... </span>
            </div>}
          {yearStatus === REQUESTSTATUS.SUCCEEDED ?
            <EChartsReact option={eChartOption}  />
            :
            (yearStatus === REQUESTSTATUS.FAILED &&
              <div className="flex align-center justify-center width-100 padding-2">
                <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                  Server Not Responding
                </span>
              </div>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};
