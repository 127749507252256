import { useEffect } from "react";
import { Grid } from "@mui/material";

import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";
import { TopRatedSolictorCardAdmin } from "../../../features/solicitor/solictor-card/top-rated-solictor-card-admin";

import { TopSaleAgentCardAdmin } from "../../../features/sales-site/top-sale-agent-card/top-sale-agent-card-admin";
import { NotificationAndRemindersCard } from "../../../components/notification-and-reminders-card/notification-and-reminders-card";

import { LineAndBarChartForAdmin } from "./charts-for-admin/line-and-bar-chart-for-admin";
import { PieChartForAdmin } from "./charts-for-admin/pie-chart-for-admin";
import { DonutChartForAdmin } from "./charts-for-admin/donut-chart-for-admin";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getAdminDashboardDataThunk } from "../../../store/dashboard/admin-dashboard/admin-dashboard-thunk";
import { axiosInstance } from "../../../lib/axios";
import { AdminDashboardCardData } from "./admin-dashboard-data";
import { DipDetailsTablePiece } from "../../../features/tickets/tickets-list-admin-tabs/dip-details/dip-details";
import { TICKETSPATH, TICKETSLISTSPATH } from "../../../constants/routes-constant";
import { useNavigate } from "react-router-dom";

export const AdminDashboard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getAdminDashboardDataThunk({ axiosInstance }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const width = window.innerWidth;
  const { adminDashboardData } = useAppSelector((state: any) => state?.adminDashboardSlice)

  return (
    <div>
      <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"start"}>

        <Grid container justifyContent={"space-between"} alignItems={"center"} item xl={7.5}
          lg={11.9} xs={12} >

          <AdminDashboardCardData />

          <Grid container justifyContent={{ lg: "space-between", xs: "center" }}
            marginTop={2}>
            <Grid item xl={6.8} xs={12} className="bg-card-white"
              mb={{ xl: 0, xs: 2 }}>
              <LineAndBarChartForAdmin />
            </Grid>

            <Grid item xl={4.9} xs={12} className="bg-card-white" mb={{ xl: 0, xs: 2 }} >
              <DonutChartForAdmin cases={adminDashboardData?.cases} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"space-between"} xl={4.3} xs={12}
        >
          <Grid item xl={5.8} md={5.5} xs={12} className="bg-card-white"
            mb={{ xl: 0, xs: 2 }}>
            <PieChartForAdmin quoteStatus={adminDashboardData?.quoteManagementStatus} />
          </Grid>

          <Grid className="bg-card-white" item xl={5.7} md={6} xs={12}
            mb={{ xl: 0, xs: 2 }}>
            <TopRatedSolictorCardAdmin />
          </Grid>

        </Grid>
      </Grid>

      <Grid container marginTop={2} justifyContent={"space-between"}>
        <Grid item xl={7.5} xs={12} px={2} mb={{ xl: 0, xs: 2 }}
          className="bg-card-white overflow-hidden" sx={{ maxHeight: 390, maxWidth: width - 70 }} >
          <div className="flex justify-space-between padding-top-1 padding-bottom-1">
            <span className="secondary-title font-weight-600">Recent Tickets</span>
            <span className="primary-color tertiary-title font-weight-600 cursor-pointer"
              onClick={() => navigate(TICKETSPATH + '/' + TICKETSLISTSPATH)}
              style={{ borderBottom: "1px solid #366EC2" }}
            >
              View All
            </span>
          </div>
          <DipDetailsTablePiece dashboardView />
        </Grid>

        <Grid className="bg-card-white" item xl={2.1} lg={5.8}
          xs={12} mb={{ xl: 0, xs: 2 }} >
          <TopSaleAgentCardAdmin />
        </Grid>

        <Grid item xl={2} lg={5.7} xs={12} padding={2}
          className="bg-card-white  font-family-monterrat width-100">
          <span className="secondary-title font-weight-600 padding-bottom-0">
            Events & Notifications
          </span>
          <Grid container className="overflow-scroll-y" sx={{ maxHeight: 350 }}>
            <NotificationAndRemindersCard viewDetails={false} />
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};