import { createAsyncThunk } from "@reduxjs/toolkit";
import { RESUMEPOSTAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { CAREERSPATH, CONTACTUSPATH } from "../../constants/routes-constant";
import { postDataAPI } from "../../lib/axios";

// posting Enquiry
export const postApplyNowResponseThunk = createAsyncThunk(
  "apply-now",
  async (
    { axiosInstance, formData, openAlert, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        RESUMEPOSTAPI,
        axiosInstance,
        formData
      );
      openAlert(response.data.message, "alert-success-message");
      navigate(`/${CONTACTUSPATH}/${CAREERSPATH}`);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
