import {
  ClickAwayListener,
  Tooltip,
  Grid,
  Divider,
  Checkbox,
} from "@mui/material";

// Custom Hook
import { useNotificationCard } from "./use-notification-card";
//dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// MUI Styles
import { tooltipNotificationCardComponenetsProps } from "./notification-card-mui-style";

import { AppToolTip } from "../../../components/app-tooltip/app-tooltip";
import {
  MUIBORDERRADIUS10,
  MUISOLITUDECOLOR,
} from "../../../constants/mui-styles-constant";

import IconButton from "@mui/material/IconButton";
// mui icons
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import DraftsIcon from "@mui/icons-material/Drafts";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
// Component Function Starts Here

const NotificationCard: React.FC = () => {
  const {
    OPEN,
    openCard,
    handleClose,
    layout,
    readMoreHanlder,
    readLessHanlder,
    selectAllHandler,
    selectNotificationHandler,
    checkedNotifications,
    deleteSelectedNotificationsHandler,
    unreadNotifications,
    viewReadNotificationsHandler,
    viewUnreadNotificationsHandler,
    markAsReadHandler,
    dispatch,
    status,
    axiosInstance,
    markUserNotificationsReadAllThunk,
    markAsUnReadHandler,
    markUserNotificationsReadThunk,
  } = useNotificationCard();
  dayjs.extend(relativeTime);

  const NotoficationData = (
    <Grid
      className="bg-card-white"
      //  boxShadow={4}
      width={{ xs: "330px", sm: "350px" }}
      sx={{ marginLeft: { xs: "20px", sm: 0 } }}
    >
      <Grid
        overflow={layout?.notifications?.length > 10 ? "auto" : "hidden"}
        height={layout?.notifications?.length > 10 ? "300px" : "250px"}
        // overflow={scroll ? "auto" : "hidden"}
        // height={scroll ? "300px" : "220px"}
        p="7px"
      >
        {!!layout?.notifications?.length ? (
          layout?.notifications?.map((item: any) => (
            <Grid
              onClick={() =>
                !item?.readAt &&
                dispatch(
                  markUserNotificationsReadThunk({
                    axiosInstance,
                    selectedIds: { notificationIds: [item?._id] },
                    dispatch,
                  })
                )
              }
              className="cursor-pointer"
              key={item._id}
              sx={{
                "& :hover": {
                  bgcolor: MUISOLITUDECOLOR,
                  borderRadius: MUIBORDERRADIUS10,
                },
              }}
            >
              {(unreadNotifications || !item?.readAt) && (
                <Grid className={` ${!item?.readAt && "font-weight-700"} `}>
                  <Grid
                    className="flex justify-space-between align-center"
                    height="75px"
                    p="7px"
                  >
                    <Grid className=" flex flex-column " gap="5px">
                      <div
                        className={`orange-color tertiary-title ${
                          !item?.readAt ? "font-weight-700" : "font-weight-500"
                        }`}
                      >
                        {item?.title}
                      </div>
                      <div
                        className={` title-10 ${
                          !item?.readAt ? "primary-color" : "primary-text-color"
                        }`}
                      >
                        {item?.message?.slice(0, 50)}
                      </div>
                      <Grid
                        gap={1}
                        className=" flex align-center grey-color "
                        p={1}
                      >
                        {/* <img src={timeIcon} alt="time" /> */}
                        <div className=" title-10 flex align-center">
                          <AccessTimeIcon
                            className=" tertiary-title"
                            sx={{ mr: 1 }}
                          />
                          {dayjs(item?.createdAt).fromNow()}
                        </div>
                      </Grid>
                    </Grid>

                    <Checkbox
                      onChange={(e: any) =>
                        selectNotificationHandler(e.target.checked, item?._id)
                      }
                      checked={checkedNotifications.includes(item?._id)}
                      icon={<RadioButtonUncheckedIcon className="title-10" />}
                      checkedIcon={<CircleIcon className="title-10" />}
                      size="small"
                    />
                  </Grid>
                  <Divider />
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <Grid className="error-color flex align-center tertiary-heading justify-center height-inherit">
            No Notifications available
          </Grid>
        )}
      </Grid>
      {/* action buttons row */}
      <Grid
        className="flex justify-space-between tertiary-title align-center bg-secondary-color  border-radius-10"
        // gap={1}
        py="5px"
        mt={1}
        px="14px"
      >
        {status !== REQUESTSTATUS.PENDING ? (
          <>
            {layout?.notifications?.length <= 10 ? (
              <AppToolTip tooltipTitle="Show more">
                <IconButton onClick={readMoreHanlder}>
                  <UnfoldMoreIcon className="primary-color cursor-pointer" />
                </IconButton>
              </AppToolTip>
            ) : (
              <AppToolTip tooltipTitle="Show less">
                <IconButton onClick={readLessHanlder}>
                  <UnfoldLessIcon className="primary-color cursor-pointer" />
                </IconButton>
              </AppToolTip>
            )}
            <Grid width="40px">
              {unreadNotifications ? (
                <AppToolTip tooltipTitle="Show Unread">
                  <IconButton
                    onClick={viewUnreadNotificationsHandler}
                    className="primary-color cursor-pointer"
                  >
                    <MarkChatUnreadIcon />
                  </IconButton>
                </AppToolTip>
              ) : (
                <AppToolTip tooltipTitle="Show All">
                  <IconButton
                    onClick={viewReadNotificationsHandler}
                    className="primary-color cursor-pointer"
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </AppToolTip>
              )}
            </Grid>

            <Grid width="40px">
              <AppToolTip tooltipTitle="Mark All Read">
                <IconButton
                  onClick={() =>
                    dispatch(
                      markUserNotificationsReadAllThunk({
                        axiosInstance,
                        dispatch,
                      })
                    )
                  }
                  className="primary-color cursor-pointer"
                >
                  <DraftsIcon />
                </IconButton>
              </AppToolTip>
            </Grid>
            <Grid width="40px">
              {!!checkedNotifications.length && (
                <AppToolTip tooltipTitle="Mark as read">
                  <IconButton
                    onClick={markAsReadHandler}
                    className="primary-color cursor-pointer"
                  >
                    <DoneAllIcon />
                  </IconButton>
                </AppToolTip>
              )}
            </Grid>
            <Grid width="40px">
              {!!checkedNotifications.length && (
                <AppToolTip tooltipTitle="Mark as Unread">
                  <IconButton
                    onClick={markAsUnReadHandler}
                    className="primary-color cursor-pointer"
                  >
                    <RemoveDoneIcon />
                  </IconButton>
                </AppToolTip>
              )}
            </Grid>
            <Grid width="40px">
              {!!checkedNotifications.length && (
                <AppToolTip tooltipTitle="Delete">
                  <IconButton
                    onClick={deleteSelectedNotificationsHandler}
                    className="primary-color cursor-pointer"
                  >
                    <DeleteIcon />
                  </IconButton>
                </AppToolTip>
              )}
            </Grid>

            <AppToolTip tooltipTitle="Select All">
              <Checkbox
                checked={
                  checkedNotifications.length === layout?.notifications?.length
                }
                icon={<RadioButtonUncheckedIcon className="tertiary-title" />}
                checkedIcon={<CircleIcon className="tertiary-title" />}
                size="small"
                onChange={selectAllHandler}
              />
            </AppToolTip>
          </>
        ) : (
          <p className="center-text width-100">Loading . . .</p>
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Grid>
          <Tooltip
            arrow
            placement="bottom-end"
            componentsProps={tooltipNotificationCardComponenetsProps}
            PopperProps={{
              disablePortal: true,
            }}
            open={OPEN}
            title={NotoficationData}
          >
            <IconButton
              sx={{ mr: { xs: 0, sm: "1.6rem" } }}
              className="margin-left-0 position-relative bg-secondary-background-color mui-icon-button-container-hover"
              onClick={openCard}
            >
              <>
                <NotificationsIcon className="primary-color mui-icon-button-icon" />
                {!!layout?.allNotifications?.filter(
                  (i: any) => i.readAt === null
                )?.length && (
                  <FiberManualRecordIcon
                    className="black-color position-absolute tertiary-title orange-color fiber-icon"
                    style={{ top: "0%", right: "0%" }}
                  />
                )}
              </>
            </IconButton>
          </Tooltip>
        </Grid>
      </ClickAwayListener>
    </>
  );
};

export default NotificationCard;
