import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
dayjs.extend(customParseFormat);

export const getRemindersThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};
export const getRemindersThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.reminders = payload?.data;
    const ticketsData: any = payload?.tickets?.map((item: any) => ({
      id: item?._id,
      title: item?.clientName,
      start: dayjs(item?.date, ["DD/MM/YYYY", "YYYY-MM-DD"]).toISOString(),
      allDay: true,
      time: item?.time
    }))

    state.tickets = ticketsData;
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getRemindersThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};



export const addRemindersThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};
export const addRemindersThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};
export const addRemindersThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const editRemindersThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};
export const editRemindersThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};
export const editRemindersThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const deleteRemindersThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};
export const deleteRemindersThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};
export const deleteRemindersThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};