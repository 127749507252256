import { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { SellerDropDown } from "./dropdowns/seller-dropdown";
import { BuyerDropDown } from "./dropdowns/buyer-dropdown";
import { hideBreadcrumb, showBreadcrumb } from "../../../store/breadcrumb/breadcrumb-slice";
import { breadcrumbCaseId } from "./breadcrumbs";
import { useAppDispatch } from "../../../store/store";

const renderDropDown = (type: string) => {
  switch (type) {
    case "seller":
      return <SellerDropDown />;
    case "buyer":
      return <BuyerDropDown />;
    default:
      return null;
  }
};

export const AddContractSolicitor = () => {
  const [option, setOptions] = useState("");
  const dispatch = useAppDispatch();
  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions((event.target as HTMLInputElement).value);
  };
  const { caseId } = useParams();
  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbCaseId(caseId)));
    return () => {
      dispatch(hideBreadcrumb());
    };
  }, []);
  return (
    <>
      <div className="bg-card-white">
        <div className="padding-top-2 padding-left-3 padding-right-3 padding-bottom-2">
          <div className="primary-text-color secondary-heading font-weight-600">
            Add Contracts
          </div>
          {/* Radio Group  */}
          <div>
            <div className="primary-text-color font-weight-500 ">
              Contract Type
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={option}
                name="radio-buttons-group"
                onChange={handleChangeOption}
              >
                <FormControlLabel
                  value="seller"
                  control={<Radio />}
                  label="Seller Contracts"
                />
                <FormControlLabel
                  value="buyer"
                  control={<Radio />}
                  label="Buyer Contracts"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {renderDropDown(option)}
        </div>
      </div>
    </>
  );
};
