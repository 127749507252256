import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const addApplyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.applyNowStatus = REQUESTSTATUS.FAILED;
  });
};

export const addApplyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.applyNowStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addApplyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.applyNowStatus = REQUESTSTATUS.PENDING;
  });
};
