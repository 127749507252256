import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { axiosInstance } from "../../../../lib/axios";
import { getSolicitorYearDashboardDataThunk } from "../../../../store/dashboard/solicitor-dashboard/solicitor-dashboard-thunk-action";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
export const BarChartForSolicitor = () => {
  const { yearStatus, solicitorYearData } = useAppSelector(
    (state: any) => state.solicitorDashboardSlice
  );

  const date = new Date();
  const year = date.getFullYear();
  const [yearData, setYearData] = useState<any>(year);

  const handleChange = (event: SelectChangeEvent) => {
    setYearData(event.target.value as string);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSolicitorYearDashboardDataThunk({ yearData, axiosInstance }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData]);

  const eChartOption = {
    tooltip: {
      trigger: "item",
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      // type: "",
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },
    grid: {
      left: "10%",
      right: "7%",
      top: "10%",
    },
    height: '75%',
    color: ["#55AFD5"],
    series: [
      {
        data: Object.values(solicitorYearData || {}),
        type: "bar",
        label: {
          show: true,
          position: "top",
        },
      },
    ],
  };

  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100">
      <Grid padding={2} container className="flex justify-space-between">
        <span className="font-weight-600 secondary-title">
          {" "}
          Completed Cases{" "}
        </span>
        <div className="margin-bottom-0 flex">
          <span className="tertiary-title font-weight-600 primary-text-color margin-right-1 padding-top-0">
            Year:
          </span>
          <FormControl fullWidth variant="standard" sx={{ minWidth: 70 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={yearData}
              onChange={handleChange}
              sx={{ height: 25, fontSize: 12 }}
            >
              <MenuItem value={year}>{year}</MenuItem>
              <MenuItem value={year - 1}>{year - 1}</MenuItem>
              <MenuItem value={year - 2}>{year - 2}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <div
        className={`${yearStatus === REQUESTSTATUS.SUCCEEDED && "overflow-scroll-x"
          } `}
      >
        {yearStatus === REQUESTSTATUS.PENDING && (
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
              Loading...{" "}
            </span>
          </div>
        )}
        {yearStatus === REQUESTSTATUS.SUCCEEDED ? (
          <EChartsReact option={eChartOption} />
        ) : (
          yearStatus === REQUESTSTATUS.FAILED && (
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
