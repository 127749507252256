import { Grid } from "@mui/material";
import { FilterIcon } from "../../../utils/svg-icon-utils";
import { AppInputField } from "../../../components/app-input-field/app-input-field";
import ModalLayout from "../../../components/modal-layout/modal-layout";
import { UpdateStatusArray } from "./update-status-modal-data";
import { useState } from "react";
import { AppButton } from "../../../components/app-button/app-button";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { patchCasesThunk } from "../../../store/cases/cases-thunk-actions";
import { useAppDispatch } from "../../../store/store";
import { useAppAlert } from "../../../components/app-alert/use-app-alert";
import { axiosInstance } from "../../../lib/axios";

export const UpdateStatusModal = ({ open, setOpen, modalData }: any) => {
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const { openAlert } = useAppAlert();
  const dispatch = useAppDispatch();

  const UpdateStatusHandler = () => {
    const formData = { status, reason };
    dispatch(
      patchCasesThunk({
        axiosInstance,
        openAlert,
        dispatch,
        caseId: modalData?.id,
        formData,
      })
    );
    setOpen(false);
    setStatus("");
  };
  return (
    <>
      {open && (
        <ModalLayout
          isModalOpen={open}
          setIsModalOpen={setOpen}
          leftLg={4}
          centerLg={4}
          rightLg={4}
        >
          <Grid container px={2}>
            <div className="font-weight-600 primary-color primary-heading">
              Status Update
            </div>
            <Grid item xs={12} pt={2}>
              <AppInputField
                label="Status"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                select={true}
                selectMenudata={UpdateStatusArray}
                value={status || modalData?.prevValue}
                onChange={(event: any) => {
                  setStatus(event.target.value as string);
                }}
                iconComponent={FilterIcon}
              />
            </Grid>
            <Grid item xs={12} pt={2}>
              <AppInputField
                label="Reason"
                labelClassName="primary-text-color font-weight-500"
                required
                multiline
                minRows={5}
                maxRows={5}
                onChange={(event: any) => {
                  setReason(event.target.value as string);
                }}
              />
            </Grid>
            <Grid item xs={12} pt={2} className="flex justify-end">
              <AppButton
                btnText={"Update Status"}
                className="btn-blue bg-grey-color border-none padding-left-1 padding-right-1 text-transform-capitalize"
                btnIconRight={<CheckCircleRoundedIcon sx={{ pl: 1 }} />}
                btnFunction={UpdateStatusHandler}
              />
            </Grid>
          </Grid>
        </ModalLayout>
      )}
    </>
  );
};
