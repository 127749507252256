import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getSolicitorDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any) => {
        state.solicitorStatus = REQUESTSTATUS.FAILED;
    });
};


export const getSolicitorDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.solicitorStatus = REQUESTSTATUS.PENDING;
    });
};

export const getSolicitorDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.solicitorStatus = REQUESTSTATUS.SUCCEEDED;
        state.solicitorData = payload;
    });
};




export const getSolicitorYearThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any) => {
        state.yearStatus = REQUESTSTATUS.FAILED;
    });
};


export const getSolicitorYearThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any) => {
        state.yearStatus = REQUESTSTATUS.PENDING;
    });
};

export const getSolicitorYearThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.yearStatus = REQUESTSTATUS.SUCCEEDED;
        state.solicitorYearData = payload?.completedCases

    });
};