import { createSlice } from "@reduxjs/toolkit";
import { getLatestNotificationThunkFulFilledReducer, getLatestNotificationThunkPendingReducer, getLatestNotificationThunkRejectedReducer } from "./notification-reducers";
import { getLatestNotificationThunk } from "./notification-thunk-actions";

const latestNotificationIntialState = {
    status: '',
    lastestNotificationData: [] as any
}

export const notificationSlice = createSlice({
    name: "latestNotification",
    initialState: latestNotificationIntialState,
    reducers: {},
    extraReducers(builder) {
        getLatestNotificationThunkRejectedReducer(builder, getLatestNotificationThunk)
        getLatestNotificationThunkPendingReducer(builder, getLatestNotificationThunk)
        getLatestNotificationThunkFulFilledReducer(builder, getLatestNotificationThunk)
    },
})
export const {} = notificationSlice.actions;

export default notificationSlice.reducer;