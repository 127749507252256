import { ClickAwayListener, Grid } from "@mui/material";
import { useState } from "react";
import { axiosInstance } from "../../../lib/axios";
import { patchCompleteThunk } from "../../../store/tickets/tickets-thunk-actions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppButton } from "../../../components/app-button/app-button";

export const StatusActionButton = ({
  status,
  text,
  idCompleted,
  openAlert,
  dispatch,
  ticketTypeProp,
}: any) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState(text);

  const statusChangeHandler = () => {
    setValue("Completed");
    dispatch(
      patchCompleteThunk({
        axiosInstance,
        ticketId: idCompleted,
        openAlert,
        dispatch,
        ticketType: ticketTypeProp,
      })
    );
    setOpenDrawer(false);
  };
  return (
    <ClickAwayListener onClickAway={() => setOpenDrawer(false)}>
      <Grid
        py={status !== "Unassigned" && text !== "Completed" ? 0.6 : 1}
        width="120px"
        className={`position-relative tertiary-title cursor-pointer flex align-center justify-center
                  bg-tertiary-color border-radius-pill ${
                    value === "Completed" ? "success" : "orange"
                  }-color`}
        onClick={() =>
          setOpenDrawer(
            status !== "Unassigned" && text !== "Completed" && !openDrawer
          )
        }
      >
        {value}
        {status !== "Unassigned" && text !== "Completed" && (
          <>
            {openDrawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </>
        )}
        {openDrawer && (
          <Grid
            width="120px"
            zIndex={999}
            bottom="100%"
            p={1}
            className="position-absolute bg-card-white"
          >
            <AppButton
              disabled={value === "Completed"}
              btnFunction={statusChangeHandler}
              btnText="Completed"
              className="bg-tertiary-color border-radius-pill text-transform-capitalize tertiary-title success-color padding-left-1 padding-right-1"
            />
          </Grid>
        )}
      </Grid>
    </ClickAwayListener>
  );
};
