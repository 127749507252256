import { Avatar, Grid, Rating, CircularProgress } from "@mui/material";
// import userIcon from "../../../assets/images/solictor/user-icon.png";
// import emailIcon from "../../../assets/images/solictor/email-icon.png";
// import phoneIcon from "../../../assets/images/solictor/phone-icon.png";
import { useAppSelector } from "../../../store/store";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";
export const TopRatedSolictorCardAdmin = () => {

  const { status, adminDashboardData } = useAppSelector((state: any) => state?.adminDashboardSlice);

  return (
    <Grid className="bg-white-color border-radius-16 font-family-monterrat width-100 "
      container padding={2}>
      <div className=" flex justify-space-between width-100 margin-bottom-0">
        <span className="font-weight-600 primary-title">
          Top Rated Solicitors
        </span>
        {/* <span className="font-weight-500 tertiary-title "
          style={{ borderBottom: "1px solid #525252" }}>
          See all{" "}
        </span> */}
      </div>
      <Grid className="overflow-scroll-y" container direction={"row"} alignItems={'start'}
        sx={{ maxHeight: 380, minHeight: 385 }}
      >
        {status === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
          </div>
        }

        {status === REQUESTSTATUS.SUCCEEDED ?
          adminDashboardData?.topRatedSolicitors?.length ?
            adminDashboardData?.topRatedSolicitors?.map((ele: any) => (
              <Grid className="flex width-100" sx={{ borderBottom: "1px solid #DDDDDD" }}
                key={ele?._id} marginY={1}>
                <div className="margin-right-0" >
                  <Avatar src={`${IMAGEBASEURL}${ele?.profileImage}`} sx={{ height: 38, width: 38 }} />
                </div>

                <Grid item xs={7.5} container direction={"column"} alignItems={'start'}>
                  <span className="font-weight-600 tertiary-title">
                    {ele?.firstName}&nbsp;{ele?.lastName}
                  </span>

                  <span className="title-10">{ele?.defaultRole}</span>

                  <Rating name="half-rating-read" defaultValue={+ele?.avgRating} precision={0.5}
                    readOnly
                    sx={{ fontSize: 12, pb: 0.5, pt: 0.3 }} />
                </Grid>

                {/* <Grid item xs={3.4} container direction="row" alignItems={"center"} >
                  <Avatar src={userIcon} sx={{ height: 19, width: 19 }} />
                  <Avatar src={emailIcon} sx={{ height: 19, width: 19 }} />
                  <Avatar src={phoneIcon} sx={{ height: 19, width: 19 }} />
                </Grid> */}
              </Grid>
            )) : (<div className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">Data Not Found</div>)
          :
          (status === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        }
      </Grid>
    </Grid>
  );
};