import { createAsyncThunk } from "@reduxjs/toolkit";
import { ENQUIRIESGETAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { HOMEPATH } from "../../constants/routes-constant";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";
import axios from "axios";

// Enquiries List Page Get
export const getEnquiriesListThunk = createAsyncThunk(
  "enquiries/get-list",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(ENQUIRIESGETAPI, axiosInstance, params);
      const responseData = {
        users: response?.data?.data?.enquiries,
        meta: response?.data?.data?.meta,
      };

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Enquiries Response Modal Patch
export const patchEnquiriesResponseThunk = createAsyncThunk(
  "enquiries-response/get-list",
  async (
    { axiosInstance, params, dispatch, enquiryId }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${ENQUIRIESGETAPI}/${enquiryId}`,
        axiosInstance,
        params
      );
      dispatch(getEnquiriesListThunk({ axiosInstance }));
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// posting Enquiry
export const postEnquiriesResponseThunk = createAsyncThunk(
  "post-enquiry",
  async (
    { axiosInstance, formData, openAlert, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        ENQUIRIESGETAPI,
        axiosInstance,
        formData
      );
      openAlert(response.data.message, "alert-success-message");
      setTimeout(() => navigate(HOMEPATH), 3005);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

// Post Enquiries AAC with a new base API URL
export const postEnquiriesAACResponse = createAsyncThunk(
  "post-enquiry-aac",
  async ({ formData, openAlert, navigate }: any, { rejectWithValue }) => {
    try {
      const enquiriesAxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_AAC_BASE_URL,

        headers: {
          "x-api-key":
            process.env.REACT_APP_ENQUIRIES_API_KEY || "AAC_API_KEY_NOT_FOUND",
        },
      });

      const response = await enquiriesAxiosInstance.post(
        ENQUIRIESGETAPI,
        formData
      );

      openAlert(
        "Enquiry Sent Successfully! Our Team will Reach You Out Shortly!",
        "alert-success-message"
      );
      setTimeout(() => navigate(HOMEPATH), 3005);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
