import { REQUESTSTATUS } from "../../constants/axios-api-constant";

// Enquiries List Page Get
export const getEnquiriesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.FAILED;
  });
};

export const getEnquiriesFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.enquiriesListData = payload;
    state.enquiriesStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getEnquiriesPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.PENDING;
  });
};

// Enquiries Message Modal Patch
export const patchEnquiriesResponseRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchEnquiriesResponseFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchEnquiriesResponsePendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.PENDING;
  });
};
// Enquiries Post
export const postEnquiriesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.FAILED;
  });
};

export const postEnquiriesFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postEnquiriesPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.enquiriesStatus = REQUESTSTATUS.PENDING;
  });
};
