import ModalLayout from "../../../../components/modal-layout/modal-layout";
import { Reactpdf } from "../../../../components/react-pdf/react-pdf";

export const ViewCertificate = (props: any) => {
  const { addcertificate, setViewCretificate } = props;

  return (
    <>
      <ModalLayout
        isModalOpen={!!addcertificate}
        setIsModalOpen={setViewCretificate}
        leftMd={4}
        centerMd={4}
        rightMd={4}
        leftLg={4}
        centerLg={4}
        rightLg={4}
      >
        <Reactpdf
          file={`${process.env.REACT_APP_BASE_IMAGE_URL}${addcertificate}`}
          height="700px"
          overflowNone={true}
        />
      </ModalLayout>
    </>
  );
};
