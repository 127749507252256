import assignTicket from "../../../assets/images/app-short-summary-cards/assign-ticket.png";
import unAssignTicket from "../../../assets/images/app-short-summary-cards/unassign-tickets.png";
import pendingCalls from "../../../assets/images/app-short-summary-cards/pending-calls.png";

import { Grid } from "@mui/material";
import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";
import { useAppSelector } from "../../../store/store";

export const SalesAgentCardData = () => {
  const { salesAgentData } = useAppSelector((state: any) => state?.salesAgentSlice)


  const saleAgentCardData: any = [
    {
      id: 1,
      bgColor: "#48995D",
      value: salesAgentData?.assignedTicketsThisWeek,
      value2: salesAgentData?.assignedTicketsThisMonth,
      title: "Assigned Tickets",
      imgSrc: assignTicket,
      thisWeekMonthToggler: true,
    },
    {
      id: 2,
      bgColor: "#FF3F3F",
      value: salesAgentData?.unassignedTicketsThisWeek,
      value2: salesAgentData?.unassignedTicketsThisMonth,
      title: "Unassigned Tickets",
      imgSrc: unAssignTicket,
      thisWeekMonthToggler: true,
    },
    {
      id: 3,
      bgColor: "#FA7901",
      value: salesAgentData?.pendingCalls,
      value2: "0",
      title: "Pending Calls",
      imgSrc: pendingCalls,
      thisWeekMonthToggler: false,
    },
  ];
  return (
    <>
      {saleAgentCardData?.map((ele: any) => (
        <Grid item xl={3.8} lg={5.9} sm={5.9} md={11.9} xs={11.9}
          marginBottom={{ xl: 0, xs: 2 }} key={ele?.id}
        >
          <ShortSummaryCards
            bgColor={ele?.bgColor}
            value={ele?.value || 0}
            title={ele?.title}
            imgSrc={ele?.imgSrc}
            thisWeekMonthToggler={ele?.thisWeekMonthToggler}
            value2={ele?.value2 || 0}
            firstLabel={"Current Week"}
            secondLabel={"Current Month"}
          />
        </Grid>
      ))}
    </>
  )
}