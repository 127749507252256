import { createSlice } from "@reduxjs/toolkit";
import {
  getReviewsDataListRejectReducer,
  getReviewsListFulFilledReducer,
  getReviewsListPendingReducer,
  postReviewPendingReducer,
  postReviewFulfilledReducer,
  postReviewRejectedReducer,
} from "./reviews-reducers";
import { getReviewsDataList, postReviewThunk } from "./reviews-thunk-action";

const reviewsAllDataInitialState = {
  reviewsList: [] as any,
  reviewsListStatus: "",
};

export const reviewsSlice = createSlice({
  name: "reviews",
  initialState: reviewsAllDataInitialState,
  reducers: {},

  extraReducers(builder) {
    getReviewsListPendingReducer(builder, getReviewsDataList);
    getReviewsListFulFilledReducer(builder, getReviewsDataList);
    getReviewsDataListRejectReducer(builder, getReviewsDataList);

    postReviewPendingReducer(builder, postReviewThunk);
    postReviewFulfilledReducer(builder, postReviewThunk);
    postReviewRejectedReducer(builder, postReviewThunk);
  },
});
export const {} = reviewsSlice.actions;

export default reviewsSlice.reducer;
