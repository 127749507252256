import { createAsyncThunk } from "@reduxjs/toolkit";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { DAYPLANNERPATH } from "../../constants/routes-constant";
import {
  postDataAPI,
  getDataAPI,
  patchDataAPI,
  deleteDataAPI,
} from "../../lib/axios";

export const getRemindersThunk = createAsyncThunk(
  "day-planner/get-reminders",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(DAYPLANNERPATH, axiosInstance);

      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);



export const addRemindersThunk = createAsyncThunk(
  "day-planner/add-reminders",
  async (
    { axiosInstance, openAlert, dispatch, newReminder }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        DAYPLANNERPATH,
        axiosInstance,
        newReminder
      );

      const responseData = {
        message: response?.data?.message,
      };
      dispatch(getRemindersThunk({ axiosInstance }));
      openAlert(responseData?.message, "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      openAlert(errorData?.error);
      return rejectWithValue(errorData);
    }
  }
);

export const editRemindersThunk = createAsyncThunk(
  "day-planner/edit-reminders",
  async (
    { axiosInstance, openAlert, reminderId, dispatch, newReminder }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${DAYPLANNERPATH}/${reminderId}`,
        axiosInstance,
        newReminder
      );
      dispatch(getRemindersThunk({ axiosInstance }));
      const responseData = {
        message: response?.data?.message,
      };

      openAlert(responseData?.message, "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      openAlert(errorData?.error, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const deleteRemindersThunk = createAsyncThunk(
  "day-planner/delete-reminders",
  async (
    { axiosInstance, openAlert, reminderId, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(
        `${DAYPLANNERPATH}/${reminderId}`,
        axiosInstance
      );

      const responseData = {
        message: response?.data?.message,
      };
      dispatch(getRemindersThunk({ axiosInstance }));

      openAlert("Reminder Deleted Successfully", "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      openAlert(
        "Cannot delete at the moment. Please try again later!",
        "alert-error-message"
      );
      return rejectWithValue(errorData);
    }
  }
);
