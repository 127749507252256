import { CircularProgress, Grid, Rating } from "@mui/material";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { dayjsFormatDate } from "../../../lib/dayjs";
import { useAppSelector } from "../../../store/store";
import "./assigned-solicitor-card-client.scss";
export const AssignedSolictorCard = () => {
  const { status, myCase } = useAppSelector(
    (state: any) => state?.clientDashboardSlice
  );

  return (
    <>
      <div className="bg-white-color border-radius-16  width-100 assigned-solictor-card">
        <Grid container padding={2}>
          <span className="font-weight-600 secondary-title">
            Assigned Solicitor{" "}
          </span>
          {status === REQUESTSTATUS.PENDING && (
            <div className="flex align-center justify-center width-100 padding-2">
              <CircularProgress size={35} />
              <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
                Loading...{" "}
              </span>
            </div>
          )}
          {status === REQUESTSTATUS.SUCCEEDED ? (
            <>
              <Grid
                container
                paddingTop={2.5}
                item
                xs={12}
                justifyContent={"space-between"}
              >
                <div className="flex flex-column">
                  <div className="padding-top-2">
                    <span className="secondary-title padding-left-0">
                      Rating
                    </span>
                  </div>
                  <Rating
                    className=" warning-color padding-top-0 "
                    name="half-rating-read"
                    value={myCase?.rating?.avgRating}
                    precision={0.5}
                    readOnly
                    sx={{ fontSize: "32px" }}
                  />
                </div>
                <div className="position-relative">
                  <span className="position-absolute rating-inside-circle font-weight-600 primary-text-color">
                    {myCase?.rating?.avgRating?.toFixed(1) || "0.0"}
                  </span>
                  <div className="position-absolute border-radius-rounded circular-empty-div"></div>

                  <CircularProgress
                    className="success-color"
                    variant="determinate"
                    size={110}
                    value={myCase?.rating?.avgRating * 20 || 0}
                  />
                </div>
              </Grid>

              <Grid item xs={12} paddingTop={1.5} container direction="column">
                <div className="flex justify-space-between solictor-card-data ">
                  <span className="secondary-title light-grey-color">
                    Case ID
                  </span>
                  <span className="secondary-title font-weight-600">
                    {myCase?.caseNo?.toString()?.slice(10)}...
                  </span>{" "}
                </div>
                <div className="flex justify-space-between solictor-card-data ">
                  <span className="secondary-title light-grey-color">
                    Solicitor Name
                  </span>
                  <span className="secondary-title font-weight-600">
                    {myCase?.solicitorName}
                  </span>
                </div>
                <div className="flex justify-space-between solictor-card-data ">
                  <span className="secondary-title light-grey-color">
                    Created Date
                  </span>
                  <span className="secondary-title font-weight-600">
                    {myCase?.createdAt
                      ? dayjsFormatDate(myCase?.createdAt, "DD/MM/YYYY")
                      : "--"}
                  </span>
                </div>
                <div className="flex justify-space-between solictor-card-data ">
                  <span className="secondary-title light-grey-color">
                    Case status
                  </span>
                  <span className="secondary-title font-weight-600">
                    {myCase?.status.toLowerCase() === "fell through"
                      ? "Completed"
                      : myCase?.status}
                  </span>
                </div>
              </Grid>
            </>
          ) : (
            status === REQUESTSTATUS.FAILED && (
              <div className="flex align-center justify-center width-100 padding-2">
                <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                  Server Not Responding
                </span>
              </div>
            )
          )}
        </Grid>
      </div>
    </>
  );
};
