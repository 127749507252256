import { useRef, useState } from "react";
import { Box } from "@mui/system";
import { UPLOADINTERFACE } from "../OPTIONINTERFACE";

export const Upload: React.FC<UPLOADINTERFACE> = ({ value: imageUrl, valChangeHand, fileChanheHand }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const selectFile = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      fileChanheHand(event.target.files[0]);
      const imageUrl = URL.createObjectURL(event.target.files[0]);
      valChangeHand(imageUrl);
    }
  };
  return (
    <>
      <div className="primary-text-color font-weight-500 margin-bottom-1">
        Upload Your Signature here
      </div>
      {/*  Upload Here  */}
      {!imageUrl && (
        <Box
          onClick={selectFile}
          style={{
            height: "200px",
            border: "1px solid black",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>Select File</span>
          <input
            onChange={onFileChange}
            type="file"
            style={{ display: "none" }}
            ref={fileRef}
            accept="image/*"
          />
        </Box>
      )}

      {imageUrl && (
        <div
          onClick={selectFile}
          style={{
            height: "200px",
            border: "1px solid black",
            background: `url(${imageUrl}) no-repeat`,
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        ></div>
      )}
    </>
  );
};
