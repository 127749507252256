import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERS, ADMINUSERS } from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

export const createNewUserThunk = createAsyncThunk(
  "create-new-user",
  async (
    { axiosInstance, newUser, openAlert, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(USERS, axiosInstance, newUser);
      dispatch(getAdminUsersThunk({ axiosInstance }));

      openAlert(response.data.message, "alert-success-message");

      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(error.response.data.message, "alert-error-message");

      rejectWithValue(errorData);
    }
  }
);

export const getUsersThunk = createAsyncThunk(
  "get-users-by-role",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(USERS, axiosInstance, params);

      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      rejectWithValue(errorData);
    }
  }
);

export const getAdminUsersThunk = createAsyncThunk(
  "get-admin-users",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(ADMINUSERS, axiosInstance, params);

      return response.data.data;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      rejectWithValue(errorData);
    }
  }
);

// // working and integrated
export const changeUserStatusThunk = createAsyncThunk(
  "change-user-status",
  async (
    {
      axiosInstance,
      userId,
      paramsForGettingUsers,
      dispatch,
      openAlert,
      param,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        USERS + `/${userId}/change-status`,
        axiosInstance,
        param
      );
      dispatch(getUsersThunk({ axiosInstance, params: paramsForGettingUsers }));

      openAlert(response.data.message, "alert-success-message");
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, "alert-error-message");
      rejectWithValue(errorData);
    }
  }
);

// // issue from BE needs to be resolved for it to work
export const editUserThunk = createAsyncThunk(
  "edit-user",
  async (
    { axiosInstance, userId, dispatch, editedUser, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        USERS + `/${userId}`,
        axiosInstance,
        editedUser
      );
      openAlert(response.data.message, "alert-success-message");
      dispatch(getAdminUsersThunk({ axiosInstance }));
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error.response.data.message,
      };
      openAlert(errorData.error, "alert-error-message");
      rejectWithValue(errorData);
    }
  }
);
