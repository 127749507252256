import { useNavigate } from "react-router-dom";
import { Avatar, CircularProgress, Grid } from "@mui/material";
import { AppCrispInfoCard } from "../../../components/app-crisp-info-card/app-crisp-info-card";
import "./estate-agent-dashboard.scss"
import Delete from "../../../assets/svg/delegates/delete.svg";
import Total from "../../../assets/svg/delegates/total.svg";
import Verified from "../../../assets/svg/delegates/verified.svg";
import { useEffect, useState } from "react";
import { LineChartForEstateAgent } from "./chart-for-estate-agent/line-chart-for-estate-agent";
import { BarChartForEstateAgent } from "./chart-for-estate-agent/bar-chart-for-estate-agent";
import { PieChartForEstateAgent } from "./chart-for-estate-agent/pie-chart-for-estate-agent";
import { DELEGATESPATH } from "../../../constants/routes-constant";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getEstateAgentDashboardDataThunk } from "../../../store/dashboard/estate-agent-dashboard/estate-agent-dashboard-thunk";
import { axiosInstance } from "../../../lib/axios";
import { showInfo } from "../../../store/layout/layout-slice";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const EstateAgentDashboard = () => {
  const navigate = useNavigate();
  const [toggleChart, setToggleChart] = useState<boolean>(false);
  const { estateAgentData, status } = useAppSelector((state: any) => state?.estateAgentSlice)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getEstateAgentDashboardDataThunk({ axiosInstance }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  setTimeout(() => {
    dispatch(
      showInfo({
        info1: estateAgentData?.totalEarned ?? 0o0,
        info2: estateAgentData?.estimatedEarning ?? 0o0,
        infoabout1: "Total Amount",
        infoabout2: "Pending Amount",
      })
    );
  }, 200)


  return (
    <div>
      <Grid container>
        <Grid container spacing={2} className="margin-bottom-2">
          <Grid item xs={12} md={5.9} lg={3.5} xl={3}>
            <AppCrispInfoCard
              info={estateAgentData?.inProgress ?? '\u00a0\u00a0'}
              infoAbout="Total Members"
              imgSrc={Total}
            />
          </Grid>
          <Grid item xs={12} md={5.9} lg={3.5} xl={3}>
            <AppCrispInfoCard
              info={estateAgentData?.completed ?? '\u00a0\u00a0'}
              infoAbout="Completed Applications"
              imgSrc={Verified}
            />
          </Grid>
          <Grid item xs={12} md={5.9} lg={3.5} xl={3}>
            <AppCrispInfoCard
              info={estateAgentData?.total ?? '\u00a0\u00a0'}
              infoAbout="In-progress Applications"
              imgSrc={Delete}
            />
          </Grid>
          <Grid item xs={12} md={3.2} lg={3} container
            direction={"column"} alignItems={"end"} justifyContent={"end"}>
            <span className="font-weight-600 tertiary-title  padding-top-0 padding-right-0 primary-text-color">
              Delegate Reference Number:
            </span>
            <span className="primary-color font-weight-600 primary-heading padding-top-0 padding-right-0">
              {estateAgentData?.drn || ''}
            </span>
          </Grid>
        </Grid>

        <Grid container item xs={12} py={2}
          px={2.2}
          className="bg-card-white border-radius-16 margin-bottom-2"
        >
          <Grid item xs={12} container pb={2} justifyContent={"space-between"}>
            <span className="font-weight-600 secondary-title">
              {" "}
              New Joinings
            </span>
            <span
              className="font-weight-600 primary-color tertiary-title cursor-pointer"
              onClick={() => navigate(DELEGATESPATH)}
            >
              {" "}
              View All
            </span>
          </Grid>

          <Grid container item xl={11}>
            {status === REQUESTSTATUS.PENDING &&
              <div className="flex align-center justify-center width-100 padding-2">
                <CircularProgress size={35} />
                <span className="primary-color font-weight-600  center-text secondary-title padding-left-0">Loading... </span>
              </div>
            }

            {status === REQUESTSTATUS.SUCCEEDED &&
              estateAgentData?.newJoining?.length ?
              estateAgentData?.newJoining?.map((ele: any) => (
                <Grid className=" border-radius-16 font-family-monterrat width-100 bg-tertiary-color"
                  container px={1.5} py={2} item lg={2.2} md={5.8} xs={12} mx={1}
                  mt={estateAgentData?.newJoining?.length === 6 ? 1.5 : 0} mb={{ lg: 0, xs: 2 }}
                  key={ele?._id}
                >
                  <div>
                    <Avatar
                      src={`${IMAGEBASEURL}${ele?.profileImage}`}
                      sx={{ height: 38, width: 38 }}
                    />
                  </div>
                  <div className="flex flex-column justify-space-around margin-left-0">
                    <span className="font-weight-500 primary-title">
                      {ele?.name || '--'}
                    </span>
                    <span className="tertiary-title font-weight-600">
                      {ele?.userType}
                    </span>
                  </div>
                </Grid>
              )) : status !== REQUESTSTATUS.PENDING ?
                <div className="flex align-center justify-center width-100 padding-2">
                  <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                    Data Not Found
                  </span>
                </div>
                : status === REQUESTSTATUS.FAILED &&
                <div className="flex align-center justify-center width-100 padding-2">
                  <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                    Server Not Responding
                  </span>
                </div>
            }
          </Grid>
        </Grid>

        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            lg={5.9}
            xs={12}
            mb={{ lg: 0, xs: 2 }}
            className="bg-card-white border-radius-16 position-relative"
          >
            <span className={`position-absolute text-earning tertiary-title font-weight-600 cursor-pointer
                ${!toggleChart ? "primary-color" : "light-grey-color"
              }`}
              onClick={() => setToggleChart(false)}
              style={{ left: "40%", top: 16 }}
            >
              Earnings
            </span>

            <span className={`position-absolute text-users tertiary-title font-weight-600 cursor-pointer 
              ${toggleChart ? "primary-color" : "light-grey-color"
              }`}
              onClick={() => setToggleChart(true)}
              style={{ left: "52%", top: 16, marginLeft: 10 }}
            >
              Users
            </span>

            {toggleChart ? (
              <LineChartForEstateAgent />
            ) : (
              <BarChartForEstateAgent />
            )}
          </Grid>

          <Grid item lg={5.9} xs={12} className="bg-card-white border-radius-16"
          >
            <PieChartForEstateAgent />
          </Grid>
        </Grid>
      </Grid>
    </div >
  );
};
