import { Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./custom-temp-file.scss";
import { READONLYINTERFACE } from "./app-text-editor-interfaces";

export const ReadOnlyMood: React.FC<READONLYINTERFACE> = ({ value }) => {
  return (
    <Grid container>
      <ReactQuill value={value} readOnly={true} theme="bubble" />
    </Grid>
  );
};
