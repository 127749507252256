import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getClientsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getClientsThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.clientsLists = payload;
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getClientsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};
