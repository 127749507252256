import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAlert } from "./components/app-alert/use-app-alert";
import { SIGNINPATH } from "./constants/routes-constant";
import { useAxiosInterceptors } from "./hooks/use-axios-interceptors";
// import { useNavigate } from "react-router-dom";

import Routing from "./routes/routes";
// import { setLoginSuccess } from "./store/auth/auth-slice";
// import { logoutThunk, refreshTokenThunk } from "./store/auth/auth-thunk-actions";
import { getReferenceLenderThunk, referenceDataThunk } from "./store/reference-data/reference-data-thunk-actions";
import { setLenderReferenceData, setReferenceData } from "./store/reference-data/reference-data-slice";
import { useAppDispatch } from "./store/store";
import { getDataFromLocalStorage } from "./utils/views-utils";
import { axiosRefresh } from "./lib/axios";
import { setLoginSuccess } from "./store/auth/auth-slice";
import {
  LOCALSTORAGEAUTHUSERINFO,
  LOCALSTORAGELENDERREFERENCEDATA,
  LOCALSTORAGEREFERENCEDATA,
  LOCALSTORAGEUSERLOGGEDINTIME,
} from "./constants/local-storage-constant";
import { myPersonalDetailsThunk } from "./store/personal-details/personal-details-thunk-actions";
import { setLenderReferenceDataReducer } from "./store/reference-data/reference-data-reducer";
// import { myUserProfileDataThunk } from "./store/personal-user/personal-user-thunk-actions";

const isAuth = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);

const data = {
  token: isAuth?.token,
  user: isAuth?.user,
  role: isAuth?.role,
};

const referenceData = getDataFromLocalStorage(LOCALSTORAGEREFERENCEDATA);
const lenderReferenceData = getDataFromLocalStorage(LOCALSTORAGELENDERREFERENCEDATA);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors();
  const { openAlert } = useAppAlert();

  useEffect(() => {
    if (!!isAuth?.token) {
      dispatch(setLoginSuccess(data));
    }
  }, []);

  useEffect(() => {
    if (!!isAuth?.token) {
      dispatch(myPersonalDetailsThunk({ axiosInstance, openAlert }));
    }
  }, []);

  useEffect(() => {
    if (!!referenceData) {
      dispatch(setReferenceData(referenceData));
      return;
    }
    dispatch(referenceDataThunk({ axiosInstance, openAlert }));
  }, []);

  useEffect(() => {
    if (!!lenderReferenceData) {
      dispatch(setLenderReferenceData(lenderReferenceData));
      return;
    }
    dispatch(getReferenceLenderThunk({ axiosInstance}));
  }, []);

  return <Routing />;
}

export default App;
