import { CircularProgress, Grid } from "@mui/material";
import "./style.scss";
import ReactECharts from "echarts-for-react";
import { useAppSelector } from "../../../../store/store";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";

export const PieChartForAdmin = (props: any) => {
  const { quoteStatus } = props
  const { status } = useAppSelector((state: any) => state?.adminDashboardSlice)
  const eChartOption = {
    tooltip: {
      trigger: "item",
    },
    color: [" #48995D", "#FFBF00"],
    legend: {
      showInLegend: true,
      orient: "horizontal",
      left: "center",
      top: "bottom",
      data: ["Done", "Pending "],
      icon: "circle",
      padding: 10,
    },
    series: [
      {
        type: "pie",
        radius: "84%",
        avoidLabelOverlap: false,
        center: ["50%", "45%"],
        data: [
          { value: quoteStatus?.done || 0, name: "Done" },
          { value: quoteStatus?.pending || 0, name: "Pending " },
        ],
        itemStyle: {
          normal: {
            label: {
              fontSize: 17,
              fontWeight: 600,
              color: "#ffff",
              show: true,
              position: "inner",
              formatter: function (params: { value: string }) {
                return params.value + "%\n";
              },
            },
            labelLine: {
              show: false,
            },
            borderWidth: 3,
            borderColor: "#fff",
          },
        },
        label: {
          show: false,
        },
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 height-100">
      <Grid padding={2} className="height-100">
        <div className="flex justify-space-between margin-bottom-1">
          <span className="font-weight-600 secondary-title">
            {" "}
            Quote Management Status{" "}
          </span>
        </div>
        {status === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 width-100 center-text secondary-title padding-left-0">Loading... </span>
          </div>}

        {status === REQUESTSTATUS.SUCCEEDED ?
          < ReactECharts option={eChartOption} className="pie-chart-div" />
          :
          (status === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        }
      </Grid>
    </div>
  );
};
