export const REQUESTSTATUS = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const BASEURLBACKEND = `${process.env.REACT_APP_BASE_URL_BACKEND}`;
export const IMAGEBASEURL = `${process.env.REACT_APP_BASE_IMAGE_URL}`;

export const SIGNUPAPI = "auth/signup";
export const LOGINAPI = "auth/signin";
export const LOGOUTAPI = "auth/signout";
export const VERIFYEMAILAPI = "auth/verification-status";
export const VERIFYTOKENAPI = "auth/verify-token";
export const RESENDVERIFICATIONREQUESTAPI = "auth/resend-link";
export const REFRESHTOKENAPI = "auth/refresh-token";
export const FORGOTPASSWORDAPI = "auth/forgot-password";
export const RESETPASSWORDAPI = "auth/confirm-forgot-password";
export const CHANGEPASSWORDAPI = "auth/change-password";
export const CHANGEEMAILAPI = "auth/change-email";
export const VERIFYCHANGEEMAILAPI = "auth/verify-change-email";

export const REFERENCEDATAAPI = "reference-data";

export const USERNOTIFICATIONSAPI = "user-notifications";
export const USERNOTIFICATIONSLATESTAPI = "user-notifications/latest";
export const USERNOTIFICATIONSREADAPI = "user-notifications/read";
export const USERNOTIFICATIONSREADALLAPI = "user-notifications/read/all";
export const USERNOTIFICATIONSUNREADAPI = "user-notifications/unread";
export const USERNOTIFICATIONSUNREADALLAPI = "user-notifications/unread-all";
export const USERNOTIFICATIONSDELETEAPI = "user-notifications/delete";
export const USERNOTIFICATIONSDELETEALLAPI = "user-notifications/delete-all";
export const USERS = "/users";
export const ADMINUSERS = "/users/admin-users";
export const USERSRIGHTS = "/user-rights";

export const SOLICITORSAPI = "/solicitors";

export const MYPERSONALPROFILEDETAILSAPI = "user-profile/my-profile";
export const MYPERSONALPROFILEIMAGECHANGEAPI =
  "user-profile/update-profile-image";
export const MYPERSONALPROFILEEDITAPI = "user-profile/edit-profile";
export const MYPERSONALPROFILEADDDOCUMENTAPI = "user-profile/documents";
export const MYPERSONALPROFILEEDITDOCUMENTAPI = "user-profile/documents";
export const MYPERSONALPROFILEDELETEDOCUMENTAPI = "user-profile/documents";

export const ONBOARDINGAPI = "onboarding";
export const ONBOARDINGCHANGESTATUSAPI = "onboarding/change-status";

export const BACKUPAPI = "backups";

export const DELEGATESADDMEMBERAPI = "delegates/invite-member";
export const QUOTEMANAGEMENTLISTAPI = "quote-management";

export const JOBSAPI = "jobs";
export const FAQAPI = "faqs";
export const FAQCATEGORYAPI = "faq-category";
export const Delegates = "all-delegate";

// Tickets
export const TICKETSAPI = "tickets";
export const TICKETSASSIGNEESAPI = "tickets/assignees";
export const ENQUIRIESGETAPI = "enquiries";
export const POSTDEMO = "tickets/schedule-demo";

// DIP APIs
export const DIP_API_PREFIX = "dip-applications";
export const DIP_MORTGAGE_DETAILS_API = "dip-applications/mortgage-details";
export const getPropertyDetailsApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/property-details`;
export const getApplicationDetailsApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/client-details/application-details`;
export const getContactDetailsApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/client-details/contact-details`;
export const getEmploymentDetailsApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/client-details/employment-financial-income-details`;
export const getOutgoingsApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/client-details/outgoings`;
export const getCreditHistoryApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/client-details/credit-history`;
export const getReviewApi = () => `${DIP_API_PREFIX}/reviewed`;
export const confrimDIPApplicationApi = (dipId: string) =>
  `${DIP_API_PREFIX}/${dipId}/confirm`;

//Resume API

export const RESUMEPOSTAPI = "/resumes";

// Reviews Api
export const REVIEWSLIST = "/review/list-reviews";
export const ADDREVIEWAPI = "/review/add-review";

//CONTRACTS API's
export const GETLISTOFCONTRACTS = "/contract";
export const ADDSELLERCONTRACT = "/contract/seller";

export const CASESAPI = "/cases";
export const CLIENTSAPI = "user-profile/my-clients";
