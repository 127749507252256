import { useEffect, useState } from "react";
import { ISIDEBARMENU } from "../sidebar-interface";
import { setMobDrawer } from "../../../store/layout/layout-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";

// windows's size function
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export const useSidebarMenu = ({ item }: ISIDEBARMENU) => {
  const { openDrawer } = useAppSelector((state) => state.layout);
  const dispatch = useAppDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [smallScreenWidth, setSmallScreenWidth] = useState<boolean>();
  const [subNav, setSubNav] = useState<boolean>(false);

  // UseEffect to get the width of window when load
  useEffect(() => {
    const handleWindowResize = () => setWindowSize(getWindowSize());
    window.addEventListener("resize", handleWindowResize);

    if (windowSize.innerWidth <= 600) setSmallScreenWidth(true);
    else setSmallScreenWidth(false);
  }, [windowSize.innerWidth]);

  const sidebarLinkHandler = () => {
    if (item.subNav?.length) {
      setSubNav(!subNav);
      //for Mobile screen
      if (smallScreenWidth) dispatch(setMobDrawer(true));
    } else {
      //for Mobile screen
      if (smallScreenWidth) dispatch(setMobDrawer(false));
    }
  };
  const dropdownLinkHandler = () => {
    //for Mobile screen
    if (smallScreenWidth) dispatch(setMobDrawer(false));
  };

  return {
    smallScreenWidth,
    openDrawer,
    subNav,
    sidebarLinkHandler,
    dropdownLinkHandler,
    dispatch,
  };
};
