import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  myPersonalDetailsAddDocumentThunkFulfilledReducer,
  myPersonalDetailsAddDocumentThunkPendingReducer,
  myPersonalDetailsAddDocumentThunkRejectedReducer,
  myPersonalDetailsChangeProfileImageThunkFulfilledReducer,
  myPersonalDetailsChangeProfileImageThunkPendingReducer,
  myPersonalDetailsChangeProfileImageThunkRejectedReducer,
  myPersonalDetailsDeleteDocumentThunkFulfilledReducer,
  myPersonalDetailsDeleteDocumentThunkPendingReducer,
  myPersonalDetailsDeleteDocumentThunkRejectedReducer,
  myPersonalDetailsEditDocumentThunkFulfilledReducer,
  myPersonalDetailsEditDocumentThunkPendingReducer,
  myPersonalDetailsEditDocumentThunkRejectedReducer,
  myPersonalDetailsThunkFulfilledReducer,
  myPersonalDetailsThunkPendingReducer,
  myPersonalDetailsThunkRejectedReducer,
  myPersonalDetailsUpdateThunkFulfilledReducer,
  myPersonalDetailsUpdateThunkPendingReducer,
  myPersonalDetailsUpdateThunkRejectedReducer,
} from "./personal-details-reducers";
import {
  myPersonalDetailsAddDocumentThunk,
  myPersonalDetailsChangeProfileImageThunk,
  myPersonalDetailsDeleteDocumentThunk,
  myPersonalDetailsEditDocumentThunk,
  myPersonalDetailsThunk,
  myPersonalDetailsUpdateThunk,
} from "./personal-details-thunk-actions";

const personalDetailsInitialState = {
  sentRequestStatus: REQUESTSTATUS.IDLE,
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  changeProfileImageStatus: REQUESTSTATUS.IDLE,
  addDocumentStatus: REQUESTSTATUS.IDLE,
  editDocumentStatus: REQUESTSTATUS.IDLE,
  deleteDocumentStatus: REQUESTSTATUS.IDLE,
  message: "",
  name: ``,
  profileImage: "",
  role: "",
  drn: 0,
  documents: [] as any,
  personalProfileInfo: [] as any,
  solicitorpersonalProfileInfo: {} as any,
  title: "",
  dob: "1997-07-16T19:20:30+01:00",
  solicitorFees: {} as any,
};

export const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState: personalDetailsInitialState,
  reducers: {},
  extraReducers(builder) {
    myPersonalDetailsThunkPendingReducer(builder, myPersonalDetailsThunk);
    myPersonalDetailsThunkFulfilledReducer(builder, myPersonalDetailsThunk);
    myPersonalDetailsThunkRejectedReducer(builder, myPersonalDetailsThunk);

    myPersonalDetailsUpdateThunkPendingReducer(
      builder,
      myPersonalDetailsUpdateThunk
    );
    myPersonalDetailsUpdateThunkFulfilledReducer(
      builder,
      myPersonalDetailsUpdateThunk
    );
    myPersonalDetailsUpdateThunkRejectedReducer(
      builder,
      myPersonalDetailsUpdateThunk
    );

    myPersonalDetailsChangeProfileImageThunkPendingReducer(
      builder,
      myPersonalDetailsChangeProfileImageThunk
    );
    myPersonalDetailsChangeProfileImageThunkFulfilledReducer(
      builder,
      myPersonalDetailsChangeProfileImageThunk
    );
    myPersonalDetailsChangeProfileImageThunkRejectedReducer(
      builder,
      myPersonalDetailsChangeProfileImageThunk
    );

    myPersonalDetailsAddDocumentThunkPendingReducer(
      builder,
      myPersonalDetailsAddDocumentThunk
    );
    myPersonalDetailsAddDocumentThunkFulfilledReducer(
      builder,
      myPersonalDetailsAddDocumentThunk
    );
    myPersonalDetailsAddDocumentThunkRejectedReducer(
      builder,
      myPersonalDetailsAddDocumentThunk
    );

    myPersonalDetailsEditDocumentThunkPendingReducer(
      builder,
      myPersonalDetailsEditDocumentThunk
    );
    myPersonalDetailsEditDocumentThunkFulfilledReducer(
      builder,
      myPersonalDetailsEditDocumentThunk
    );
    myPersonalDetailsEditDocumentThunkRejectedReducer(
      builder,
      myPersonalDetailsEditDocumentThunk
    );

    myPersonalDetailsDeleteDocumentThunkPendingReducer(
      builder,
      myPersonalDetailsDeleteDocumentThunk
    );
    myPersonalDetailsDeleteDocumentThunkFulfilledReducer(
      builder,
      myPersonalDetailsDeleteDocumentThunk
    );
    myPersonalDetailsDeleteDocumentThunkRejectedReducer(
      builder,
      myPersonalDetailsDeleteDocumentThunk
    );
  },
});

export const { } = personalDetailsSlice.actions;

export default personalDetailsSlice.reducer;
