import { useEffect, useState } from "react";
import {
  CircularProgress, FormControl, Grid,
  MenuItem, Select, SelectChangeEvent,
} from "@mui/material";
import "./style.scss"
import EChartsReact from "echarts-for-react";
import { getMortgageBrokerYearDashboardDataThunk } from "../../../../store/dashboard/mortgage-broker-dashboard/mortgage-broker-dashboard-thunk";
import { axiosInstance } from "../../../../lib/axios";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";

export const LineChartForMortgageBroker = () => {
  const { yearStatus, mortgageBrokerUsersData } = useAppSelector(
    (state: any) => state?.mortgageBrokerSlice
  );
  const date = new Date();
  const year = date.getFullYear();
  const [yearData, setYearData] = useState<any>(year);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getMortgageBrokerYearDashboardDataThunk({ yearData, axiosInstance })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData]);

  const eChartOption = {
    tooltip: {
      trigger: "item",
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      // type: "value",
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },
    legend: {
      data: ["Estate Agent", "Solictor", "Client", "Mortgage Broker"],
      icon: "circle",
      left: "right",
      orient: "horizontal",
    },
    grid: { top: "22.5%", left: '13%' },
    height: "60%",
    series: [
      {
        data: mortgageBrokerUsersData?.ESTATE_AGENT,
        type: "line",
        smooth: true,
        color: "#FF3F3F",
        name: "Estate Agent",
      },
      {
        data: mortgageBrokerUsersData?.SOLICITOR,
        type: "line",
        smooth: true,
        color: "#48995D",
        name: "Solictor",
      },
      {
        data: mortgageBrokerUsersData?.CLIENT,
        type: "line",
        smooth: true,
        color: "#366EC2",
        name: "Client",
      },
      {
        data: mortgageBrokerUsersData?.MORTGAGE_BROKER,
        type: "line",
        smooth: true,
        color: "#FA7901",
        name: "Mortgage Broker",
      },
    ],
  };

  const handleChange = (event: SelectChangeEvent) => {
    setYearData(event.target.value as string);
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 ">
      <Grid padding={2} container className="flex justify-space-between">
        <span className="font-weight-600 secondary-title"> Users </span>
        <div className="margin-bottom-0 flex">
          <span className="tertiary-title font-weight-600 primary-text-color margin-right-1 padding-top-0">
            Year:
          </span>
          <FormControl fullWidth variant="standard" sx={{ minWidth: 70 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={yearData}
              onChange={handleChange}
              sx={{ height: 25, fontSize: 12, border: "none" }}
            >
              <MenuItem value={year}>{year}</MenuItem>
              <MenuItem value={year - 1}>{year - 1}</MenuItem>
              <MenuItem value={year - 2}>{year - 2}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grid>
      <div className="padding-right-0">
        {yearStatus === REQUESTSTATUS.PENDING && (
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
              Loading...{" "}
            </span>
          </div>
        )}
        {yearStatus === REQUESTSTATUS.SUCCEEDED ? (
          <EChartsReact option={eChartOption} />
        ) : (
          yearStatus === REQUESTSTATUS.FAILED && (
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
