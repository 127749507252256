export const tooltipPopper = {
  sx: {
    backgroundColor: "white",
    color: "black",
    minWidth: "200px",
    maxWidth: "max-content",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important",
    border: "1px solid rgba(128, 128, 128, 0.13)",
  },
};

export const tooltipArrow = {
  sx: { color: "white" },
};
