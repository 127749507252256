import { createSlice } from "@reduxjs/toolkit";
import { getClientDataThunkFulfilledReducer, getClientDataThunkPendingReducer, getClientDataThunkRejectedReducer } from "./client-dashboard-reducer";
import { getClientDashboardDataThunk } from "./client-dashboard-thunk";


const clientDashboardIntialState = {
    status: '',
    myCase: {} as any,
    
    totalContract: 0,
    
    signedWeekly: 0,
    signedMonthly: 0,
    
    unsignedMonthly: 0,
    unsignedWeekly: 0,

    ChangeRequestMonthly: 0,
    ChangeRequestWeekly: 0
};

export const clientDashboardSlice = createSlice({
    name: "clientDashboard",
    initialState: clientDashboardIntialState,
    reducers: {},
    extraReducers(builder) {
        getClientDataThunkRejectedReducer(builder, getClientDashboardDataThunk)
        getClientDataThunkPendingReducer(builder, getClientDashboardDataThunk)
        getClientDataThunkFulfilledReducer(builder, getClientDashboardDataThunk)
    },
});


export default clientDashboardSlice.reducer;