import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import { IAUTHSTATE } from "./auth-slice-interface";

// need to remove this also
export const setLoginSuccessReducer = (state: IAUTHSTATE, action: any) => {
  state.user = action.payload.user;
  state.token = action.payload.token;
  state.refreshToken = action.payload.refreshToken;
  state.status = REQUESTSTATUS.SUCCEEDED;
  state.error = "";
  state.success = action.payload.success;
  state.role = action.payload.role;
};

export const setUploadProfileImageReducer = (
  state: IAUTHSTATE,
  action: any
) => {
  state.setUploadProfileImage = action.payload;
};

export const setProfileImageReducer = (state: IAUTHSTATE, action: any) => {
  state.user!.profileImage = action.payload;
};

export const setUserDataReducer = (state: IAUTHSTATE, action: any) => {
  // state.user = action.payload;

  state.user!.userId = action.payload.userId;
  state.user!.gender = action.payload.gender;
  state.user!.country = action.payload.country;
  state.user!.dob = action.payload.dob;
  state.user!.city = action.payload.city;
  state.user!.address = action.payload.address;
  state.user!.aboutMe = "";
  state.user!.language = action.payload.language;
  state.user!.firstName = action.payload.firstName;
  state.user!.lastName = action.payload.lastName;
  state.user!.phoneNumber = action.payload.phoneNumber;
};

export const ThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: IAUTHSTATE) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const loginThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const loginThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error || "server is not responding";
    state.user = null;
    state.token = "";
    state.success = "";
  });
};
export const verifyTokenThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: IAUTHSTATE) => {
    state.verifyStatus = REQUESTSTATUS.PENDING;
  });
};

export const verifyTokenThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.verifyStatus = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const verifyTokenThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.verifyStatus = REQUESTSTATUS.FAILED;
    state.error = payload?.error || "server is not responding";
    state.user = null;
    state.token = "";
    state.success = "";
  });
};

export const logoutThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = null;
    state.token = "";
    state.success = payload.success;
    state.error = "";
  });
};

export const logoutThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.token = "";
  });
};

export const registerThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    // state.token = payload.token;
    state.error = "";
    state.success = payload.success;
    state.userEmail = payload.userEmail;
  });
};

export const registerThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
    state.userEmail = payload.userEmail;
  });
};

// forgot password
export const forgotPasswordThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const forgotPasswordThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
  });
};
//resend link
export const resendLinkThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.error = "";
  });
};

export const resendLinkThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.success = "";
  });
};
//Verify Email
export const verifyEmailThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.emailVerified = payload.emailVerified;
    state.idVerified = payload.idVerified;
    state.idVerificationStatus = payload.idVerificationStatus;
    state.idVerificationDisabled = payload.idVerificationDisabled;
    state.shouldReferAdmin = payload.shouldReferAdmin;
    state.error = "";
  });
};

export const verifyEmailThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.emailVerified = payload.emailVerified;
    state.idVerified = payload.idVerified;
    state.idVerificationStatus = payload.idVerificationStatus;
    state.idVerificationDisabled = payload.idVerificationDisabled;
    state.success = "";
  });
};
// reset-password
export const resetPasswordThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.isPasswordReset = payload.isPasswordReset;
    state.error = "";
  });
};

export const resetPasswordThunkRejectReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.isPasswordReset = payload.isPasswordReset;
  });
};

export const refreshTokenThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.user = payload.user;
    state.token = payload.token;
    state.success = payload.success;
    state.role = payload.role;
    state.error = "";
  });
};

export const refreshTokenThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.user = null;
    state.token = "";
    state.success = "";
  });
};

export const changePasswordThunkFulfillReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload;
  });
};

export const changePasswordThankRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    // state.error = payload.error;
    state.error = payload?.error;
  });
};

export const changeEmailThunkFulfillReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload;
  });
};

export const changeEmailThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    // state.error = payload.error;
    state.error = payload?.error;
  });
};

export const verifyPasswordThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload;
  });
};

export const verifyPasswordThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    // state.error = payload.error;
    state.error = payload?.error;
  });
};

export const verifyChangeEmailThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.isEmailChanged = payload.isEmailChanged;
    state.error = "";
  });
};

export const verifyChangeEmailThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: IAUTHSTATE, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload?.error;
    state.isEmailChanged = payload.isEmailChanged;
    state.success = "";
  });
};

export const checkPasswordPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const checkPasswordFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const checkPasswordRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};
