export const dipTableHead = [
  {
    id: 1,
    name: "Sr.No",
  },
  {
    id: 2,
    name: "Mortgage Type",
  },
  {
    id: 3,
    name: "Loan Purpose",
  },
  {
    id: 4,
    name: "Issued on",
  },
  {
    id: 5,
    name: "Application Type",
  },
  {
    id: 6,
    name: "Lender Name",
  },
  {
    id: 7,
    name: "Call Booked At",
  },
  {
    id: 8,
    name: "Action",
  },
];
export const dipTableHeadWithoutAction = [
  {
    id: 1,
    name: "Sr.No",
  },
  {
    id: 2,
    name: "Mortgage Type",
  },
  {
    id: 3,
    name: "Loan Purpose",
  },
  {
    id: 4,
    name: "Issued on",
  },
  {
    id: 5,
    name: "Application Type",
  },
  {
    id: 6,
    name: "Lender Name",
  },
  {
    id: 7,
    name: "Call Booked At",
  },
];
export const dipHistoryData = [
  {
    "Mortgage Type": "Buy to Let",
    "Loan Purpose": "Purchase",
    "Issued on": "12-Dec-2022",
    "Applicant Type": "Joint",
    "Call Booked At": "24/10/2023 - 09:00 a.m. - 10:00 a.m.",
  },
  {
    "Mortgage Type": "Right to buy",
    "Loan Purpose": "Purchase",
    "Issued on": "12-Dec-2022",
    "Applicant Type": "Joint",
    "Call Booked At": "24/10/2023 - 09:00 a.m. - 10:00 a.m.",
  },
  {
    "Mortgage Type": "Buy to Let",
    "Loan Purpose": "Purchase",
    "Issued on": "12-Dec-2022",
    "Applicant Type": "Joint",
    "Call Booked At": "24/10/2023 - 09:00 a.m. - 10:00 a.m.",
  },
  {
    "Mortgage Type": "Buy to Let",
    "Loan Purpose": "Purchase",
    "Issued on": "12-Dec-2022",
    "Applicant Type": "Joint",
    "Call Booked At": "24/10/2023 - 09:00 a.m. - 10:00 a.m.",
  },
];

export const findMortgageTypeList = [
  {
    id: 1,
    name: "Standard Residential",
  },
  {
    id: 2,
    name: "Standard Residential (inc. Shared equity/ Help To Buy)",
  },
  {
    id: 3,
    name: "Buy To Let",
  },
  {
    id: 4,
    name: "Right to buy",
  },
  {
    id: 5,
    name: "Shared Ownership",
  },
  {
    id: 6,
    name: "Self build",
  },
  {
    id: 7,
    name: "Shared equity/ Help to buy",
  },
  {
    id: 8,
    name: "Let To buy",
  },
  {
    id: 9,
    name: "Equity Release",
  },
];
