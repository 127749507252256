import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  USERNOTIFICATIONSAPI,
  USERNOTIFICATIONSDELETEALLAPI,
  USERNOTIFICATIONSDELETEAPI,
  USERNOTIFICATIONSLATESTAPI,
  USERNOTIFICATIONSREADALLAPI,
  USERNOTIFICATIONSREADAPI,
  USERNOTIFICATIONSUNREADALLAPI,
  USERNOTIFICATIONSUNREADAPI,
} from "../../constants/axios-api-constant";
import { getDataAPI, patchDataAPI } from "../../lib/axios";
import { useAppDispatch } from "../store";

// const backend=useAppDispatch()
export const getUserNotificationsThunk = createAsyncThunk(
  "layout/user-notifications",
  async ({ axiosInstance, params }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await getDataAPI(
        USERNOTIFICATIONSAPI,
        axiosInstance,
        params
      );
      const formatData = response?.data?.data;
      const responseData = {
        notifications: formatData?.notifications,
        notificationsMeta: formatData?.meta,
      };
      // backend(getAllUserNotificationsThunk({ axiosInstance }));
      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const getAllUserNotificationsThunk = createAsyncThunk(
  "get-all-notifications",
  async ({ axiosInstance }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await getDataAPI(
        USERNOTIFICATIONSAPI,
        axiosInstance,
        { limit: 1000 }
      );
      const formatData = response?.data?.data;
      const responseData = {
        notifications: formatData?.notifications,
        notificationsMeta: formatData?.meta,
      };
      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const getUserLatestNotificationsThunk = createAsyncThunk(
  "layout/user-notifications-latest",
  async ({ axiosInstance }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await getDataAPI(
        USERNOTIFICATIONSLATESTAPI,
        axiosInstance
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsReadThunk = createAsyncThunk(
  "layout/user-notifications-read",
  async (
    { axiosInstance, selectedIds, dispatch }: any,
    { rejectWithValue }: any
  ) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSREADAPI,
        axiosInstance,
        selectedIds
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(
        getUserNotificationsThunk({ axiosInstance, params: { limit: 1000 } })
      );
      dispatch(getAllUserNotificationsThunk({ axiosInstance }));

      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsReadAllThunk = createAsyncThunk(
  "layout/user-notifications-read-all",
  async ({ axiosInstance, dispatch }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSREADALLAPI,
        axiosInstance
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(
        getUserNotificationsThunk({ axiosInstance, params: { limit: 1000 } })
      );
      dispatch(getAllUserNotificationsThunk({ axiosInstance }));

      return response.data.message;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsUnReadAllThunk = createAsyncThunk(
  "layout/user-notifications-unread-all",
  async ({ axiosInstance, dispatch }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSUNREADALLAPI,
        axiosInstance
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(getAllUserNotificationsThunk({ axiosInstance }));

      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsUnReadThunk = createAsyncThunk(
  "layout/user-notifications-unread",
  async (
    { axiosInstance, selectedIds, dispatch }: any,
    { rejectWithValue }: any
  ) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSUNREADAPI,
        axiosInstance,
        selectedIds
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(getUserNotificationsThunk({ axiosInstance }));
      dispatch(getAllUserNotificationsThunk({ axiosInstance }));

      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsDeleteThunk = createAsyncThunk(
  "layout/user-notifications-delete",
  async (
    { axiosInstance, selectedIds, dispatch }: any,
    { rejectWithValue }: any
  ) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSDELETEAPI,
        axiosInstance,
        selectedIds
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(getUserNotificationsThunk({ axiosInstance }));
      dispatch(getAllUserNotificationsThunk({ axiosInstance }));

      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
export const markUserNotificationsDeleteAllThunk = createAsyncThunk(
  "layout/user-notifications-delete-all",
  async ({ axiosInstance, dispatch }: any, { rejectWithValue }: any) => {
    try {
      const response: any = await patchDataAPI(
        USERNOTIFICATIONSDELETEALLAPI,
        axiosInstance
      );
      const formatData = response?.data?.data;
      const responseData = {
        locations: formatData,
      };
      dispatch(getUserNotificationsThunk({ axiosInstance }));

      return responseData;
    } catch (error) {
      const errorData = {
        message: "error",
      };
      rejectWithValue(errorData);
    }
  }
);
