import { SvgIcon, SvgIconProps } from "@mui/material";

export const DateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.06107 3.95492C2.69469 3.49457 3.45858 3.25614 4.5 3.13266V4C4.5 4.82843 5.17157 5.5 6 5.5C6.82843 5.5 7.5 4.82843 7.5 4V3.00281C7.9641 3 8.46281 3 9 3C9.53719 3 10.0359 3 10.5 3.00281V4C10.5 4.82843 11.1716 5.5 12 5.5C12.8284 5.5 13.5 4.82843 13.5 4V3.13266C14.5414 3.25614 15.3053 3.49457 15.9389 3.95492C16.3634 4.26331 16.7367 4.6366 17.0451 5.06107C17.6094 5.83783 17.8403 6.81038 17.9347 8.25H0.0653361C0.159744 6.81038 0.390566 5.83783 0.954915 5.06107C1.26331 4.6366 1.6366 4.26331 2.06107 3.95492Z"
          fill="#525252"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.010698 9.74978C0 10.4158 0 11.161 0 12C0 15.7497 0 17.6246 0.954915 18.9389C1.26331 19.3634 1.6366 19.7367 2.06107 20.0451C3.3754 21 5.25027 21 9 21C9.83894 21 10.584 21 11.25 20.9893L11.25 20.9192C11.2499 20.0672 11.2499 19.5482 11.3208 19.1005C11.7105 16.6401 13.6401 14.7105 16.1005 14.3208C16.5482 14.2499 17.0672 14.2499 17.9192 14.25L17.9893 14.25C18 13.584 18 12.8389 18 12C18 11.161 18 10.4158 17.9893 9.74978L17.971 9.75H0.0288086L0.010698 9.74978ZM6 12.25C5.58579 12.25 5.25 12.5858 5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H12C12.4142 13.75 12.75 13.4142 12.75 13C12.75 12.5858 12.4142 12.25 12 12.25H6Z"
          fill="#525252"
        />
        <path
          d="M15.9389 20.0451C15.1622 20.6094 14.1896 20.8403 12.75 20.9347C12.7501 20.0243 12.7535 19.6435 12.8023 19.3352C13.0904 17.5166 14.5166 16.0904 16.3352 15.8023C16.6435 15.7535 17.0243 15.7501 17.9347 15.75C17.8403 17.1896 17.6094 18.1622 17.0451 18.9389C16.7367 19.3634 16.3634 19.7367 15.9389 20.0451Z"
          fill="#525252"
        />
        <path
          d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1V4C5.25 4.41421 5.58579 4.75 6 4.75C6.41421 4.75 6.75 4.41421 6.75 4V1Z"
          fill="#525252"
        />
        <path
          d="M12.75 1C12.75 0.585786 12.4142 0.25 12 0.25C11.5858 0.25 11.25 0.585786 11.25 1V4C11.25 4.41421 11.5858 4.75 12 4.75C12.4142 4.75 12.75 4.41421 12.75 4V1Z"
          fill="#525252"
        />
      </svg>
    </SvgIcon>
  );
};

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.25 4.37307C0.25 2.07558 2.16114 0.25 4.47246 0.25H15.5842C17.8642 0.25 19.75 2.05092 19.75 4.318C19.75 5.52281 19.2037 6.66027 18.2668 7.43032L14.7465 10.3238C13.7936 11.107 13.25 12.2522 13.25 13.4521V15.1817C13.25 16.2855 12.7061 17.3147 11.8002 17.9547L9.85896 19.3261C8.38547 20.367 6.25 19.3745 6.25 17.5134V13.336C6.25 12.1925 5.75613 11.0961 4.87987 10.3159L1.62728 7.41967C0.752889 6.64108 0.25 5.53648 0.25 4.37307ZM6 4.25C5.58579 4.25 5.25 4.58579 5.25 5C5.25 5.41421 5.58579 5.75 6 5.75L14 5.75C14.4142 5.75 14.75 5.41422 14.75 5C14.75 4.58579 14.4142 4.25 14 4.25L6 4.25Z"
          fill="#525252"
        />
      </svg>
    </SvgIcon>
  );
};
export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2541 13.0419L15.5091 11.2092C15.5876 10.6454 15.6593 10.1299 15.7231 9.65702C15.8542 8.68528 15.9197 8.19941 15.6291 7.86706C15.3386 7.53472 14.8393 7.53472 13.8406 7.53472H4.50537C3.50668 7.53472 3.00733 7.53472 2.7168 7.86706C2.42626 8.19941 2.49179 8.68528 2.62283 9.65702C2.68661 10.13 2.7583 10.6452 2.8368 11.2092L3.09185 13.0419C3.36905 15.0337 3.50765 16.0296 3.80152 16.8295C4.35107 18.3252 5.30102 19.4393 6.43485 19.9178C7.04117 20.1736 7.75178 20.1736 9.17297 20.1736C10.5942 20.1736 11.3048 20.1736 11.9111 19.9178C13.0449 19.4393 13.9949 18.3252 14.5444 16.8295C14.8383 16.0296 14.9769 15.0337 15.2541 13.0419ZM7.95768 9.47917C7.95768 9.07646 7.63122 8.75 7.22852 8.75C6.82581 8.75 6.49935 9.07646 6.49935 9.47917V17.2569C6.49935 17.6597 6.82581 17.9861 7.22852 17.9861C7.63122 17.9861 7.95768 17.6597 7.95768 17.2569V9.47917ZM11.8466 9.47917C11.8466 9.07646 11.5201 8.75 11.1174 8.75C10.7147 8.75 10.3882 9.07646 10.3882 9.47917V17.2569C10.3882 17.6597 10.7147 17.9861 11.1174 17.9861C11.5201 17.9861 11.8466 17.6597 11.8466 17.2569V9.47917Z"
          fill="#DD3B3B"
        />
        <path
          d="M9.17296 0C6.62248 0 4.5549 2.06757 4.5549 4.61806V4.86111H1.39518C0.992475 4.86111 0.666016 5.18757 0.666016 5.59028C0.666016 5.99299 0.992475 6.31944 1.39518 6.31944H16.9507C17.3534 6.31944 17.6799 5.99299 17.6799 5.59028C17.6799 5.18757 17.3534 4.86111 16.9507 4.86111H13.791V4.61806C13.791 2.06757 11.7234 0 9.17296 0Z"
          fill="#DD3B3B"
        />
      </svg>
    </SvgIcon>
  );
};
export const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6451 1.51473L13.7455 1.57415C14.6134 2.0875 15.3372 2.51556 15.8671 2.93455C16.4265 3.37679 16.8704 3.8844 17.0564 4.5954C17.2423 5.30641 17.1056 5.97344 16.837 6.64297C16.6506 7.10769 16.3764 7.62991 16.0461 8.2256L15.3375 7.79409L15.3284 7.7886L7.60389 3.21947L6.88151 2.78327C7.21623 2.19922 7.51781 1.70037 7.81473 1.30618C8.24638 0.733101 8.74183 0.278251 9.43581 0.0877372C10.1298 -0.102776 10.7809 0.0373313 11.4344 0.312509C12.0535 0.573213 12.7772 1.00133 13.6451 1.51473Z"
          fill="#EA9F0F"
        />
        <path
          d="M6.04337 4.26688L2.16405 11.1508C1.8319 11.7391 1.56927 12.2042 1.47196 12.7333C1.37466 13.2624 1.45406 13.7936 1.55448 14.4654L1.58154 14.647C1.7669 15.8942 1.91969 16.9221 2.15013 17.7017C2.39114 18.517 2.76162 19.2227 3.49028 19.6537C4.21894 20.0847 5.00068 20.0606 5.81033 19.8668C6.58449 19.6814 7.52983 19.303 8.67671 18.8439L8.84374 18.7771C9.46182 18.5303 9.95053 18.3352 10.3491 17.9843C10.7477 17.6334 11.0096 17.1678 11.3408 16.5789L15.2109 9.71109L14.4872 9.27037L6.7576 4.6982L6.04337 4.26688Z"
          fill="#EA9F0F"
        />
      </svg>
    </SvgIcon>
  );
};
export const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.182 10.5272C20.182 9.68076 20.1134 9.06248 19.9646 8.42188H10.2969V12.2432H15.9714C15.8573 13.1929 15.239 14.6229 13.8661 15.5842L13.847 15.7123L16.9037 18.0805L17.1153 18.102C19.0603 16.305 20.182 13.6616 20.182 10.5272Z"
          fill="white"
        />
        <path
          d="M10.2919 20.596C13.0717 20.596 15.406 19.6809 17.1104 18.1022L13.8611 15.5852C12.9916 16.1919 11.8244 16.6152 10.2919 16.6152C7.56919 16.6152 5.25807 14.819 4.43397 12.3359L4.31329 12.3459L1.13508 14.8058L1.09375 14.9215C2.78659 18.2848 6.26484 20.596 10.2919 20.596Z"
          fill="white"
        />
        <path
          d="M4.43874 12.3335C4.22135 11.6929 4.09571 11.006 4.09571 10.2968C4.09571 9.58763 4.22135 8.90074 4.42717 8.26014L4.42138 8.12376L1.2035 5.625L1.09853 5.67542C0.400065 7.07069 0 8.63789 0 10.2968C0 11.9558 0.400065 13.523 1.09853 14.9191L4.43874 12.3335Z"
          fill="white"
        />
        <path
          d="M10.2919 3.98164C12.2253 3.98164 13.5297 4.81649 14.2736 5.51495L17.1799 2.6773C15.3944 1.01835 13.0717 0 10.2919 0C6.26484 0 2.78659 2.31112 1.09375 5.67448L4.42322 8.26003C5.25807 5.7778 7.56919 3.98164 10.2919 3.98164Z"
          fill="white"
        />
        <path
          d="M20.182 10.5272C20.182 9.68076 20.1134 9.06248 19.9646 8.42188H10.2969V12.2432H15.9714C15.8573 13.1929 15.239 14.6229 13.8661 15.5842L13.847 15.7123L16.9037 18.0805L17.1153 18.102C19.0603 16.305 20.182 13.6616 20.182 10.5272Z"
          fill="#4285F4"
        />
        <path
          d="M10.2919 20.596C13.0717 20.596 15.406 19.6809 17.1104 18.1022L13.8611 15.5852C12.9916 16.1919 11.8244 16.6152 10.2919 16.6152C7.56919 16.6152 5.25807 14.819 4.43397 12.3359L4.31329 12.3459L1.13508 14.8058L1.09375 14.9215C2.78659 18.2848 6.26484 20.596 10.2919 20.596Z"
          fill="#34A853"
        />
        <path
          d="M4.43874 12.3335C4.22135 11.6929 4.09571 11.006 4.09571 10.2968C4.09571 9.58763 4.22135 8.90074 4.42717 8.26014L4.42138 8.12376L1.2035 5.625L1.09853 5.67542C0.400065 7.07069 0 8.63789 0 10.2968C0 11.9558 0.400065 13.523 1.09853 14.9191L4.43874 12.3335Z"
          fill="#FBBC05"
        />
        <path
          d="M10.2919 3.98164C12.2253 3.98164 13.5297 4.81649 14.2736 5.51495L17.1799 2.6773C15.3944 1.01835 13.0717 0 10.2919 0C6.26484 0 2.78659 2.31112 1.09375 5.67448L4.42322 8.26003C5.25807 5.7778 7.56919 3.98164 10.2919 3.98164Z"
          fill="#EB4335"
        />
      </svg>
    </SvgIcon>
  );
};

export const OutlookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4139_41687)">
          <path
            d="M22.2812 5.03125H12.2188C11.822 5.03125 11.5 5.35325 11.5 5.75C11.5 6.14675 11.822 6.46875 12.2188 6.46875H20.1868L15.7708 9.90294L12.604 7.889L11.8335 9.10225L15.4273 11.3879C15.5437 11.4626 15.6788 11.5 15.8125 11.5C15.9678 11.5 16.1244 11.4497 16.2538 11.3491L21.5625 7.21912V16.5312H12.2188C11.822 16.5312 11.5 16.8532 11.5 17.25C11.5 17.6468 11.822 17.9688 12.2188 17.9688H22.2812C22.678 17.9688 23 17.6468 23 17.25V5.75C23 5.35325 22.678 5.03125 22.2812 5.03125Z"
            fill="#2172B9"
          />
          <path
            d="M12.6773 0.885031C12.5134 0.748469 12.2935 0.689531 12.0865 0.732656L0.5865 2.88891C0.245812 2.95216 0 3.24828 0 3.59472V19.4072C0 19.7522 0.245812 20.0498 0.5865 20.113L12.0865 22.2693C12.1296 22.2779 12.1742 22.2822 12.2188 22.2822C12.3855 22.2822 12.5479 22.2247 12.6773 22.1169C12.8426 21.9803 12.9375 21.7762 12.9375 21.5635V1.43847C12.9375 1.22428 12.8426 1.02159 12.6773 0.885031Z"
            fill="#2172B9"
          />
          <path
            d="M6.46875 16.5312C4.48787 16.5312 2.875 14.5964 2.875 12.2187C2.875 9.84112 4.48787 7.90625 6.46875 7.90625C8.44963 7.90625 10.0625 9.84112 10.0625 12.2187C10.0625 14.5964 8.44963 16.5312 6.46875 16.5312ZM6.46875 9.34375C5.27994 9.34375 4.3125 10.6332 4.3125 12.2187C4.3125 13.8043 5.27994 15.0937 6.46875 15.0937C7.65756 15.0937 8.625 13.8043 8.625 12.2187C8.625 10.6332 7.65756 9.34375 6.46875 9.34375Z"
            fill="#FAFAFA"
          />
        </g>
        <defs>
          <clipPath id="clip0_4139_41687">
            <rect width="23" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export const ModalDeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 2.5C18.7533 2.5 14.5 6.75329 14.5 12V12.5H8C7.17157 12.5 6.5 13.1716 6.5 14C6.5 14.8284 7.17157 15.5 8 15.5H40C40.8284 15.5 41.5 14.8284 41.5 14C41.5 13.1716 40.8284 12.5 40 12.5H33.5V12C33.5 6.75329 29.2467 2.5 24 2.5Z"
          fill="white"
        />
        <path
          d="M37.0344 25.559L36.5097 29.329C35.9395 33.4265 35.6544 35.4752 35.0498 37.1207C33.9193 40.1976 31.9652 42.4894 29.6327 43.4737C28.3854 44 26.9236 44 24 44C21.0764 44 19.6146 44 18.3673 43.4737C16.0348 42.4894 14.0807 40.1976 12.9502 37.1207C12.3456 35.4752 12.0605 33.4265 11.4903 29.3291L10.9656 25.559C10.8041 24.3986 10.6566 23.3389 10.5254 22.3659C10.2558 20.3669 10.1211 19.3673 10.7187 18.6837C11.3164 18 12.3436 18 14.3981 18H33.6019C35.6564 18 36.6836 18 37.2813 18.6837C37.8789 19.3673 37.7442 20.3669 37.4746 22.3659C37.3434 23.3386 37.1958 24.399 37.0344 25.559Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
export const ModalSuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="44"
        height="32"
        viewBox="0 0 44 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.0607 2.93934C41.6465 3.52513 41.6465 4.47488 41.0607 5.06066L17.2346 28.8867C16.9464 29.1749 16.5533 29.3334 16.1457 29.3257C15.7382 29.3181 15.3513 29.1449 15.0742 28.8461L2.90025 15.721C2.33688 15.1137 2.37256 14.1646 2.97994 13.6012C3.58732 13.0379 4.5364 13.0735 5.09976 13.6809L16.2145 25.664L38.9394 2.93934C39.5251 2.35355 40.4749 2.35355 41.0607 2.93934Z"
          fill="white"
          stroke="white"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export const ModalErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.0607 2.93934C37.6464 3.52513 37.6464 4.47487 37.0607 5.06066L5.06066 37.0607C4.47487 37.6464 3.52513 37.6464 2.93934 37.0607C2.35355 36.4749 2.35355 35.5251 2.93934 34.9393L34.9393 2.93934C35.5251 2.35355 36.4749 2.35355 37.0607 2.93934Z"
          fill="white"
          stroke="white"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.93934 2.93934C2.35355 3.52513 2.35355 4.47487 2.93934 5.06066L34.9393 37.0607C35.5251 37.6464 36.4749 37.6464 37.0607 37.0607C37.6464 36.4749 37.6464 35.5251 37.0607 34.9393L5.06066 2.93934C4.47488 2.35355 3.52513 2.35355 2.93934 2.93934Z"
          fill="white"
          stroke="white"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export const ModalInteractionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.49661 20.2326C3.49661 17.3427 5.76851 15 8.57103 15C11.3736 15 13.6455 17.3427 13.6455 20.2326C13.6455 22.0739 12.7231 23.6931 11.3288 24.6257C14.1814 25.5136 16.4017 27.7437 17.0837 30.5618C17.4226 31.9625 16.2447 33 15.0663 33H2.07577C0.897335 33 -0.280564 31.9625 0.0583888 30.5618C0.74037 27.7437 2.96063 25.5136 5.81331 24.6257C4.41899 23.6931 3.49661 22.0739 3.49661 20.2326ZM39.4285 15C36.6259 15 34.354 17.3427 34.354 20.2326C34.354 22.0739 35.2764 23.6931 36.6707 24.6257C33.8181 25.5136 31.5978 27.7437 30.9158 30.5618C30.5769 31.9625 31.7548 33 32.9332 33H45.9237C47.1022 33 48.2801 31.9625 47.9411 30.5618C47.2591 27.7437 45.0389 25.5136 42.1862 24.6257C43.5805 23.6931 44.5029 22.0739 44.5029 20.2326C44.5029 17.3427 42.231 15 39.4285 15ZM25.6529 18.1154C25.4151 17.8702 25.0296 17.8702 24.7918 18.1154C24.554 18.3606 24.554 18.7582 24.7918 19.0034L26.5943 20.8622H19.9429C19.6066 20.8622 19.334 21.1433 19.334 21.4901C19.334 21.8369 19.6066 22.118 19.9429 22.118H26.5935L24.7918 23.9759C24.554 24.2211 24.554 24.6187 24.7918 24.8639C25.0296 25.1091 25.4151 25.1091 25.6529 24.8639L28.4946 21.9337C28.7324 21.6884 28.7324 21.2909 28.4946 21.0457L25.6529 18.1154ZM23.2123 30.0948C22.9745 30.34 22.5889 30.34 22.3511 30.0948L19.5095 27.1645C19.2716 26.9193 19.2716 26.5218 19.5095 26.2765L22.3511 23.3463C22.5889 23.1011 22.9745 23.1011 23.2123 23.3463C23.4501 23.5915 23.4501 23.9891 23.2123 24.2343L21.4105 26.0922H28.0591C28.3954 26.0922 28.668 26.3733 28.668 26.7201C28.668 27.0669 28.3954 27.348 28.0591 27.348H21.4097L23.2123 29.2068C23.4501 29.452 23.4501 29.8496 23.2123 30.0948Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
