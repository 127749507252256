import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LOCALSTORAGEAUTHUSERINFO } from "../constants/local-storage-constant";
import { useAppDispatch, useAppSelector } from "../store/store";
import { getDataFromLocalStorage } from "../utils/views-utils";
import { verifyTokenThunk } from "../store/auth/auth-thunk-actions";
import { PrivateRoutes } from "./private-routes";
import { useAxiosInterceptors } from "../hooks/use-axios-interceptors";

export const WebViewRoute: React.FC<any> = (props: any, context: any) => {
  const { search } = useLocation();
  const { auth } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors();
  if (auth.status == 'idle' && !auth?.token) {
    const access_token = new URLSearchParams(search).get('access_token');
    dispatch(verifyTokenThunk({ hashParams: { access_token }, navigate, axiosInstance }));
  }
  return <>{props.children}</>;
  // return !(window as any).ReactNativeWebView ? <Navigate to={"/"}/>: <>{props.children}</>;
};
