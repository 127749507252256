import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DayPlannerCard } from "../../../components/day-planner-card/day-planner-card";
import { NotificationAndRemindersCard } from "../../../components/notification-and-reminders-card/notification-and-reminders-card";
import { CASESLISTSPATH, CASESPATH, DAYPLANNERPATH } from "../../../constants/routes-constant";
import { SolicitorTablePiece } from "../../../features/cases/cases-lists/cases-lists-solicitor";

import { RatingCardSolicitorDashboard } from "../../../features/reviews/rating-dasboard-card/rating-card-solicitor";
import { axiosInstance } from "../../../lib/axios";
import { getCasesThunk } from "../../../store/cases/cases-thunk-actions";
import { getSolicitorDashboardDataThunk } from "../../../store/dashboard/solicitor-dashboard/solicitor-dashboard-thunk-action";
import { useAppDispatch } from "../../../store/store";

import { BarChartForSolicitor } from "./chart-for-solicitor/bar-chart-for-solictor";
import { DonutChartForSolicitor } from "./chart-for-solicitor/donut-chart-for-solicitor";
import { SolicitorDahboardCardData } from "./solicitor-dashboard-data";

export const SolicitorDashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSolicitorDashboardDataThunk({ axiosInstance }))
    dispatch(getCasesThunk({ axiosInstance, params: { limit: 4 } }))
  }, [dispatch])
  const width = window.innerWidth;
  const navigate = useNavigate();


  return (
    <div>
      <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"start"}>

        <Grid container justifyContent={"space-between"} alignItems={"center"} item
          xl={9.2} lg={11.9} xs={12}>
          <SolicitorDahboardCardData />

          <Grid container justifyContent={"space-between"} marginTop={2} mb={{ xl: 0, xs: 2 }}
          >
            <Grid className="bg-card-white" container item xl={6.9} xs={12} mb={{ xl: 0, xs: 2 }}
            >
              <BarChartForSolicitor />
            </Grid>

            <Grid className="bg-card-white position-relative"
              py={1} px={2} container item xl={4.9} xs={12}
            >
              <span className="position-absolute primary-color tertiary-title font-weight-600 cursor-pointer"
                onClick={() => navigate(DAYPLANNERPATH)}
                style={{
                  right: 10,
                  top: 10,
                  zIndex: 1,
                  borderBottom: "1px solid #366EC2",
                }}
              >
                Open Day Planner
              </span>
              <DayPlannerCard dayPlannerHeight={"340px"} />
            </Grid>
          </Grid>
        </Grid>


        <Grid item container justifyContent={"center"} xl={2.6} xs={12}>
          <Grid item xs={12} mb={{ xl: 4, xs: 2 }} className="bg-card-white">
            <DonutChartForSolicitor />
          </Grid>

          <Grid item xs={12} className="bg-card-white">
            <RatingCardSolicitorDashboard />
          </Grid>
        </Grid>

        <Grid container justifyContent={"space-between"} marginTop={2}>
          <Grid item xl={8.3} lg={7.3} xs={12} px={2} className="bg-card-white overflow-hidden"
            mb={{ lg: 0, xs: 2 }} sx={{ maxWidth: width - 70 }}
          >
            <div className="flex justify-space-between padding-top-1 padding-bottom-1">
              <span className="secondary-title font-weight-600">Recent Assigned Cases</span>
              <span className="primary-color tertiary-title font-weight-600 cursor-pointer"
                onClick={() => navigate(CASESPATH + '/' + CASESLISTSPATH)}
                style={{ borderBottom: "1px solid #366EC2" }}
              >
                View All
              </span>
            </div>
            <SolicitorTablePiece dashboardView />

          </Grid>

          <Grid item xl={3.5} lg={4.5} xs={12} padding={2}
            className="bg-card-white border-radius-16 font-family-monterrat width-100 ">
            <span className="secondary-title font-weight-600 padding-bottom-0">
              Notifications and Reminders
            </span>
            <Grid container className="overflow-scroll-y"
              sx={{ maxHeight: 353.5 }}>
              <NotificationAndRemindersCard viewDetails={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};