import { AppInputField } from "../../../../components/app-input-field/app-input-field";
import { AppDateField } from "../../../../components/app-date-field/app-date-field";
import { AppPagination } from "../../../../components/app-pagination/app-pagination";
import {
  CLIENTSPATH,
  DIPADDPATH,
  DIPAPPLICATIONDETAILSPARAM,
  DIPPATH,
  SLASHPATH,
} from "../../../../constants/routes-constant";
import { AppToolTip } from "../../../../components/app-tooltip/app-tooltip";
import { MUIPRIMARYCOLOR } from "../../../../constants/mui-styles-constant";
import { AppButton } from "../../../../components/app-button/app-button";
import { ViewCertificate } from "../view-certificate/view-certificate";
import Icon from "../../../../components/app-icon/app-icon";
import SearchIcon from "@mui/icons-material/Search";
import { useDipHistory } from "./use-history-list";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { dipTableHead, dipTableHeadWithoutAction } from "./dip-history-data";
import { AppTableUI } from "../../../../components/app-table-ui/app-table-ui";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/store";
import { getDIPGETRequestStatus } from "../../../../store/dip/dip-slice";
import AppLoader from "../../../../components/app-loader/app-loader";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import dayjs from "dayjs";
import useDebounce from "../../../../hooks/useDebounce";

export const DipHistoryList = () => {
  const {
    dipApplications,
    navigate,
    setSearchParam,
    addcertificate,
    setViewCretificate,
    dipType,
    setDipType,
    setpage,
    issueOn,
    handleIssueOn,
    resetFilter,
    setResetFilter,
    resetFilterhandler,
    setSearchText,
    searchText,
    clientId
  }: any = useDipHistory();
  const mortgageType = useAppSelector(
    (state) => state.referenceData.mortgage.type
  );

  const dipApplicationStatus = useAppSelector(getDIPGETRequestStatus);
  useDebounce(() => setSearchParam(searchText), 500, [searchText]);

  const handleMortgageType = (event: any) => {
    setDipType(event.target.value as string);
    setResetFilter(true);
  };
  const mortgageTypeList = mortgageType.map((type: any, index: any) => {
    return {
      id: index + 1,
      name: type,
    };
  });

  return (
    <div className="bg-card-white">
      <Box
        className="padding-top-2 padding-bottom-2"
        sx={{ px: { xs: "1rem", sm: "3rem" } }}
      >
        <div className="flex justify-space-between align-center">
          <div className="primary-text-color secondary-heading font-weight-600">
            Decision in Principle History
          </div>
          {resetFilter && (
            <div
              className="primary-color title-10 font-weight-600 cursor-pointer"
              onClick={resetFilterhandler}
            >
              Reset Filter
            </div>
          )}
        </div>
        <div className="filter margin-top-2 margin-bottom-3">
          <Grid container spacing={1} sx={{ alignItems: "flex-end" }}>
            <Grid item xs={12} md={2.5}>
              <AppInputField
                label="Mortgage Type"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                select={true}
                selectMenudata={mortgageTypeList}
                value={dipType}
                onChange={handleMortgageType}
              />
            </Grid>

            <Grid item xs={12} md={2.5}>
              <AppDateField
                label="Issued on"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                value={issueOn}
                onChange={handleIssueOn}
              />
            </Grid>
            <Grid item xs={12} md={3.5}></Grid>
            <Grid item xs={12} md={2.5}>
              <AppInputField
                label="Search"
                labelClassName="primary-text-color font-weight-500 visibility-hidden"
                placeholder="Search"
                required={false}
                endIcon={<SearchIcon className="primary-text-color" />}
                value={searchText}
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                  setResetFilter(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <AppButton
                btnText="Apply"
                className="btn-blue width-100"
                btnFunction={() => navigate(clientId ? `${DIPPATH}${SLASHPATH}${DIPADDPATH}?clientId=${clientId}`: `${DIPPATH}/${DIPADDPATH}`)}
              />
            </Grid>
          </Grid>
        </div>
        <DipHistoryTable
          showActionButtons
          setViewCretificate={setViewCretificate}
          dipApplications={dipApplications?.dips}
          dipApplicationStatus={dipApplicationStatus}
        />

        <div className="pagination margin-top-1">
          <AppPagination
            currentPage={dipApplications?.meta?.page || 0}
            totalPages={dipApplications?.meta?.pages || 0}
            handleChangePage={(e: any, currentPage: any) => {
              setpage((currentPage - 1) * 10);
            }}
          />
        </div>

        {addcertificate && (
          <ViewCertificate
            addcertificate={addcertificate}
            setViewCretificate={setViewCretificate}
          />
        )}
      </Box>
    </div>
  );
};

export const DipHistoryTable = (props: any) => {
  const navigate = useNavigate();
  const {
    showActionButtons,
    setViewCretificate,
    height,
    dipApplications,
    dipApplicationStatus,
  } = props;
  
  const { status } = useAppSelector(
    (state: any) => state?.clientDashboardSlice
  );
  return (
    <div className="table width-100 overflow-scroll">
      <AppTableUI
        tableHeadings={
          showActionButtons ? dipTableHead : dipTableHeadWithoutAction
        }
      >
        {(
          showActionButtons
            ? dipApplicationStatus === REQUESTSTATUS.PENDING
            : status === REQUESTSTATUS.PENDING
        ) ? (
          showActionButtons ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <AppLoader />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <div className="flex align-center justify-center  padding-2">
                  <CircularProgress size={35} />
                  <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
                    Loading...{" "}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          )
        ) : (
          <>
            {dipApplications?.length ? (
              dipApplications?.map((row: any, index: any): any => (
                <TableRow
                  key={row?.mortgageDetails?.mortgageType + index}
                  className="border-bottom-light-grey"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    height={height || null}
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {index + 1}
                  </TableCell>

                  <TableCell
                    className="primary-text-color primary-title font-weight-400"
                    align="left"
                    sx={{ width: showActionButtons ? "auto" : 250 }}
                  >
                    {row?.mortgageDetails?.mortgageType}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.mortgageDetails?.loanPurpose}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {dayjs(row?.["Issued on"]).format("DD MMM,YYYY")}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.applicationType ??
                      "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.lenderName ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.ticketDetail?.callDate ?? "-"}
                  </TableCell>
                  {showActionButtons && (
                    <TableCell
                      align="left"
                      className="primary-color primary-title font-weight-400 cursor-pointer "
                    >
                      <div className="flex align-center justify-center">
                        <AppToolTip tooltipTitle="View" showArrow={false}>
                          <IconButton
                            onClick={() =>
                              navigate(
                                `${DIPPATH}/${DIPAPPLICATIONDETAILSPARAM}/${row._id}?clientId=${row.clientId}`,
                                {
                                  state: {
                                    diptabsData: [
                                      "Application Details",
                                      "Product Details",
                                    ],
                                    diptabsComponent: [
                                      "applicationDetails",
                                      "productDetails",
                                    ],
                                  },
                                }
                              )
                            }
                          >
                            <Icon
                              icon="view"
                              height={16}
                              width={16}
                              color={MUIPRIMARYCOLOR}
                            />
                          </IconButton>
                        </AppToolTip>
                        <AppToolTip
                          tooltipTitle="Certificate"
                          showArrow={false}
                        >
                          <IconButton
                            disabled={!row?.dipCertificateUrl}
                            onClick={() => setViewCretificate(row?.dipCertificateUrl)}
                          >
                            <Icon
                              icon="certificate"
                              height={16}
                              width={16}
                              {...(!!row?.dipCertificateUrl && {
                                color: MUIPRIMARYCOLOR,
                              })}
                            />
                          </IconButton>
                        </AppToolTip>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <p className="sub-heading center-text danger-color font-weight-600">
                    No data found
                  </p>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </AppTableUI>
    </div>
  );
};
