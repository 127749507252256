export const MUIPRIMARYCOLOR = "#366EC2";
export const MUISECONDARYCOLOR = "#55AFD5";
export const MUITERTIARYCOLOR = "#E9F3FD";

export const MUIBLACKCOLOR = "#000000";
export const MUIWHITECOLOR = "#ffffff";

export const MUIPRIMARYBACKGROUNDCOLOR = "#E1E1E1";
export const MUISECONDARYBACKGROUNDCOLOR = "#EDF4F8";

export const MUIPRIMARYTEXTCOLOR = "#525252";

export const MUILIGHTBLUECOLOR = "#55AFD5";
export const MUIGREYCOLOR = "#8BB0C4";
export const MUILIGHTGREYCOLOR = "#8B8B8B";
export const MUICHILLYBLUECOLOR = "#55AFD5";

export const MUIORANGECOLOR = "#FA7901";
export const MUIGREENCOLOR = "#48995D";

export const MUIALERTSUCCESSCOLOR = "#4E4B66";
export const MUIBGALERTSUCCESSCOLOR = "#E4F0E7";
export const MUIALERTERRORCOLOR = "#4E4B66";
export const MUIBGALERTERRORCOLOR = "#FFE6E6";

export const MUIERRORCOLOR = "#dc3545";
export const MUISUCCESSCOLOR = "#48995D";
export const MUIBGERRORCOLOR = "#FFEEEE";
export const MUIBGSUCCESSCOLOR = "#EEF9F1";

export const MUIWARNINGCOLOR = "#FFBF00";
export const MUIDISABLEDCOLOR = "#dddddd42";
export const MUIDANGERCOLOR = "#FF3F3F";

export const MUISOLITUDECOLOR = "#E9EFFD";

export const MUIPRIMARYHEADING = "20px";
export const MUISECONDARYHEADING = "24px";
export const MUITERTIARYHEADING = "18px";
export const MUIHEADING48 = "48px";
export const MUIHEADING42 = "42px";
export const MUIHEADING30 = "30px";
export const MUIHEADING20 = "20px";
export const MUIHEADING22 = "22px";
export const MUIHEADING18 = "18px";

export const MUIPRIMARYTITLE = "16px";
export const MUISECONDARYTITLE = "14px";
export const MUITERTIARYTITLE = "12px";
export const MUITITLE15 = "15px";
export const MUITITLE13 = "13px";
export const MUITITLE11 = "11px";
export const MUITITLE10 = "10px";
export const MUITITLE08 = "8px";
export const MUITITLE09 = "9px";

export const MUIBORDERRADIUS2 = "2px";
export const MUIBORDERRADIUS4 = "4px";
export const MUIBORDERRADIUS5 = "5px";
export const MUIBORDERRADIUS8 = "8px";
export const MUIBORDERRADIUS10 = "10px";
export const MUIBORDERRADIUS15 = "15px";
export const MUIBORDERRADIUS16 = "16px";
export const MUIBORDERRADIUS20 = "20px";
export const MUIBORDERRADIUS24 = "24px";
export const MUIBORDERRADIUSROUNDED = "50%";

export const MUIFONTWEIGHT300 = "300";
export const MUIFONTWEIGHT400 = "400";
export const MUIFONTWEIGHT500 = "500";
export const MUIFONTWEIGHT600 = "600";
export const MUIFONTWEIGHT700 = "700";
export const MUIFONTWEIGHT800 = "800";
export const MUIFONTWEIGHT900 = "900";

export const MUIBOXSHADOWPRIMARY = "0px 0px 20px 2px rgba(138, 157, 255, 0.2)";
export const MUIBOXSHADOWSECONDARY = "0px 2px 2px rgba(72, 153, 93, 0.24)";
export const MUIBOXSHADOWTERTIARYINSET = "inset 0px 0px 3px 2px #EBEDFA";
export const MUIBOXSHADOWTABS = "0px 2px 4px rgba(0, 0, 0, 0.25)";
export const MUIBOXSHADOWDATEPICKER = "0px 10px 24px rgba(54, 110, 194, 0.1)";
