import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADDREVIEWAPI, REVIEWSLIST } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";

export const getReviewsDataList = createAsyncThunk(
  "get-reviews",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(REVIEWSLIST, axiosInstance);
      const responseData = {
        data: response?.data?.data,
        message: response?.data?.message,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
export const postReviewThunk = createAsyncThunk(
  "post-review",
  async (
    { axiosInstance, openAlert, body, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(ADDREVIEWAPI, axiosInstance, body);
      dispatch(getReviewsDataList({ axiosInstance }));
      openAlert("Review Added Successfully", "alert-success-message");
      return response.data.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(
        "Something Went Wrong. Please, Try Again!",
        "alert-error-message"
      );
      return rejectWithValue(errorData);
    }
  }
);
