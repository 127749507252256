import { Box, Grid } from "@mui/material";
import { DropDown } from "../drop-down/drop-down";
import { ViewPdfInterface } from "./view-pdf-interface";
export const ViewPdf: React.FC<ViewPdfInterface> = ({
  viewHand,
  downloadPdfFile,
  status,
  labels,
}) => {
  return (
    <Grid
      direction="column"
      container
      sx={{
        height: "354px",
        border: "1px solid #dddddd",
        borderRadius: " 8px 8px 0px 0px",
        overflow: "hidden",
        boxShadow: "0px -4px 8px rgba(210, 210, 210, 0.15);",
      }}
    >
      <Grid
        item
        sx={{
          flexGrow: "1",
          padding: "5px 5px 0px 5px",
          backgroundColor: "#dae3ec",
        }}
      >
        <Grid
          sx={{
            height: "100%",
            background:
              'url("https://i.postimg.cc/K8BSS6Qf/test-3.png") no-repeat',
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute", right: "0" }}>
            <DropDown
              options={{
                ...labels,
                functions: [() => viewHand(""), () => downloadPdfFile("")],
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        item
        sx={{ padding: "20px 27px 20px 20px" }}
      >
        <Grid item sx={{ marginBottom: "10px" }}>
          <span className="primary-title font-weight-400 primary-text-color">
            Mortgage_Document.pdf
          </span>
        </Grid>
        <Grid item container>
          <Grid item sm={6}>
            <span className="font-weight-500 primary-text-color">Date:</span>
            <span className="font-weight-500 primary-text-color margin-left-0-2">
              30/01/22
            </span>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: "right" }}>
            {/* {status === 'Unsigned' && <span className="font-weight-500 success-color">Unsigned</span>} */}
            {status === "Unsigned" && (
              <span className="font-weight-500 " style={{ color: "#FF3F3F" }}>
                Unsigned
              </span>
            )}
            {status === "Change Request" && (
              <span className="font-weight-500 " style={{ color: "#FA7901" }}>
                Change Request
              </span>
            )}
            {status === "Signed" && (
              <span className="font-weight-500 success-color">Signed</span>
            )}
            {status === "Embedded Sign" && (
              <span className="font-weight-500 " style={{ color: "#366EC2" }}>
                Embed Sign
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
