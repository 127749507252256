import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

import {
  getAllNotificationsThunkFulfilledReducer,
  getThunkPendingReducer,
  getUserLatestNotificationsThunkFulfilledReducer,
  getUserLatestNotificationsThunkRejectedReducer,
  getUserNotificationsThunkFulfilledReducer,
  getUserNotificationsThunkRejectedReducer,
  markUserNotificationsDeleteAllThunkFulfilledReducer,
  markUserNotificationsDeleteAllThunkRejectedReducer,
  markUserNotificationsDeleteThunkFulfilledReducer,
  markUserNotificationsDeleteThunkRejectedReducer,
  markUserNotificationsReadAllThunkFulfilledReducer,
  markUserNotificationsReadAllThunkRejectedReducer,
  markUserNotificationsReadThunkFulfilledReducer,
  markUserNotificationsReadThunkRejectedReducer,
  markUserNotificationsUnReadAllThunkFulfilledReducer,
  markUserNotificationsUnReadAllThunkRejectedReducer,
  markUserNotificationsUnReadThunkFulfilledReducer,
  markUserNotificationsUnReadThunkRejectedReducer,
  ThunkPendingReducer,
} from "./layout-reducers";

import {
  getAllUserNotificationsThunk,
  getUserLatestNotificationsThunk,
  getUserNotificationsThunk,
  markUserNotificationsDeleteAllThunk,
  markUserNotificationsDeleteThunk,
  markUserNotificationsReadAllThunk,
  markUserNotificationsReadThunk,
  markUserNotificationsUnReadAllThunk,
  markUserNotificationsUnReadThunk,
} from "./layout-thunk-actions";

const layoutInitialState: any = {
  mobDrawer: false,
  openDrawer: true,
  backgroundImage: "",
  status: REQUESTSTATUS.IDLE,
  getStatus: REQUESTSTATUS.IDLE,
  notifications: [] as any,
  notificationsMeta: {} as any,
  sidebarMd: 4,
  sidebarLg: 2.2,
  mainMd: 8,
  mainLg: 9.8,
  hideSidebar: false,
  infoabout1: "",
  info1: 0,
  infoabout2: "",
  info2: 0,
  showShortInfo: false,
  allNotifications: [],
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: layoutInitialState,
  reducers: {
    setMobDrawer(state, action) {
      state.mobDrawer = action.payload;
    },
    setOpenDrawer(state, action) {
      state.openDrawer = action.payload;
    },
    setBackgroundImage(state, { payload }) {
      state.backgroundImage = payload;
    },
    setGrid(state, { payload }) {
      state.sidebarMd = payload.sidebarMd;
      state.sidebarLg = payload.sidebarLg;
      state.mainMd = payload.mainMd;
      state.mainLg = payload.mainLg;
    },
    setGridDefault(state) {
      state.sidebarMd = 4;
      state.sidebarLg = 2.2;
      state.mainMd = 8;
      state.mainLg = 9.8;
    },
    setSidebarHide(state) {
      state.hideSidebar = true;
    },
    setSidebarShow(state) {
      state.hideSidebar = false;
    },
    showInfo(state, { payload }) {
      state.info1 = payload.info1;
      state.info2 = payload.info2;
      state.infoabout2 = payload.infoabout2;
      state.infoabout1 = payload.infoabout1;
      state.showShortInfo = true;
    },
    hideInfo(state) {
      state.info1 = "";
      state.info2 = "";
      state.infoabout2 = "";
      state.infoabout1 = "";
      state.showShortInfo = false;
    },
  },
  extraReducers(builder) {
    getThunkPendingReducer(builder, getUserNotificationsThunk);
    getUserNotificationsThunkFulfilledReducer(
      builder,
      getUserNotificationsThunk
    );
    getUserNotificationsThunkRejectedReducer(
      builder,
      getUserNotificationsThunk
    );

    getThunkPendingReducer(builder, getUserLatestNotificationsThunk);
    getUserLatestNotificationsThunkFulfilledReducer(
      builder,
      getUserLatestNotificationsThunk
    );
    getUserLatestNotificationsThunkRejectedReducer(
      builder,
      getUserLatestNotificationsThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsReadThunk);
    markUserNotificationsReadThunkFulfilledReducer(
      builder,
      markUserNotificationsReadThunk
    );
    markUserNotificationsReadThunkRejectedReducer(
      builder,
      markUserNotificationsReadThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsReadAllThunk);
    markUserNotificationsReadAllThunkFulfilledReducer(
      builder,
      markUserNotificationsReadAllThunk
    );
    markUserNotificationsReadAllThunkRejectedReducer(
      builder,
      markUserNotificationsReadAllThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsUnReadThunk);
    markUserNotificationsUnReadThunkFulfilledReducer(
      builder,
      markUserNotificationsUnReadThunk
    );
    markUserNotificationsUnReadThunkRejectedReducer(
      builder,
      markUserNotificationsUnReadThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsUnReadAllThunk);
    markUserNotificationsUnReadAllThunkFulfilledReducer(
      builder,
      markUserNotificationsUnReadAllThunk
    );
    markUserNotificationsUnReadAllThunkRejectedReducer(
      builder,
      markUserNotificationsUnReadAllThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsDeleteThunk);
    markUserNotificationsDeleteThunkFulfilledReducer(
      builder,
      markUserNotificationsDeleteThunk
    );
    markUserNotificationsDeleteThunkRejectedReducer(
      builder,
      markUserNotificationsDeleteThunk
    );

    ThunkPendingReducer(builder, markUserNotificationsDeleteAllThunk);
    markUserNotificationsDeleteAllThunkFulfilledReducer(
      builder,
      markUserNotificationsDeleteAllThunk
    );
    markUserNotificationsDeleteAllThunkRejectedReducer(
      builder,
      markUserNotificationsDeleteAllThunk
    );
    getAllNotificationsThunkFulfilledReducer(
      builder,
      getAllUserNotificationsThunk
    );
  },
});

export const {
  setMobDrawer,
  setOpenDrawer,
  setBackgroundImage,
  setGrid,
  setGridDefault,
  setSidebarHide,
  setSidebarShow,
  showInfo,
  hideInfo,
} = layoutSlice.actions;

export default layoutSlice.reducer;
