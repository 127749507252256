import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addJobsThunkFulfilledReducer,
  addJobsThunkPendingReducer,
  addJobsThunkRejectedReducer,
  deleteJobsThunkFulfilledReducer,
  deleteJobsThunkPendingReducer,
  deleteJobsThunkRejectedReducer,
  editJobsThunkFulfilledReducer,
  editJobsThunkPendingReducer,
  editJobsThunkRejectedReducer,
  emptySingleJobReducer,
  getJobsThunkFulfilledReducer,
  getJobsThunkPendingReducer,
  getJobsThunkRejectedReducer,
  getSingleJobsThunkFulfilledReducer,
  getSingleJobsThunkPendingReducer,
  getSingleJobsThunkRejectedReducer,
} from "./jobs-reducers";
import {
  addJobsThunk,
  deleteJobsThunk,
  editJobsThunk,
  getJobsThunk,
  getSingleJobsThunk,
} from "./jobs-thunk-actions";

const jobsInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  jobsLists: [] as any,
  meta: {} as any,
  singleJob: {} as any,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: jobsInitialState,
  reducers: {
    emptySingleJob: emptySingleJobReducer,
  },
  extraReducers(builder) {
    getJobsThunkPendingReducer(builder, getJobsThunk);
    getJobsThunkFulfilledReducer(builder, getJobsThunk);
    getJobsThunkRejectedReducer(builder, getJobsThunk);

    getSingleJobsThunkPendingReducer(builder, getSingleJobsThunk);
    getSingleJobsThunkFulfilledReducer(builder, getSingleJobsThunk);
    getSingleJobsThunkRejectedReducer(builder, getSingleJobsThunk);

    addJobsThunkPendingReducer(builder, addJobsThunk);
    addJobsThunkFulfilledReducer(builder, addJobsThunk);
    addJobsThunkRejectedReducer(builder, addJobsThunk);

    editJobsThunkPendingReducer(builder, editJobsThunk);
    editJobsThunkFulfilledReducer(builder, editJobsThunk);
    editJobsThunkRejectedReducer(builder, editJobsThunk);

    deleteJobsThunkPendingReducer(builder, deleteJobsThunk);
    deleteJobsThunkFulfilledReducer(builder, deleteJobsThunk);
    deleteJobsThunkRejectedReducer(builder, deleteJobsThunk);
  },
});

export const { emptySingleJob } = jobsSlice.actions;

export default jobsSlice.reducer;
