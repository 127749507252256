import assignTicket from "../../../assets/images/app-short-summary-cards/assign-ticket.png";
import unAssignTicket from "../../../assets/images/app-short-summary-cards/unassign-tickets.png";
import pendingCalls from "../../../assets/images/app-short-summary-cards/pending-calls.png";

import { Grid } from "@mui/material";
import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";
import { useAppSelector } from "../../../store/store";

export const MortgageBrokerDashboardCardData = () => {

  const { mortgageBrokerData } = useAppSelector((state: any) => state?.mortgageBrokerSlice)
  const mortgageBrokerCardData: any = [
    {
      id: 1,
      bgColor: "#FF3F3F",
      value: mortgageBrokerData?.unassignedCount?.week,
      value2: mortgageBrokerData?.unassignedCount?.month,
      title: "Unassigned Tickets",
      imgSrc: unAssignTicket,
      thisWeekMonthToggler: true,
    },
    {
      id: 2,
      bgColor: "#48995D",
      value: mortgageBrokerData?.assignedCount?.week,
      value2: mortgageBrokerData?.assignedCount?.month,
      title: "Assigned Tickets",
      imgSrc: assignTicket,
      thisWeekMonthToggler: true,
    },
    {
      id: 3,
      bgColor: "#FA7901",
      value: mortgageBrokerData?.pendingCalls,
      value2: "0",
      title: "Pending Calls",
      imgSrc: pendingCalls,
      thisWeekMonthToggler: false,
    },
  ];
  return (
    <>
      {mortgageBrokerCardData?.map((ele: any) => (
        <Grid item xl={3.8} lg={5.9} sm={5.9} md={11.9}
          xs={11.9}
          marginBottom={{ xl: 0, xs: 2 }}
          key={ele?.id}
        >
          <ShortSummaryCards
            bgColor={ele?.bgColor}
            value={ele?.value || 0}
            title={ele?.title}
            imgSrc={ele?.imgSrc}
            thisWeekMonthToggler={ele?.thisWeekMonthToggler}
            value2={ele?.value2 || 0}
            firstLabel={"Current Week"}
            secondLabel={"Current Month"}
          />
        </Grid>
      ))}
    </>
  )
}