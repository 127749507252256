/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";

// Interfaces
import { ISIDEBARDROPDOWN, ISIDEBARMENU } from "../sidebar-interface";

// SCSS
import "./sidebar-menu.scss";

// Custom Hook
import { useSidebarMenu } from "./use-sidebar-menu";

// MUI Styles
import {
  DropdownLink,
  NavLinkMenu,
  SidebarLabel,
} from "./sidebar-menu-mui-style";
import { useAppSelector } from "../../../store/store";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

// Component Function Starts Here
const SidebarMenu: React.FC<ISIDEBARMENU> = ({ item }) => {
  const { openDrawer, subNav, sidebarLinkHandler, dropdownLinkHandler } =
    useSidebarMenu({ item });
  const { auth } = useAppSelector((state) => state);

  return (
    <>
      {!!auth?.role?.find((role: any) => item.allowedRoles.includes(role)) && (
        <>
          <div className="sidebar-menu-items">
            <NavLink
              className="flex padding-left-1 padding-right-1 padding-top-1 padding-bottom-1 align-center"
              to={item?.path}
              onClick={sidebarLinkHandler}
              key={item?.path}
            >
              <div className="icon" style={{ height: "24px", width: "24px" }}>
                <img
                  src={item?.icon}
                  alt={item?.icon}
                  className="width-100 height-100"
                />
              </div>
              <div className="primary-title primary-text-color margin-left-1 sidebar-menu-title">
                {item?.title}
              </div>
            </NavLink>
          </div>

          {/* <div className="sidebar-menu-dropdwon-parent">
                {subNav && openDrawer &&
                  item.subNav?.map((item: ISIDEBARDROPDOWN) => {
                    return !!auth?.role?.find((role: any) => item?.allowedRoles?.includes(role)) && (
                      <div key={item.id}>
                        <NavLink
                          onClick={dropdownLinkHandler}
                          className="sidebar-menu-drop"
                          to={item.path}
                          key={item.id}
                        >
                          <div className="secondary-title font-weight-400">{item.title}</div>
                        </NavLink>
                      </div>
                    );
                  })}
              </div> */}
        </>
      )}
    </>
  );
};

export default SidebarMenu;
