import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addFaqThunkFulfilledReducer,
  addFaqThunkPendingReducer,
  addFaqThunkRejectedReducer,
  deleteFaqThunkFulfilledReducer,
  deleteFaqThunkPendingReducer,
  deleteFaqThunkRejectedReducer,
  editFaqThunkFulfilledReducer,
  editFaqThunkPendingReducer,
  editFaqThunkRejectedReducer,
  emptySingleFaqReducer,
  getFaqCategoriesThunkFulfilledReducer,
  getFaqCategoriesThunkPendingReducer,
  getFaqCategoriesThunkRejectedReducer,
  getFaqThunkFulfilledReducer,
  getFaqThunkPendingReducer,
  getFaqThunkRejectedReducer,
  getSingleFaqThunkFulfilledReducer,
  getSingleFaqThunkPendingReducer,
  getSingleFaqThunkRejectedReducer,
} from "./faq-reducers";
import {
  addFaqThunk,
  deleteFaqThunk,
  editFaqThunk,
  getFaqCategoryThunk,
  getFaqThunk,
  getSingleFaqThunk,
} from "./faq-thunk-actions";

const faqInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  FAQRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  faqCategories: [],
  faqsLists: [] as any,
  meta: {} as any,
  singleFaq: {} as any,
};

export const faqSlice = createSlice({
  name: "faq",
  initialState: faqInitialState,
  reducers: {
    emptySingleFaq: emptySingleFaqReducer,
  },
  extraReducers(builder) {
    getFaqThunkPendingReducer(builder, getFaqThunk);
    getFaqThunkFulfilledReducer(builder, getFaqThunk);
    getFaqThunkRejectedReducer(builder, getFaqThunk);

    getSingleFaqThunkPendingReducer(builder, getSingleFaqThunk);
    getSingleFaqThunkFulfilledReducer(builder, getSingleFaqThunk);
    getSingleFaqThunkRejectedReducer(builder, getSingleFaqThunk);

    addFaqThunkPendingReducer(builder, addFaqThunk);
    addFaqThunkFulfilledReducer(builder, addFaqThunk);
    addFaqThunkRejectedReducer(builder, addFaqThunk);

    editFaqThunkPendingReducer(builder, editFaqThunk);
    editFaqThunkFulfilledReducer(builder, editFaqThunk);
    editFaqThunkRejectedReducer(builder, editFaqThunk);

    deleteFaqThunkPendingReducer(builder, deleteFaqThunk);
    deleteFaqThunkFulfilledReducer(builder, deleteFaqThunk);
    deleteFaqThunkRejectedReducer(builder, deleteFaqThunk);

    getFaqCategoriesThunkFulfilledReducer(builder, getFaqCategoryThunk);
    getFaqCategoriesThunkPendingReducer(builder, getFaqCategoryThunk);
    getFaqCategoriesThunkRejectedReducer(builder, getFaqCategoryThunk);
  },
});

export const { emptySingleFaq } = faqSlice.actions;

export default faqSlice.reducer;
