import {
  CONTRACTASSIGNPATHPARANS,
  CONTRACTDETAILSPARAMS,
  CONTRACTPATH,
  SLASHPATH,
} from "../../../constants/routes-constant";
const otherBreadcrumbs = (id: string | undefined) => {
  return [
    {
      id: 1,
      linkName: " Contracts ",
      className: "global-breadcrumb-previous-link",
      linkPath: CONTRACTPATH,
    },
    {
      id: 2,
      linkName: "\\",
      className: "global-breadcrumb-previous-link margin-right-0 margin-left-0",
    },
    {
      id: 3,
      linkName: " Assign Contracts ",
      className: "global-breadcrumb-previous-link margin-right-0 margin-left-0",
      linkPath: `${CONTRACTPATH}${SLASHPATH}${CONTRACTASSIGNPATHPARANS}${SLASHPATH}${id}`,
    },
    {
      id: 4,
      linkName: "\\",
      className: "global-breadcrumb-previous-link margin-right-0 margin-left-0",
    },
    {
      id: 5,
      linkName: " Update Contract ",
      className: "global-breadcrumb-active",
    },
  ]
}

export const breadcrumbdataForSignContract = {
  // otherBreadcrumbs: otherBreadcrumbs(),
};

export const breadcrumbCaseId = (id: string | undefined) => {
  return {
    otherBreadcrumbs: otherBreadcrumbs(id),
  }
} 