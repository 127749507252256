import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  deleteDocumentsThunkFulfilledReducer,
  deleteDocumentsThunkPendingReducer,
  deleteDocumentsThunkRejectedReducer,
  getCasesThunkFulfilledReducer,
  getCasesThunkPendingReducer,
  getCasesThunkRejectedReducer,
  getDocumentsThunkFulfilledReducer,
  getDocumentsThunkPendingReducer,
  getDocumentsThunkRejectedReducer,
  patchCasesThunkFulfilledReducer,
  patchCasesThunkPendingReducer,
  patchCasesThunkRejectedReducer,
  patchDocumentsThunkFulfilledReducer,
  patchDocumentsThunkPendingReducer,
  patchDocumentsThunkRejectedReducer,
  postDocumentsThunkFulfilledReducer,
  postDocumentsThunkPendingReducer,
  postDocumentsThunkRejectedReducer,
} from "./cases-reducers";
import {
  deleteUploadDocumnetsThunk,
  getCasesThunk,
  getUploadDocumnetsThunk,
  patchCasesThunk,
  patchUploadDocumnetsThunk,
  postUploadDocumnetsThunk,
} from "./cases-thunk-actions";

const CasesInitialState = {
  getRequestStatus: REQUESTSTATUS.IDLE,
  casesLists: [] as any,
  documnetsList: [] as any,
};

export const CasesSlice = createSlice({
  name: "Cases",
  initialState: CasesInitialState,
  reducers: {},
  extraReducers(builder) {
    getCasesThunkPendingReducer(builder, getCasesThunk);
    getCasesThunkFulfilledReducer(builder, getCasesThunk);
    getCasesThunkRejectedReducer(builder, getCasesThunk);

    patchCasesThunkPendingReducer(builder, patchCasesThunk);
    patchCasesThunkFulfilledReducer(builder, patchCasesThunk);
    patchCasesThunkRejectedReducer(builder, patchCasesThunk);

    getDocumentsThunkPendingReducer(builder, getUploadDocumnetsThunk);
    getDocumentsThunkFulfilledReducer(builder, getUploadDocumnetsThunk);
    getDocumentsThunkRejectedReducer(builder, getUploadDocumnetsThunk);

    postDocumentsThunkPendingReducer(builder, postUploadDocumnetsThunk);
    postDocumentsThunkFulfilledReducer(builder, postUploadDocumnetsThunk);
    postDocumentsThunkRejectedReducer(builder, postUploadDocumnetsThunk);

    patchDocumentsThunkPendingReducer(builder, patchUploadDocumnetsThunk);
    patchDocumentsThunkFulfilledReducer(builder, patchUploadDocumnetsThunk);
    patchDocumentsThunkRejectedReducer(builder, patchUploadDocumnetsThunk);

    deleteDocumentsThunkPendingReducer(builder, deleteUploadDocumnetsThunk);
    deleteDocumentsThunkFulfilledReducer(builder, deleteUploadDocumnetsThunk);
    deleteDocumentsThunkRejectedReducer(builder, deleteUploadDocumnetsThunk);
  },
});

export const {} = CasesSlice.actions;

export default CasesSlice.reducer;
