import {
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppInputField } from "../../../components/app-input-field/app-input-field";
import { FilterIcon } from "../../../utils/svg-icon-utils";
import SearchIcon from "@mui/icons-material/Search";
import { AppTableUI } from "../../../components/app-table-ui/app-table-ui";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import AppLoader from "../../../components/app-loader/app-loader";
import {
  MUIBORDERRADIUS10,
  MUIDANGERCOLOR,
  MUIFONTWEIGHT500,
  MUILIGHTGREYCOLOR,
  MUIPRIMARYCOLOR,
  MUISECONDARYTITLE,
  MUISUCCESSCOLOR,
  MUIWHITECOLOR,
} from "../../../constants/mui-styles-constant";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AssignmentModal } from "../common/assignment-modal";
import success from "../../../assets/svg/components/global-modal/success.svg";
import { useNavigate } from "react-router";
import { TICKETSPATH } from "../../../constants/routes-constant";
import {
  getTicketsListThunk,
  patchAssignToMeThunk,
} from "../../../store/tickets/tickets-thunk-actions";
import { axiosInstance } from "../../../lib/axios";
import { AppPagination } from "../../../components/app-pagination/app-pagination";
import { useAppAlert } from "../../../components/app-alert/use-app-alert";
import { StatusActionButton } from "../common/status-action";
import {
  CallFilterStatus,
  TicketFilterStatus,
  ticketsListsMortgageBrokerTableHead,
  ticketsListsMortgageBrokerTableHeadDashboard,
} from "./tickets-lists-data";

export const TicketsListsMortgageBroker = () => {
  const [ticketDropdownValue, setTicketDropdownValue] = useState("");
  const [callDropdownValue, setCallDropdownValue] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [assign, setAssign] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [ticketId, setTicketId] = useState("");
  const { openAlert } = useAppAlert();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //dropdown
  const dropdownTicketFilterHandler = (e: any) => {
    e.target.value === "All"
      ? setTicketDropdownValue("")
      : setTicketDropdownValue(e.target.value);
  };
  //dropdown
  const dropdownCallFilterHandler = (e: any) => {
    e.target.value === "All"
      ? setCallDropdownValue("")
      : setCallDropdownValue(e.target.value);
  };
  // Search Filter
  const searchHandler = (e: any) => {
    setSearchedText(e.target.value);
  };

  //get Data
  const { ticketsListData, ticketsStatus } = useAppSelector(
    (state) => state.tickets
  );
  // Tickets List
  useEffect(() => {
    const params = {
      search: searchedText,
      ticketStatus: ticketDropdownValue,
      callStatus: callDropdownValue,
      ticketType: "dip",
      limit: 7,
      offset: currentPage,
    };
    const debouce = setTimeout(
      () => dispatch(getTicketsListThunk({ axiosInstance, params })),
      500
    );
    return () => clearTimeout(debouce);
  }, [ticketDropdownValue, callDropdownValue, searchedText, currentPage]);

  const AssignToMeHandler = () => {
    dispatch(
      patchAssignToMeThunk({
        axiosInstance,
        ticketId,
        openAlert,
        dispatch,
        ticketType: "dip",
      })
    );
    setAssign(false);
  };

  return (
    <Grid className="table width-100 overflow-scroll bg-card-white" p={5}>
      <div className="font-weight-600 primary-text-color secondary-heading">
        Tickets
      </div>
      <Grid
        container
        className="flex justify-space-between"
        mb={4}
        mt={3}
        spacing={2}
      >
        <Grid item md={3} xs={12}>
          <AppInputField
            label="Ticket Status"
            labelClassName="primary-text-color font-weight-500"
            required={false}
            select={true}
            selectMenudata={TicketFilterStatus}
            value={ticketDropdownValue}
            onChange={dropdownTicketFilterHandler}
            iconComponent={FilterIcon}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <AppInputField
            label="Call Status"
            labelClassName="primary-text-color font-weight-500"
            required={false}
            select={true}
            selectMenudata={CallFilterStatus}
            value={callDropdownValue}
            onChange={dropdownCallFilterHandler}
            iconComponent={FilterIcon}
          />
        </Grid>
        <Grid item md={3} xs={12}></Grid>
        <Grid item md={3} xs={12}>
          <AppInputField
            label={"\u00a0\u00a0"}
            placeholder="Search"
            required={false}
            select={false}
            onChange={searchHandler}
            endIcon={<SearchIcon />}
          />
        </Grid>
      </Grid>

      <RecentDetailsTablePiece
        setTicketId={setTicketId}
        setAssign={setAssign}
      />

      <AppPagination
        currentPage={ticketsListData?.meta?.page ?? 0}
        totalPages={ticketsListData?.meta?.pages ?? 0}
        handleChangePage={(a, b) => {
          setCurrentPage((b - 1) * 7);
        }}
      />

      <AssignmentModal
        setOpen={setAssign}
        open={assign}
        color={"success"}
        img={success}
        message={"Are you sure you want to assign ticket to Yourself?"}
        onClick={AssignToMeHandler}
      />
    </Grid>
  );
};
export const RecentDetailsTablePiece = (props: any) => {
  const { setTicketId, setAssign, dashboardView } = props;
  const { openAlert } = useAppAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ticketsListData, ticketsStatus } = useAppSelector(
    (state) => state.tickets
  );
  const { status } = useAppSelector((state: any) => state?.mortgageBrokerSlice);
  return (
    <>
      <AppTableUI
        tableHeadings={
          dashboardView
            ? ticketsListsMortgageBrokerTableHeadDashboard
            : ticketsListsMortgageBrokerTableHead
        }
      >
        {(
          !dashboardView
            ? ticketsStatus === REQUESTSTATUS.PENDING
            : status === REQUESTSTATUS.PENDING
        ) ? (
          !dashboardView ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <AppLoader />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <div className="flex align-center justify-center  padding-2">
                  <CircularProgress size={35} />
                  <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
                    Loading...
                  </span>
                </div>
              </TableCell>
            </TableRow>
          )
        ) : (
          <>
            {ticketsListData?.users?.length &&
            ticketsStatus === REQUESTSTATUS.SUCCEEDED ? (
              ticketsListData?.users?.map((row: any, index: any): any => (
                <TableRow
                  key={row?.["_id"]}
                  className="border-bottom-light-grey"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="primary-text-color primary-title font-weight-400"
                    align="left"
                  >
                    {row?.["clientName"] ?? "-"}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      {row?.["phoneNo"] ?? "-"}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["date"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["time"] ?? "-"}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      {row?.["category"] ?? "-"}
                    </TableCell>
                  )}
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      {row?.["mortgageType"] ?? "-"}
                    </TableCell>
                  )}
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color tertiary-title font-weight-600 center-text"
                    >
                      {row?.["ticketStatus"] === "Unassigned" ? (
                        <Grid
                          sx={{ width: "100px", p: 1 }}
                          className="warning-color bg-tertiary-color border-radius-pill"
                        >
                          Unassigned
                        </Grid>
                      ) : (
                        <Grid
                          sx={{ width: "100px", p: 1 }}
                          className="success-color bg-tertiary-color border-radius-pill"
                        >
                          Assigned
                        </Grid>
                      )}
                    </TableCell>
                  )}
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color tertiary-title font-weight-600 center-text"
                      width={"120px"}
                    >
                      <StatusActionButton
                        status={row?.["ticketStatus"]}
                        text={row?.["callStatus"]}
                        idCompleted={row?.["_id"]}
                        openAlert={openAlert}
                        dispatch={dispatch}
                        ticketTypeProp={"dip"}
                      />
                    </TableCell>
                  )}
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      <div className="flex">
                        <Tooltip
                          title="View"
                          placement="top"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                background: MUIPRIMARYCOLOR,
                                fontSize: MUISECONDARYTITLE,
                                fontWeight: MUIFONTWEIGHT500,
                                borderRadius: MUIBORDERRADIUS10,
                                paddingX: "15px",
                              },
                            },
                          }}
                        >
                          <Grid
                            className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                            sx={{
                              height: 40,
                              color: MUIPRIMARYCOLOR,
                              "&:hover": {
                                background: MUIPRIMARYCOLOR,
                                color: `${MUIWHITECOLOR} !important`,
                              },
                            }}
                            onClick={() => {
                              navigate(
                                `${TICKETSPATH}/details/${row?.["dipId"]}?clientId=${row?.dipInfo?.["clientId"]}`,
                                {
                                  state: {
                                    diptabsData: [
                                      "Application Details",
                                      "Product Details",
                                    ],
                                    diptabsComponent: [
                                      "applicationDetails",
                                      "productDetails",
                                    ],
                                  },
                                }
                              );
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </Grid>
                        </Tooltip>
                        {row?.["ticketStatus"] === "Unassigned" ? (
                          <Tooltip
                            title="Assign To Me"
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  background: MUISUCCESSCOLOR,
                                  fontSize: MUISECONDARYTITLE,
                                  fontWeight: MUIFONTWEIGHT500,
                                  borderRadius: MUIBORDERRADIUS10,
                                  paddingX: "15px",
                                },
                              },
                            }}
                          >
                            <Grid
                              className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                              sx={{
                                height: 40,
                                color: MUISUCCESSCOLOR,
                                "&:hover": {
                                  background: MUISUCCESSCOLOR,
                                  color: `${MUIWHITECOLOR} !important`,
                                },
                              }}
                              onClick={() => {
                                setTicketId(row?.["_id"]);
                                setAssign(true);
                              }}
                            >
                              <HowToRegIcon />
                            </Grid>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Assigned"
                            placement="top"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  background: MUILIGHTGREYCOLOR,
                                  fontSize: MUISECONDARYTITLE,
                                  fontWeight: MUIFONTWEIGHT500,
                                  borderRadius: MUIBORDERRADIUS10,
                                  paddingX: "15px",
                                },
                              },
                            }}
                          >
                            <Grid
                              className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                              sx={{
                                height: 40,
                                color: MUILIGHTGREYCOLOR,
                                "&:hover": {
                                  background: MUILIGHTGREYCOLOR,
                                  color: `${MUIWHITECOLOR} !important`,
                                },
                              }}
                            >
                              <HowToRegIcon />
                            </Grid>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <p className="error-color font-weight-600 center-text width-100">
                    Data Not Found!
                  </p>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </AppTableUI>
    </>
  );
};
