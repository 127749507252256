import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { axiosInstance } from "../../../../lib/axios";
import { AppButton } from "../../../../components/app-button/app-button";
import { AppInputField } from "../../../../components/app-input-field/app-input-field";
import { AppTextEditior } from "../../../../components/app-text-editor/app-text-editor";
import { SignaturePad } from "../../../../components/signature-pad/signature-pad";
import {
  getBuyerFormContentThunk,
  sendBuyerContractThunk,
} from "../../../../store/contracts/contracts-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { BuyerOptionHeader } from "../../common/buyer-option-header";
import { dropdownOptionsForBuyers } from "./dropdown-data";
import { tempDataForQuill } from "./temp-data";
import { dataURLtoFile } from "../../../../utils/data-conversion-algos";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import AppLoader from "../../../../components/app-loader/app-loader";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";

export const BuyerDropDown = () => {
  const [caseStatus, setCaseStatus] = useState("");
  const [editorStates, setEditorStates] = useState({
    value: tempDataForQuill,
    editOrUpdate: true,
  });
  const [signatureInfo, setSignatureInfo] = useState({
    type: "",
    content: "",
    typeContent: "",
  });
  const navigate = useNavigate();
  const { openAlert } = useAppAlert();
  const dispatch = useAppDispatch();
  const { htmlContentForBuyerContract, status } = useAppSelector((state) => state.contracts);
  const { caseId } = useParams();
  const handleCaseStatus = (event: any) => {
    dispatch(
      getBuyerFormContentThunk({
        axiosInstance,
        formType: event.target.value,
        caseId,
      })
    );
    setEditorStates((pre) => ({
      ...pre,
      value: editorStates.value?.replace(caseStatus, event.target.value),
      editOrUpdate: true,
    }));
    setCaseStatus(event.target.value as string);
  };

  const editHan = () => {
    setEditorStates((pre) => ({
      ...pre,
      editOrUpdate: !editorStates.editOrUpdate,
    }));
  };
  const updateBtnClickHan = () => {
    setEditorStates((pre) => ({
      ...pre,
      editOrUpdate: !editorStates.editOrUpdate,
    }));
  };
  const updateHand = (updatedVal: string) => {
    setEditorStates((pre) => ({ ...pre, value: updatedVal }));
  };
  const signatureHandler = (type: string, content: string) => {
    if (type === "type") {
      setSignatureInfo((pre) => ({
        ...pre,
        type,
        content: "",
        typeContent: content,
      }));
      return;
    }
    setSignatureInfo((pre) => ({ ...pre, type, content }));
  };
  const signFileHandler1 = (file: any) => {
    setSignatureInfo((pre) => ({ ...pre, type: "upload", content: file }));
  };

  const sendContract = () => {
    const formData = new FormData();
    formData.append("contractType", "buyer_contracts");
    formData.append("formType", caseStatus);
    formData.append(
      "solicitorSignatureFile",
      signatureInfo?.type === "draw"
        ? dataURLtoFile(signatureInfo.content, "solicitorsign.png")
        : signatureInfo.content
    );
    formData.append("solicitorSignatureType", signatureInfo.type);
    formData.append("solicitorSignatureContent", signatureInfo?.typeContent);
    formData.append("content", editorStates?.value);
    dispatch(
      sendBuyerContractThunk({
        axiosInstance,
        formData,
        caseId,
        navigate,
        openAlert,
      })
    );
    setCaseStatus("");
    setSignatureInfo({ type: "", content: "", typeContent: "" });
  };
  useEffect(() => {
    setEditorStates((pre) => ({ ...pre, value: htmlContentForBuyerContract }));
  }, [htmlContentForBuyerContract]);
  return (
    <>
      {status === REQUESTSTATUS.PENDING ? (
        <AppLoader />
      ) : (
        <Grid container>
          <Grid item sm={12} container sx={{ marginBottom: "1.1rem" }}>
            <Grid item sm={4} xs={12}>
              <AppInputField
                label="Form Type"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                select={true}
                selectMenudata={dropdownOptionsForBuyers}
                value={caseStatus}
                onChange={handleCaseStatus}
              />
            </Grid>
          </Grid>
          {caseStatus !== "" && (
            <>
              {/* Header  */}
              <Grid item sm={12}>
                <BuyerOptionHeader />
              </Grid>
              {/* Text Editor  */}
              <Grid item sm={12}>
                <AppTextEditior
                  states={editorStates}
                  editHan={editHan}
                  updateBtnClickHan={updateBtnClickHan}
                  updateHand={updateHand}
                />
              </Grid>
              {/* Signature Pad  */}
              <Grid
                item
                sm={12}
                container
                sx={{
                  borderBottom: "1px solid black",
                  paddingBottom: "2em",
                  marginBottom: "1em",
                }}
              >
                <Grid item sm={4} xs={12}>
                  <SignaturePad
                    signatureContentHan={signatureHandler}
                    signFileHandler={signFileHandler1}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} container justifyContent="flex-end">
                <AppButton
                  btnText="Send"
                  className="btn-blue cursor-pointer tertiary-title add-item-btn"
                  btnFunction={sendContract}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};
