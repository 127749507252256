import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getEstateAgentThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.status = REQUESTSTATUS.FAILED;
    });
};


export const getEstateAgentThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING;
    });
};

export const getEstateAgentThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.estateAgentData = payload


    });
};


export const getEstateAgentYearThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.yearStatus = REQUESTSTATUS.FAILED;
    });
};


export const getEstateAgentYearThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.yearStatus = REQUESTSTATUS.PENDING;
    });
};

export const getEstateAgentYearThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {

        state.yearStatus = REQUESTSTATUS.SUCCEEDED;
        state.estateAgentEarning = payload?.earnings
        state.estateAgentUser = payload?.users

    });
};