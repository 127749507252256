// Components
// import { HeaderMainLayout } from "../header/header-main-layout";
// import {FooterMainLayout} from "../footer/footer-main-layout";

// SCSS
// import "./main-layout.scss";
import { useAppSelector } from "../../store/store";
// import { BodyMainLayout } from "../body/body-main-layout";
import { HeaderSalesSite } from "../header/header-sales-site";
import { Outlet } from "react-router-dom";

// Component start here
export const SalesSiteLayout = () => {
  const { layout } = useAppSelector((state) => state);

  return (
    <div className="flex flex-column min-height-100vh bg-white-color">
      {/* <div className="header margin-outlet-x"> */}
      {/* <div className="position-absolute side-bar-triangle"></div> */}
      {/* <HeaderSalesSite /> */}
      {/* </div> */}
      {/* <div className="main-body flex-grow-1 margin-outlet-x"> */}
      <Outlet />
      {/* </div> */}
      {/* <div className="footer">
        <FooterMainLayout />
      </div> */}
    </div>
  );
};
