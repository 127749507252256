import { CircularProgress, Grid } from "@mui/material";
import EChartsReact from "echarts-for-react";
import { useState } from "react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { useAppSelector } from "../../../../store/store";
import "./style.scss";
export const LineAndBarChartForAdmin = () => {
  const { status, adminDashboardData } = useAppSelector((state: any) => state?.adminDashboardSlice)
  const [barOrLineChart, setbarOrLineChart] = useState("line");
  const eChartOption = {
    legend: {
      data: ["Inactive", "Active", "Total Users"],
      icon: "circle",
      left: "right",
      orient: "horizontal",
    },
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: "6%",
      right: "3%",
      top: "15%",
    },
    xAxis: {
      data: ["Client", "Sale Agent", "Solictor"],
    },
    yAxis: {
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        data: adminDashboardData?.userStatistics?.inActive || [0, 0, 0],
        type: barOrLineChart,
        color: "#FF3F3F",
        smooth: true,
        name: "Inactive",
        showBackground: true,
        label: {
          show: true,
          position: "top",
        },
      },
      {
        data: adminDashboardData?.userStatistics?.active || [0, 0, 0],
        type: barOrLineChart,
        color: "#48995D",
        smooth: true,
        name: "Active",
        showBackground: true,
        label: {
          show: true,
          position: "top",
        },
      },
      {
        data: adminDashboardData?.userStatistics?.total || [0, 0, 0],
        type: barOrLineChart,
        color: "#366EC2",
        smooth: true,
        name: "Total Users",
        showBackground: true,
        label: {
          show: true,
          position: "top",
        },
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 height-100">
      <Grid padding={2}>
        <div className="flex justify-space-between">
          <span className="font-weight-600 secondary-title">
            {" "}
            User Statistics{" "}
          </span>
          <span className="font-weight-600 tertiary-title ">
            <span
              className={`cursor-pointer ${barOrLineChart === "line" && "primary-color"
                }`}
              onClick={() => setbarOrLineChart("line")}
            >
              Line Chart
            </span>{" "}
            &nbsp; &nbsp; &nbsp;
            <span
              className={`cursor-pointer ${barOrLineChart === "bar" && "primary-color"
                }`}
              onClick={() => setbarOrLineChart("bar")}
            >
              Bar Chart
            </span>
          </span>
        </div>
        {status === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100  padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title   padding-left-0">Loading... </span>
          </div>}
        <Grid className="line-chart-div" paddingTop={1}>
          {status === REQUESTSTATUS.SUCCEEDED ?
            <EChartsReact option={eChartOption} />
            :
            (status === REQUESTSTATUS.FAILED &&
              <div className="flex align-center justify-center width-100 padding-2">
                <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                  Server Not Responding
                </span>
              </div>
            )
          }

        </Grid>
      </Grid>
    </div>
  );
};
