import sprite from "../../assets/svg/sprite.svg";

const Icon = ({
  icon,
  width = 20,
  height = 20,
  color = "",
}: {
  icon: string;
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg width={width} height={height} color={color}>
      <use href={`${sprite}#icon-${icon}`}></use>
    </svg>
  );
};

export default Icon;
