import { useState, useRef } from "react";
import { Grid, IconButton } from "@mui/material";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import ModalLayout from "../../../components/modal-layout/modal-layout";
import { RenderPdf } from "../../../components/render-pdf/render-pdf";
import { FILEUPLOADERPROPS } from "./UPDATECONTRACTINTERFACE";
import { readFile } from "../../../utils/data-conversion-algos";

export const FileUploader: React.FC<FILEUPLOADERPROPS> = ({ fileHand }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [fileUrl, setFileurl] = useState<any>(null);

  const fileClickHand = () => {
    fileRef.current && fileRef.current.click();
  };
  const onFileUpload = (event: any) => {
    setFile(event.target.files[0]);
    fileHand(event.target.files[0]);
  };

  // const uploadPdf = () => {
  //     const formData = new FormData();
  //     formData.append('contractType', 'seller_contracts');
  //     formData.append('formType', caseStatus);
  //     formData.append('contractFile', file);
  //     dispatch(addSellerContractThunk({ axiosInstance, formData, caseId, navigate, openAlert }));
  //     setFile(null);
  //     setCaseStatus("");
  // }

  const clearFile = () => {
    setFile(null);
  };

  const openPdf = async () => {
    const url = await readFile(file, "dataURL");
    setFileurl(url);
    setPreviewModalOpen(true);
  };
  return (
    <Grid container>
      {file === null && (
        <>
          <Grid
            container
            onClick={fileClickHand}
            sx={{
              border: "1px dashed #000000",
              borderRadius: "10px",
              height: "150px",
              cursor: "pointer",
            }}
          >
            <Grid
              item
              sm={12}
              container
              justifyContent="center"
              alignContent="center"
            >
              <Grid item>
                <IconButton aria-label="delete">
                  <AttachFileIcon />
                </IconButton>
                <span>{file ? file?.name : null}</span>
              </Grid>
            </Grid>
            <input
              onChange={onFileUpload}
              style={{ display: "none" }}
              ref={fileRef}
              type="file"
              accept="application/pdf"
            />
          </Grid>
        </>
      )}

      {file !== null && (
        <>
          <Grid
            container
            sx={{
              border: "1px dashed #000000",
              borderRadius: "10px",
              height: "150px",
              cursor: "pointer",
            }}
          >
            <Grid
              item
              sm={12}
              container
              justifyContent="center"
              alignContent="center"
            >
              <Grid
                item
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(54, 110, 194, 0.1), rgba(54, 110, 194, 0.1)), #FFFFFF",
                  position: "relative",
                  padding: "0.8em 0.5em",
                  borderRadius: "2px",
                }}
              >
                <IconButton
                  aria-label="delete"
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "100%",
                    backgroundColor: "#366EC2",
                    transform: "translate(-50%,-50%)",
                    width: "0.8em",
                    height: "0.8em",
                  }}
                  onClick={clearFile}
                >
                  <CloseIcon
                    sx={{
                      color: "white",
                      width: "0.7em",
                      height: "0.7em",
                    }}
                  />
                </IconButton>
                <IconButton aria-label="delete">
                  <AttachFileIcon />
                </IconButton>
                <span>
                  {file
                    ? file?.name.length > 10
                      ? `${file.name.slice(0, 10).replace(".", "")}...`
                      : file?.name
                    : null}
                </span>
                <span
                  onClick={openPdf}
                  style={{ marginLeft: "0.2em", color: "#48995D" }}
                >
                  (Preview)
                </span>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {isPreviewModalOpen && fileUrl && (
        <ModalLayout
          isModalOpen={isPreviewModalOpen}
          setIsModalOpen={setPreviewModalOpen}
          leftMd={4}
          centerMd={3}
          rightMd={4}
        >
          <RenderPdf file={fileUrl} />
        </ModalLayout>
      )}
    </Grid>
  );
};
