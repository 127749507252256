import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getReferenceLenderThunk,
  referenceDataThunk,
} from "./reference-data-thunk-actions";
import {
  getGetReferenceLenderThunkFulfilledReducer,
  getGetReferenceLenderThunkPendingReducer,
  getGetReferenceLenderThunkRejectedReducer,
  referenceDataThunkFulfilledReducer,
  referenceDataThunkPendingReducer,
  referenceDataThunkRejectedReducer,
  setLenderReferenceDataReducer,
  setReferenceDataReducer,
} from "./reference-data-reducer";

const referenceDataInitialState: any = {
  mortgage: {} as any,
  property: {} as any,
  otherData: [] as any,
  referenceLender:[] as any,
  status: REQUESTSTATUS.IDLE,
};

export const referenceDataSlice = createSlice({
  name: "referenceData",
  initialState: referenceDataInitialState,
  reducers: {
    setReferenceData: setReferenceDataReducer,
    setLenderReferenceData:setLenderReferenceDataReducer
  },
  extraReducers(builder) {
    referenceDataThunkPendingReducer(builder, referenceDataThunk);
    referenceDataThunkFulfilledReducer(builder, referenceDataThunk);
    referenceDataThunkRejectedReducer(builder, referenceDataThunk);

    //Get ReferenceLender
    getGetReferenceLenderThunkPendingReducer(builder, getReferenceLenderThunk);
    getGetReferenceLenderThunkFulfilledReducer(
      builder,
      getReferenceLenderThunk
    );
    getGetReferenceLenderThunkRejectedReducer(builder, getReferenceLenderThunk);
  },
});

export const { setReferenceData,setLenderReferenceData } = referenceDataSlice.actions;


export default referenceDataSlice.reducer;
