import { useNavigate } from "react-router-dom";
import "./personal-details-summary-view-style.scss";
import { Avatar, CircularProgress, Grid } from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { PROFILEMANAGEMENTPATH } from "../../../constants/routes-constant";
import { useAppSelector } from "../../../store/store";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { dayjsFormatDate } from "../../../lib/dayjs";

export const PersonalDetailsSummaryView = () => {
  const { profileImage, getRequestStatus } = useAppSelector((state: any) => state?.personalDetails);
  const savedItem: any = localStorage.getItem("mortgage-basket-auth-user-info");
  const userData = JSON.parse(savedItem);


  const navigate = useNavigate();
  return (
    <>
      <Grid container item xl={12} lg={9} xs={12} className="bg-card-white">
        <Grid container padding={2}>
          <span className="font-weight-600 secondary-title">Profile</span>
          {
            getRequestStatus === REQUESTSTATUS.PENDING &&
            <div className="flex align-center justify-center width-100 padding-2">
              <CircularProgress size={35} />
              <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
            </div>
          }

          {
            getRequestStatus === REQUESTSTATUS.SUCCEEDED ?
              <>
                <Grid container paddingY={3} item md={12} justifyContent={"center"} className="position-relative"
                >
                  <div className="position-absolute border-radius-rounded circular-radius"></div>
                  <CircularProgress
                    variant="determinate"
                    value={
                      // (Object.keys(userData.user).length / 34 || 35) * 100
                      100
                    }

                    // value={
                    //   Object.keys(userData.user).length > 4 ? 15 :
                    //   Object.keys(userData.user).length > 8 ? 30 :
                    //   Object.keys(userData.user).length > 16 ? 60 :
                    //     Object.keys(userData.user).length > 32 ? 80 :
                    //       Object.keys(userData.user).length > 34 && 100
                    // }
                    className="position-absolute"
                    size={129.5}
                    sx={{ top: 19 }}
                    thickness={1}
                    color={"success"}
                  />
                  <Avatar
                    src={`${IMAGEBASEURL}${profileImage}` || ''}
                    sx={{ height: 120, width: 120 }}></Avatar>
                </Grid>

                <Grid
                  className="tertiary-title"
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent={"center"}
                >
                  <span className="flex align-center">
                    <EmailRoundedIcon className="primary-title primary-color" />{" "}
                    &nbsp; &nbsp; &nbsp; {userData?.user?.email || ''}
                  </span>{" "}
                  <br />
                  <span className="flex align-center">
                    <LocalPhoneRoundedIcon className="primary-title primary-color" />{" "}
                    &nbsp; &nbsp; &nbsp;{userData?.user?.phoneNumber || ''}
                  </span>
                </Grid>

                <Grid paddingY={1.7} container>
                  <span className="primary-color tertiary-title font-weight-600 padding-bottom-0">
                    Details
                  </span>
                  <Grid item xs={12} container direction="column">
                    <div className="flex justify-space-between padding-top-0 ">
                      <span className="secondary-title light-grey-color">Status</span>{" "}
                      <span className="tertiary-title font-weight-600">
                        {/* {userData?.user?.onboardingStatus || ''} */}
                        Completed
                      </span>{" "}
                    </div>
                    <div className="flex justify-space-between padding-top-0 ">
                      <span className="secondary-title light-grey-color">Update</span>{" "}
                      <span className="tertiary-title font-weight-600">
                        {userData?.user?.updatedAt ? dayjsFormatDate(userData?.user?.updatedAt, "DD-MMM-YYYY") : '--'}
                      </span>
                    </div>
                    <div className="flex justify-space-between padding-top-0 ">
                      <span className="secondary-title light-grey-color">
                        Date of Birth
                      </span>{" "}
                      <span className="secondary-title font-weight-600">
                        {userData?.user?.dob ? dayjsFormatDate(userData?.user?.dob, "DD-MMM-YYYY") : 'DOB not set'}
                      </span>
                    </div>
                  </Grid>
                </Grid>

                <Grid container justifyContent="end" paddingTop={3}>
                  <span
                    className="primary-color font-weight-700 secondary-title cursor-pointer"
                    onClick={() => navigate(PROFILEMANAGEMENTPATH)}
                  >
                    Go to Your Profile
                  </span>
                </Grid>
              </>
              :
              (
                getRequestStatus === REQUESTSTATUS.FAILED &&
                <div className="flex align-center justify-center width-100 padding-2">
                  <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                    Server Not Responding
                  </span>
                </div>
              )
          }
        </Grid>

      </Grid>

    </>
  );
};
