import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  createDIPApplicationThunkFulfilledReducer,
  createDIPApplicationThunkPendingReducer,
  createDIPApplicationThunkRejectedReducer,
  updateDIPApplicationThunkFulfilledReducer,
  updateDIPApplicationThunkPendingReducer,
  updateDIPApplicationThunkRejectedReducer,
  getDIPApplicationThunkFulfilledReducer,
  getDIPApplicationThunkPendingReducer,
  getDIPApplicationThunkRejectedReducer,
  getProductsThunkPendingReducer,
  getProductsThunkFulfilledReducer,
  getProductsThunkRejectedReducer,
  getDIPApplicationsThunkPendingReducer,
  getDIPApplicationsThunkFulfilledReducer,
  getDIPApplicationsThunkRejectedReducer,
  confirmApplicationThunkPendingReducer,
  confirmApplicationThunkFulfilledReducer,
  confirmApplicationThunkRejectedReducer,
  getGetPostCodeThunkPendingReducer,
  getGetPostCodeThunkFulfilledReducer,
  getGetPostCodeThunkRejectedReducer,
  getProductThunkPendingReducer,
  getProductThunkFulfilledReducer,
  getProductThunkRejectedReducer,
  getGetPropertyPostCodeThunkPendingReducer,
  getGetPropertyPostCodeThunkFulfilledReducer,
  getGetPropertyPostCodeThunkRejectedReducer,
  getGetApplicantPostCodeThunkPendingReducer,
  getGetApplicantPostCodeThunkFulfilledReducer,
  getGetApplicantPostCodeThunkRejectedReducer,
} from "./dip-reducers";
import {
  createDIPApplicationThunk,
  updateDIPApplicationThunk,
  getDIPApplicationThunk,
  getProductsThunk,
  getDIPApplicationsThunk,
  confirmApplicationThunk,
  getPostCodeThunk,
  getProductThunk,
  getPropertyPostCodeThunk,
  getApplicantPostCodeThunk,
} from "./dip-thunk-actions";

const dipInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  application: {} as any,
  products: [] as any,
  product: [] as any,
  applicationList: {} as any,
  postCodes: null as any,
  propertyPostCodes: null as any,
  applicantPostCodes: null as any,
  lender:null as any,
  meta: {} as any,
};

export const dipSlice = createSlice({
  name: "dip",
  initialState: dipInitialState,
  reducers: {
    updateApplication(state, action) {
      state.application = action.payload;
    },
  },
  extraReducers(builder) {
    // Get All Products
    getProductsThunkPendingReducer(builder, getProductsThunk);
    getProductsThunkFulfilledReducer(builder, getProductsThunk);
    getProductsThunkRejectedReducer(builder, getProductsThunk);

    // Get single Product
    getProductThunkPendingReducer(builder, getProductThunk);
    getProductThunkFulfilledReducer(builder, getProductThunk);
    getProductThunkRejectedReducer(builder, getProductThunk);

    // Get Single Application
    getDIPApplicationThunkPendingReducer(builder, getDIPApplicationThunk);
    getDIPApplicationThunkFulfilledReducer(builder, getDIPApplicationThunk);
    getDIPApplicationThunkRejectedReducer(builder, getDIPApplicationThunk);

    //Get All dip Application
    getDIPApplicationsThunkPendingReducer(builder, getDIPApplicationsThunk);
    getDIPApplicationsThunkFulfilledReducer(builder, getDIPApplicationsThunk);
    getDIPApplicationsThunkRejectedReducer(builder, getDIPApplicationsThunk);

    //Get PostCode
    getGetPostCodeThunkPendingReducer(builder, getPostCodeThunk);
    getGetPostCodeThunkFulfilledReducer(builder, getPostCodeThunk);
    getGetPostCodeThunkRejectedReducer(builder, getPostCodeThunk);

    //Get PropertyPostCode
    getGetPropertyPostCodeThunkPendingReducer(builder, getPropertyPostCodeThunk);
    getGetPropertyPostCodeThunkFulfilledReducer(builder, getPropertyPostCodeThunk);
    getGetPropertyPostCodeThunkRejectedReducer(builder, getPropertyPostCodeThunk);

    //Get ApplicantPostCode
    getGetApplicantPostCodeThunkPendingReducer(builder, getApplicantPostCodeThunk);
    getGetApplicantPostCodeThunkFulfilledReducer(builder, getApplicantPostCodeThunk);
    getGetApplicantPostCodeThunkRejectedReducer(builder, getApplicantPostCodeThunk);

  
    // Create DIP Application
    createDIPApplicationThunkPendingReducer(builder, createDIPApplicationThunk);
    createDIPApplicationThunkFulfilledReducer(
      builder,
      createDIPApplicationThunk
    );
    createDIPApplicationThunkRejectedReducer(
      builder,
      createDIPApplicationThunk
    );

    // Patch DIP Application
    updateDIPApplicationThunkPendingReducer(builder, updateDIPApplicationThunk);
    updateDIPApplicationThunkFulfilledReducer(
      builder,
      updateDIPApplicationThunk
    );
    updateDIPApplicationThunkRejectedReducer(
      builder,
      updateDIPApplicationThunk
    );

    // Confirm Application
    confirmApplicationThunkPendingReducer(builder, confirmApplicationThunk);
    confirmApplicationThunkFulfilledReducer(builder, confirmApplicationThunk);
    confirmApplicationThunkRejectedReducer(builder, confirmApplicationThunk);
  },
});

export const getDIPMortgagePostStatus = (state: any) =>
  state.dip.postRequestStatus;
export const getDIPGETRequestStatus = (state: any) =>
  state.dip.getRequestStatus;
export const getDIPPATCHRequestStatus = (state: any) =>
  state.dip.patchRequestStatus;
export const getDIPDELETERequestStatus = (state: any) =>
  state.dip.deleteRequestStatus;
export const getClientIdSelector = (state: any) =>
  state?.dip?.application?.clientId;
export const getDipId = (state: any) => state?.dip?.application?.dipId;
export const getProducts = (state: any) => state?.dip?.products;
export const getProduct = (state: any) => state?.dip?.product;
export const getDipApplications = (state: any) => state?.dip?.applicationList;
export const getDipApplication = (state: any) => state?.dip?.application;
export const getPostCode = (state: any) => state?.dip?.postCodes;
export const getPropertyPostCode = (state: any) => state?.dip?.propertyPostCodes;
export const getApplicantPostCode = (state: any) => state?.dip?.applicantPostCodes;

export const { updateApplication } = dipSlice.actions;

export default dipSlice.reducer;
