import { createAsyncThunk } from "@reduxjs/toolkit";
import { Delegates, JOBSAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getDelegatesThunk = createAsyncThunk(
  "delegates/get-delegates",
  async (
    { axiosInstance, openAlert, navigate, dispatch, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(JOBSAPI, axiosInstance, params);

      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        delegatesLists: formatData.delegate_postings,
        meta: formatData.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getSingleDelegatesThunk = createAsyncThunk(
  "delegates/get-single-delegates",
  async (
    { axiosInstance, openAlert, delegateId, setAddDelegate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        `${JOBSAPI}/${delegateId}`,
        axiosInstance
      );
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        singleDelegate: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddDelegate?.(true);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const addDelegatesThunk = createAsyncThunk(
  "delegates/add-delegates",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddDelegate, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI("/invite-member", axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddDelegate?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getClientDelegatesThunk({
          axiosInstance,
          openAlert,
          navigate,
          dispatch,
          params,
        })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const editDelegatesThunk = createAsyncThunk(
  "delegates/edit-delegates",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddDelegate, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(Delegates, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddDelegate?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getDelegatesThunk({
          axiosInstance,
          openAlert,
          navigate,
          dispatch,
          params,
        })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const deleteDelegatesThunk = createAsyncThunk(
  "delegates/delete-delegates",
  async (
    { axiosInstance, openAlert, body, dispatch, setIsDelete, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(JOBSAPI, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setIsDelete?.({ open: false, id: null });
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getDelegatesThunk({
          axiosInstance,
          openAlert,
          navigate,
          dispatch,
          params,
        })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");
      setIsDelete?.({ open: false, id: null });
      return rejectWithValue(errorData);
    }
  }
);

export const getClientDelegatesThunk = createAsyncThunk(
  "client/delegates/get-delegates",
  async (
    { axiosInstance, openAlert, navigate, dispatch, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(Delegates, axiosInstance, params);

      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        delegatesLists: formatData.delegates ?? [],
        delegatesinfo: formatData.delegateInfo ?? [],
        meta: formatData.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
