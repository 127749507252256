import { CircularProgress, Grid } from "@mui/material";
import "./style.scss";
import EChartsReact from "echarts-for-react";
import { useAppSelector } from "../../../../store/store";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";

export const DonutChartForSolicitor = () => {
  const { solicitorStatus, solicitorData } = useAppSelector((state: any) => state?.solicitorDashboardSlice)

  const peenth =
    solicitorData?.cases?.thisMonth?.completed +
    solicitorData?.cases?.thisMonth?.inProgress +
    solicitorData?.cases?.thisMonth?.onHold +
    solicitorData?.cases?.thisMonth?.postCompleted +
    solicitorData?.cases?.thisMonth?.fellThrough

  const eChartOption = {
    fontWeight: 600,
    fontSize: 40,
    title: {
      text: peenth,
      subtext: "Total",
      fontWeight: 600,
      fontSize: 40,
      left: "center",
      top: 60,
    },
    tooltip: { trigger: "item" },

    color: ["#FFBF00", "#FA7901", "#366EC2", "#FF3F3F", "#48995D"],

    legend: {
      showInLegend: true,
      height: "250px",
      data: [
        `Completed ${solicitorData?.cases?.thisMonth?.completed || '0'}`,
        `In Progress ${solicitorData?.cases?.thisMonth?.inProgress || '0'}`,
        `On Hold ${solicitorData?.cases?.thisMonth?.onHold || '0'}`,
        `Post Completed ${solicitorData?.cases?.thisMonth?.postCompleted || '0'}`,
        `Fell Through ${solicitorData?.cases?.thisMonth?.fellThrough || '0'}`,
      ],
      icon: "circle",
      orient: "horizontal",
      bottom: "bottom",
      right: "",
      left: "center",
    },

    series: [
      {
        type: "pie",
        radius: ["55%", "30%"],
        center: ["50%", "30%"],
        data: [
          {
            value: solicitorData?.cases?.thisMonth?.inProgress,
            name: `In Progress ${solicitorData?.cases?.thisMonth?.inProgress || '0'}`
          },
          {
            value: solicitorData?.cases?.thisMonth?.onHold,
            name: `On Hold ${solicitorData?.cases?.thisMonth?.onHold || '0'}`
          },
          {
            value: solicitorData?.cases?.thisMonth?.postCompleted,
            name: `Post Completed ${solicitorData?.cases?.thisMonth?.postCompleted || '0'}`
          },
          {
            value: solicitorData?.cases?.thisMonth?.fellThrough,
            name: `Fell Through ${solicitorData?.cases?.thisMonth?.fellThrough || '0'}`
          },
          {
            value: solicitorData?.cases?.thisMonth?.completed,
            name: `Completed ${solicitorData?.cases?.thisMonth?.completed || '0'}`
          },
        ],

        itemStyle: {
          normal: {
            labelLine: { show: false },
            borderWidth: 6,
            borderColor: "#fff",
            borderRadius: 8,
          },
        },
        label: { show: false },
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 ">
      <Grid padding={2}>
        <div className="flex justify-space-between margin-bottom-1">
          <span className="font-weight-600 secondary-title"> Cases </span>
          <span className="font-weight-600 title-10 primary-color "
          // onClick={() => setLatestOrThisMonth(!latestOrthisMonth)}
          >
            {" "}
            This Month
          </span>
        </div>
        {solicitorStatus === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
          </div>}
        {solicitorStatus === REQUESTSTATUS.SUCCEEDED ?
          <EChartsReact option={eChartOption} className="donut-chart-size" />
          :
          (solicitorStatus === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        }
      </Grid>
    </div>
  );
};