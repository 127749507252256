import { Avatar, CircularProgress, Grid } from "@mui/material";
import { IMAGEBASEURL, REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { useAppSelector } from "../../../store/store";

export const TopSaleAgentCardAdmin = () => {

  const { status, adminDashboardData } = useAppSelector((state: any) => state?.adminDashboardSlice)
  return (
    <div>
      <Grid container
        className="bg-white-color border-radius-16 font-family-monterrat width-100 "
        padding={2}
      >
        <div className=" flex justify-space-between width-100 margin-bottom-0">
          <span className="font-weight-600 secondary-title">
            Top Sale Agents
          </span>
        </div>
        <Grid className="overflow-scroll-y" container
          direction={"row"}
          sx={{ maxHeight: 277 }}
        >
          {status === REQUESTSTATUS.PENDING &&
            <div className="flex align-center justify-center width-100 padding-2">
              <CircularProgress size={35} />
              <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
            </div>
          }
          {status === REQUESTSTATUS.SUCCEEDED ?
            adminDashboardData?.topRatedSalesAgents?.length ?
              adminDashboardData?.topRatedSalesAgents?.map((ele: any) => (
                <Grid className="flex width-100" sx={{ borderBottom: "1px solid #DDDDDD" }}
                  key={ele?._id} marginY={0.5} paddingY={0.5}
                >
                  <div className="margin-right-0">
                    <Avatar src={`${IMAGEBASEURL}${ele?.profileImage}`} sx={{ height: 38, width: 38 }} />
                  </div>

                  <Grid item xs={9} container direction={"column"}
                    justifyContent={"space-evenly"}
                  >
                    <span className="font-weight-600 tertiary-title">
                      {ele?.firstName}&nbsp;{ele?.lastName}
                    </span>
                    <span className="title-9">{ele?.defaultRole}</span>
                  </Grid>
                  {/* 
                  <Grid item xs={3.6} container direction="row"
                    alignItems={"center"}>
                    <span className="title-8 primary-color">{ele?.completed}</span>
                  </Grid> */}
                </Grid>
              )) : (
                <div className="error-color font-weight-600 center-text width-100 primary-title padding-top-1 padding-bottom-1">Data Not Found</div>
              )
            :
            (status === REQUESTSTATUS.FAILED &&
              <div className="flex align-center justify-center width-100 padding-2">
                <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                  Server Not Responding
                </span>
              </div>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};