import { Grid, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { AppInputField } from "../../../../../components/app-input-field/app-input-field";
import {
  AssignTicketTableHead,
  UserTypeStatus,
} from "./dip-details-assign-ticket-data";
import { FilterIcon } from "../../../../../utils/svg-icon-utils";
import { AppTableUI } from "../../../../../components/app-table-ui/app-table-ui";
import AppLoader from "../../../../../components/app-loader/app-loader";
import { REQUESTSTATUS } from "../../../../../constants/axios-api-constant";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  getAssigneesListThunk,
  patchAssigneesListThunk,
} from "../../../../../store/tickets/tickets-thunk-actions";
import { axiosInstance } from "../../../../../lib/axios";
import { useAppAlert } from "../../../../../components/app-alert/use-app-alert";

export const DipDetailsAssignTicket = ({ ticketId, setAssignTicket }: any) => {
  const [userTypeValue, setUserTypeValue] = useState("");
  const { openAlert } = useAppAlert();

  const dispatch = useAppDispatch();
  //dropdown
  const dropdownUserFilterHandler = (e: any) => {
    e.target.value === "All"
      ? setUserTypeValue("")
      : setUserTypeValue(e.target.value);
  };

  //get Data
  const { assigneesListData, assignStatus } = useAppSelector(
    (state) => state.tickets
  );

  // Assignees List
  useEffect(() => {
    const params = {
      userType: userTypeValue,
      ticketType: "dip",
    };
    dispatch(getAssigneesListThunk({ axiosInstance, params }));
  }, [userTypeValue]);

  const assignTicket = (userId: any) => {
    dispatch(
      patchAssigneesListThunk({
        axiosInstance,
        ticketId,
        userId,
        openAlert,
        dispatch,
        ticketType: "dip",
      })
    );
    setAssignTicket(false);
  };

  return (
    <Grid container maxHeight={600}>
      <Grid container className="flex justify-space-between" mb={4}>
        <Grid
          item
          md={3}
          className="primary-text-color font-weight-600 primary-heading"
        >
          Assign Ticket
        </Grid>
        <Grid item md={3}>
          <AppInputField
            label="User Type"
            labelClassName="primary-text-color font-weight-500"
            required={false}
            select={true}
            selectMenudata={UserTypeStatus}
            value={userTypeValue}
            onChange={dropdownUserFilterHandler}
            iconComponent={FilterIcon}
          />
        </Grid>
      </Grid>
      <AppTableUI tableHeadings={AssignTicketTableHead}>
        {assignStatus === REQUESTSTATUS.PENDING ? (
          <TableRow>
            <TableCell colSpan={6} align="center">
              <AppLoader />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {assigneesListData?.length ? (
              assigneesListData?.map((row: any, index: any): any => (
                <TableRow
                  key={row?.["_id"]}
                  className="border-bottom-light-grey"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="primary-text-color primary-title font-weight-400"
                    align="left"
                  >
                    {row?.["firstName"] + " " + row?.["lastName"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["defaultRole"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["city"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {(Number(row?.["activeRequests"]) < 0
                      ? 0
                      : row?.["activeRequests"]) ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["closedRequests"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color tertiary-title font-weight-600 center-text"
                  >
                    <div
                      className="bg-success-color border-radius-10 font-weight-600
                                    white-color tertiary-title padding-2 cursor-pointer"
                      onClick={() => assignTicket(row?.["_id"])}
                    >
                      Assign
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <p className="center-text width-100">No data</p>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </AppTableUI>
    </Grid>
  );
};
