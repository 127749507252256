import { createAsyncThunk } from "@reduxjs/toolkit";
import { FAQAPI, FAQCATEGORYAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getFaqThunk = createAsyncThunk(
  "faq/get-faq",
  async (
    { axiosInstance, openAlert, navigate, dispatch, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(FAQAPI, axiosInstance, params);
      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        faqsLists: formatData.faqs,
        meta: formatData?.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getSingleFaqThunk = createAsyncThunk(
  "faq/get-single-faq",
  async (
    { axiosInstance, openAlert, faqId, setAddFaq }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(`${FAQAPI}/${faqId}`, axiosInstance);
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        singleFaq: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddFaq(true);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const addFaqThunk = createAsyncThunk(
  "faq/add-faq",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddFaq, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(FAQAPI, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddFaq?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getFaqThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const editFaqThunk = createAsyncThunk(
  "faq/edit-faq",
  async (
    {
      axiosInstance,
      openAlert,
      dispatch,
      body,
      setAddFaq,
      navigate,
      faqId,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${FAQAPI}/${faqId}`,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddFaq?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getFaqThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const deleteFaqThunk = createAsyncThunk(
  "faq/delete-faq",
  async (
    { axiosInstance, openAlert, faqId, dispatch, setIsDelete, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(`${FAQAPI}/${faqId}`, axiosInstance);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getFaqThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      setIsDelete?.({ open: false, id: null });

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");
      setIsDelete?.({ open: false, id: null });

      return rejectWithValue(errorData);
    }
  }
);

export const getFaqCategoryThunk = createAsyncThunk(
  "faq-category",
  async ({ axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(FAQCATEGORYAPI, axiosInstance);
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
