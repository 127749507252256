import { createAsyncThunk } from "@reduxjs/toolkit";
import { JOBSAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getJobsThunk = createAsyncThunk(
  "jobs/get-jobs",
  async (
    { axiosInstance, openAlert, navigate, dispatch, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(JOBSAPI, axiosInstance, params);
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        jobsLists: formatData.job_postings,
        meta: formatData.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getSingleJobsThunk = createAsyncThunk(
  "jobs/get-single-jobs",
  async (
    { axiosInstance, openAlert, jobId, setAddJob }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(`${JOBSAPI}/${jobId}`, axiosInstance);
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        singleJob: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(true);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const addJobsThunk = createAsyncThunk(
  "jobs/add-jobs",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddJob, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(JOBSAPI, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getJobsThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const editJobsThunk = createAsyncThunk(
  "jobs/edit-jobs",
  async (
    {
      axiosInstance,
      openAlert,
      dispatch,
      body,
      jobid,
      setAddJob,
      navigate,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${JOBSAPI}/${jobid}`,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getJobsThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const deleteJobsThunk = createAsyncThunk(
  "jobs/delete-jobs",
  async (
    { axiosInstance, openAlert, jobId, dispatch, setIsDelete, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(
        `${JOBSAPI}/${jobId}`,
        axiosInstance
      );

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setIsDelete?.({ open: false, id: null });
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getJobsThunk({ axiosInstance, openAlert, navigate, dispatch, params })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");
      setIsDelete?.({ open: false, id: null });
      return rejectWithValue(errorData);
    }
  }
);
