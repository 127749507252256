import { Grid } from "@mui/material";

export const AppCrispInfoCard = (props: any) => {
  const { imgSrc, infoAbout, info, infoClassName, infoAboutClassName } = props;
  return (
    <>
      <div className="bg-card-white padding-top-1 padding-left-1 padding-right-1 padding-bottom-1 border-radius-16">
        <Grid container>
          <Grid item xs={6} md={8}>
            <div>
              <div className="height-50 width-50">
                <img
                  src={imgSrc}
                  alt={`${infoAbout}${info}`}
                  className="width-100 heigth-100"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={4} className="flex justify-center">
            <div className={`heading-48 font-weight-600 ${infoClassName}`}>
              {info}
            </div>
          </Grid>
        </Grid>
        <div
          className={`font-weight-500 primary-text-color ${infoAboutClassName}`}
        >
          {infoAbout}
        </div>
      </div>
    </>
  );
};
