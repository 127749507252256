import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADMINDASHBOARDPATH } from "../../../constants/routes-constant";
import { getDataAPI } from "../../../lib/axios";

export const getAdminDashboardDataThunk = createAsyncThunk("get-admin-dashboard-data",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(ADMINDASHBOARDPATH, axiosInstance);
            return response.data;
        } catch (error: any) {
            
            const errorData = {

                //   error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
            };
            return rejectWithValue(errorData);
        }
    }
);
