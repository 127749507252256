import { CircularProgress, Grid } from "@mui/material";
import { useEffect } from "react";
import {
  IMAGEBASEURL,
  REQUESTSTATUS,
} from "../../constants/axios-api-constant";
import { MUIPRIMARYCOLOR } from "../../constants/mui-styles-constant";
import { axiosInstance } from "../../lib/axios";
import { getLatestNotificationThunk } from "../../store/notification-and-reminder/notification-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../store/store";
import meetingAlert from "../../assets/images/notification-and-reminders-card/meeting-alert.png";

export const NotificationAndRemindersCard = (props: any) => {
  const { viewDetails } = props;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getLatestNotificationThunk({ axiosInstance }));
  }, [dispatch]);
  const { status, lastestNotificationData } = useAppSelector(
    (state: any) => state.notificationSlice
  );

  const conditionalyColorScheme: any = {
    new_contract: "#FA7901",
    new_meeting: "#FF3F3F",
    case_updates: "#366EC2",
    document_updates: "#48995D",
    new_ticket: "#366EC2",
    new_meeting_alert: "#FF3F3F",
    missed_meeting: "#366EC2",
    assigned_ticket: "#48995D",
    call_updates: "#366EC2",
    access_revoked: "#FA7901",
    new_ticket_alert: "#FF3F3F",
    fees_updates: "#366EC2",
    user_rights_update: "#48995D",
  };

  return (
    <>
      {status === REQUESTSTATUS.PENDING && <div className="flex align-center justify-center width-100 padding-2">
        <CircularProgress size={35} />
        <span className="font-weight-600 primary-color secondary-title padding-left-0"> Loading...</span>
      </div>}
      {lastestNotificationData?.map((ele: any) =>
        status === REQUESTSTATUS.SUCCEEDED &&
        <Grid key={ele?._id} container direction={"row"} paddingX={1.5}
          paddingY={viewDetails ? 2.4 : 2.2}
          marginTop={viewDetails ? 1.3 : 1.2}
          justifyContent={'space-between'}
          bgcolor={conditionalyColorScheme[ele?.type] || MUIPRIMARYCOLOR}
          className="border-radius-8 white-color"
        >
          {viewDetails ? (
            <>
              <Grid item lg={1.4} sm={1} xs={2} container alignItems={"center"} justifyContent={"center"}>
                <img alt='' src={ele?.icon ? `${IMAGEBASEURL}${ele?.icon}` : meetingAlert}
                  style={{ height: 30 }} />
              </Grid>

              <Grid container item lg={8} sm={10.8} xs={9.8}
                direction={"column"}
                justifyContent={"space-between"}>
                <span className="secondary-title font-weight-500 padding-bottom-0">
                  {ele?.title || ""}
                </span>

                <span className="tertiary-title font-weight-300"
                  style={{ fontWeight: 300 }}>
                  {ele?.message || ""}
                </span>
              </Grid>

              <Grid container item lg={2.5} xs={12}
                alignContent={"center"}
                justifyContent={"end"}
              >
                <span className="tertiary-title cursor-pointer visibility-hidden"
                  style={{ fontWeight: 300 }}
                >
                  View Details
                </span>
              </Grid>
            </>
          ) : (
            <>
              <Grid container item lg={1.6} xs={3} sm={2}
                alignItems={"center"} justifyContent={"center"}
              >
                <img alt='' style={{ height: 28 }}
                  src={ele?.icon ? `${IMAGEBASEURL}${ele?.icon}` : meetingAlert}
                />
              </Grid>
              <Grid container item lg={9.5} xs={9} sm={8}
                direction={"column"} justifyContent={"center"}
              >
                <span className="tertiary-title font-weight-600 padding-bottom-0">
                  {ele?.title || ""}
                </span>
                <span
                  className="title-9 overflow-hidden"
                  style={{
                    fontWeight: 300,
                  }}
                >
                  {ele?.message || ""}
                </span>
              </Grid>
            </>
          )}
        </Grid>
      )
      }
      {status === REQUESTSTATUS.FAILED ?
        <div className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
          <span> Server Not Responding </span>
        </div>
        :
        (status !== REQUESTSTATUS.PENDING && !lastestNotificationData?.length &&
          <div className="flex align-center justify-center width-100 padding-2">
          <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
            Data Not Found
          </span>
        </div>
        )
      }
    </>
  );
};