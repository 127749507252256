import { useEffect, useRef } from "react";
import { Designer, Template, checkTemplate } from "@pdfme/ui";
import DownloadIcon from "@mui/icons-material/Download";
import { generate } from "@pdfme/generator";
import { Grid } from "@mui/material";
import { axiosInstance } from "../../lib/axios";
import { getTemplate, cloneDeep } from "./helper";
import { AppButton } from "../app-button/app-button";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  embedSignatureContractThunk,
  getContentOfSignleContractThunk,
  getContractsThunk,
} from "../../store/contracts/contracts-thunk-actions";
import { useAppAlert } from "../app-alert/use-app-alert";
import { useParams, useSearchParams } from "react-router-dom";

// This is not a global component if you want to use it please refactor it take out the save and update functionality outside the component and alos make changes where i am already using it

export const PDFEDITOR: React.FC<{
  pdfFile?: string;
  contractId?: string;
  setIsEmbedModalOpen?: any;
}> = ({ pdfFile, contractId, setIsEmbedModalOpen }) => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const dispatch = useAppDispatch();
  const {
    contracts: { status, singleContractInfoHolder },
    auth,
  } = useAppSelector((state) => state);
  const { openAlert } = useAppAlert();
  const { caseId, contractId: paramContractId } = useParams();

  useEffect(() => {
    if (designerRef.current && auth.token) {
      let template: Template = getTemplate();
      try {
        const templateString = localStorage.getItem("template");
        const templateJson = templateString
          ? JSON.parse(templateString)
          : getTemplate();
        checkTemplate(templateJson);
        template = templateJson as Template;
        if (paramContractId) {
          dispatch(
            getContentOfSignleContractThunk({
              axiosInstance,
              caseId,
              contractId: paramContractId,
            })
          );
        } else {
          updateBasePdf();
        }
      } catch {
        localStorage.removeItem("template");
      }

      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
      });
      designer.current.onSaveTemplate(onSaveTemplate);
    }
    return () => {
      if (designer.current) {
        designer.current.destroy();
      }
    };
  }, [designerRef, auth]);

  useEffect(() => {
    if (singleContractInfoHolder?.contractFile?.url) {
      updateBasePdf(singleContractInfoHolder?.contractFile?.url);
    }
  }, [singleContractInfoHolder]);

  // const updateBasePdf = () => {
  //   fetch(pdfFile).then((response) => {
  //     response.blob().then((blob) => {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       reader.onload = () => {
  //         let basePdf = reader.result;
  //         if (designer.current) {
  //           designer.current.updateTemplate(
  //             Object.assign(cloneDeep(designer.current.getTemplate()), {
  //               basePdf,
  //             })
  //           );
  //         }
  //       };
  //     });
  //   });
  // };
  const updateBasePdf = (fileUrl?: string) => {
    fetch(`${process.env.REACT_APP_BASE_IMAGE_URL}${fileUrl ?? pdfFile}`).then(
      (response) => {
        response.blob().then((blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            let basePdf = reader.result;
            if (designer.current) {
              designer.current.updateTemplate(
                Object.assign(cloneDeep(designer.current.getTemplate()), {
                  basePdf,
                })
              );
            }
          };
        });
      }
    );
  };
  // const updateBasePdf = () => {
  //   fetch(`https://pdf-lib.js.org/assets/with_update_sections.pdf`).then(
  //     (response) => {
  //       response.blob().then((blob) => {
  //         let reader = new FileReader();
  //         reader.readAsDataURL(blob);
  //         reader.onload = () => {
  //           let basePdf = reader.result;
  //           if (designer.current) {
  //             designer.current.updateTemplate(
  //               Object.assign(cloneDeep(designer.current.getTemplate()), {
  //                 basePdf,
  //               })
  //             );
  //           }
  //         };
  //       });
  //     }
  //   );
  // };
  const onSaveTemplate = (template?: Template) => {
    if (designer.current) {
      localStorage.setItem(
        "template",
        JSON.stringify(template || designer.current.getTemplate())
      );
      alert("Saved!");
    }
  };

  // const onGeneratePDF = async () => {
  //   if (designer.current) {
  //     const template = designer.current.getTemplate();
  //     const inputs = template.sampledata ?? [];
  //     const pdf = await generate({ template, inputs });
  //     const blob = new Blob([pdf.buffer], { type: "application/pdf" });
  //     window.open(URL.createObjectURL(blob));
  //   }
  // };

  const downloadPdf = async () => {
    if (designer.current) {
      const template = designer.current.getTemplate();
      const inputs = template.sampledata ?? [];
      const pdf = await generate({ template, inputs });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "filename.pdf";
      a.click();
    }
  };
  const savePdf = async () => {
    if (designer.current) {
      const template = designer.current.getTemplate();
      const inputs = template.sampledata ?? [];
      const pdf = await generate({ template, inputs });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      const file = new File([blob], "signature_embedded.pdf", {
        type: blob.type,
      });
      const formData = new FormData();
      formData.append("contractFile", file);
      setIsEmbedModalOpen && setIsEmbedModalOpen(false);

      await dispatch(
        embedSignatureContractThunk({
          axiosInstance,
          formData,
          contractId: paramContractId || contractId,
          openAlert,
          setIsEmbedModalOpen,
        })
      );
      (window as any).ReactNativeWebView.postMessage("Saved");
      alert("Saved")

      setIsEmbedModalOpen &&
        dispatch(
          getContractsThunk({
            axiosInstance,
            dynamicpart: `${caseId}?offset=0&limit=8&status=All`,
          })
        );
    }
  };
  return (
    <Grid container>
      {!paramContractId ? (
        <Grid item sm={6}>
          Contracts
        </Grid>
      ) : (
        <></>
      )}
      <Grid item sm={6} container justifyContent="flex-end">
        <Grid item>
          <AppButton
            btnText="Save"
            className="btn-blue cursor-pointer tertiary-title add-item-btn "
            btnFunction={savePdf}
          />
        </Grid>
        {!paramContractId ? (
          <Grid item>
            <AppButton
              btnText="Download"
              className="btn-blue cursor-pointer tertiary-title add-item-btn margin-left-1"
              btnFunction={downloadPdf}
              btnIconRight={
                <DownloadIcon sx={{ color: "white", paddingLeft: "0.5em" }} />
              }
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        item
        sm={12}
        sx={{
          height: "90vh",
          "& > div > div > div": { width: "calc(100% - 5px)" },
        }}
      >
        <div>
          <div ref={designerRef} />
        </div>
      </Grid>
    </Grid>
  );
};
