export const TicketFilterStatus = [
  { id: "0", name: "All" },
  { id: "1", name: "Assigned" },
  { id: "2", name: "Unassigned" },
];

export const CallFilterStatus = [
  { id: "0", name: "All" },
  { id: "1", name: "Pending" },
  { id: "2", name: "Completed" },
];

export const DipDetailsTableHead = [
  {
    id: 1,
    name: "Ticket ID",
  },
  {
    id: 2,
    name: "Client Name",
  },
  {
    id: 3,
    name: "Phone",
  },
  {
    id: 4,
    name: "Email",
  },
  {
    id: 5,
    name: "Call Date",
  },
  {
    id: 6,
    name: "Call Time",
  },
  {
    id: 7,
    name: "Mortgage Type",
  },
  {
    id: 8,
    name: "Ticket Status",
  },
  {
    id: 9,
    name: "Call Status",
  },
  {
    id: 10,
    name: "Action",
  },
];
export const DipDetailsTableHeadPiece = [
  {
    id: 1,
    name: "Ticket ID",
  },
  {
    id: 2,
    name: "Client Name",
  },
 
  {
    id: 5,
    name: "Call Date",
  },
  {
    id: 6,
    name: "Call Time",
  },
  {
    id: 7,
    name: "Mortgage Type",
  },
 
];