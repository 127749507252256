import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getClientDataThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.status = REQUESTSTATUS.FAILED;
    });
};


export const getClientDataThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING;
    });
};

export const getClientDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.myCase = payload?.mycase;
        state.totalContract = payload?.total;
        state.signedWeekly = payload?.SignedWeekly
        state.signedMonthly =payload?.SignedMonthly
        state.unsignedMonthly = payload?.UnsignedMonthly;
        state.unsignedWeekly = payload?.UnsignedWeekly;
        state.ChangeRequestMonthly=payload?.ChangeRequestMonthly;
        state.ChangeRequestWeekly=payload?.ChangeRequestWeekly;
        

    });
};