import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { UserDetailCard } from "../../components/user-detail-card/user-detail-card";
import { AppBrand } from "../../components/app-brand/app-brand";
import { getDataFromLocalStorage } from "../../utils/views-utils";
import { LOCALSTORAGEAUTHUSERINFO } from "../../constants/local-storage-constant";
import { useAppSelector } from "../../store/store";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";
import NotificationCard from "./notification-card/notification-card";

export const HeaderMainLayout = () => {
  const data = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
  const { personalDetails } = useAppSelector((state) => state);

  return (
    <div className="header">
      <Grid
        container
        className="align-center height-100 bg-white-opacity-color border-bottom-left-radius-24 
            border-bottom-right-radius-24 box-shadow-primary padding-top-0 padding-bottom-0"
      >
        <Grid
          item
          xs={12}
          sm={5.5}
          md={4}
          xl={2}
          className="flex margin-top-0"
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          <AppBrand
            brandLogoContainerClassName="margin-left-1"
            routelink="/dashboard"
          />
        </Grid>
        <Grid item xs={12} md={4} sm={0.1} xl={7}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          xl={3}
          sm={6}
          className="flex margin-top-0"
          justifyContent={{ xs: "center", sm: "flex-end" }}
        >
          <UserDetailCard
            name={personalDetails?.name}
            description={data?.role?.[0]?.replace("_", " ")?.toLowerCase()}
            userDetailsClassName=""
            nameClassName="font-weight-600 primary-text-color tertiary-heading"
            descriptionClassName="float-right light-grey-color font-weight-700 tertiary-title"
            imageDivClassName="margin-left-0 "
            userImageClassName="border-solid-orange"
            userImage={`${IMAGEBASEURL}${personalDetails?.profileImage}`}
          />

          {/* <IconButton className="margin-left-0 margin-right-1 position-relative bg-secondary-background-color mui-icon-button-container-hover">
            <NotificationsIcon className="primary-color mui-icon-button-icon" />
            <FiberManualRecordIcon
              className="black-color position-absolute tertiary-title orange-color fiber-icon"
              style={{ top: "0%", right: "0%" }}
            />
          </IconButton> */}
        </Grid>
      </Grid>
    </div>
  );
};
