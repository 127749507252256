import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  partnerWithUsAsXThunkFulfilledReducer,
  partnerWithUsAsXThunkPendingReducer,
  partnerWithUsAsXThunkRejectedReducer,
} from "./partner-with-us-sales-site-reducers";
import { partnerWithUsAsXThunk } from "./partner-with-us-sales-site-thunk-actions";

const PartnerWithUsSaleSiteInitialState = {
  sentRequestStatus: REQUESTSTATUS.IDLE,
};

export const PartnerWithUsSalesSiteSlice = createSlice({
  name: "partnerWithUsSalesSite",
  initialState: PartnerWithUsSaleSiteInitialState,
  reducers: {},
  extraReducers(builder) {
    partnerWithUsAsXThunkPendingReducer(builder, partnerWithUsAsXThunk);
    partnerWithUsAsXThunkFulfilledReducer(builder, partnerWithUsAsXThunk);
    partnerWithUsAsXThunkRejectedReducer(builder, partnerWithUsAsXThunk);
  },
});

export const {} = PartnerWithUsSalesSiteSlice.actions;

export default PartnerWithUsSalesSiteSlice.reducer;
