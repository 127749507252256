import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { DesktopDatePickerPaperProps } from "./app-date-field-mui-style";
import {
  MUIWHITECOLOR,
  MUIPRIMARYCOLOR,
  MUIERRORCOLOR,
  MUIBORDERRADIUS24,
  MUIBOXSHADOWTERTIARYINSET,
} from "../../constants/mui-styles-constant";
import { DateIcon } from "../../utils/svg-icon-utils";

export const AppDateField = (props: any) => {
  const {
    label,
    placeholder,
    required,
    disableFuture,
    disableHighlightToday,
    borderColor,
    labelClassName,
    onChange,
    maxDate,
    minDate,
    value,
    touched,
    error,
    size,
    openTo,
  } = props;

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div
        className={`primary-title flex margin-bottom-0 align-center ${labelClassName}`}
      >
        {label}
        <span className="danger-color primary-title margin-left-0-2">
          {(required ?? true) && "  *"}
        </span>
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disableFuture={disableFuture || false}
          disableHighlightToday={disableHighlightToday || false}
          maxDate={maxDate}
          minDate={minDate}
          PaperProps={DesktopDatePickerPaperProps}
          value={value}
          openTo={openTo || "day"}
          components={{
            OpenPickerIcon: DateIcon,
          }}
          onChange={(value: any) => onChange?.(value)}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: borderColor || MUIWHITECOLOR,
                    boxShadow: `${MUIBOXSHADOWTERTIARYINSET} !important`,
                    borderRadius: MUIBORDERRADIUS24,
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: MUIPRIMARYCOLOR,
                  },
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: MUIPRIMARYCOLOR,
                  },
                },
                "& .MuiOutlinedInput-root.Mui-error": {
                  "& > fieldset": {
                    borderColor: MUIERRORCOLOR,
                  },
                },
              }}
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder,
                readOnly: true,
              }}
              size={size || "small"}
              className={` ${
                touched && !!error && "border-radius-24 border-error"
              }`}
              onChange={onChange}
              error={touched && !!error}
            />
          )}
        />
      </LocalizationProvider>
      <span className="error-color primary-title">{touched && error}</span>
    </>
  );
};
