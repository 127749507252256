import totalContractImg from "../../../assets/images/app-short-summary-cards/total-contracts.png";
import signedContractImg from "../../../assets/images/app-short-summary-cards/signed-contract.png";
import unsignedContractImg from "../../../assets/images/app-short-summary-cards/unsigned-contracts.png";
import changeRequestImg from "../../../assets/images/app-short-summary-cards/change-request-contracts.png";

import { Grid } from "@mui/material";
import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";
import { useAppSelector } from "../../../store/store";

export const SolicitorDahboardCardData = () => {
  const { solicitorData } =
    useAppSelector((state: any) => state.solicitorDashboardSlice)
  const solicitorCardData: any = [
    {
      id: 1,
      bgColor: "#366EC2",
      value: solicitorData?.contracts?.total?.thisMonth,
      value2:
        // solicitorData?.contracts?.total?.thisMonth
        '0'
      ,
      title: "Total Contracts",
      imgSrc: totalContractImg,
      thisWeekMonthToggler: false,
    },
    {
      id: 2,
      bgColor: "#48995D",
      value: solicitorData?.contracts?.signed?.thisWeek,
      value2: solicitorData?.contracts?.signed?.thisMonth,
      title: "Signed Contracts",
      imgSrc: signedContractImg,
      thisWeekMonthToggler: true,
    },
    {
      id: 3,
      bgColor: "#FF3F3F",
      value: solicitorData?.contracts?.unsigned?.thisWeek,
      value2: solicitorData?.contracts?.unsigned?.thisMonth,
      title: "Unsigned Contracts",
      imgSrc: unsignedContractImg,
      thisWeekMonthToggler: true,
    },
    {
      id: 4,
      bgColor: "#FA7901",
      value: solicitorData?.contracts?.changeRequested?.thisWeek,
      value2: solicitorData?.contracts?.changeRequested?.thisMonth,
      title: "Change Request Contracts",
      imgSrc: changeRequestImg,
      thisWeekMonthToggler: true,
    },
  ];
  return (
    <>
      {solicitorCardData?.map((ele: any) => (
        <Grid item xl={2.9} lg={5.9} sm={5.9} md={11.9} xs={11.9}
          marginBottom={{ xl: 0, xs: 2 }} key={ele?.id}
        >
          <ShortSummaryCards
            bgColor={ele?.bgColor}
            value={ele?.value || 0}
            title={ele?.title}
            imgSrc={ele?.imgSrc}
            thisWeekMonthToggler={ele?.thisWeekMonthToggler}
            value2={ele?.value2 || 0}
            firstLabel={"This Week"}
            secondLabel={"This Month"}
            isSolicitor
          />
        </Grid>
      )
      )}
    </>
  )
}
