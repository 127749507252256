import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getAssigneesFulfilledReducer,
  getAssigneesPendingReducer,
  getAssigneesRejectedReducer,
  getCertificateFulfilledReducer,
  getCertificatePendingReducer,
  getCertificateRejectedReducer,
  getTicketsFulfilledReducer,
  getTicketsPendingReducer,
  getTicketsRejectedReducer,
  patchAssigneesFulfilledReducer,
  patchAssigneesPendingReducer,
  patchAssigneesRejectedReducer,
  patchAssignToMeFulfilledReducer,
  patchAssignToMePendingReducer,
  patchAssignToMeRejectedReducer,
  patchCompleteFulfilledReducer,
  patchCompletePendingReducer,
  patchCompleteRejectedReducer,
  patchUnassignFulfilledReducer,
  patchUnassignPendingReducer,
  patchUnassignRejectedReducer,
  postDemoFulfilledReducer,
  postDemoPendingReducer,
  postDemoRejectedReducer,
  postEvidenceResearchFulfilledReducer,
  postEvidenceResearchPendingReducer,
  postEvidenceResearchRejectedReducer,
  postIllustrationFulfilledReducer,
  postIllustrationPendingReducer,
  postIllustrationRejectedReducer,
  postPaymentScheduleFulfilledReducer,
  postPaymentSchedulePendingReducer,
  postPaymentScheduleRejectedReducer,
} from "./tickets-reducers";
import {
  getAssigneesListThunk,
  getTicketsListThunk,
  patchAssigneesListThunk,
  patchAssignToMeThunk,
  patchCompleteThunk,
  patchUnassignThunk,
  postDemoThunk,
  postIllustrationThunk,
  postEvidenceResearchThunk,
  postPaymentScheduleThunk,
  getCertificateThunk,
  sendEvidenceResearchEmailThunk,
  sendIllustrationEmailThunk,
  sendPaymentScheduleEmailThunk,
} from "./tickets-thunk-actions";

const ticketsListInitialState: any = {
  paymentScheduleStatus: REQUESTSTATUS.IDLE,
  evidenceResearchStatus: REQUESTSTATUS.IDLE,
  illustrationStatus: REQUESTSTATUS.IDLE,
  assignStatus: REQUESTSTATUS.IDLE,
  ticketsListData: [],
  assigneesListData: [],
  paymentSchedulePdfUrl: "",
  evidenceResearchPdfUrl: "",
  illustrationPdfUrl: "",
};

export const ticketsSlice = createSlice({
  name: "ticketsList",
  initialState: ticketsListInitialState,
  reducers: {},
  extraReducers(builder) {
    getTicketsRejectedReducer(builder, getTicketsListThunk);
    getTicketsFulfilledReducer(builder, getTicketsListThunk);
    getTicketsPendingReducer(builder, getTicketsListThunk);

    getAssigneesRejectedReducer(builder, getAssigneesListThunk);
    getAssigneesFulfilledReducer(builder, getAssigneesListThunk);
    getAssigneesPendingReducer(builder, getAssigneesListThunk);

    patchAssigneesRejectedReducer(builder, patchAssigneesListThunk);
    patchAssigneesFulfilledReducer(builder, patchAssigneesListThunk);
    patchAssigneesPendingReducer(builder, patchAssigneesListThunk);

    patchAssignToMeRejectedReducer(builder, patchAssignToMeThunk);
    patchAssignToMeFulfilledReducer(builder, patchAssignToMeThunk);
    patchAssignToMePendingReducer(builder, patchAssignToMeThunk);

    patchUnassignRejectedReducer(builder, patchUnassignThunk);
    patchUnassignFulfilledReducer(builder, patchUnassignThunk);
    patchUnassignPendingReducer(builder, patchUnassignThunk);

    patchCompleteRejectedReducer(builder, patchCompleteThunk);
    patchCompleteFulfilledReducer(builder, patchCompleteThunk);
    patchCompletePendingReducer(builder, patchCompleteThunk);

    postDemoRejectedReducer(builder, postDemoThunk);
    postDemoFulfilledReducer(builder, postDemoThunk);
    postDemoPendingReducer(builder, postDemoThunk);

    postIllustrationPendingReducer(builder, postIllustrationThunk);
    postIllustrationFulfilledReducer(builder, postIllustrationThunk);
    postIllustrationRejectedReducer(builder, postIllustrationThunk);

    postEvidenceResearchRejectedReducer(builder, postEvidenceResearchThunk);
    postEvidenceResearchFulfilledReducer(builder, postEvidenceResearchThunk);
    postEvidenceResearchPendingReducer(builder, postEvidenceResearchThunk);

    postPaymentScheduleRejectedReducer(builder, postPaymentScheduleThunk);
    postPaymentScheduleFulfilledReducer(builder, postPaymentScheduleThunk);
    postPaymentSchedulePendingReducer(builder, postPaymentScheduleThunk);

    // postPaymentScheduleRejectedReducer(builder, postPaymentScheduleEmailThunk);
    // postPaymentScheduleFulfilledReducer(builder, postPaymentScheduleEmailThunk);
    // postPaymentSchedulePendingReducer(builder, postPaymentScheduleEmailThunk);

    // postPaymentScheduleRejectedReducer(builder, postIllustrationEmailThunk);
    // postPaymentScheduleFulfilledReducer(builder, postIllustrationEmailThunk);
    // postPaymentSchedulePendingReducer(builder, postIllustrationEmailThunk);

    // postPaymentScheduleRejectedReducer(builder, postEvidenceResearchEmailThunk);
    // postPaymentScheduleFulfilledReducer(builder, postEvidenceResearchEmailThunk);
    // postPaymentSchedulePendingReducer(builder, postEvidenceResearchEmailThunk);

    getCertificateRejectedReducer(builder, getCertificateThunk);
    getCertificateFulfilledReducer(builder, getCertificateThunk);
    getCertificatePendingReducer(builder, getCertificateThunk);
  },
});

export const {} = ticketsSlice.actions;

export default ticketsSlice.reducer;
