import { createSlice } from "@reduxjs/toolkit";

import {
  loginThunkFulfilledReducer,
  loginThunkRejectedReducer,
  logoutThunkFulfilledReducer,
  logoutThunkRejectedReducer,
  registerThunkFulfilledReducer,
  registerThunkRejectedReducer,
  setLoginSuccessReducer,
  setProfileImageReducer,
  setUploadProfileImageReducer,
  setUserDataReducer,
  ThunkPendingReducer,
  changePasswordThunkFulfillReducer,
  changePasswordThankRejectedReducer,
  forgotPasswordThunkFulfilledReducer,
  forgotPasswordThunkRejectedReducer,
  resetPasswordThunkFulfilledReducer,
  resetPasswordThunkRejectReducer,
  refreshTokenThunkFulfilledReducer,
  refreshTokenThunkRejectedReducer,
  resendLinkThunkFulfilledReducer,
  resendLinkThunkRejectedReducer,
  verifyEmailThunkFulfilledReducer,
  verifyEmailThunkRejectedReducer,
  changeEmailThunkFulfillReducer,
  changeEmailThunkRejectedReducer,
  verifyPasswordThunkRejectedReducer,
  verifyPasswordThunkFulfilledReducer,
  verifyChangeEmailThunkRejectedReducer,
  verifyChangeEmailThunkFulfilledReducer,
  verifyTokenThunkPendingReducer,
  verifyTokenThunkFulfilledReducer,
  verifyTokenThunkRejectedReducer,
  checkPasswordPendingReducer,
  checkPasswordFulfilledReducer,
  checkPasswordRejectedReducer,
} from "./auth-reducers";

import {
  changeEmailThunk,
  changePasswordThunk,
  checkPasswordThunk,
  forgotPasswordThunk,
  loginThunk,
  logoutThunk,
  refreshTokenThunk,
  registerThunk,
  resendVerificationRequestThunk,
  resetPasswordThunk,
  verifyChangeEmailThunk,
  verifyEmailThunk,
  verifyPasswordThunk,
  verifyTokenThunk,
} from "./auth-thunk-actions";

import { IAUTHSTATE } from "./auth-slice-interface";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

const authInitialState: IAUTHSTATE = {
  status: REQUESTSTATUS.IDLE, //'idle' | 'loading' | 'succeeded' | 'failed'
  user: null,
  error: "",
  token: "",
  refreshToken: "",
  success: "",
  role: [],
  setUploadProfileImage: false,
  message: "",
  userEmail: "",
  idVerified: false,
  idVerificationStatus: "",
  idVerificationDisabled: "",
  emailVerified: false,
  isPasswordReset: false,
  isEmailChanged: false,
  verifyStatus: REQUESTSTATUS.IDLE,
  shouldReferAdmin: {},
};

const authSlice = createSlice({
  name: "auth",

  initialState: authInitialState,

  reducers: {
    clearSignupForm: (state: any, action) => {
      state.clearSignupInitialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        defaultRole: action.payload,
      };
    },
    resetErrorAndMessage: (state: any) => {
      state.error = "";
      state.message = "";
    },
    // no more reducers can be made here. only these two if needed i will make.
    setUploadProfileImage: setUploadProfileImageReducer,
    setProfileImage: setProfileImageReducer,

    setLoginSuccess: setLoginSuccessReducer,
    setUserData: setUserDataReducer,
  },

  extraReducers(builder) {
    //login thunk reducers
    ThunkPendingReducer(builder, loginThunk);
    loginThunkFulfilledReducer(builder, loginThunk);
    loginThunkRejectedReducer(builder, loginThunk);

    //register thunk reducers
    ThunkPendingReducer(builder, registerThunk);
    registerThunkFulfilledReducer(builder, registerThunk);
    registerThunkRejectedReducer(builder, registerThunk);

    //logout thunk reducers
    ThunkPendingReducer(builder, logoutThunk);
    logoutThunkFulfilledReducer(builder, logoutThunk);
    logoutThunkRejectedReducer(builder, logoutThunk);

    //Change Password reducers
    ThunkPendingReducer(builder, changePasswordThunk);
    changePasswordThunkFulfillReducer(builder, changePasswordThunk);
    changePasswordThankRejectedReducer(builder, changePasswordThunk);

    //Change Email reducers
    ThunkPendingReducer(builder, changeEmailThunk);
    changeEmailThunkFulfillReducer(builder, changeEmailThunk);
    changeEmailThunkRejectedReducer(builder, changeEmailThunk);

    // forgot password reducers

    ThunkPendingReducer(builder, forgotPasswordThunk);
    forgotPasswordThunkFulfilledReducer(builder, forgotPasswordThunk);
    forgotPasswordThunkRejectedReducer(builder, forgotPasswordThunk);

    // reset-password reducers
    // resend link reducers

    ThunkPendingReducer(builder, resendVerificationRequestThunk);
    resendLinkThunkFulfilledReducer(builder, resendVerificationRequestThunk);
    resendLinkThunkRejectedReducer(builder, resendVerificationRequestThunk);
    // verify email reducers

    ThunkPendingReducer(builder, verifyEmailThunk);
    verifyEmailThunkFulfilledReducer(builder, verifyEmailThunk);
    verifyEmailThunkRejectedReducer(builder, verifyEmailThunk);
    // reset-password reducers

    ThunkPendingReducer(builder, resetPasswordThunk);
    resetPasswordThunkFulfilledReducer(builder, resetPasswordThunk);
    resetPasswordThunkRejectReducer(builder, resetPasswordThunk);

    // refresh token
    ThunkPendingReducer(builder, refreshTokenThunk);
    refreshTokenThunkFulfilledReducer(builder, refreshTokenThunk);
    refreshTokenThunkRejectedReducer(builder, refreshTokenThunk);

    //verify password
    ThunkPendingReducer(builder, verifyPasswordThunk);
    verifyPasswordThunkFulfilledReducer(builder, verifyPasswordThunk);
    verifyPasswordThunkRejectedReducer(builder, verifyPasswordThunk);

    ThunkPendingReducer(builder, verifyChangeEmailThunk);
    verifyChangeEmailThunkFulfilledReducer(builder, verifyChangeEmailThunk);
    verifyChangeEmailThunkRejectedReducer(builder, verifyChangeEmailThunk);

    verifyTokenThunkPendingReducer(builder, verifyTokenThunk);
    verifyTokenThunkFulfilledReducer(builder, verifyTokenThunk);
    verifyTokenThunkRejectedReducer(builder, verifyTokenThunk);

    checkPasswordPendingReducer(builder, checkPasswordThunk);
    checkPasswordFulfilledReducer(builder, checkPasswordThunk);
    checkPasswordRejectedReducer(builder, checkPasswordThunk);
  },
});

export const getUserIdSelector = (state: any) => state?.auth?.user?.userId;
export const getUserRoles = (state: any) => state?.auth?.user?.roles;

export const {
  setLoginSuccess,
  setProfileImage,
  setUserData,
  setUploadProfileImage,
  clearSignupForm,
  resetErrorAndMessage,
} = authSlice.actions;

export default authSlice.reducer;
