import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getLatestNotificationThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {
        
        state.status = REQUESTSTATUS.FAILED;
    });
};


export const getLatestNotificationThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING;
    });
};

export const getLatestNotificationThunkFulFilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.lastestNotificationData=payload
    });
};