import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addBuyerContractThunkFulfilledReducer,
  addBuyerContractThunkPendingReducer,
  addBuyerContractThunkRejectedReducer,
  addSellerContractThunkFulfilledReducer,
  addSellerContractThunkPendingReducer,
  addSellerContractThunkRejectedReducer,
  embedSignatureOnPdfThunkFulfilledReducer,
  embedSignatureOnPdfThunkPendingReducer,
  embedSignatureOnPdfThunkRejectedReducer,
  getBuyerContractContentThunkFulfilledReducer,
  getBuyerContractContentThunkPendingReducer,
  getBuyerContractContentThunkRejectedReducer,
  getContractsThunkFulfilledReducer,
  getContractsThunkPendingReducer,
  getContractsThunkRejectedReducer,
  getSingleContractContentThunkFulfilledReducer,
  getSingleContractContentThunkPendingReducer,
  getSingleContractContentThunkRejectedReducer,
  signOrChangeReqContractThunkFulfilledReducer,
  signOrChangeReqContractThunkPendingReducer,
  signOrChangeReqContractThunkRejectedReducer,
  updateContractContentThunkFulfilledReducer,
  updateContractContentThunkPendingReducer,
  updateContractContentThunkRejectedReducer,
} from "./contracts-reducers";
import {
  addSellerContractThunk,
  embedSignatureContractThunk,
  getBuyerFormContentThunk,
  getContentOfSignleContractThunk,
  getContractsThunk,
  sendBuyerContractThunk,
  signOrChangeContractThunk,
  updateContractThunk,
} from "./contracts-thunk-actions";

const contractInitialState = {
  status: REQUESTSTATUS.IDLE,
  contracts: [] as any,
  pagination: { total: 0, page: 0, pages: 0 },
  htmlContentForBuyerContract: "",
  singleContractInfoHolder: {} as any,
};

export const contracts = createSlice({
  name: "contracts",
  initialState: contractInitialState,
  reducers: {},
  extraReducers(builder) {
    getContractsThunkFulfilledReducer(builder, getContractsThunk);
    getContractsThunkPendingReducer(builder, getContractsThunk);
    getContractsThunkRejectedReducer(builder, getContractsThunk);

    addSellerContractThunkFulfilledReducer(builder, addSellerContractThunk);
    addSellerContractThunkPendingReducer(builder, addSellerContractThunk);
    addSellerContractThunkRejectedReducer(builder, addSellerContractThunk);

    signOrChangeReqContractThunkRejectedReducer(
      builder,
      signOrChangeContractThunk
    );
    signOrChangeReqContractThunkFulfilledReducer(
      builder,
      signOrChangeContractThunk
    );
    signOrChangeReqContractThunkPendingReducer(
      builder,
      signOrChangeContractThunk
    );

    getBuyerContractContentThunkRejectedReducer(
      builder,
      getBuyerFormContentThunk
    );
    getBuyerContractContentThunkFulfilledReducer(
      builder,
      getBuyerFormContentThunk
    );
    getBuyerContractContentThunkPendingReducer(
      builder,
      getBuyerFormContentThunk
    );

    addBuyerContractThunkRejectedReducer(builder, sendBuyerContractThunk);
    addBuyerContractThunkFulfilledReducer(builder, sendBuyerContractThunk);
    addBuyerContractThunkPendingReducer(builder, sendBuyerContractThunk);

    getSingleContractContentThunkRejectedReducer(
      builder,
      getContentOfSignleContractThunk
    );
    getSingleContractContentThunkFulfilledReducer(
      builder,
      getContentOfSignleContractThunk
    );
    getSingleContractContentThunkPendingReducer(
      builder,
      getContentOfSignleContractThunk
    );

    updateContractContentThunkRejectedReducer(builder, updateContractThunk);
    updateContractContentThunkFulfilledReducer(builder, updateContractThunk);
    updateContractContentThunkPendingReducer(builder, updateContractThunk);

    embedSignatureOnPdfThunkRejectedReducer(
      builder,
      embedSignatureContractThunk
    );
    embedSignatureOnPdfThunkFulfilledReducer(
      builder,
      embedSignatureContractThunk
    );
    embedSignatureOnPdfThunkPendingReducer(
      builder,
      embedSignatureContractThunk
    );
  },
});

export const {} = contracts.actions;

export default contracts.reducer;
