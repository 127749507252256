import { Grid } from "@mui/material";
import logo from "../../../assets/logo/logo.svg";
export const BuyerOptionHeader = () => {
    return (
        <Grid container>
            <Grid item sm={6} container alignItems="center">
                <img src={logo} alt="footer-logo" />
            </Grid>
            <Grid container justifyContent="flex-end" item sm={6}>
                <Grid item>
                    <p>
                        <span>Phone:</span>030164001212
                    </p>
                    <p>
                        <span>Email:</span>google@gmail.com
                    </p>
                    <p>
                        <span>Address:</span>Anywhere ssst etc
                    </p>
                </Grid>
            </Grid>
        </Grid>
    );
};
