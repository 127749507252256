import { Navigate } from "react-router-dom";
import { LOCALSTORAGEAUTHUSERINFO } from "../constants/local-storage-constant";
import { useAppSelector } from "../store/store";
import { getDataFromLocalStorage } from "../utils/views-utils";

export const AuthProtect: React.FC<any> = (props: any) => {
  // const { auth } = useAppSelector((state) => state);
  const auth = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
  // in case of sale site     <Navigate to="/home" /> else    <Navigate to="/" />
  return !auth?.token ? <>{props.children}</> : <Navigate to="/" />;
  // uncomment it if backend does not work
  // return <>{props.children}</>
};
