import { REQUESTSTATUS } from "../../constants/axios-api-constant";

// Tickets List Page Get
export const getTicketsRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const getTicketsFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsListData = payload;
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getTicketsPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Assignees List Page Get
export const getAssigneesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.assignStatus = REQUESTSTATUS.FAILED;
  });
};

export const getAssigneesFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.assigneesListData = payload;
    state.assignStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getAssigneesPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.assignStatus = REQUESTSTATUS.PENDING;
  });
};

// Assignees List Page Patch
export const patchAssigneesRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchAssigneesFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchAssigneesPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Assignees To Me Patch
export const patchAssignToMeRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchAssignToMeFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchAssignToMePendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Unassign Patch
export const patchUnassignRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchUnassignFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchUnassignPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Mark As Complete Patch
export const patchCompleteRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchCompleteFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchCompletePendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Post a Demo
export const postDemoRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const postDemoFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postDemoPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

// Post illustrations
export const postIllustrationRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.illustrationStatus = REQUESTSTATUS.FAILED;
  });
};

export const postIllustrationFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.illustrationPdfUrl = payload.data.data;
    state.illustrationStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postIllustrationPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.illustrationStatus = REQUESTSTATUS.PENDING;
  });
};

export const postEvidenceResearchRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.evidenceResearchStatus = REQUESTSTATUS.FAILED;
  });
};

export const postEvidenceResearchFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    console.log(payload);

    state.evidenceResearchPdfUrl = payload.data.data;
    state.evidenceResearchStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postEvidenceResearchPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.evidenceResearchStatus = REQUESTSTATUS.PENDING;
  });
};

export const postPaymentScheduleRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.paymentScheduleStatus = REQUESTSTATUS.FAILED;
  });
};

export const postPaymentScheduleFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.paymentSchedulePdfUrl = payload.data.data;
    state.paymentScheduleStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postPaymentSchedulePendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.paymentScheduleStatus = REQUESTSTATUS.PENDING;
  });
};

export const getCertificatePendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.PENDING;
  });
};

export const getCertificateRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.ticketsStatus = REQUESTSTATUS.FAILED;
  });
};

export const getCertificateFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    console.log(payload);

    state.certificate = payload.data.data;
    state.ticketsStatus = REQUESTSTATUS.SUCCEEDED;
  });
};
