import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { AppButton } from "../app-button/app-button";
import ModalLayout from "../modal-layout/modal-layout";
import { SignatureOptions } from "../sign-contract-signature-options/sign-contract-signature-options";
import { SIGNATURECONTENTINTERFACE } from "../sign-contract-signature-options/OPTIONINTERFACE";
import { SIGNATUREPADINTERFACE } from "./SIGNATUREPADINTERFACE";

export const SignaturePad: React.FC<SIGNATUREPADINTERFACE> = ({
  signatureContentHan,
  signFileHandler
}) => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [signatureContent, setSignatureContent] =
    useState<SIGNATURECONTENTINTERFACE>({
      type: "",
      content: "",
      signed: false,
      file: ''
    });
  const handleSign = (type: string, content: string) => {
    setSignatureContent((pre) => ({ ...pre, type, content }));
    setPreviewModalOpen(false);
    signatureContentHan(type, content);

  };
  const handleFileChange = (file: any) => {
    signFileHandler(file);
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "150px",
          backgroundColor: 'rgba(54, 110, 194, 0.05)',
          border: '1px solid #0000001c'
        }}
        className="margin-top-1"
      >
        <div style={{ textAlign: "center", overflow: 'hidden', width: "100%", height: "100%" }}>
          {/* Draw  */}
          {signatureContent.type === "draw" && (
            <img
              style={{ width: "100%", height: "100%" }}
              src={signatureContent.content}
            />
          )}

          {/* Type  */}
          {signatureContent.type === "type" && (
            <p>{signatureContent.content}</p>
          )}

          {/* uploaded image  */}
          {signatureContent.type === "upload" && (
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={signatureContent.content}
            />
          )}
        </div>
      </div>
      <div style={{ textAlign: "right", margin: "1em 0" }}>
        <AppButton
          btnText="Add"
          className="btn-blue cursor-pointer tertiary-title add-item-btn"
          style={{ backgroundcolor: "red" }}
          btnFunction={() => setPreviewModalOpen(true)}
          btnIcon={<AddIcon />}
        />
      </div>
      {/* temp div for model */}
      {isPreviewModalOpen && (
        <ModalLayout
          isModalOpen={isPreviewModalOpen}
          setIsModalOpen={setPreviewModalOpen}
          leftMd={4}
          centerMd={3}
          rightMd={4}
        >
          <SignatureOptions handleSign={handleSign} handleFileChange={handleFileChange} />
        </ModalLayout>
      )}
    </>
  );
};
