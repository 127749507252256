import { useState, useRef } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SignatureCanvas from "react-signature-canvas";
import { Draw } from "./options/draw";
import { Type } from "./options/type";
import { Upload } from "./options/upload";
import { AppButton } from "../app-button/app-button";
import { SIGNATUREOPTIONPROPS } from "./OPTIONINTERFACE";

export const SignatureOptions: React.FC<SIGNATUREOPTIONPROPS> = ({
  handleSign,
  handleFileChange,
}) => {
  const [option, setOptions] = useState<string>("draw");
  const [signValue, setSignValue] = useState<string>("");
  const [imgSignFile, setImgSignFile] = useState<string>("");
  const [imgFile, setImgFile] = useState<string>("");
  const signatureRef = useRef<SignatureCanvas>(null);

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions((event.target as HTMLInputElement).value);
  };

  const handleSignClickHan = () => {
    switch (option) {
      case "draw":
        const url =
          signatureRef.current?.getTrimmedCanvas().toDataURL("image/png") || "";
        handleSign(option, url);
        break;
      case "type":
        handleSign(option, signValue);
        break;
      case "upload":
        handleSign(option, imgSignFile);
        handleFileChange(imgFile);
        break;
      case "default":
    }
  };

  const signValChangeHand = (e: any) => {
    if (e.target.value.length > 500)
      return alert(
        "Etna Lamba Signature to nai hota, message bta dayye update kr donga"
      );
    setSignValue(e.target.value);
  };

  const imageSignatureHan = (file: any) => {
    setImgSignFile(file);
  };

  const fileChanheHand = (file: any) => {
    setImgFile(file);
  };
  const clearSignatures = () => {
    setSignValue("");
    setImgSignFile("");
    setImgFile("");
    signatureRef?.current?.clear();
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="primary-color font-weight-600 primary-heading">
        eSignature
      </div>
      <div className="primary-text-color font-weight-500 margin-bottom-1">
        Input type
      </div>
      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label">Input type</FormLabel> */}
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={option}
          name="radio-buttons-group"
          onChange={handleChangeOption}
        >
          <FormControlLabel value="draw" control={<Radio />} label="Draw" />
          <FormControlLabel value="type" control={<Radio />} label="Type" />
          <FormControlLabel value="upload" control={<Radio />} label="Upload" />
        </RadioGroup>
      </FormControl>

      {/* Options  */}
      {option === "draw" && <Draw signatureRef={signatureRef} />}
      {option === "type" && (
        <Type value={signValue} valChangeHand={signValChangeHand} />
      )}
      {option === "upload" && (
        <Upload
          value={imgSignFile}
          valChangeHand={imageSignatureHan}
          fileChanheHand={fileChanheHand}
        />
      )}

      {/* clear and sign btns  */}
      <Grid container>
        <Hidden smDown>
          <Grid item sm={12} container justifyContent="flex-end">
            <AppButton
              btnText="Clear"
              className="btn-orange cursor-pointer tertiary-title "
              btnFunction={clearSignatures}
              btnIconRight={<RestartAltIcon />}
            />
            <AppButton
              btnText="Sign"
              className="btn-orange cursor-pointer tertiary-title"
              btnFunction={handleSignClickHan}
              btnIconRight={<CheckCircleRoundedIcon />}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={10}>
              <AppButton
                btnText="Clear"
                className="btn-orange cursor-pointer tertiary-title margin-top-1"
                btnFunction={clearSignatures}
                btnIconRight={<RestartAltIcon />}
              />
              <AppButton
                btnText="Sign"
                className="btn-orange cursor-pointer tertiary-title margin-top-1"
                btnFunction={handleSignClickHan}
                btnIconRight={<CheckCircleRoundedIcon />}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};
