import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getReviewsListPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.PENDING;
  });
};
export const getReviewsListFulFilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.SUCCEEDED;
    state.reviewsList = payload?.data;
  });
};
export const getReviewsDataListRejectReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.FAILED;
  });
};

export const postReviewPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.PENDING;
  });
};
export const postReviewFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.SUCCEEDED;
  });
};
export const postReviewRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.reviewsListStatus = REQUESTSTATUS.FAILED;
  });
};
