// MUI
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Images
import DashboardIcon from "../../assets/svg/layout/dashboard.svg";
import DelegateIcon from "../../assets/svg/layout/delegate.svg";
import ReviewsIcon from "../../assets/svg/layout/reviews.svg";
import ProfileIcon from "../../assets/svg/layout/profile.svg";
import TicketsIcon from "../../assets/svg/layout/tickets.svg";

import CaseIcon from "../../assets/svg/layout/case.svg";
import DocumentIcon from "../../assets/svg/layout/document.svg";
import ContractIcon from "../../assets/svg/layout/contract.svg";
import DayPlannerIcon from "../../assets/svg/layout/day-planner.svg";
import SettingsIcon from "../../assets/svg/layout/settings.svg";
// import TicketsIcon from "../../assets/svg/layout/tickets.svg";
// Interfaces
import { ISIDEBARDATA, ISIDEBARICONS } from "./sidebar-interface";
import {
  HELPPATH,
  DASHBOARDPATH,
  DAYPLANNERPATH,
  SLASHPATH,
  DIPPATH,
  DIPHISTORYPATH,
  FINDASOLICITORPATH,
  SOLICITORPATH,
  CONTRACTPATH,
  CONTRACTLISTSPATH,
  CASESLISTSPATH,
  CASESPATH,
  DELEGATESLISTSPATH,
  DELEGATESPATH,
  REVIEWSPATH,
  REVIEWSOVERALLRATINGSPATH,
  PROFILEMANAGEMENTPATH,
  PROFILEPATH,
  TICKETSPATH,
  USERSPATH,
  USERONBOARDINGREQUESTSPATH,
  USERSREQUESTPATH,
  QUOTEPATH,
  SETTINGSPATH,
  DOCUMENTSPATH,
  CLIENTSPATH,
} from "../../constants/routes-constant";

import { USERROLES } from "../../constants/roles-constant";

export const sidebarData: any[] = [
  {
    id: 1,
    title: "Dashboard",
    path: DASHBOARDPATH,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    icon: DashboardIcon,
  },
  {
    id: 2,
    title: "User Management",
    path: `${USERSPATH}`,
    allowedRoles: [USERROLES.ADMIN],
    icon: CaseIcon,
  },
  {
    id: 3,
    title: "Onboarding Request",
    path: `${USERSREQUESTPATH}`,
    allowedRoles: [USERROLES.ADMIN],
    icon: CaseIcon,
  },

  {
    id: 4,
    title: "Tickets",
    path: TICKETSPATH,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
    ],
    icon: TicketsIcon,
  },
  {
    id: 5,
    title: "Quote Management",
    path: `${QUOTEPATH}`,
    allowedRoles: [USERROLES.ADMIN],
    icon: CaseIcon,
  },
  {
    id: 6,
    title: "Cases",
    path: `${CASESPATH}`,
    allowedRoles: [USERROLES.SOLICITOR],
    icon: CaseIcon,
  },
  {
    id: 6.2,
    title: "Clients",
    path: `${CLIENTSPATH}`,
    allowedRoles: [USERROLES.ESTATEAGENT],
    icon: CaseIcon,
  },
  {
    id: 7,
    title: "Documents",
    path: `${DOCUMENTSPATH}`,
    allowedRoles: [USERROLES.SOLICITOR],
    icon: DocumentIcon,
  },
  {
    id: 8,
    title: "DIP History",
    path: `${DIPPATH}`,
    allowedRoles: [USERROLES.CLIENT],
    icon: CaseIcon,
    iconClosed: <AddIcon />,
    iconOpened: <RemoveIcon />,

    subNav: [
      {
        id: "1",
        title: "Menu Preview",
        allowedRoles: [USERROLES.CLIENT],
        // path: MENUPATH + SLASHPATH + MENUPREVIEWPATH,
      },
      {
        id: "2",
        title: "Create Menu",
        allowedRoles: [USERROLES.CLIENT],
        // path: MENUPATH + SLASHPATH + CREATEMENUPATH,
      },
    ],
  },
  {
    id: 9,
    title: "Find a Solicitor",
    path: `${SOLICITORPATH}`,
    allowedRoles: [USERROLES.CLIENT],
    icon: CaseIcon,
  },

  {
    id: 10,
    title: "Contract",
    path: `${CONTRACTPATH}`,
    allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
    icon: ContractIcon,
  },

  {
    id: 11,
    title: "Case Tracking",
    path: `${CASESPATH}`,
    allowedRoles: [USERROLES.CLIENT],
    icon: CaseIcon,
    iconClosed: <AddIcon />,
    iconOpened: <RemoveIcon />,
    subNav: [
      {
        id: "1",
        title: "Overall rating",
        allowedRoles: [USERROLES.CLIENT, USERROLES.SALESAGENT],
        // path: REVIEWSANDFEEDBACKPATH + SLASHPATH + OVERALLRATINGSPATH,
      },
      {
        id: "2",
        title: "Rate guest",
        allowedRoles: [USERROLES.CLIENT],
        // path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
      },

      {
        id: "3",
        title: "Rate host",
        allowedRoles: [USERROLES.SALESAGENT],
        // path: REVIEWSANDFEEDBACKPATH + SLASHPATH + RATINGRECORDSPATH,
      },
    ],
  },
  {
    id: 12,
    title: "Delegates",
    path: `${DELEGATESPATH}`,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    icon: DelegateIcon,
  },
  {
    id: 13,
    title: "Day Planner",
    path: DAYPLANNERPATH,
    allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
    icon: DayPlannerIcon,
  },
  // {
  //   id: 14,
  //   title: "Help",
  //   path: HELPPATH,
  //   allowedRoles:[USERROLES.CLIENT,USERROLES.SALESAGENT, USERROLES.ESTATEAGENT, USERROLES.ADMIN, USERROLES.MORTGAGEBROKER, USERROLES.SOLICITOR],
  //   icon: HelpIcon
  // },

  {
    id: 15,
    title: "Profile Management",
    path: `${PROFILEMANAGEMENTPATH}`,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    icon: ProfileIcon,
  },
  {
    id: 16,
    title: "Reviews",
    path: `${REVIEWSPATH}`,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    icon: ReviewsIcon,
  },
  {
    id: 17,
    title: "Settings",
    path: `${SETTINGSPATH}`,
    allowedRoles: [USERROLES.ADMIN],
    icon: SettingsIcon,
  },
];

export const sidebarIconData: any[] = [
  {
    id: 1,
    icon: <img src={ProfileIcon} alt="profile-icon" />,
    // path: ACCOUNTSETTINGSPATH,
  },
  {
    id: 2,
    icon: <img src={DayPlannerIcon} alt="calendar-icon" />,
    path: DAYPLANNERPATH,
  },
];
