import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getMortgageBrokerThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.status = REQUESTSTATUS.FAILED;
    });
};


export const getMortgageBrokerThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING;
    });
};

export const getMortgageBrokerThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.mortgageBrokerData = payload?.dashboard


    });
};




export const getMortgageBrokerYearThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.yearStatus = REQUESTSTATUS.FAILED;
    });
};


export const getMortgageBrokerYearThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.yearStatus = REQUESTSTATUS.PENDING;
    });
};

export const getMortgageBrokerYearThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {

        

        state.yearStatus = REQUESTSTATUS.SUCCEEDED;
        state.mortgageBrokerUsersData = payload?.users
        // state.estateAgentUser = payload?.users

    });
};