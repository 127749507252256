import { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { axiosInstance } from "../../../lib/axios";
import { AppInputField } from "../../../components/app-input-field/app-input-field";
import { findASolicitorMortgageTypeList } from "./sign-contract-data";
// import { SignatureOptions } from './sign-contract-signature-options/sign-contract-signature-options';
import ModalLayout from "../../../components/modal-layout/modal-layout";
import { AppButton } from "../../../components/app-button/app-button";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import {
  hideBreadcrumb,
  showBreadcrumb,
} from "../../../store/breadcrumb/breadcrumb-slice";
import { breadcrumbCaseId, breadcrumbdataForSignContract } from "./breadcrumbs";
import { SignatureOptions } from "../../../components/sign-contract-signature-options/sign-contract-signature-options";
import { RenderHtmlQuill } from "./render-html-quill";
import { TextEditor } from "./text-editior/text-editor";
import { SignaturePad } from "../../../components/signature-pad/signature-pad";
import { tempDataForQuill } from "./text-editior/temp-data";
import { Signpads } from "./signature-pads/signature-pads";
import { useNavigate, useParams } from "react-router-dom";
import {
  getContentOfSignleContractThunk,
  signOrChangeContractThunk,
} from "../../../store/contracts/contracts-thunk-actions";
import AppLoader from "../../../components/app-loader/app-loader";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { ReadOnlyMood } from "../../../components/app-text-editor/read-only-mood";
import { Reactpdf } from "../../../components/react-pdf/react-pdf";
import { dataURLtoFile } from "../../../utils/data-conversion-algos";
import { useAppAlert } from "../../../components/app-alert/use-app-alert";
// function dataURLtoFile(dataurl: any, filename: any) {
//   if (dataurl === "") return '';
//   let arr = dataurl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], filename, { type: mime });
// }

export const SignContract = () => {
  const [findASolicitorMortgageType, setFindASolicitorMortgageType] =
    useState("Unsigned");
  const [signInfoHolder, setSignInfoHolder] = useState({
    html: tempDataForQuill,
    clientSuggestion: "",
    contractType: "single",
    signatureType1: "",
    signatureContent1: "",
    signatureType2: "",
    signatureContent2: "",
    submitStatus: false,
  });
  const { caseId, contractId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { status, singleContractInfoHolder } = useAppSelector(
    (state) => state.contracts
  );
  const { openAlert } = useAppAlert();

  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbCaseId(caseId)));
    dispatch(
      getContentOfSignleContractThunk({ axiosInstance, caseId, contractId })
    );
    return () => {
      dispatch(hideBreadcrumb());
    };
  }, []);

  const handleFindASolicitorMortgageType = (event: any) => {
    setFindASolicitorMortgageType(event.target.value as string);
  };

  const submitData = () => {
    const formData = new FormData();
    if (
      signInfoHolder?.signatureContent1 === "" &&
      signInfoHolder?.signatureContent2 === "" &&
      signInfoHolder?.clientSuggestion === ""
    ) {
      return;
    }
    formData.append("formType", singleContractInfoHolder?.formType);
    formData.append(
      "clientSignature1",
      signInfoHolder?.signatureType1 === "draw"
        ? dataURLtoFile(signInfoHolder.signatureContent1, "sign1.png")
        : signInfoHolder?.signatureContent1
    );
    formData.append(
      "clientSignature2",
      signInfoHolder?.signatureType2 === "draw"
        ? dataURLtoFile(signInfoHolder.signatureContent2, "sign2.png")
        : signInfoHolder?.signatureContent2
    );
    formData.append("client1SignatureType", signInfoHolder?.signatureType1);
    formData.append(
      "client1SignatureContent",
      signInfoHolder?.signatureType1 === "type"
        ? signInfoHolder?.signatureContent1
        : ""
    );
    formData.append("client2SignatureType", signInfoHolder?.signatureType2);
    formData.append(
      "client2SignatureContent",
      signInfoHolder?.signatureType2 === "type"
        ? signInfoHolder?.signatureContent2
        : ""
    );
    formData.append("clientSuggestion", signInfoHolder?.clientSuggestion);

    dispatch(
      signOrChangeContractThunk({
        axiosInstance,
        caseId,
        contractId,
        formData,
        navigate,
        openAlert,
      })
    );
  };

  const clientSuggestionHan = (e: any) => {
    if (e.target.value.length > 500) return alert("Bus Kr Day bhai");
    setSignInfoHolder((pre) => ({ ...pre, clientSuggestion: e.target.value }));
  };

  const signatureHandler1 = (type: string, content: string) => {
    setSignInfoHolder((pre) => ({
      ...pre,
      signatureType1: type,
      signatureContent1: content,
      submitStatus: true,
    }));
  };

  const signatureHandler2 = (type: string, content: string) => {
    setSignInfoHolder((pre) => ({
      ...pre,
      signatureType2: type,
      signatureContent2: content,
      submitStatus: true,
    }));
  };

  const signFileHandler1 = (file: any) => {
    setSignInfoHolder((pre) => ({
      ...pre,
      signatureContent1: file,
    }));
  };

  const signFileHandler2 = (file: any) => {
    setSignInfoHolder((pre) => ({
      ...pre,
      signatureContent2: file,
    }));
  };

  const submitBtnClickHan = () => {
    submitData();
  };
  return (
    <>
      {status === REQUESTSTATUS.PENDING ? (
        <AppLoader />
      ) : (
        <div className="bg-card-white">
          <div className="padding-top-2 padding-left-3 padding-right-3 padding-bottom-2">
            {/* Top Headings Area  */}
            <div className="primary-text-color secondary-heading font-weight-600">
              Sign Contract
            </div>
            {/* Select Contract Type  */}
            <div className="primary-text-color font-weight-500 margin-top-3">
              <Grid item xs={12} md={4} lg={4}>
                <AppInputField
                  label="Contract Type"
                  labelClassName="primary-text-color font-weight-500"
                  required={false}
                  value={findASolicitorMortgageType}
                />
              </Grid>
            </div>
            {singleContractInfoHolder?.contractType === "buyer_contracts" && (
              <ReadOnlyMood
                value={singleContractInfoHolder?.contractContent || ""}
              />
            )}
            {singleContractInfoHolder?.contractType === "seller_contracts" && (
              <div
                style={{
                  height: "500px",
                  overflow: "auto",
                  marginTop: "0.9em",
                }}
              >
                {/* <Reactpdf
                  file={`${process.env.REACT_APP_BASE_IMAGE_URL}${singleContractInfoHolder?.contractFile?.url}`}
                /> */}
                <iframe
                  src={`${process.env.REACT_APP_BASE_IMAGE_URL}${singleContractInfoHolder?.contractFile?.url}`}
                  style={{ width: "100%", height: "100%" }}
                />
                {/* <Reactpdf
                  file="https://pdf-lib.js.org/assets/with_update_sections.pdf"
                /> */}
              </div>
            )}
            <div
              className="margin-top-2"
              style={{
                borderBottom: "1px solid #525252",
              }}
            >
              <div className="primary-color font-weight-600 primary-heading">
                eSignature of Solicitor
              </div>
              {singleContractInfoHolder?.solicitorSignatureFile?.url && (
                <img
                  style={{ maxWidth: "330px", maxHeight: "150px" }}
                  src={`${process.env.REACT_APP_BASE_IMAGE_URL}${singleContractInfoHolder?.solicitorSignatureFile?.url}`}
                  alt="eSignature of solicitor"
                />
              )}
              {singleContractInfoHolder?.solicitorSignatureType === "type" &&
                singleContractInfoHolder?.solicitorSignatureContent}
            </div>
            {/* client suggestion and eSignature of client  */}
            <div className="margin-top-2">
              <Grid container>
                {/* client suggestion  */}
                <Grid item sm={6} xs={12}>
                  <div className="primary-text-color font-weight-500 margin-bottom-1">
                    Client Suggestion
                  </div>
                  {/* client suggetion input field  */}
                  <textarea
                    rows={6}
                    value={signInfoHolder.clientSuggestion}
                    onChange={clientSuggestionHan}
                    style={{
                      border: "1px solid #525252",
                      borderRadius: "5px",
                      outline: "none",
                      width: "80%",
                      resize: "none",
                    }}
                  />
                </Grid>
                {/* eSignature Of client  */}
                <Grid item sm={2}></Grid>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Signpads
                    signatureHandler1={signatureHandler1}
                    signatureHandler2={signatureHandler2}
                    applicationType={singleContractInfoHolder?.caseType}
                    signFileHandler1={signFileHandler1}
                    signFileHandler2={signFileHandler2}
                  />
                </Grid>
                <div style={{ textAlign: "right", width: "100%" }}>
                  <AppButton
                    btnText="Send"
                    className="btn-gray cursor-pointer tertiary-title add-item-btn"
                    btnFunction={() => submitBtnClickHan()}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
