import { createAsyncThunk } from "@reduxjs/toolkit";
import { SOLICITORSAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { getDataAPI, postDataAPI } from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getsolicitorThunk = createAsyncThunk(
  "solicitor/get-solicitor",
  async ({ axiosInstance, openAlert, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${SOLICITORSAPI}/all-dips`,
        axiosInstance,
        params
      );
      const responseData = {
        message: response?.data?.message,
        solicitorLists: response.data?.data?.dips,
        meta: response?.data?.data?.meta,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const getsolicitorModalThunk = createAsyncThunk(
  "solicitor/get-solicitor-modal",
  async ({ axiosInstance, openAlert, dipId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${SOLICITORSAPI}/find?dipId=${dipId}`,
        axiosInstance
      );
      const responseData = {
        message: response?.data?.message,
        solicitorListModal: response?.data?.data,
        meta: response?.data?.data?.meta,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

// Solictor Assign Post
export const postSolicitorThunk = createAsyncThunk(
  "solicitor/assign",
  async (
    { axiosInstance, dispatch, openAlert, apiData }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        `${SOLICITORSAPI}/assign`,
        axiosInstance,
        apiData
      );
      openAlert("New Case has been Created.", "success");
      dispatch(getsolicitorThunk({ axiosInstance }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
