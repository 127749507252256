import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../lib/axios";
import {
  hideBreadcrumb,
  showBreadcrumb,
} from "../../../store/breadcrumb/breadcrumb-slice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { breadcrumbdataForContractDetailsSolicitor } from "./breadcrumbs";
import {
  CONTRACTPATH,
  SLASHPATH,
  CONTRACTADDSOLICITIORPATHPARAM,
  CONTRACTUPDATEPATHPARAMS,
} from "../../../constants/routes-constant";
import { getContractsThunk } from "../../../store/contracts/contracts-thunk-actions";

export const useContractSolicitorDetails = () => {
  const [caseStatus, setCaseStatus] = useState<string>("All");
  const [contractDetialInfoHolder, setContractInfoHolder] = useState({
    pdfFile: "",
    solicitorSignatureType: "",
    solicitorSignatureContent: "",
    clinetSignaturs: [],
    embedContractId: "",
    embedContractPdfFile: "",
  });
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [Pagination, setPagination] = useState<{
    total: number;
    pages: number;
    page: number;
  }>({ total: 1, pages: 1, page: 1 });
  const [currentPage, setCurrentPage] = useState(0);
  const { contracts, status, pagination } = useAppSelector(
    (state) => state.contracts
  );
  const { caseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // for bread crumbs
  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbdataForContractDetailsSolicitor));
    return () => {
      dispatch(hideBreadcrumb());
    };
  }, []);

  // useEffect(() => {
  //   setPagination({ total: 1, pages: 1, page: 1 });
  //   setCurrentPage(0);
  // }, [caseStatus]);

  useEffect(() => {
    dispatch(
      getContractsThunk({
        axiosInstance,
        dynamicpart: `${caseId}?offset=${currentPage}&limit=8&status=${caseStatus}`,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    dispatch(
      getContractsThunk({
        axiosInstance,
        dynamicpart: `${caseId}?offset=${0}&limit=8&status=${caseStatus}`,
      })
    );
  }, [caseStatus]);

  // set Local Pagination
  useEffect(() => {
    status === "succeeded" && setPagination(pagination);
  }, [status]);

  const handleCaseStatus = (event: any) => {
    setCaseStatus(event.target.value as string);
  };

  const viewHand = (
    id: string,
    status: "Signed" | "Unsigned" | "Change Request" | "Embedded Sign"
  ) => {
    switch (status) {
      case "Embedded Sign":
        const contractEmbed = contracts.find((item: any) => item?._id === id);
        setContractInfoHolder((pre) => ({
          ...pre,
          embedContractId: contractEmbed?._id,
          embedContractPdfFile: contractEmbed?.contractFile?.url,
        }));
        setIsEmbedModalOpen(true);
        break;
      case "Signed":
        const contract = contracts.find((item: any) => item?._id === id);
        setContractInfoHolder((pre) => ({
          ...pre,
          pdfFile: contract?.contractFile?.url,
          solicitorSignatureType: contract?.solicitorSignatureType,
          solicitorSignatureContent:
            contract?.solicitorSignatureType === "type"
              ? contract?.solicitorSignatureContent
              : contract?.solicitorSignatureFile?.url,
          clinetSignaturs: contract?.clientSignatures,
        }));
        setPreviewModalOpen(true);
        break;
      case "Unsigned":
        const contractUnsigned = contracts.find(
          (item: any) => item?._id === id
        );
        const pdfUrl =
          contractUnsigned?.contractType === "seller_contracts"
            ? contractUnsigned?.contractFile?.url
            : contractUnsigned?.temporaryContractFile?.url;
        setContractInfoHolder((pre) => ({
          ...pre,
          pdfFile: pdfUrl,
          solicitorSignatureType: contractUnsigned?.solicitorSignatureType,
          solicitorSignatureContent:
            contractUnsigned?.solicitorSignatureType === "type"
              ? contractUnsigned?.solicitorSignatureContent
              : contractUnsigned?.solicitorSignatureFile?.url,
          clinetSignaturs: contractUnsigned?.clientSignatures,
        }));
        setPreviewModalOpen(true);
        break;
      case "Change Request":
        navigate(
          `${CONTRACTPATH}${SLASHPATH}${CONTRACTUPDATEPATHPARAMS}${SLASHPATH}${caseId}${CONTRACTPATH}${SLASHPATH}${id}`
        );
        break;
      default:
        break;
    }
  };

  const downloadPdfFile = (id: string) => {
    const contract = contracts.find((item: any) => item?._id === id);
    const pdfUrl = contract?.contractFile?.url;
    console.log({ pdfUrl });
    fetch(`${process.env.REACT_APP_BASE_IMAGE_URL}${pdfUrl}`).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "filename.pdf";
          a.click();
        });
      }
    );
  };
  const addBtnHan = () => {
    navigate(
      `${CONTRACTPATH}${SLASHPATH}${CONTRACTADDSOLICITIORPATHPARAM}${SLASHPATH}${caseId}`
    );
  };
  return {
    caseStatus,
    contractDetialInfoHolder,
    isPreviewModalOpen,
    setPreviewModalOpen,
    contracts,
    handleCaseStatus,
    viewHand,
    downloadPdfFile,
    Pagination,
    currentPage,
    setCurrentPage,
    status,
    caseId,
    addBtnHan,
    isEmbedModalOpen,
    setIsEmbedModalOpen,
  };
};
