import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getClientsThunkFulfilledReducer,
  getClientsThunkPendingReducer,
  getClientsThunkRejectedReducer,
} from "./client-reducers";
import { getClientThunk } from "./client-thunk-actions";

const clientInitialState = {
  getRequestStatus: REQUESTSTATUS.IDLE,
  clientsLists: [] as any,
};

export const clientSlice = createSlice({
  name: "client",
  initialState: clientInitialState,
  reducers: {},
  extraReducers(builder) {
    getClientsThunkPendingReducer(builder, getClientThunk);
    getClientsThunkFulfilledReducer(builder, getClientThunk);
    getClientsThunkRejectedReducer(builder, getClientThunk);
  },
});

export const { } = clientSlice.actions;

export default clientSlice.reducer;
