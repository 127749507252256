import { Grid } from "@mui/material";

import { AppAlert } from "../app-alert/app-alert";
import { AppBreadcrumbs } from "../app-breadcrumbs/app-breadcrumbs";

import { useAppSelector } from "../../store/store";
import { ShortInfo } from "../short-info/short-info";

export const AlertBreadcrumbs = () => {
  const { breadcrumb } = useAppSelector((state) => state);
  const { alert } = useAppSelector((state) => state);
  const { layout } = useAppSelector((state) => state);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={4} lg={2.2} xl={2.2}></Grid>
      <Grid
        item
        xs={12}
        md={4.5}
        lg={6.8}
        xl={6.8}
        className="margin-top-2"
        sx={{ mb: { md: "1.5rem", xs: "unset" } }}
      >
        {breadcrumb.showBreadcrumb && <AppBreadcrumbs />}
      </Grid>
      <Grid item sm={12} md={3.5} lg={3} xs={12} className="margin-top-1">
        {layout.showShortInfo && (
          <ShortInfo
            infoabout1={layout.infoabout1}
            info1={layout.info1}
            infoabout2={layout.infoabout2}
            info2={layout.info2}
          />
        )}
      </Grid>
      <div
        className="bg-card-white"
        style={{ zIndex: 9999, position: "fixed", right: "5%", top: "8.5%" }}
      >
        {alert?.showAlert && <AppAlert />}
      </div>
    </Grid>
  );
};
