import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const partnerWithUsAsXThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.sentRequestStatus = REQUESTSTATUS.FAILED;
  });
};
export const partnerWithUsAsXThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.sentRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};
export const partnerWithUsAsXThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.sentRequestStatus = REQUESTSTATUS.PENDING;
  });
};
