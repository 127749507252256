import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOGINAPI,
  LOGOUTAPI,
  REFRESHTOKENAPI,
  SIGNUPAPI,
  CHANGEPASSWORDAPI,
  FORGOTPASSWORDAPI,
  RESENDVERIFICATIONREQUESTAPI,
  VERIFYEMAILAPI,
  RESETPASSWORDAPI,
  CHANGEEMAILAPI,
  VERIFYCHANGEEMAILAPI,
  VERIFYTOKENAPI,
} from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  LOCALSTORAGEAUTHUSERINFO,
  LOCALSTORAGEREMEMBERMEUSER,
  LOCALSTORAGEUSERLOGGEDINTIME,
} from "../../constants/local-storage-constant";
import { USERROLES } from "../../constants/roles-constant";
import {
  AUTHENTICATIONPATH,
  IDVERIFICATIONPATH,
  SENTEMAILPATH,
  SIGNINPATH,
  SLASHPATH,
} from "../../constants/routes-constant";
import { getDataAPI, postDataAPI, putDataAPI } from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/views-utils";
import { getUsersThunk } from "../manage-users/manage-users-thunk-actions";

export const registerThunk = createAsyncThunk(
  "auth/register",
  async (
    { userCredentials, navigate, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        SIGNUPAPI,
        axiosInstance,
        userCredentials
      );

      const responseData = {
        user: response.data.user,
        message: response.data.message,
        userEmail: userCredentials.email,
      };
      // openAlert(responseData.message)
      showApiAlert(responseData.message, openAlert, "alert-success-message");
      // saveDataToLocalStorage('smd-user-email', response.data.data)
      navigate(IDVERIFICATIONPATH + "?user=" + response.data.data.userId + "&status=Not Started");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
        userEmail: userCredentials.email,
      };
      // openAlert(errorData.error)
      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (
    { userCredentials, rememberMe, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        LOGINAPI,
        axiosInstance,
        userCredentials
      );
      const formatResponse = response?.data?.data;
      const responseData = {
        token: formatResponse.authToken, // store access token
        refreshToken: formatResponse.refreshToken, // store refresh token
        user: formatResponse.user, // store user details
        message: response.data.message,
        role: [formatResponse.user.defaultRole],
        // store success message
      };
      if (!formatResponse.user.idVerified) {
        window.location.href =
          window.location.origin +
          IDVERIFICATIONPATH +
          "?user=" +
          formatResponse.user.userId +
          "&status=" +
          (formatResponse.user.idVerificationStatus ?? "Not Started");
      } else {
        // openAlert(responseData.message , 'alert-success-message')
        showApiAlert(responseData.message, openAlert, "alert-success-message");

        const rememberMeData = {
          email: userCredentials.email,
          password: userCredentials.password,
        };
        saveDataToLocalStorage(LOCALSTORAGEAUTHUSERINFO, responseData);
        saveDataToLocalStorage(LOCALSTORAGEUSERLOGGEDINTIME, Date.now());
        rememberMe &&
          saveDataToLocalStorage(LOCALSTORAGEREMEMBERMEUSER, rememberMeData);

        window.location.href =
          window.location.origin + window.location.search.slice(14);
      }
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      // openAlert(errorData.error)
      showApiAlert(errorData.error, openAlert);

      return rejectWithValue(errorData);
    }
  }
);

export const verifyTokenThunk = createAsyncThunk(
  "auth/verify-token",
  async (
    { hashParams, navigate, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(VERIFYTOKENAPI, axiosInstance, {
        accessToken: hashParams?.access_token,
        ...(hashParams?.id_token && { idToken: hashParams?.id_token }),
      });
      const formatResponse = response?.data?.data;
      const responseData = {
        token: hashParams?.access_token, // store access token
        refreshToken: hashParams?.id_token, // store refresh token
        user: formatResponse.user, // store user details
        message: response.data.message,
        role: [USERROLES.CLIENT],
        // store success message
      };
      openAlert &&
        showApiAlert(responseData.message, openAlert, "alert-success-message");

      saveDataToLocalStorage(LOCALSTORAGEAUTHUSERINFO, responseData);
      saveDataToLocalStorage(LOCALSTORAGEUSERLOGGEDINTIME, Date.now());

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      navigate("/authentication/signin");
      // openAlert(errorData.error)
      showApiAlert(errorData.error, openAlert);

      return rejectWithValue(errorData);
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "auth/logout",

  async ({ navigate, axiosInstance }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(LOGOUTAPI, axiosInstance);

      const responseData = {
        message: response.data.message,
      };
      // showApiAlert(responseData.message, openAlert, "alert-success-message");

      removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
      navigate(SIGNINPATH);
      return responseData;
    } catch (error: any) {
      removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
      navigate(SIGNINPATH);
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      // showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const refreshTokenThunk = createAsyncThunk(
  "auth/refreshToken",
  async ({ navigate, axiosRefresh }: any, { rejectWithValue }: any) => {
    const authData = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
    const payload = {
      userId: authData.user.userId,
      refreshToken: authData.refreshToken,
    };
    try {
      const response = await putDataAPI(REFRESHTOKENAPI, axiosRefresh, payload);
      const responseData = {
        token: response.data.data.authToken,
        refreshToken: response.data.data.refreshToken,
        success: response.data.message,
        user: authData.user,
        role: authData.role,
      };
      saveDataToLocalStorage(LOCALSTORAGEAUTHUSERINFO, responseData);

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
      navigate(SIGNINPATH);
      return rejectWithValue(errorData);
    }
  }
);

// forgot password
export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgot-password",
  async (
    {
      userCredentials,
      axiosInstance,
      openAlert,
      setShowForm,
      setEmail,
      setMinutes,
      functionTimer,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        FORGOTPASSWORDAPI,
        axiosInstance,
        userCredentials
      );
      const responseData = {
        userEmail: userCredentials.email,
        message: response?.data?.message,
      };
      openAlert?.(responseData?.message, "alert-success-message");
      setShowForm?.(false);
      setEmail?.(userCredentials.email);
      setMinutes?.(functionTimer?.());
      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
        userEmail: userCredentials.email,
      };
      // openAlert(errorData.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "auth/reset-password",
  async (
    {
      userCredentials,
      navigate,
      axiosInstance,
      openAlert,
      setShowForm,
      setEmail,
      dispatch,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        RESETPASSWORDAPI,
        axiosInstance,
        userCredentials
      );

      const responseData = {
        message: response?.data?.message,
        isPasswordReset:
          response?.data.message ===
          "Your password have been successfully changed."
            ? true
            : false,
        // email: response.data.email,
        // password: response.data.password,
        // code: response.data.code
      };

      openAlert?.(responseData?.message, "alert-success-message");
      setShowForm?.(false);
      setEmail?.(userCredentials.email);
      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
        isPasswordReset: false,
      };
      setShowForm?.(false);
      setEmail?.(userCredentials.email);
      //  openAlert(errorData.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      dispatch(
        forgotPasswordThunk({
          userCredentials,
          navigate,
          axiosInstance,
          openAlert,
          setShowForm,
          setEmail,
        })
      );
      return rejectWithValue(errorData);
    }
  }
);

export const resendVerificationRequestThunk = createAsyncThunk(
  "auth/resend-link",
  async (
    {
      userCredentials,
      axiosInstance,
      openAlert,
      setMinutes,
      functionTimer,
      dispatch,
      navigate,
      isAdmin,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        RESENDVERIFICATIONREQUESTAPI,
        axiosInstance,
        userCredentials
      );
      const responseData = {
        message: response.data.message,
      };

      setMinutes && setMinutes?.(functionTimer?.());
      showApiAlert(
        !isAdmin
          ? responseData.message
          : "Resent a verification email to user.",
        openAlert,
        "alert-success-message"
      );

      if (!isAdmin) {
        dispatch(
          verifyEmailThunk({
            axiosInstance,
            getParams: userCredentials,
            openAlert,
            dispatch,
            navigate,
          })
        );
      } else {
        setTimeout(() => {
          dispatch(getUsersThunk({ axiosInstance, params: isAdmin }));
        }, 600);
      }

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.errors || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const verifyEmailThunk = createAsyncThunk(
  "auth/verification-status",
  async (
    { axiosInstance, getParams, openAlert, dispatch, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        VERIFYEMAILAPI,
        axiosInstance,
        getParams
      );
      const responseData = {
        shouldReferAdmin: response.data.data,
        message: response.data.message,
        idVerified: response.data.data.isVerified,
        idVerificationStatus: response.data.data.status,
        idVerificationDisabled: response.data.data.disabled,
        emailVerified:
          response.data.message ===
          "Your email address is verified successfully."
            ? true
            : false,
      };
      if (response.data.data.isVerified) {
        setTimeout(() => {
          navigate(SIGNINPATH);
        }, 1000);
      }

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
        emailVerified: false,
      };
      // const userCredentials = {
      //   userId: getParams.userId,
      // };
      // dispatch(
      //   resendVerificationRequestThunk({
      //     userCredentials,
      //     axiosInstance,
      //     openAlert,
      //     dispatch,
      //     navigate,
      //   })
      // );
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const changePasswordThunk = createAsyncThunk(
  "auth/change-password",
  async (
    { body, navigate, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        CHANGEPASSWORDAPI,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      openAlert(responseData.message, "alert-success-message");
      setTimeout(() => {
        removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
        navigate("/authentication/signin");
      }, 1000);
      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      // openAlert(errorData?.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const changeEmailThunk = createAsyncThunk(
  "auth/change-email",
  async ({ body, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(CHANGEEMAILAPI, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      // openAlert(responseData.message, 'alert-success-message')
      // setTimeout(()=>{
      //   removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO)
      //   navigate('/authentication/signin')
      // },1000)
      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      // openAlert(errorData?.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const verifyPasswordThunk = createAsyncThunk(
  "auth/verify-password",
  async (
    { body, axiosInstance, openAlert, setShowFields }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(LOGINAPI, axiosInstance, body);
      const responseData = {
        // token: formatResponse.authToken,  // store access token
        // refreshToken: formatResponse.refreshToken,  // store refresh token
        message: response.data.message,
        // role: [formatResponse.user.defaultRole]
        // store success message
      };
      // openAlert(responseData.message , 'alert-success-message')
      // showApiAlert(responseData.message, openAlert, 'alert-success-message')
      responseData.message === "Successfully logged in." &&
        setShowFields?.(true);

      // const rememberMeData = {
      //   email: userCredentials.email,
      //   password: userCredentials.password
      // }
      // saveDataToLocalStorage(LOCALSTORAGEAUTHUSERINFO, responseData)
      // saveDataToLocalStorage(LOCALSTORAGEUSERLOGGEDINTIME, Date.now())
      // rememberMe && saveDataToLocalStorage(LOCALSTORAGEREMEMBERMEUSER, rememberMeData)
      // navigate('/')
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      // openAlert(errorData.error)
      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const verifyChangeEmailThunk = createAsyncThunk(
  "auth/verify-change-email",
  async (
    { axiosInstance, getParams, openAlert, dispatch, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        VERIFYCHANGEEMAILAPI,
        axiosInstance,
        getParams
      );
      const responseData = {
        message: response.data.verifyEmail.message,
        isEmailChanged:
          response.data.verifyEmail.message ===
          "Your email address is change verified successfully."
            ? true
            : false,
      };
      // openAlert(responseData.message)
      setTimeout(() => {
        removeDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
        navigate("/authentication/signin");
      }, 1000);
      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
        isEmailChanged: false,
      };
      // const body = {
      //   email: getParams.userId
      // }
      // dispatch(changeEmailThunk({ body, navigate , axiosInstance , openAlert}))

      // dispatch(resendEmailThunk({ userCredentials, navigate, axiosInstance, openAlert }))
      // sendEmailAgain?.()
      // openAlert(errorData.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const checkPasswordThunk = createAsyncThunk(
  "auth/check-password",
  async (
    { password, axiosInstance, openAlert, setShowFields }: any,
    { rejectWithValue }
  ) => {
    try {
      const userData = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);

      const response = await putDataAPI(LOGINAPI, axiosInstance, {
        email: userData.user.email,
        password: password,
      });
      setShowFields(true);
      // return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      openAlert("Password is Incorrect", "alert-error-message");
      setShowFields(false);
      // showApiAlert(errorData.error, openAlert);

      return rejectWithValue(errorData);
    }
  }
);
