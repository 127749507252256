import Checkbox from "@mui/material/Checkbox";
import { checkBoxSx } from "./checkbox-button-mui-style";

export const CheckBoxButton = (props: any) => {
  const {
    fontSize,
    label,
    errors,
    touched,
    tooltip,
    text,
    otherSxProps,
    borderRadius = "50px",
  } = props;
  return (
    <>
      {label && (
        <div>
          <label
            className={`${errors && touched ? "error-color" : "black-color"}`}
          >
            {label} <span className="error-color">*</span>
          </label>{" "}
          {tooltip}
        </div>
      )}
      <Checkbox
        sx={() => checkBoxSx(fontSize, borderRadius, otherSxProps)}
        {...props}
      />
      {text}
    </>
  );
};
