import { Box, Grid } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FilterIcon } from "../../../utils/svg-icon-utils";
import { AppInputField } from "../../../components/app-input-field/app-input-field";
import {
  casesStatusType,
  casesStatusTypeSolicitor,
} from "./contract-list-details-data";
import { ViewPdf } from "../../../components/view-pdf/view-pdf";
import { AppPagination } from "../../../components/app-pagination/app-pagination";

import ModalLayout from "../../../components/modal-layout/modal-layout";
import { RenderPdf } from "../../../components/render-pdf/render-pdf";
import AppLoader from "../../../components/app-loader/app-loader";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { useContractSolicitorDetails } from "./use-contract-details-solicitor";
import { AppButton } from "../../../components/app-button/app-button";
import { EmbedSignatureContracts } from "./embed-signature-contract/embed-signature-contracts";

export const ContractDetailsSolicitor = () => {
  const {
    caseStatus,
    contractDetialInfoHolder,
    isPreviewModalOpen,
    setPreviewModalOpen,
    contracts,
    handleCaseStatus,
    viewHand,
    downloadPdfFile,
    Pagination,
    setCurrentPage,
    status,
    caseId,
    addBtnHan,
    isEmbedModalOpen,
    setIsEmbedModalOpen,
  } = useContractSolicitorDetails();
  return (
    <>
      {status === REQUESTSTATUS.PENDING ? (
        <AppLoader />
      ) : (
        <>
          <div className="bg-card-white">
            <div className="padding-top-2 padding-left-3 padding-right-3 padding-bottom-2">
              {/* Top Headings Area  */}
              {/* Top Headings Area  */}
              <Grid container>
                <Grid item lg={6} sm={12}>
                  <div
                    className="primary-text-color secondary-heading font-weight-600"
                    title={caseId}
                  >
                    Case ID:#{caseId?.slice(0, 4)}...
                  </div>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  container
                  direction="row-reverse"
                  alignItems="center"
                >
                  <Grid item>
                    <AppButton
                      btnText="Add"
                      className="btn-blue cursor-pointer tertiary-title add-item-btn "
                      btnFunction={addBtnHan}
                      btnIconRight={
                        <AddCircleIcon
                          sx={{ color: "white", paddingLeft: "0.8em" }}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    className="primary-text-color secondary-heading font-weight-400 margin-right-1"
                    style={{ display: "inline-block" }}
                  >
                    <span className="primary-text-color font-weight-300">
                      {" "}
                      Total:
                    </span>
                    {Pagination?.total && Pagination?.total}
                  </Grid>
                </Grid>
              </Grid>
              {/* Filter  */}
              <div className="filter margin-top-2 margin-bottom-3">
                <Grid container spacing={1} className="align-center">
                  <Grid item xs={12} md={4} lg={3}>
                    <AppInputField
                      label="Status"
                      labelClassName="primary-text-color font-weight-500"
                      required={false}
                      select={true}
                      selectMenudata={casesStatusTypeSolicitor}
                      value={caseStatus}
                      onChange={handleCaseStatus}
                    />
                  </Grid>
                </Grid>
              </div>
              {/* List of Pending Cases  */}
              <Grid container spacing={2}>
                {contracts &&
                  contracts.map((item: any, index: number) => {
                    return (
                      <Grid
                        sx={{ padding: "1em", marginBottom: "1em" }}
                        item
                        key={index}
                        md={12}
                        sm={12}
                        lg={3}
                      >
                        <ViewPdf
                          viewHand={() => viewHand(item._id, item.status)}
                          downloadPdfFile={() => downloadPdfFile(item?._id)}
                          status={item.status}
                          labels={{
                            names:
                              item.status === "Embedded Sign"
                                ? [
                                    { name: "Embed Signature" },
                                    { name: "Download" },
                                  ]
                                : [{ name: "View" }, { name: "Download" }],
                          }}
                        />
                      </Grid>
                    );
                  })}

                {contracts && contracts.length === 0 && (
                  <Box
                    className={"error-color"}
                    component={"span"}
                    sx={{ width: "100%", textAlign: "center" }}
                  >
                    No Contract Found
                  </Box>
                )}
              </Grid>
              {/* Pagination  */}
              <div className="pagination margin-top-1">
                {contracts && contracts.length > 0 && Pagination && (
                  <AppPagination
                    currentPage={Pagination.page}
                    totalPages={Pagination.pages}
                    handleChangePage={(e: any, page: any) => {
                      setCurrentPage((page - 1) * 8);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* Pdf Preview Model  */}
          {isPreviewModalOpen && (
            <ModalLayout
              isModalOpen={isPreviewModalOpen}
              setIsModalOpen={setPreviewModalOpen}
              leftMd={4}
              centerMd={3}
              rightMd={4}
            >
              {/* <RenderPdf
                file={`${process.env.REACT_APP_BASE_IMAGE_URL}${contractDetialInfoHolder.pdfFile}`}
              /> */}
              {/* <RenderPdf
                file="https://pdf-lib.js.org/assets/with_update_sections.pdf"
              /> */}
              <RenderPdf
                clinetSignaturs={contractDetialInfoHolder?.clinetSignaturs}
                file={`${process.env.REACT_APP_BASE_IMAGE_URL}${contractDetialInfoHolder?.pdfFile}`}
                solicitorSignatureContent={
                  contractDetialInfoHolder?.solicitorSignatureContent
                }
                solicitorSignatureType={
                  contractDetialInfoHolder?.solicitorSignatureType
                }
              />
            </ModalLayout>
          )}
          {/* Embed Model  */}
          {isEmbedModalOpen && (
            <ModalLayout
              isModalOpen={isEmbedModalOpen}
              setIsModalOpen={setIsEmbedModalOpen}
              leftMd={0}
              centerMd={12}
              rightMd={0}
              leftLg={1}
              centerLg={10}
              rightLg={1}
            >
              <EmbedSignatureContracts
                contractId={contractDetialInfoHolder?.embedContractId}
                pdfFile={contractDetialInfoHolder?.embedContractPdfFile}
                // pdfFile="https://pdf-lib.js.org/assets/with_update_sections.pdf"
                setIsEmbedModalOpen={setIsEmbedModalOpen}
              />
            </ModalLayout>
          )}
        </>
      )}
    </>
  );
};
