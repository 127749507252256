import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAxiosInterceptors } from "../../../../hooks/use-axios-interceptors";
import { getDipApplications } from "../../../../store/dip/dip-slice";
import { getDIPApplicationsThunk } from "../../../../store/dip/dip-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

export const useDipHistory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dipApplications = useAppSelector(getDipApplications);
  const axiosInstance = useAxiosInterceptors();
  const { clientId } = useParams();
  const [issueOn, setissueOn] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [searchParam, setSearchParam] = useState<any>("");
  const [dipType, setDipType] = useState<any>("");
  const [addcertificate, setViewCretificate] = useState(null);
  const [MortgageType, setMortgageType] = useState("");
  const [addDelegate, setAddDelegate] = useState(false);
  const [createdDate, setCreatedDate] = useState<any>(null);
  const [page, setpage] = useState(0);
  const [resetFilter, setResetFilter] = useState(false);
  const [searchText, setSearchText] = useState<any>("");

  const handleDelegateStatus = (event: any) => {
    setDipType(event.target.value as string);
  };

  const handleIssueOn = (newValue: any) => {
    setissueOn(dayjs(newValue).format("YYYY-MM-DD"));
    setResetFilter(true);
  };

  const checkValue = (value: any) => {
    return value !== "" ? value : undefined;
  };

  useEffect(() => {
    const params = {
      search: checkValue(searchParam),
      mortgageType: checkValue(dipType),
      issueOn: checkValue(issueOn),
      offset: checkValue(page),
      clientId: checkValue(clientId)
    };
    dispatch(getDIPApplicationsThunk({ axiosInstance, params }));
  }, [axiosInstance, issueOn, dipType, clientId, dispatch, page, searchParam]);

  const resetFilterhandler = () => {
    setDipType(null);
    setissueOn(null);
    setSearchText("");
    setResetFilter(false);
  };

  return {
    navigate,
    issueOn,
    handleIssueOn,
    handleDelegateStatus,
    addDelegate,
    setAddDelegate,
    dipType,
    setDipType,
    searchParam,
    setSearchParam,
    dateTo,
    setDateTo,
    dipApplications,
    setpage,
    page,
    addcertificate,
    setViewCretificate,
    MortgageType,
    setMortgageType,
    createdDate,
    setCreatedDate,
    resetFilter,
    setResetFilter,
    resetFilterhandler,
    searchText,
    setSearchText,
    clientId
  };
};
