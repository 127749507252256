import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getModalsolicitorThunkFulfilledReducer,
  getModalsolicitorThunkPendingReducer,
  getModalsolicitorThunkRejectedReducer,
  getsolicitorThunkFulfilledReducer,
  getsolicitorThunkPendingReducer,
  getsolicitorThunkRejectedReducer,
  postAssigneSolicitorFulfilledReducer,
  postAssigneSolicitorPendingReducer,
  postAssigneSolicitorRejectedReducer,
} from "./solicitor-reducers";
import {
  getsolicitorModalThunk,
  getsolicitorThunk,
  postSolicitorThunk,
} from "./solicitor-thunk-actions";

const solicitorInitialState = {
  getRequestStatus: REQUESTSTATUS.IDLE,
  solicitorLists: [] as any,
  solisitorsListModal: [] as any,
  solisitorsListModalStatus: REQUESTSTATUS.IDLE,
};

export const solicitorSlice = createSlice({
  name: "solicitor",
  initialState: solicitorInitialState,
  reducers: {},
  extraReducers(builder) {
    getsolicitorThunkPendingReducer(builder, getsolicitorThunk);
    getsolicitorThunkFulfilledReducer(builder, getsolicitorThunk);
    getsolicitorThunkRejectedReducer(builder, getsolicitorThunk);

    getModalsolicitorThunkPendingReducer(builder, getsolicitorModalThunk);
    getModalsolicitorThunkFulfilledReducer(builder, getsolicitorModalThunk);
    getModalsolicitorThunkRejectedReducer(builder, getsolicitorModalThunk);

    postAssigneSolicitorRejectedReducer(builder, postSolicitorThunk);
    postAssigneSolicitorFulfilledReducer(builder, postSolicitorThunk);
    postAssigneSolicitorPendingReducer(builder, postSolicitorThunk);
  },
});

export const {} = solicitorSlice.actions;

export default solicitorSlice.reducer;
