import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.svg";

export const AppBrand = (props: any) => {
  const {
    brandSrc,
    brandWidth,
    brandHeight,
    brandLogoContainerClassName,
    routelink,
  } = props;

  return (
    <div className={`${brandLogoContainerClassName}`}>
      <Link to={routelink}>
        <img
          width={brandWidth}
          height={brandHeight}
          src={brandSrc || logo}
          alt={brandSrc || "logo"}
        />
      </Link>
    </div>
  );
};
