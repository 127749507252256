import { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import "./drawSignature.scss";
import { DRAWSIGNATUREPROPS } from "../OPTIONINTERFACE";

export const DrawSignature:React.FC<DRAWSIGNATUREPROPS> = ({signatureRef}) => {
  return (
    <>
      <SignaturePad
        ref={signatureRef}
        penColor="black"
        canvasProps={{ className: "sigPad" }}
      />
    </>
  );
};
