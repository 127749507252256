import {
  MUIBORDERRADIUS24,
  MUIBOXSHADOWDATEPICKER,
  MUIFONTWEIGHT600,
  MUILIGHTGREYCOLOR,
  MUIORANGECOLOR,
  MUIPRIMARYCOLOR,
  MUIPRIMARYHEADING,
  MUISECONDARYTITLE,
  MUITERTIARYCOLOR,
  MUIWHITECOLOR,
} from "../../constants/mui-styles-constant";

export const DesktopDatePickerPaperProps = {
  sx: {
    borderRadius: MUIBORDERRADIUS24,
    color: MUIPRIMARYCOLOR,
    fontSize: MUIPRIMARYHEADING,
    fontWeight: MUIFONTWEIGHT600,
    boxShadow: MUIBOXSHADOWDATEPICKER,
    maxWidth: "350px",
    "& .css-epd502": {
      width: "100%",
    },
    "& .MuiCalendarOrClockPicker-root": {
      width: "100%",
    },
    "& .MuiCalendarPicker-root": {
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      color: MUILIGHTGREYCOLOR,
      fontSize: MUISECONDARYTITLE,
    },
    "& .MuiSvgIcon-root": {
      color: MUIPRIMARYCOLOR,
    },
    "& .Mui-selected": {
      backgroundColor: `${MUIORANGECOLOR} !important`,
      color: MUIWHITECOLOR,
    },
    "& .MuiButtonBase-root:hover": {
      backgroundColor: `${MUITERTIARYCOLOR} !important`,
      color: MUIPRIMARYCOLOR,
    },
  },
};
