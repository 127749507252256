import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getContractsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getContractsThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.contracts = [...payload.data.contracts];
    state.pagination = payload.data.meta;
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getContractsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const addSellerContractThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const addSellerContractThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addSellerContractThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const signOrChangeReqContractThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const signOrChangeReqContractThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const signOrChangeReqContractThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const getBuyerContractContentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getBuyerContractContentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.htmlContentForBuyerContract =
      payload?.data?.data?.contractContent?.content;
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getBuyerContractContentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const addBuyerContractThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const addBuyerContractThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addBuyerContractThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const getSingleContractContentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getSingleContractContentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.singleContractInfoHolder = payload.data;
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getSingleContractContentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const updateContractContentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const updateContractContentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const updateContractContentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const embedSignatureOnPdfThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const embedSignatureOnPdfThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
  });
};

export const embedSignatureOnPdfThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};
