import { Grid, Pagination } from "@mui/material";
import {
  MUIBORDERRADIUS8,
  MUIFONTWEIGHT500,
  MUILIGHTGREYCOLOR,
  MUIORANGECOLOR,
  MUIPRIMARYCOLOR,
  MUIPRIMARYTITLE,
  MUIWHITECOLOR,
} from "../../constants/mui-styles-constant";

interface IAPPPAGINATION {
  currentPage: number | undefined;
  totalPages: number | undefined;
  handleChangePage?: (event: any, index: number) => void;
}

export const AppPagination = (props: IAPPPAGINATION) => {
  const { currentPage, totalPages, handleChangePage } = props;

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={12} md={6} className="tertiary-title selected-field-color">
        <span>
          Page {currentPage} of {totalPages}{" "}
        </span>
      </Grid>
      <Grid item xs={12} md={6}>
        <Pagination
          className="pagination-bg-color"
          defaultPage={1}
          defaultValue={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 1,
            "& .MuiButtonBase-root": {
              color: MUILIGHTGREYCOLOR,
              fontWeight: MUIFONTWEIGHT500,
              fontSize: MUIPRIMARYTITLE,
            },
            "& .MuiButtonBase-root.Mui-selected": {
              backgroundColor: MUIORANGECOLOR,
              color: MUIWHITECOLOR,
              borderRadius: MUIBORDERRADIUS8,
            },
            "& .MuiButtonBase-root.Mui-selected:hover": {
              backgroundColor: MUIORANGECOLOR,
              color: MUIWHITECOLOR,
              borderRadius: MUIBORDERRADIUS8,
            },
            "& .MuiPaginationItem-icon": {
              backgroundColor: MUIPRIMARYCOLOR,
              color: MUIWHITECOLOR,
              borderRadius: MUIBORDERRADIUS8,
            },
          }}
          count={totalPages || 0}
          page={currentPage || 0}
          shape="rounded"
          onChange={handleChangePage}
        />
      </Grid>
    </Grid>
  );
};
