import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addDelegatesThunkFulfilledReducer,
  addDelegatesThunkPendingReducer,
  addDelegatesThunkRejectedReducer,
  deleteDelegatesThunkFulfilledReducer,
  deleteDelegatesThunkPendingReducer,
  deleteDelegatesThunkRejectedReducer,
  editDelegatesThunkFulfilledReducer,
  editDelegatesThunkPendingReducer,
  editDelegatesThunkRejectedReducer,
  emptySingleDelegateReducer,
  getClientDelegatesThunkFulfilledReducer,
  getClientDelegatesThunkPendingReducer,
  getClientDelegatesThunkRejectedReducer,
  getDelegatesThunkFulfilledReducer,
  getDelegatesThunkPendingReducer,
  getDelegatesThunkRejectedReducer,
  getSingleDelegatesThunkFulfilledReducer,
  getSingleDelegatesThunkPendingReducer,
  getSingleDelegatesThunkRejectedReducer,
} from "./delegates-reducers";
import {
  addDelegatesThunk,
  deleteDelegatesThunk,
  editDelegatesThunk,
  getClientDelegatesThunk,
  getDelegatesThunk,
  getSingleDelegatesThunk,
} from "./delegates-thunk-actions";

const delegatesInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  delegatesLists: [] as any,
  clientdelegatesLists: [] as any,
  delegatesinfo:[]as any,
  meta: {} as any,
  singleDelegate: {} as any,
};

export const delegatesSlice = createSlice({
  name: "delegates",
  initialState: delegatesInitialState,
  reducers: {
    emptySingleDelegate: emptySingleDelegateReducer,
  },
  extraReducers(builder) {
    getDelegatesThunkPendingReducer(builder, getDelegatesThunk);
    getDelegatesThunkFulfilledReducer(builder, getDelegatesThunk);
    getDelegatesThunkRejectedReducer(builder, getDelegatesThunk);

    getSingleDelegatesThunkPendingReducer(builder, getSingleDelegatesThunk);
    getSingleDelegatesThunkFulfilledReducer(builder, getSingleDelegatesThunk);
    getSingleDelegatesThunkRejectedReducer(builder, getSingleDelegatesThunk);

    addDelegatesThunkPendingReducer(builder, addDelegatesThunk);
    addDelegatesThunkFulfilledReducer(builder, addDelegatesThunk);
    addDelegatesThunkRejectedReducer(builder, addDelegatesThunk);

    editDelegatesThunkPendingReducer(builder, editDelegatesThunk);
    editDelegatesThunkFulfilledReducer(builder, editDelegatesThunk);
    editDelegatesThunkRejectedReducer(builder, editDelegatesThunk);

    deleteDelegatesThunkPendingReducer(builder, deleteDelegatesThunk);
    deleteDelegatesThunkFulfilledReducer(builder, deleteDelegatesThunk);
    deleteDelegatesThunkRejectedReducer(builder, deleteDelegatesThunk);

    getClientDelegatesThunkPendingReducer(builder, getClientDelegatesThunk);
    getClientDelegatesThunkFulfilledReducer(builder, getClientDelegatesThunk);
    getClientDelegatesThunkRejectedReducer(builder, getClientDelegatesThunk);
  },
});

export const { emptySingleDelegate } = delegatesSlice.actions;

export default delegatesSlice.reducer;
