export const EMPTYPATH = "";
export const SLASHPATH = "/";
export const HASHPATH = "#";

//sales site routes portion

export const HOMEPATH = "/home";
export const PARTNERWITHUSPATH = "/partner-with-us/:partnerType";
export const PARTNERWITHUSASSOLICITORPATH = "/partner-with-us/solicitor";
export const PARTNERWITHUSASMORTGAGEBROKERPATH =
  "/partner-with-us/mortgage_broker";
export const PARTNERWITHUSASESTATEAGENTPATH = "/partner-with-us/estate_agent";
export const PARTNERWITHUSPARAM = "/partner-with-us";
export const ABOUTUSPATH = "/about-us";
export const BLOGPATH = "blog";
export const BLOGLISTSPATH = "blog-lists";
export const SINGLEBLOGPATH = "single-blog/:id";
export const SINGLEBLOGPARAM = "single-blog";
export const SALESSITEHELPFAQPATH = "help";
export const POLICIESPATH = "/policies";
export const TERMSOFUSEPATH = "terms-of-use";
export const PRIVACYPOLICYPATH = "privacy-policy";
export const COOKIESPOLICYPATH = "cookies-policy";
export const CONTACTUSPATH = "contact-us";
export const ENQUIRYFORMPATH = "enquiry-form";
export const CAREERSPATH = "careers";
export const APPLYNOWFORMPATH = "apply-now";
export const SCHEDULEADEMOPATH = "schedule-a-demo";
export const FIRSTTIMEBUYERPATH = "/first-time-buyer";
export const REMORTAGEPATH = "/remortage";
export const BUYERTOLETPATH = "/buy-to-let";
export const RENTALVALUEPATH = "/Rental-value-for-buy";

// sales site routes portion

// may be this path will not require in future
export const MAILPATH = "/mail";
export const SENTEMAILPATH = "sent-email";

export const AUTHENTICATIONPATH = "/authentication";
export const SIGNINPATH = "/authentication/signin";
export const SIGNUPPATH = "/authentication/signup";
export const RESETPASSWORDPATH = "/authentication/reset-password";
export const FORGOTPASSWORDPATH = "/authentication/forgot-password";
export const IDVERIFICATIONPATH = "/authentication/verification";
export const CHANGEEMAILVERIFICATIONPATH =
  "/authentication/change-email-verify";
export const CHANGEPASSWORDPATH = "change-password";

export const UNAUTHORIZEDPATH = "/unauthorized";
export const LOGOUTPATH = "/log-out";

export const DASHBOARDPATH = "/dashboard";
export const HELPPATH = "/help";

export const DAYPLANNERPATH = "/day-planner";

export const PROFILEMANAGEMENTPATH = "/profile-management";
export const PROFILEPATH = "profile";
export const EDITPROFILEPATH = "edit-profile";
export const CHANGEEMAILPATH = "change-email";
export const VIEWQUOTEPATH = "view-quote";

export const CASESPATH = "/cases";
export const CASESLISTSPATH = "lists";
export const CASEDETAILSPATH = "details/:dipId";
export const CASEDETAILSPARAM = "details";

export const DELEGATESPATH = "/delegates";
export const DELEGATESLISTSPATH = "lists";

export const CONTRACTPATH = "/contract";
export const CONTRACTLISTSPATH = "lists";
export const CONTRACTDETAILSPARAMS = "pending-cases";
export const CONTRACTDETAILSPATH = "pending-cases/:caseId";
export const CONTRACTSIGNSPATHPARAMS = "sign-contract";
export const CONTRACTSIGNSPATH =
  "pending-cases/:caseId/sign-contract/:contractId";
export const CONTRACTASSIGNPATH = "assign-contract/:caseId";
export const CONTRACTCASEASSIGNPATH = "assign-contract/:caseId/:contractId";
export const CONTRACTASSIGNPATHPARANS = "assign-contract";
export const CONTRACTADDSOLICITIORPATHPARAM = "add-contract";
export const CONTRACTADDSOLICITIORPATH = "add-contract/:caseId";
export const CONTRACTUPDATEPATHPARAMS = "update-contract";

export const CONTRACTUPDATEPATH =
  "update-contract/:caseId/contract/:contractid";

// dashboard Api
export const SALESAGENTDASHBOARDPATH = 'dashboard/sales-agent'
export const MORTGAGEBROKERDASHBOARDPATH = 'dashboard/mortgage-broker'
export const ESTATEAGENTDASHBOARDPATH = 'dashboard/estate-agent'
export const ADMINDASHBOARDPATH = 'dashboard/super-admin'
export const CLIENTDASHBOARDPATH = 'dashboard/client'
export const SOLICITORDASHBOARDPATH = 'dashboard/solicitor';

// user Notification api
export const userNotificationLatestPath = "user-notifications/latest";

export const DIPPATH = "/dip";
export const DIPADDPATH = "add-dip";
export const DIPADDPARAMS = "add-dip/:dipId";
export const DIPAPPLICATIONDETAILSPATH = "details/:dipId";
export const DIPAPPLICATIONDETAILSPARAM = "details";
export const DIPHISTORYPATH = "history";

export const REVIEWSPATH = "/reviews";
export const REVIEWSOVERALLRATINGSPATH = "overall-ratings";
export const ADDREVIEWSPATH = "add-review/:reviewId";
export const ADDREVIEWSPARAM = "add-review";
export const REVIEWSLISTSPATH = "lists";

export const SOLICITORPATH = "/solicitor";
export const FINDASOLICITORPATH = "find-a-solicitor";
export const SOLICITORDETAILSPATH = "details/:dipId";

export const TICKETSPATH = "/tickets";
export const TICKETSLISTSPATH = "lists";
export const TICKETSDETAILS = "details/:dipId";

export const USERSPATH = "/users";
export const USERMANAGEMENTPATH = "manage-user-lists";

export const USERSREQUESTPATH = "/users-request";
export const USERONBOARDINGREQUESTSPATH = "onbaording-user-requests";

export const QUOTEPATH = "/quote";
export const QUOTEMANGEMENTPATH = "quote-management";
export const QUOTEMANGEMENTFORM = "quote-form";
export const QUOTEMANGEMENTFORMID = "quote-form/:formId";

export const SETTINGSPATH = "/settings";
export const ACCOUNTSETTINGSPATH = "account-settings";

export const DOCUMENTSPATH = "/documents";
export const DOCUMENTSLISTSPATH = "lists";
export const CASEDOCUMENTSPATH = "case-documents";
export const CASEDOCUMENTSPATHID = "case-documents/:caseId";

export const CLIENTSPATH = "/clients";
