import { createSlice } from "@reduxjs/toolkit";
import { getEstateAgentThunkRejectedReducer, getEstateAgentThunkPendingReducer, getEstateAgentThunkFulfilledReducer, getEstateAgentYearThunkRejectedReducer, getEstateAgentYearThunkPendingReducer, getEstateAgentYearThunkFulfilledReducer } from "./estate-agent-dashboard-reducer";
import { getEstateAgentDashboardDataThunk, getEstateAgentYearDashboardDataThunk } from "./estate-agent-dashboard-thunk";


const estateAgentDashboardInitialState = {
    status: '',
    estateAgentData: {} as any,

    yearStatus: '' as any,
    estateAgentEarning: {} as any,
    estateAgentUser: {} as any,
};

export const estateAgentSlice = createSlice({
    name: "estateAgentDashboard",
    initialState: estateAgentDashboardInitialState,
    reducers: {},
    extraReducers(builder) {
        getEstateAgentThunkRejectedReducer(builder, getEstateAgentDashboardDataThunk)
        getEstateAgentThunkPendingReducer(builder, getEstateAgentDashboardDataThunk)
        getEstateAgentThunkFulfilledReducer(builder, getEstateAgentDashboardDataThunk)

        getEstateAgentYearThunkRejectedReducer(builder, getEstateAgentYearDashboardDataThunk)
        getEstateAgentYearThunkPendingReducer(builder, getEstateAgentYearDashboardDataThunk)
        getEstateAgentYearThunkFulfilledReducer(builder, getEstateAgentYearDashboardDataThunk)

    },
});


export default estateAgentSlice.reducer;