import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const referenceDataThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload.error;
  });
};

export const referenceDataThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.mortgage = payload.mortgage;
    state.property = payload.property;
    state.otherData = payload.otherData;
  });
};
export const referenceDataThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const setReferenceDataReducer = (state: any, { payload }: any) => {
  state.status = REQUESTSTATUS.SUCCEEDED;
  state.mortgage = payload.mortgage;
  state.property = payload.property;
  state.otherData = payload.otherDatata;
};

export const setLenderReferenceDataReducer = (state: any, { payload }: any) => {
  state.status = REQUESTSTATUS.SUCCEEDED;
  state.referenceLender = payload;
};




// Get Lender Refernce Data

export const getGetReferenceLenderThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getGetReferenceLenderThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.lender = { ...payload };
  });
};

export const getGetReferenceLenderThunkRejectedReducer = (
  builder: any,
  action: any,
)=>{
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};