import { createAsyncThunk } from "@reduxjs/toolkit";
import { POSTDEMO, TICKETSAPI, TICKETSASSIGNEESAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { HOMEPATH } from "../../constants/routes-constant";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";

// DIP Details List Page Get
export const getTicketsListThunk = createAsyncThunk(
  "tickets/dip-details/get-list",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(TICKETSAPI, axiosInstance, params);
      const responseData = {
        users: response?.data?.data?.tickets,
        meta: response?.data?.data?.meta,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Assignees List Page Get
export const getAssigneesListThunk = createAsyncThunk(
  "assignees/dip-details/get-list",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(TICKETSASSIGNEESAPI, axiosInstance, params);
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Assignees Ticket Patch
export const patchAssigneesListThunk = createAsyncThunk(
  "assignees/dip-details/assign",
  async (
    { axiosInstance, userId, ticketId, dispatch, openAlert, ticketType }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${TICKETSAPI}/${ticketId}/assign-to?userId=${userId}`,
        axiosInstance
      );
      openAlert(response?.data?.message, "success");
      dispatch(getTicketsListThunk({ axiosInstance, params: { ticketType } }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Assignees To Me Ticket Patch
export const patchAssignToMeThunk = createAsyncThunk(
  "dip-details/assign-to-me",
  async (
    { axiosInstance, ticketId, dispatch, openAlert, ticketType }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(`${TICKETSAPI}/${ticketId}/assign-me`, axiosInstance);
      openAlert(response?.data?.message, "success");
      dispatch(getTicketsListThunk({ axiosInstance, params: { ticketType } }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Unassign Ticket Patch
export const patchUnassignThunk = createAsyncThunk(
  "dip-details/unassign",
  async (
    { axiosInstance, ticketId, dispatch, openAlert, ticketType }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(`${TICKETSAPI}/${ticketId}/un-assign`, axiosInstance);
      openAlert(response?.data?.message, "success");
      dispatch(getTicketsListThunk({ axiosInstance, params: { ticketType } }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Mark As Complete Patch
export const patchCompleteThunk = createAsyncThunk(
  "dip-details/complete",
  async (
    { axiosInstance, ticketId, dispatch, openAlert, ticketType }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(`${TICKETSAPI}/${ticketId}/complete`, axiosInstance);
      openAlert(response?.data?.message, "success");
      dispatch(getTicketsListThunk({ axiosInstance, params: { ticketType } }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData?.error, "alert-error-message");
      dispatch(getTicketsListThunk({ axiosInstance, params: { ticketType } }));
      return rejectWithValue(errorData);
    }
  }
);

// Demo Post
export const postDemoThunk = createAsyncThunk(
  "dip-details/post-demo",
  async ({ axiosInstance, formData, openAlert, navigate }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(POSTDEMO, axiosInstance, formData);
      openAlert(response.data.message, "alert-success-message");
      setTimeout(() => navigate(HOMEPATH), 3005);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const postPaymentScheduleThunk = createAsyncThunk(
  "dip-applications/payment-schedule",
  async ({ axiosInstance, formData, openAlert, dipId }: any, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await postDataAPI(
        `dip-applications/${dipId}/generate/payment-schedule`,
        axiosInstance,
        formData
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const postIllustrationThunk = createAsyncThunk(
  "dip-details/post-illustration",
  async ({ axiosInstance, formData, openAlert, dipId }: any, { rejectWithValue }) => {
    try {
      const response = await postDataAPI(
        `/dip-applications/${dipId}/generate/illustration`,
        axiosInstance,
        formData
      );
      openAlert(response.data.message, "alert-success-message");
      // setTimeout(() => navigate(HOMEPATH), 3005);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
export const postEvidenceResearchThunk = createAsyncThunk(
  "dip-applications/eor-document",
  async ({ axiosInstance, formData, openAlert, dipId }: any, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await postDataAPI(
        `dip-applications/${dipId}/generate/eor-document`,
        axiosInstance,
        formData
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getCertificateThunk = createAsyncThunk(
  "tickets/dip-details/get-certificate",
  async ({ axiosInstance, openAlert, dipId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `/dip-applications/${dipId}/generate/certificate`,
        axiosInstance
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const sendPaymentScheduleEmailThunk = createAsyncThunk(
  "dip-applications/payment-schedule",
  async ({ axiosInstance, openAlert, dipId }: any, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await getDataAPI(
        `dip-applications/${dipId}/email/payment-schedule`,
        axiosInstance
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

//

export const sendIllustrationEmailThunk = createAsyncThunk(
  "dip-applications/payment-schedule",
  async ({ axiosInstance, openAlert, dipId }: any, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await getDataAPI(
        `dip-applications/${dipId}/email/illustration`,
        axiosInstance
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

//

export const sendEvidenceResearchEmailThunk = createAsyncThunk(
  "dip-applications/payment-schedule",
  async ({ axiosInstance, openAlert, dipId }: any, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await getDataAPI(
        `dip-applications/${dipId}/email/eor-document`,
        axiosInstance
      );

      openAlert(response.data.message, "alert-success-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
