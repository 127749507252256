import {
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppInputField } from "../../../../components/app-input-field/app-input-field";
import {
  CallFilterStatus,
  DipDetailsTableHead,
  DipDetailsTableHeadDashboard,
  TicketFilterStatus,
} from "./dip-details-data";
import { FilterIcon } from "../../../../utils/svg-icon-utils";
import SearchIcon from "@mui/icons-material/Search";
import { AppTableUI } from "../../../../components/app-table-ui/app-table-ui";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import AppLoader from "../../../../components/app-loader/app-loader";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  MUIBORDERRADIUS10,
  MUIDANGERCOLOR,
  MUIFONTWEIGHT500,
  MUILIGHTGREYCOLOR,
  MUISECONDARYTITLE,
  MUIWHITECOLOR,
  MUISUCCESSCOLOR,
} from "../../../../constants/mui-styles-constant";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ModalLayout from "../../../../components/modal-layout/modal-layout";
import { DipDetailsAssignTicket } from "./modals/dip-details-assign-ticket";
import { AssignmentModal } from "../../common/assignment-modal";
import unassignedImage from "../../../../assets/svg/components/global-modal/unassigned.svg";
import {
  getTicketsListThunk,
  patchUnassignThunk,
} from "../../../../store/tickets/tickets-thunk-actions";
import { axiosInstance } from "../../../../lib/axios";
import { AppPagination } from "../../../../components/app-pagination/app-pagination";
import { useAppAlert } from "../../../../components/app-alert/use-app-alert";
import { AppToolTip } from "../../../../components/app-tooltip/app-tooltip";

export const DipDetails = () => {
  const [ticketDropdownValue, setTicketDropdownValue] = useState("");
  const [callDropdownValue, setCallDropdownValue] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [unassign, setUnassign] = useState(false);
  const [assignTicket, setAssignTicket] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [id, setId] = useState("");
  const { openAlert } = useAppAlert();
  const dispatch = useAppDispatch();

  //dropdown
  const dropdownTicketFilterHandler = (e: any) => {
    e.target.value === "All"
      ? setTicketDropdownValue("")
      : setTicketDropdownValue(e.target.value);
  };

  //dropdown
  const dropdownCallFilterHandler = (e: any) => {
    e.target.value === "All"
      ? setCallDropdownValue("")
      : setCallDropdownValue(e.target.value);
  };

  // Search Filter
  const searchHandler = (e: any) => {
    setSearchedText(e.target.value);
  };

  //get Data
  const { ticketsListData, ticketsStatus } = useAppSelector(
    (state) => state.tickets
  );

  // Tickets List
  useEffect(() => {
    const params = {
      search: searchedText,
      ticketStatus: ticketDropdownValue,
      callStatus: callDropdownValue,
      ticketType: "dip",
      limit: 7,
      offset: currentPage,
    };
    const debouce = setTimeout(
      () => dispatch(getTicketsListThunk({ axiosInstance, params })),
      500
    );
    return () => clearTimeout(debouce);
  }, [ticketDropdownValue, callDropdownValue, searchedText, currentPage]);

  const unAssignHandler = () => {
    dispatch(
      patchUnassignThunk({
        axiosInstance,
        ticketId: id,
        openAlert,
        dispatch,
        ticketType: "dip",
      })
    );
    setUnassign(false);
  };

  return (
    <Grid className="table width-100 overflow-scroll">
      <Grid container className="flex justify-space-between" mb={4} spacing={2}>
        <Grid
          item
          xs={12}
          md={9}
          className="flex"
          gap={2}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Grid item xs={12} md={4}>
            <AppInputField
              label="Ticket Status"
              labelClassName="primary-text-color font-weight-500"
              required={false}
              select={true}
              selectMenudata={TicketFilterStatus}
              value={ticketDropdownValue}
              onChange={dropdownTicketFilterHandler}
              iconComponent={FilterIcon}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInputField
              label="Call Status"
              labelClassName="primary-text-color font-weight-500"
              required={false}
              select={true}
              selectMenudata={CallFilterStatus}
              value={callDropdownValue}
              onChange={dropdownCallFilterHandler}
              iconComponent={FilterIcon}
            />
          </Grid>
        </Grid>
        <Grid item md={3} sx={{ mt: { xs: -3, sm: "auto" } }}>
          <AppInputField
            label={"\u00a0\u00a0"}
            placeholder="Search"
            required={false}
            select={false}
            onChange={searchHandler}
            endIcon={<SearchIcon />}
          />
        </Grid>
      </Grid>

      <DipDetailsTablePiece
        setAssignTicket={setAssignTicket}
        setId={setId}
        setUnassign={setUnassign}
      />

      <AppPagination
        currentPage={ticketsListData?.meta?.page ?? 0}
        totalPages={ticketsListData?.meta?.pages ?? 0}
        handleChangePage={(a, b) => {
          setCurrentPage((b - 1) * 7);
        }}
      />

      <AssignmentModal
        setOpen={setUnassign}
        open={unassign}
        color={"danger"}
        img={unassignedImage}
        message={"Are you sure you want to Unassign?"}
        onClick={unAssignHandler}
      />

      {assignTicket && (
        <ModalLayout
          isModalOpen={assignTicket}
          setIsModalOpen={setAssignTicket}
          leftMd={1.5}
          centerMd={9}
          rightMd={1.5}
        >
          <DipDetailsAssignTicket
            ticketId={id}
            setAssignTicket={setAssignTicket}
          />
        </ModalLayout>
      )}
    </Grid>
  );
};
export const DipDetailsTablePiece = (props: any) => {
  const { setAssignTicket, setId, setUnassign, dashboardView } = props;
  const { ticketsListData, ticketsStatus } = useAppSelector(
    (state) => state.tickets
  );
  const { status, adminDashboardData } = useAppSelector(
    (state: any) => state?.adminDashboardSlice
  );
  return (
    <>
      <AppTableUI
        tableHeadings={
          dashboardView ? DipDetailsTableHeadDashboard : DipDetailsTableHead
        }
      >
        {(
          !dashboardView
            ? ticketsStatus === REQUESTSTATUS.PENDING
            : status === REQUESTSTATUS.PENDING
        ) ? (
          !dashboardView ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <AppLoader />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <div className="flex align-center justify-center  padding-2">
                  <CircularProgress size={35} />
                  <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
                    Loading...{" "}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          )
        ) : (
          <>
            {(!dashboardView
              ? ticketsListData?.users
              : adminDashboardData?.recentTickets || []
            )?.length ? (
              (!dashboardView
                ? ticketsListData?.users
                : adminDashboardData?.recentTickets || []
              )?.map((row: any, index: any): any => (
                <TableRow
                  key={row?.["_id"]}
                  className="border-bottom-light-grey"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    whiteSpace: "nowrap",
                  }}
                >
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="primary-text-color primary-title font-weight-400"
                    align="left"
                  >
                    {row?.["clientName"] ?? "-"}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      {row?.["phoneNo"] ?? "-"}
                    </TableCell>
                  )}

                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["date"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["time"] ?? "-"}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      <AppToolTip
                        tooltipTitle={row?.["mortgageType"] ?? "Missing Title"}
                      >
                        <div className="cursor-pointer">
                          {`${row?.["mortgageType"]?.slice(0, 23) ?? "-"} 
                            ${
                              row?.["mortgageType"]?.length > 23 ? ". . . " : ""
                            }`}
                        </div>
                      </AppToolTip>
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className="primary-text-color tertiary-title font-weight-600 center-text"
                  >
                    {row?.["ticketStatus"] === "Unassigned" ? (
                      <Grid
                        sx={{ p: 1 }}
                        className="warning-color bg-tertiary-color border-radius-20"
                      >
                        Unassigned
                      </Grid>
                    ) : (
                      <Tooltip
                        title={
                          <div className="padding-right-1">
                            <div className="primary-color font-weight-500 primary-title">
                              Sale Agent Name
                            </div>
                            <p className="primary-text-color font-weight-400 secondary-title">
                              {row?.assignDetail?.assigneeName}
                            </p>
                            <p className="primary-color font-weight-500 primary-title">
                              Date
                            </p>
                            <div className="primary-text-color font-weight-400 secondary-title">
                              {row?.assignDetail?.date}
                            </div>
                          </div>
                        }
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              background: MUIWHITECOLOR,
                              borderRadius: MUIBORDERRADIUS10,
                              p: 2,
                              boxShadow: 2,
                            },
                          },
                        }}
                        sx={{
                          arrow: {
                            color: "red",
                            backgroundColor: "red",
                            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.2)",
                            borderColor: "red",
                            borderWidth: "1px",
                          },
                        }}
                      >
                        <Grid
                          className="success-color bg-tertiary-color border-radius-20 flex justify-center align-center cursor-pointer"
                          sx={{
                            p: 1,
                          }}
                        >
                          Assigned
                        </Grid>
                      </Tooltip>
                    )}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color tertiary-title font-weight-600 center-text"
                    >
                      {row?.["callStatus"] === "Pending" ? (
                        <Grid
                          sx={{ width: "100px", p: 1 }}
                          className="orange-color
                                        bg-tertiary-color border-radius-20 "
                        >
                          Pending
                        </Grid>
                      ) : (
                        <Grid
                          sx={{ width: "100px", p: 1 }}
                          className="success-color
                                        bg-tertiary-color border-radius-20 "
                        >
                          Completed
                        </Grid>
                      )}
                    </TableCell>
                  )}

                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      <div className="flex">
                        {row?.["ticketStatus"] === "Unassigned" &&
                          row?.["callStatus"] === "Pending" && (
                            <>
                              <Tooltip
                                title="Not Assigned"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUILIGHTGREYCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUILIGHTGREYCOLOR,
                                    "&:hover": {
                                      background: MUILIGHTGREYCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                >
                                  <CancelIcon />
                                </Grid>
                              </Tooltip>
                              <Tooltip
                                title="Assign"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUISUCCESSCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUISUCCESSCOLOR,
                                    "&:hover": {
                                      background: MUISUCCESSCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                  onClick={() => {
                                    setAssignTicket(true);
                                    setId(row?.["_id"]);
                                  }}
                                >
                                  <HowToRegIcon />
                                </Grid>
                              </Tooltip>
                            </>
                          )}
                        {row?.["ticketStatus"] === "Assigned" &&
                          row?.["callStatus"] === "Pending" && (
                            <>
                              <Tooltip
                                title="Unassign"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUIDANGERCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUIDANGERCOLOR,
                                    "&:hover": {
                                      background: MUIDANGERCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                  onClick={() => {
                                    setUnassign(true);
                                    setId(row?.["_id"]);
                                  }}
                                >
                                  <CancelIcon />
                                </Grid>
                              </Tooltip>
                              <Tooltip
                                title="Assigned"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUILIGHTGREYCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUILIGHTGREYCOLOR,
                                    "&:hover": {
                                      background: MUILIGHTGREYCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                >
                                  <HowToRegIcon />
                                </Grid>
                              </Tooltip>
                            </>
                          )}
                        {row?.["ticketStatus"] === "Assigned" &&
                          row?.["callStatus"] === "Completed" && (
                            <>
                              <Tooltip
                                title="Completed"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUILIGHTGREYCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUILIGHTGREYCOLOR,
                                    "&:hover": {
                                      background: MUILIGHTGREYCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                >
                                  <CancelIcon />
                                </Grid>
                              </Tooltip>
                              <Tooltip
                                title="Completed"
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: MUILIGHTGREYCOLOR,
                                      fontSize: MUISECONDARYTITLE,
                                      fontWeight: MUIFONTWEIGHT500,
                                      borderRadius: MUIBORDERRADIUS10,
                                      paddingX: "15px",
                                    },
                                  },
                                }}
                              >
                                <Grid
                                  className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                                  sx={{
                                    height: 40,
                                    color: MUILIGHTGREYCOLOR,
                                    "&:hover": {
                                      background: MUILIGHTGREYCOLOR,
                                      color: `${MUIWHITECOLOR} !important`,
                                    },
                                  }}
                                >
                                  <HowToRegIcon />
                                </Grid>
                              </Tooltip>
                            </>
                          )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <p className="center-text font-weight-600 error-color width-100">
                    No data
                  </p>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </AppTableUI>
    </>
  );
};
