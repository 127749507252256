import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const myPersonalDetailsThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {


    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.name = payload.name;
    state.title = payload.title;
    state.role = payload.role;
    state.drn = payload.drn;
    state.documents = payload.documents;
    state.personalProfileInfo = payload.personalProfileInfo;
    state.solicitorpersonalProfileInfo = payload.solicitorpersonalProfileInfo;
    state.profileImage = payload.profileImage;
    state.solicitorFees = payload.solicitorFees;
  });
};

export const myPersonalDetailsThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const myPersonalDetailsUpdateThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsUpdateThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.message = payload.message;
    state.name = payload.name;
    state.title = payload.title;
    state.role = payload.role;
    state.drn = payload.drn;
    state.documents = payload.documents;
    state.personalProfileInfo = payload.personalProfileInfo;
    state.solicitorpersonalProfileInfo = payload.solicitorpersonalProfileInfo;
  });
};

export const myPersonalDetailsUpdateThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const myPersonalDetailsChangeProfileImageThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.changeProfileImageStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsChangeProfileImageThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.changeProfileImageStatus = REQUESTSTATUS.SUCCEEDED;
    state.profileImage = payload.profileImage;
  });
};

export const myPersonalDetailsChangeProfileImageThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.changeProfileImageStatus = REQUESTSTATUS.FAILED;
  });
};

export const myPersonalDetailsAddDocumentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.addDocumentStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsAddDocumentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.addDocumentStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const myPersonalDetailsAddDocumentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.addDocumentStatus = REQUESTSTATUS.FAILED;
  });
};

export const myPersonalDetailsEditDocumentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.editDocumentStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsEditDocumentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.editDocumentStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const myPersonalDetailsEditDocumentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.editDocumentStatus = REQUESTSTATUS.FAILED;
  });
};

export const myPersonalDetailsDeleteDocumentThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.deleteDocumentStatus = REQUESTSTATUS.PENDING;
  });
};

export const myPersonalDetailsDeleteDocumentThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.deleteDocumentStatus = REQUESTSTATUS.SUCCEEDED;
    // state.documents = state.documents.filter((obj: any) => obj._id !== payload.documentId);
  });
};

export const myPersonalDetailsDeleteDocumentThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.deleteDocumentStatus = REQUESTSTATUS.FAILED;
  });
};
