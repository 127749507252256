import { useState, useEffect } from "react";
import { useAxiosInterceptors } from "../../../hooks/use-axios-interceptors";
import {
  getUserNotificationsThunk,
  markUserNotificationsReadThunk,
  markUserNotificationsUnReadThunk,
  markUserNotificationsDeleteThunk,
  markUserNotificationsReadAllThunk,
  getAllUserNotificationsThunk,
} from "../../../store/layout/layout-thunk-actions";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export const useNotificationCard = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInterceptors();
  const [pageLength, setPageLength] = useState(3);
  const { layout } = useAppSelector((state) => state);

  const [unreadNotifications, setUnreadNotifications] = useState(true);
  const [checkedNotifications, setCheckedNotifications] = useState<any>([]);

  const OPEN = Boolean(anchorEl);
  const openCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    !!anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  // all selection on view
  const selectAllHandler: any = (e: any) => {
    let checked = e.target.checked;
    let backup = [...layout?.notifications];
    let array: any = [];
    backup.forEach((i: any) => array.push(i._id));
    checked ? setCheckedNotifications(array) : setCheckedNotifications([]);
  };
  //single selection
  const selectNotificationHandler = (status: any, id: any) => {
    if (checkedNotifications?.includes(id) && !status) {
      setCheckedNotifications((prev: any) => prev.filter((i: any) => i !== id));
    } else {
      setCheckedNotifications((prev: any) => [...prev, id]);
    }
  };
  // ----------
  const readMoreHanlder = () => {
    setPageLength(1000);
    setCheckedNotifications([]);
    setUnreadNotifications(true);
  };
  const readLessHanlder = () => {
    setPageLength(3);
    setCheckedNotifications([]);
    setUnreadNotifications(true);
  };
  //------------
  //read-unread selected Notifications
  const markAsReadHandler = () => {
    const selectedIds = { notificationIds: checkedNotifications };
    dispatch(
      markUserNotificationsReadThunk({ axiosInstance, selectedIds, dispatch })
    );

    setCheckedNotifications([]);
  };
  const markAsUnReadHandler = () => {
    const selectedIds = { notificationIds: checkedNotifications };
    dispatch(
      markUserNotificationsUnReadThunk({ axiosInstance, selectedIds, dispatch })
    );

    setCheckedNotifications([]);
  };
  //---------
  const deleteSelectedNotificationsHandler = () => {
    const selectedIds = { notificationIds: checkedNotifications };
    dispatch(
      markUserNotificationsDeleteThunk({ axiosInstance, selectedIds, dispatch })
    );

    setCheckedNotifications([]);
  };

  const viewUnreadNotificationsHandler = () => {
    setUnreadNotifications(false);
    setPageLength(1000);
    setCheckedNotifications([]);
  };
  const viewReadNotificationsHandler = () => {
    setUnreadNotifications(true);
    setPageLength(1000);
    setCheckedNotifications([]);
  };

  useEffect(() => {
    const params = {
      limit: pageLength,
      offset: 0,
    };
    dispatch(
      getUserNotificationsThunk({
        axiosInstance,
        params,
      })
    );
    dispatch(getAllUserNotificationsThunk({ axiosInstance }));
  }, [pageLength]);

  return {
    OPEN,
    openCard,
    handleClose,
    layout,
    status: layout?.status,
    readMoreHanlder,
    readLessHanlder,
    selectAllHandler,
    selectNotificationHandler,
    checkedNotifications,
    deleteSelectedNotificationsHandler,
    unreadNotifications,
    viewReadNotificationsHandler,
    viewUnreadNotificationsHandler,
    markAsReadHandler,
    markAsUnReadHandler,
    dispatch,
    axiosInstance,
    markUserNotificationsReadAllThunk,
    markUserNotificationsReadThunk,
  };
};
