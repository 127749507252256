import { useState } from "react";

import { DrawSignature } from "./draw-signature";
import { DRAWPROPS } from "../OPTIONINTERFACE";


export const Draw:React.FC<DRAWPROPS> = ({signatureRef}) => {
  const [test, setTest] = useState(false);
  return (
    <>
      <div className="primary-text-color font-weight-500 margin-bottom-1">
        Draw Your Signature here
      </div>
      {/*  Draw Here  */}
      <div style={{ height: "200px", border: "1px dashed black" }}>
        <DrawSignature signatureRef={signatureRef} />
      </div>
      
    </>
  );
};
