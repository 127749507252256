import { REQUESTSTATUS } from "../../constants/axios-api-constant";

// Solicitors get API
export const getsolicitorThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getsolicitorThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.solicitorLists = payload;
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getsolicitorThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

// Solitiors List Modal
export const getModalsolicitorThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getModalsolicitorThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.solisitorsListModal = payload;
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getModalsolicitorThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
    state.solisitorsListModal = [];
  });
};

// Post Assign Solicitor
export const postAssigneSolicitorRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const postAssigneSolicitorFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const postAssigneSolicitorPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};
