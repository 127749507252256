import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getAdminDataThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
  });
};

export const getAdminDataThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const getAdminDataThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    
        state.status = REQUESTSTATUS.SUCCEEDED;
        state.closedTicketsThisMonth = payload?.closedTicketsThisMonth;
        state.closedTicketsThisWeek= payload?.closedTicketsThisWeek;
        state.newTicketsThisMonth= payload?.newTicketsThisMonth;
        state.newTicketsThisWeek= payload?.newTicketsThisWeek;
        state.totalTickets= payload?.totalTickets;
        state.adminDashboardData=payload

    });
};
