import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const AppTableUI = (props: any) => {
  const { children, tableHeadings, showActon } = props;

  return (
    <div className="table width-100 overflow-scroll">
      <Table aria-label="simple table">
        <TableHead className="bg-tertiary-background-color  border-radius-10 primary-text-color primary-title font-weight-600">
          <TableRow className="border-radius-10">
            {!!tableHeadings.length &&
              tableHeadings.map((heading: any, index: any) => (
                <TableCell
                  align={heading?.align || "left"}
                  sx={{ whiteSpace: "nowrap" }}
                  key={heading?.id}
                  className={`primary-text-color primary-title font-weight-600
                                ${index === 0 && "border-top-left-radius-10"}
                                ${
                                  index === tableHeadings?.length - 1 &&
                                  "border-top-right-radius-10"
                                }`}
                >
                  {heading?.name}
                </TableCell>
              ))}
            {showActon && (
              <TableCell className="primary-text-color primary-title font-weight-600">
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </div>
  );
};
