import { Link } from "react-router-dom";

// MUI
import { Grid } from "@mui/material";

// Data
// import { footerContent, footerData } from "./footer-data";

// Interfaces
// import { IFOOTERCONTENT, IFOOTERICONS } from "./footer-interface";

// SCSS
import "./footer.scss";
import { useFooter } from "./use-footer";

// Component Functions Starts Here
export const FooterMainLayout = () => {
  const { xSmallWidth, smallWidth, openDrawer }: any = useFooter();

  return (
    // <Grid container className="footer" bottom="0">
    //   <Grid
    //     item
    //     className="footer-grid-1 rwap-text copyright-grid"
    //     xs={12}
    //     sm={openDrawer && smallWidth ? 12 : !openDrawer && xSmallWidth ? 12 : 5}
    //     lg={4}
    //     display="flex"
    //     alignItems="center"
    //     justifyContent={
    //       (openDrawer && smallWidth) || xSmallWidth ? "center" : "flex-start"
    //     }
    //   >
    //     <p className="tertiary-title text-color">
    //       Copyright ©2023 All rights reserved by{" "}
    //       <Link to="#" className="primary-color">
    //         Orcalo Limited
    //       </Link>
    //     </p>
    //   </Grid>
    //   <Grid
    //     item
    //     // marginTop="8px"
    //     className="footer-grid-2"
    //     xs={12}
    //     sm={openDrawer && smallWidth ? 12 : !openDrawer && xSmallWidth ? 12 : 3}
    //     lg={4}
    //   >
    //     {footerData.map((item: IFOOTERICONS) => (
    //       <Link key={item.id} to={item.path}>
    //         <span className="footer-text-color footer-icons-hover"> {item.icon}</span>
    //       </Link>
    //     ))}
    //   </Grid>
    //   <Grid
    //     item
    //     className="footer-grid-3"
    //     xs={12}
    //     sm={openDrawer && smallWidth ? 12 : !openDrawer && xSmallWidth ? 12 : 4}
    //     lg={4}
    //     display="flex"
    //     alignItems="center"
    //     justifyContent={
    //       (openDrawer && smallWidth) || xSmallWidth ? "center" : "flex-end"
    //     }
    //   >
    //     <p className="tertiary-title selected-field-color footer-rwap-text">
    //       {footerContent.map((item: IFOOTERCONTENT) => (
    //         <Link to={item.path} key={item.id}>
    //           <span className="wrap-text">{item.text}</span>
    //         </Link>
    //       ))}
    //     </p>
    //   </Grid>
    // </Grid>
    <Grid container>
      <Grid
        item
        xs={1}
        className="bg-transparent-blue-color footer-transform-1 footer  margin-top-1"
        height="30px"
      ></Grid>
      <Grid
        item
        xs={8}
        className="bg-primary-color footer margin-top-1 footer-transform-2"
        height="30px"
      ></Grid>
      <Grid
        item
        xs={3}
        className="bg-primary-color footer margin-top-1 footer-transform-3"
        height="30px"
      ></Grid>
    </Grid>
  );
};
