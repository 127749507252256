import { Grid } from "@mui/material";

export const ShortInfo = (props: any) => {
  const { infoabout1, info1, infoabout2, info2 } = props;
  return (
    <>
      <Grid container className="align-center">
        <Grid item xs={12} md={2.5} >
          <div className="primary-text-color tertiary-title">{infoabout1}</div>
        </Grid>
        <Grid item xs={12} md={3} >
          <div className="primary-color secondary-heading font-weight-600">
            <span className="success-color"> £ </span> <span>{info1}</span>
          </div>
        </Grid>
        <Grid item xs={12} md={3.5} >
          <div className="primary-text-color tertiary-title ">{infoabout2}</div>
        </Grid>
        <Grid item xs={12} md={3} >
          <div className="primary-color secondary-heading font-weight-600">
            <span className="danger-color"> £ </span> <span>{info2}</span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
