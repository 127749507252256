import { createSlice } from "@reduxjs/toolkit";

import { getSalesAgentThunkFulfilledReducer, getSalesAgentThunkPendingReducer, getSalesAgentThunkRejectedReducer, getSalesAgentYearThunkFulfilledReducer, getSalesAgentYearThunkPendingReducer, getSalesAgentYearThunkRejectedReducer } from "./sales-agent-dashboard-reducer";
import { getSalesAgentDashboardDataThunk, getSalesAgentYearDashboardDataThunk } from "./sales-agent-dashboard-thunk";

const salesAgentDashboardInitialState = {
    status: '',
    salesAgentData: {} as any,

    yearStatus: '' as any,
    salesAgentUserData: {} as any
};

export const salesAgentSlice = createSlice({
    name: "salesAgentDashboard",
    initialState: salesAgentDashboardInitialState,
    reducers: {},
    extraReducers(builder) {
        getSalesAgentThunkRejectedReducer(builder, getSalesAgentDashboardDataThunk)
        getSalesAgentThunkPendingReducer(builder, getSalesAgentDashboardDataThunk)
        getSalesAgentThunkFulfilledReducer(builder, getSalesAgentDashboardDataThunk)

        getSalesAgentYearThunkRejectedReducer(builder, getSalesAgentYearDashboardDataThunk)
        getSalesAgentYearThunkPendingReducer(builder, getSalesAgentYearDashboardDataThunk)
        getSalesAgentYearThunkFulfilledReducer(builder, getSalesAgentYearDashboardDataThunk)
    },
});
export default salesAgentSlice.reducer;