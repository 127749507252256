export const UserTypeStatus = [
  { id: "0", text: "All", Value: "All" },
  { id: "1", text: "Sale Agent", Value: "SALES_AGENT" },
  { id: "2", text: "Mortgage Broker", Value: "MORTGAGE_BROKER" },
];

export const AssignTicketTableHead = [
  {
    id: 1,
    name: "ID",
  },
  {
    id: 2,
    name: "User Name",
  },
  {
    id: 3,
    name: "User Type",
  },
  {
    id: 4,
    name: "Location",
  },
  {
    id: 5,
    name: "Active Requests",
  },
  {
    id: 6,
    name: "Closed Requests",
  },
  {
    id: 7,
    name: "Action",
  },
];
