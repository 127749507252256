export const casesListsSolicitorTableHead = [
  {
    id: 1,
    name: "Case ID",
  },
  {
    id: 2,
    name: "Clients Name",
  },
  {
    id: 3,
    name: "Email",
  },
  {
    id: 4,
    name: "Date Created",
  },
  {
    id: 5,
    name: "Applicant Type",
  },
  {
    id: 6,
    name: "Case Status",
  },
  {
    id: 7,
    name: "Action",
  },
];


export const casesListsSolicitorDashboardTableHead = [
  {
    id: 1,
    name: "Case ID",
  },
  {
    id: 2,
    name: "Clients Name",
  },
  {
    id: 4,
    name: "Date Created",
  },
  {
    id: 5,
    name: "Application Type",
  },
  {
    id: 6,
    name: "Status",
  },
];


export const casesListsClientTableHead = [
  {
    id: 1,
    name: "Sr#",
  },
  {
    id: 2,
    name: "Case ID",
  },
  {
    id: 3,
    name: "Applicant Type",
  },
  {
    id: 4,
    name: "Solicitor",
  },

  {
    id: 5,
    name: "Date Created",
  },

  {
    id: 6,
    name: "Case Status",
  },
  {
    id: 7,
    name: "Action",
  },
];

export const casesStatusList = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Completed",
  },
  {
    id: 3,
    name: "Post Completion",
  },
  {
    id: 4,
    name: "On Hold",
  },
  {
    id: 5,
    name: "In Progress",
  },
  {
    id: 6,
    name: "Fell Through",
  },
];
