import { REQUESTSTATUS } from "../../../constants/axios-api-constant";

export const getSalesAgentThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.status = REQUESTSTATUS.FAILED;
    });
};


export const getSalesAgentThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.status = REQUESTSTATUS.PENDING;
    });
};

export const getSalesAgentThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {

        state.status = REQUESTSTATUS.SUCCEEDED;
        state.salesAgentData = payload


    });
};




export const getSalesAgentYearThunkRejectedReducer = (builder: any, action: any) => {
    builder.addCase(action.rejected, (state: any, { payload }: any) => {

        state.yearStatus = REQUESTSTATUS.FAILED;
    });
};


export const getSalesAgentYearThunkPendingReducer = (builder: any, action: any) => {
    builder.addCase(action.pending, (state: any, { payload }: any) => {
        state.yearStatus = REQUESTSTATUS.PENDING;
    });
};

export const getSalesAgentYearThunkFulfilledReducer = (builder: any, action: any) => {
    builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
        
        state.yearStatus = REQUESTSTATUS.SUCCEEDED;
        state.salesAgentUserData = payload?.users
    });
};


