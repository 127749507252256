export const tabsDynamicProps = (index: number | any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const accordionDynamicProps = (index: number | any) => {
  return {
    id: `panel${index}a-header`,
    "aria-controls": `panel${index}a-content`,
  };
};

export const disableBlurOnMouseDown = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  event.preventDefault();
};

export const onDrop = (
  e: React.DragEvent<HTMLLabelElement>,
  { setDragOver, setFileDropError, setFile, acceptedFileType }: any
) => {
  e.preventDefault();

  setDragOver(false);

  const selectedFile = e?.dataTransfer?.files[0];

  if (selectedFile.type.split("/")[0] !== acceptedFileType) {
    return setFileDropError("Please provide an image file to upload!");
  }

  setFile(selectedFile);
};

export const setFiles = (
  e: any,
  { acceptedFileType, setFileDropError }: any
) => {
  const selectedFile = e?.dataTransfer?.files[0];

  if (selectedFile.type.split("/")[0] !== acceptedFileType) {
    return setFileDropError("Please provide an image file to upload!");
  }
};

export const getDataFromLocalStorage = (storageKey: any) => {
  try {
    const data = localStorage?.getItem(storageKey);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Error parsing JSON from localStorage", error);
    return null;
  }
};

export const saveDataToLocalStorage = (storageKey: any, dataToSave: any) => {
  const data = localStorage.setItem(storageKey, JSON.stringify(dataToSave));
  return data;
};

export const removeDataFromLocalStorage = (storageKey: any) => {
  const data = localStorage.removeItem(storageKey);
  return data;
};
