import {
  MUIBORDERRADIUS10,
  MUIBOXSHADOWTABS,
  MUIDISABLEDCOLOR,
  MUILIGHTGREYCOLOR,
  MUIPRIMARYCOLOR,
  MUISECONDARYTITLE,
  MUITERTIARYCOLOR,
  MUIWHITECOLOR,
} from "../../constants/mui-styles-constant";

export const SELECTPAPER = {
  marginTop: 0.8,
  maxHeight: "170px",
  width: 50,
  background: MUIWHITECOLOR,
  boxShadow: MUIBOXSHADOWTABS,
  borderRadius: MUIBORDERRADIUS10,
  overflowX: "hidden",
  position: "relative",
  "&::-webkit-scrollbar": {
    width: "4px",
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "1rem",
    backgroundColor: MUIPRIMARYCOLOR,
  },
  "& ul": {
    p: 0,
  },
  "& li": {
    fontSize: MUISECONDARYTITLE,
    color: MUILIGHTGREYCOLOR,
  },
  "& .MuiMenuItem-root": {
    borderBottom: `1px solid ${MUIDISABLEDCOLOR}`,
  },
  "& .MuiMenuItem-root.Mui-selected": {
    backgroundColor: MUITERTIARYCOLOR,
    color: MUIPRIMARYCOLOR,
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: MUITERTIARYCOLOR,
    color: MUIPRIMARYCOLOR,
  },
};
