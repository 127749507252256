import { Grid, Modal } from "@mui/material";
import { AppButton } from "../../../components/app-button/app-button";
import {
  MUIDANGERCOLOR,
  MUISUCCESSCOLOR,
} from "../../../constants/mui-styles-constant";

export const AssignmentModal = ({
  open,
  setOpen,
  color,
  img,
  message,
  onClick,
}: any) => {
  const themeColor =
    color === "success"
      ? MUISUCCESSCOLOR
      : color === "danger" && MUIDANGERCOLOR;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "300px", sm: "400px", md: "500px" },
    boxShadow: 24,
    borderTop: `10px solid ${themeColor}`,
    borderRadius: "8px",
    p: 3,
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={style} textAlign={"center"} className="bg-card-white">
        <div className="flex align-center justify-center">
          <Grid
            className={`bg-${color}-color flex align-center justify-center border-radius-rounded`}
            width={"105px"}
            height="105px"
          >
            <img src={img} alt="modal-type" height="50px" />
          </Grid>
        </div>
        <p className={`primary-heading font-weight-600 ${color}-color`}>
          {message}
        </p>
        <div className="flex justify-center">
          <Grid
            height="0px"
            width="50px"
            border={`1px solid ${themeColor}`}
            mb={1}
          />
        </div>
        <Grid container justifyContent={"center"} marginTop={2} mt={2} gap={2}>
          <AppButton
            className="btn-orange-outline primary-color tertiary-title font-weight-400 border-radius-pill bg-tertiary-color"
            type="button"
            btnText="No"
            btnFunction={() => {
              setOpen(false);
            }}
          />
          <AppButton
            className={`bg-${color}-color white-color tertiary-title font-weight-400 border-radius-pill`}
            type="button"
            btnText="Yes"
            btnFunction={onClick}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
