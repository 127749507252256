import { Template,BLANK_PDF  } from "@pdfme/generator";

export const cloneDeep = (obj: any) => JSON.parse(JSON.stringify(obj));
export const getTemplate = () => {
  const template: Template = {
    schemas: [
     
    ],
    basePdf:BLANK_PDF,
  };
  return template;
};



