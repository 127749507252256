import * as React from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DropDownInterface } from "./drop-down-interface";
const listItemStyles = {
  height: "2.5rem",
  width: "15rem",
  "&:hover": {
    color: "#3772c2",
    backgroundColor: "#366ec22b",
  },
};
export const DropDown: React.FC<DropDownInterface> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index: number) => {
    options.functions[index] && options.functions[index]();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon className="primary-color" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        sx={{
          ".MuiMenu-list": {
            padding: "0",
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.names.map((option: any, index: number) => (
          <MenuItem
            key={index}
            sx={{ ...listItemStyles }}
            onClick={() => handleClose(index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

// How To Use This Component
// This component take "options" as a prop this is the structure for passing data {names:[{name:op1},{name:op2}],functions:[() => fun1,() => fun2]}
// Feel Free To edit this because its styling is hard coded
