import { Fade, Grid, Modal } from "@mui/material";

const ModalLayout = (props: any) => {
  const {
    mainContainerSX,
    children,
    innerContainerSX,
    isModalOpen,
    setIsModalOpen,
    leftMd,
    centerMd,
    rightMd,
    leftLg,
    centerLg,
    rightLg,
    marginTop = 3,
  } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      closeAfterTransition
      onClose={() => setIsModalOpen(false)}
    >
      <Fade in={true}>
        <Grid
          container
          minHeight={"100vh"}
          className="flex align-center justify-center"
        >
          <Grid item xs={1} md={leftMd || 2} lg={leftLg || 2}></Grid>
          <Grid item xs={10} md={centerMd || 8} lg={centerLg || 8}>
            <Grid
              className=" position-relative box-shadow-primary 
                                        border-radius-20 bg-white-color"
              sx={mainContainerSX}
              p={2}
            >
              <Grid
                className="box-shadow-secondary 
                            cursor-pointer flex align-center justify-center 
                            position-absolute center-text bg-white-color
                            border-radius-rounded close-modal-hover"
                top={0}
                right="-25px"
                width="20px"
                height="20px"
                pb="3px"
                sx={{ "&:hover": {} }}
                onClick={() => setIsModalOpen(false)}
              >
                {" "}
                x{" "}
              </Grid>

              <Grid
                marginTop={marginTop}
                sx={{ maxHeight: { xs: 550, sm: 800 } }}
                className="overflow-scroll-y"
              >
                <Grid sx={innerContainerSX}>{children}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={rightMd || 2} lg={rightLg || 2}></Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ModalLayout;
