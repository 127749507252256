import totalContractImg from "../../../assets/images/app-short-summary-cards/total-contracts.png";
import signedContractImg from "../../../assets/images/app-short-summary-cards/signed-contract.png";
import unsignedContractImg from "../../../assets/images/app-short-summary-cards/unsigned-contracts.png";
import changeRequestImg from "../../../assets/images/app-short-summary-cards/change-request-contracts.png";

import newContact from "../../../assets/images/notification-and-reminders-card/new-contact.png";
import meetingAlert from "../../../assets/images/notification-and-reminders-card/meeting-alert.png";
import caseUpdate from "../../../assets/images/notification-and-reminders-card/case-update.png";
import documentUpdate from "../../../assets/images/notification-and-reminders-card/document-update.png";
import { useAppSelector } from "../../../store/store";
import { Grid } from "@mui/material";
import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";

export const ClientDataFile = () => {

  const { totalContract, signedWeekly, signedMonthly, unsignedMonthly, unsignedWeekly,
    ChangeRequestMonthly, ChangeRequestWeekly } = useAppSelector((state: any) => state?.clientDashboardSlice)

  const clientCardData: any = [
    {
      id: 1,
      bgColor: "#366EC2",
      value: totalContract,
      value2: "0",
      title: "Total Contracts",
      imgSrc: totalContractImg,
      thisWeekMonthToggler: false,
    },
    {
      id: 2,
      bgColor: "#48995D",
      value: signedWeekly,
      value2: signedMonthly,
      title: "Signed Contracts",
      imgSrc: signedContractImg,
      thisWeekMonthToggler: true,
    },
    {
      id: 3,
      bgColor: "#FF3F3F",
      value: unsignedWeekly,
      value2: unsignedMonthly,
      title: "Unsigned Contracts",
      imgSrc: unsignedContractImg,
      thisWeekMonthToggler: true,
    },
    {
      id: 4,
      bgColor: "#FA7901",
      value: ChangeRequestWeekly,
      value2: ChangeRequestMonthly,
      title: "Change Request Contracts",
      imgSrc: changeRequestImg,
      thisWeekMonthToggler: true,
    },
  ];

  return (
    <>
      {clientCardData?.map((ele: any) => (
        <Grid item xl={2.9} lg={5.9} sm={5.9} md={11.9}
          xs={11.9}
          marginBottom={{ xl: 0, xs: 2 }}
          key={ele?.id}
        >
          <ShortSummaryCards
            bgColor={ele?.bgColor}
            value={ele?.value || 0}
            title={ele?.title || 0}
            imgSrc={ele?.imgSrc}
            thisWeekMonthToggler={ele?.thisWeekMonthToggler}
            value2={ele?.value2}
            firstLabel={"This Week"}
            secondLabel={"This Month"}
            isCLient
          />
        </Grid>
      )
      )}
    </>
  )
}


// export const notificationCardData: any = [
//   {
//     id: 1,
//     heading: "New Contract",
//     discription: "Waiting for your sign",
//     color: "#FA7901",
//     cardImg: newContact,
//   },
//   {
//     id: 2,
//     heading: "Meeting Alert",
//     discription: "You have new meeting alert",
//     color: "#FF3F3F",
//     cardImg: meetingAlert,
//   },
//   {
//     id: 3,
//     heading: "Case Update",
//     discription: "Case id 123 Status update",
//     color: "#366EC2",
//     cardImg: caseUpdate,
//   },
//   {
//     id: 4,
//     heading: "Document Update",
//     discription: "Document sign is pending for one day",
//     color: "#48995D",
//     cardImg: documentUpdate,
//   },
// ];
