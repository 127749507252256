import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DIP_API_PREFIX,
  DIP_MORTGAGE_DETAILS_API,
  JOBSAPI,
} from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getProductsThunk = createAsyncThunk(
  "dip/get-products",
  async (
    { axiosInstance, openAlert, params, otherData }: any,
    { rejectWithValue }
  ) => {
    const { dipId } = otherData;
    if (!dipId) {
      showApiAlert("Dip ID is missing!", openAlert, "alert-error-message");

      return rejectWithValue({ error: "DIP ID is missing!" });
    }

    try {
      const response = await getDataAPI(
        `${DIP_API_PREFIX}/${dipId}/get-products`,
        axiosInstance,
        { ...params }
      );
      const responseData = {
        data: response?.data?.data,
        message: "Continue your application",
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getProductThunk = createAsyncThunk(
  "dip/get-product",
  async (
    { axiosInstance, openAlert, params, otherData }: any,
    { rejectWithValue }
  ) => {
    const { dipId,productCode } = otherData;
    if (!dipId) {
      showApiAlert("Dip ID is missing!", openAlert, "alert-error-message");

      return rejectWithValue({ error: "DIP ID is missing!" });
    }

    try {
      const response = await getDataAPI(
        `${DIP_API_PREFIX}/${dipId}/get-product`,
        axiosInstance,
        { ...params }
      );
      console.log("response",response?.data?.data);
      
      const responseData = {
        data: response?.data?.data,
        message: "Continue your application",
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getDIPApplicationThunk = createAsyncThunk(
  "dip/get-dip-application",
  async (
    { axiosInstance, openAlert, params, otherData }: any,
    { rejectWithValue }
  ) => {
    const { dipId, clientId } = otherData;
    if (!dipId) {
      showApiAlert("Dip ID is missing!", openAlert, "alert-error-message");

      return rejectWithValue({ error: "DIP ID is missing!" });
    }

    try {
      const response = await getDataAPI(
        `${DIP_API_PREFIX}/${dipId}`,
        axiosInstance,
        { ...params, clientId }
      );

      const responseData = {
        data: response?.data?.data,
        message: "Continue your application",
      };

      // if (responseData?.data?.applicationStage.toLowerCase() === "confirmed")
      //   showApiAlert(
      //     "Application already sent",
      //     openAlert,
      //     "alert-success-message"
      //   );

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getDIPApplicationsThunk = createAsyncThunk(
  "dip-applications",
  async (
    { axiosInstance, openAlert, navigate, dispatch, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(DIP_API_PREFIX, axiosInstance, params);

      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        data: formatData,
        meta: formatData.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const createDIPApplicationThunk = createAsyncThunk(
  "dip/add-dip",
  async (
    { axiosInstance, openAlert, body, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        DIP_MORTGAGE_DETAILS_API,
        axiosInstance,
        body,
        params
      );

      const responseData = {
        message: response?.data?.message,
        data: response.data,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const updateDIPApplicationThunk = createAsyncThunk(
  "dip/update-dip",
  async (
    { url, axiosInstance, openAlert, body, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(url, axiosInstance, body, params);

      const responseData = {
        message: response?.data?.message || "Application sent",
        data: response.data,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const confirmApplicationThunk = createAsyncThunk(
  "dip/confirm-dip",
  async (
    { url, axiosInstance, openAlert, body, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(url, axiosInstance, body, params);

      const responseData = {
        message: response?.data?.message,
        data: response.data,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const deleteDIPThunk = createAsyncThunk(
  "dip/delete-dip",
  async (
    { axiosInstance, openAlert, body, dispatch, setIsDelete, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(JOBSAPI, axiosInstance, body);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setIsDelete?.({ open: false, id: null });
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(
        getDIPApplicationThunk({
          axiosInstance,
          openAlert,
          navigate,
          dispatch,
          params,
        })
      );
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");
      setIsDelete?.({ open: false, id: null });
      return rejectWithValue(errorData);
    }
  }
);

export const getPostCodeThunk = createAsyncThunk(
  "get/postCode",
  async (
    { axiosInstance,params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        `search/postcodes/${params.id}`,
        axiosInstance
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };


      return rejectWithValue(errorData);
    }
  }
);

export const getPropertyPostCodeThunk = createAsyncThunk(
  "get/propertyPostCode",
  async (
    { axiosInstance,params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        `search/postcodes/${params.id}`,
        axiosInstance
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };


      return rejectWithValue(errorData);
    }
  }
);

export const getApplicantPostCodeThunk = createAsyncThunk(
  "get/applicantPostCode",
  async (
    { axiosInstance,params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        `search/postcodes/${params.id}`,
        axiosInstance
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };


      return rejectWithValue(errorData);
    }
  }
);

