import * as Yup from "yup";

import {
  ALLCHARACTERSONLYVALIDATION,
  ATLEASTONELOWERCASEVALIDATION,
  ATLEASTONESPECIALCHARACTERVALIDATION,
  ATLEASTONEUPPERCASEVALIDATION,
  MAXIMUMNAMELENGTH,
  MAXIMUMPASSWORDLENGTHVALIDATION,
  MAXIMUMPHONENUMBERLENGTH,
  MINIMUMPASSWORDLENGTHVALIDATION,
  MAXUPLOADFILESIZE,
  UKNUMBERVALIDATION,
  ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
  // ALPHANUMERICVALIDATION,
  BOTHAPLHANUMERICVALIDATION,
  // ALPHANUMERICANDCHARACTERS,
  ONLYNUMERICVALUESVALIDATION,
  // ALPHANUMERICANDOTHERSBASICCHARACTERS,
  ALPHANUMERICWITHBASICCHARACTERSVALIDATION,
} from "../constants/validations-constant";

////sales sitess  validations
////sales sitess  validations

export const JOINUSASESTATEAGENTFORMVALIDATIONSCHEMA = Yup.object({
  identityNo: Yup.string()
    .trim()
    .required("AML Number is required")
    .matches(BOTHAPLHANUMERICVALIDATION, "not a valid AML number"),
  // companyName: Yup.string().trim().required(" Company Name is required"),
  firstName: Yup.string()
    .trim()
    .required(" First Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  lastName: Yup.string()
    .trim()
    .required(" Last Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  email: Yup.string().email("Email is in-valid").required("Email is required"),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number") // eslint-disable-line
    .required("Phone is required"),
  drivingLicenseFile: Yup.mixed().required("Driving License is required"),
  // drn: Yup.string().trim().required("DRN Number is required"),
  postCode: Yup.string().trim().required(" Post Code is required"),
  // address:Yup.string().required('Address is required. click on the search icon of post code field to populate address'),
  flatNo: Yup.string()
    .trim()
    .matches(ALPHANUMERICWITHBASICCHARACTERSVALIDATION, "invalid flat/ unit "),
  buildingName: Yup.string()
    .trim()
    .required("Building Name is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  buildingNo: Yup.string()
    .trim()
    .matches(ONLYNUMERICVALUESVALIDATION, "Only numbers are allowed"),
  streetName: Yup.string()
    .trim()
    .required("Street Name is required")
    .matches(
      ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
      "Only alphabets are allowed"
    ),
  city: Yup.string()
    .trim()
    .required("Town/City is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  country: Yup.string()
    .trim()
    .required("Country is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  county: Yup.string()
    .trim()
    .required("County is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
});

export const JOINUSASMORTGAGEBROKERFORMVALIDATIONSCHEMA = Yup.object({
  identityNo: Yup.string()
    .trim()
    .required("FCA Number is required")
    .matches(BOTHAPLHANUMERICVALIDATION, "not a valid AML number"),
  // companyName: Yup.string().trim().required(" Company Name is required"),
  firstName: Yup.string()
    .trim()
    .required(" First Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  lastName: Yup.string()
    .trim()
    .required(" Last Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  email: Yup.string().email("Email is in-valid").required("Email is required"),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number") // eslint-disable-line
    .required("Phone is required"),
  drivingLicenseFile: Yup.mixed().required("Driving License is required"),
  // drn: Yup.string().trim().required("DRN Number is required"),
  postCode: Yup.string().trim().required(" Post Code is required"),
  // address:Yup.string().required('Address is required. click on the search icon of post code field to populate address'),
  flatNo: Yup.string()
    .trim()
    .matches(ALPHANUMERICWITHBASICCHARACTERSVALIDATION, "Invalid flat/ unit "),
  buildingName: Yup.string()
    .trim()
    .required("Building Name is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  buildingNo: Yup.string()
    .trim()
    .matches(ONLYNUMERICVALUESVALIDATION, "Only numbers are allowed"),
  streetName: Yup.string()
    .trim()
    .required("Street Name is required")
    .matches(
      ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
      "Only alphabets are allowed"
    ),
  city: Yup.string()
    .trim()
    .required("Town/City is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  country: Yup.string()
    .trim()
    .required("Country is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  county: Yup.string()
    .trim()
    .required("County is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
});

export const JOINUSASSOLICITORFORMVALIDATIONSCHEMA = Yup.object({
  identityNo: Yup.string()
    .trim()
    .required("SRA Number is required")
    .matches(BOTHAPLHANUMERICVALIDATION, "Not a valid SRA number"),
  // companyName: Yup.string().trim().required(" Company Name is required"),
  firstName: Yup.string()
    .trim()
    .required(" First Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  lastName: Yup.string()
    .trim()
    .required(" Last Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  email: Yup.string().email("Email is in-valid").required("Email is required"),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number") // eslint-disable-line
    .required("Phone is required"),
  drivingLicenseFile: Yup.mixed().required("Driving License is required"),
  // drn: Yup.string().trim().required("DRN Number is required"),
  postCode: Yup.string().trim().required(" Post Code is required"),
  // address:Yup.string().required('Address is required. click on the search icon of post code field to populate address'),
  flatNo: Yup.string()
    .trim()
    .matches(ALPHANUMERICWITHBASICCHARACTERSVALIDATION, "invalid flat/ unit "),
  buildingName: Yup.string()
    .trim()
    .required("Building Name is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  buildingNo: Yup.string()
    .trim()
    .matches(ONLYNUMERICVALUESVALIDATION, "Only numbers are allowed"),
  streetName: Yup.string()
    .trim()
    .required("Street Name is required")
    .matches(
      ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
      "only alphabets are allowed"
    ),
  city: Yup.string()
    .trim()
    .required("Town/City is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  country: Yup.string()
    .trim()
    .required("Country is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  county: Yup.string()
    .trim()
    .required("County is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
});

////// sales sitess validationn
////// sales sitess validationn

export const SIGNUPFORMVALIDATIONSCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Email is Invalid")
    .required("Required Field")
    .typeError('include "@" in email address'),
  password: Yup.string()
    .required("Required Field")

    .matches(/^(?=.{8,})/, "At least 8 characters long") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Must include atleast 1 number")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "At least 8 characters long")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed"), // eslint-disable-line
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Password and Confirmed Password Does not Match"
    )
    .oneOf(
      [Yup.ref("password"), null],
      "Password and Confirmed Password Does not Match"
    )
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Required Field"),
  drnNumber: Yup.string().trim(),
});

export const SIGNINFORMVALIDATIONSCHEMA = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const FORGOTPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  email: Yup.string().email("Email is in-valid").required("Email is required"),
});

export const RESETPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  // email: Yup.string().email("Email is in valid").required("Required field"),
  // code: Yup.string().required("Verification Code required"),
  password: Yup.string()
    .required("Required Field")

    .matches(/^(?=.{8,})/, "At least 8 characters long") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Must include atleast 1 number")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "At least 8 characters long")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed"), // eslint-disable-line
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Password and Confirmed Password Does not Match"
    )
    .oneOf(
      [Yup.ref("password"), null],
      "Password and Confirmed Password Does not Match"
    )
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Confirm Password is required"),
});

export const CHANGEPASSWORDFORMVALIDATIONSCHEMA = Yup.object({
  currentPassword: Yup.string().required("Required Field"),
  newPassword: Yup.string()
    .required("Required Field")

    .matches(/^(?=.{8,})/, "At least 8 characters long") // eslint-disable-line
    // .matches(ALLCASECHARACTERSONLYVALIDATION, "Please match the given criteria") // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(
      ATLEASTONEUPPERCASEVALIDATION,
      "Must include atleast 1 uppercase letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONELOWERCASEVALIDATION,
      "Must include atleast 1 small letter"
    ) // eslint-disable-line
    .matches(
      ATLEASTONESPECIALCHARACTERVALIDATION,
      "Must include atleast 1 special character"
    ) // eslint-disable-line
    .matches(/^(?=.{6,30}$)\D*\d/, "Must include atleast 1 number")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "At least 8 characters long")
    .max(MAXIMUMPASSWORDLENGTHVALIDATION, "Maximum 30 characters allowed"), // eslint-disable-line
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Password must Match")
    .min(MINIMUMPASSWORDLENGTHVALIDATION, "Must be atleast 8 characters long")
    .required("Required Field"),
});

export const CHANGESEAMILFORMVALIDATIONSCHEMA = Yup.object({
  currentPassword: Yup.string().required("Required Field"),
  newEmail: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
});

export const checkImage = (file: any) => {
  let err = "";
  if (!file) {
    return (err = "File does not exist.");
  }
  if (file.type !== "image/jpeg" && file.type !== "image/png") {
    return (err = "Accepted file type is jpeg, jpg and png");
  }
  if (file.type !== "image/jpeg" && file.type !== "image/png") {
    return (err = "Accepted file type is jpeg, jpg and png");
  }

  if (file.size > MAXUPLOADFILESIZE) {
    // 1mb
    return (err = "The largest image size is 1Mb.");
  }
  if (file.size > MAXUPLOADFILESIZE) {
    // 1mb
    return (err = "The largest image size is 1Mb.");
  }
  return err;
};

export const checkPdf = (file: any) => {
  let err = "";
  if (!file) {
    return (err = "File does not exist.");
  }
  if (file.type !== "application/pdf" && file.type !== ".pdf") {
    return (err = "Accepted file type is Pdf");
  }

  // if (file.size > MAXUPLOADFILESIZE) {
  //   // 1mb
  //   return (err = "The largest image size is 1Mb.");
  // }
  // if (file.size > MAXUPLOADFILESIZE) {
  //   // 1mb
  //   return (err = "The largest image size is 1Mb.");
  // }
  return err;
};

export const UPSERTPERSONALDETAILSFORMSCHEMA = Yup.object({
  title: Yup.string().trim().required(" Title is required"),
  firstName: Yup.string()
    .trim()
    .required(" First Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  middleName: Yup.string(),
  // .trim()
  // .required("Middle Name is required")
  // .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
  // .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  lastName: Yup.string()
    .trim()
    .required(" Last Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  dob: Yup.mixed().required("DOB is required"),
  age: Yup.string().required("Age is required"),
  gender: Yup.string().trim().required("Gender is required"),
  maritalstatus: Yup.string().trim().required("Marital Status is required"),
  employment: Yup.string().trim().required("Employment is required"),
  occupation: Yup.string().trim().required("Occupation is required"),
  citizenship: Yup.string().trim().required("Citizenship is required"),
  email: Yup.string().email("Email is in-valid").required("Email is required"),
  phone: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number") // eslint-disable-line
    .required("Required Field"),
  drnNumber: Yup.string().trim(),
  postcode: Yup.string().trim().required(" Post code is required"),
  isDetailedAddress: Yup.boolean(),
  address: Yup.string(),
  // address:Yup.string().required('Address is required. click on the search icon of post code field to populate address'),
  // flatNo: Yup.string()
  //   .trim()
  //   .required("Required Field")
  //   .matches(ALPHANUMERICWITHBASICCHARACTERSVALIDATION, "Invalid flat/ unit "),
  // buildingName: Yup.string()
  //   .trim()
  //   .required("Required Field")
  //   .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  buildingNo: Yup.string()
    .trim()
    .required("Required Field")
    .matches(ONLYNUMERICVALUESVALIDATION, "Only numbers are allowed"),
  streetName: Yup.string()
    .trim()
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  city: Yup.string()
    .trim()
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  country: Yup.string()
    .trim()
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
  county: Yup.string()
    .trim()
    .required("Required Field")
    .matches(ALLCHARACTERSONLYVALIDATION, "Only alphabets are allowed"),
});

export const ADDUSERWITHOUTADDRESSVALIDATIONSCHEMA = Yup.object({
  userType: Yup.string().required("Required Field"),
  firstName: Yup.string().required("Required Field"),
  middleName: Yup.string().required("Required Field"),
  lastName: Yup.string().required("Required Field"),
  dob: Yup.string().required("Required Field"),
  gender: Yup.string().required("Required Field"),
  email: Yup.string().email("Email is invalid").required("Required Field"),

  phone: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number (+44xxxxxxxxxx)") // eslint-disable-line
    .required("Phone is required"),
  postCode: Yup.string().required("Required Field"),
});
export const ADDUSERWITHADDRESSVALIDATIONSCHEMA = Yup.object({
  defaultRole: Yup.string().required("Required Field"),
  firstName: Yup.string().required("Required Field"),
  // middleName: Yup.string().required("Required Field"),
  lastName: Yup.string().required("Required Field"),
  dob: Yup.string().required("Required Field"),
  gender: Yup.string().required("Required Field"),
  email: Yup.string().email("Email is invalid").required("Required Field"),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number (+44xxxxxxxxxx)") // eslint-disable-line
    .required("Phone is required"),
  postCode: Yup.string().required("Required Field"),
  // flat: Yup.string().required("Required Field"),
  buildingName: Yup.string().required("Required Field"),
  // buildingNo: Yup.string().required("Required Field"),
  city: Yup.string().required("Required Field"),
  streetName: Yup.string().required("Required Field"),
  country: Yup.string().required("Required Field"),
  county: Yup.string().required("Required Field"),
});

//Enquiry Form Schema
export const ENQUIRYFORMSCHEMA = Yup.object({
  name: Yup.string().trim().required("Name is Required"),
  email: Yup.string()
    .trim()
    .email("Email is Invalid")
    .required("Email is Required"),
  phoneNumber: Yup.string()
    .trim()
    .required("Phone Number is Required")
    .matches(/^\+(?:[0-9]\s?){9,14}[0-9]$/, "Enter a Valid Phone"),
  query: Yup.string().trim().required("Enquiry is Required"),
});

//Schedule a Demo Schema

export const SCHEDULEFORMSCHEMA = Yup.object({
  clientName: Yup.string().required("Name is required"),
  email: Yup.string().required("Email Address is required"),
  clientRole: Yup.string().required("Role is required"),
  phoneNo: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number")
    .required("Phone is required"),
});

//Apply Now Schema
export const APPLYNOWSCHEMA = Yup.object({
  firstName: Yup.string()
    .trim()
    .required(" First Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  lastName: Yup.string()
    .trim()
    .required("Last Name is required")
    .max(MAXIMUMNAMELENGTH, "Must be 20 Characters or less")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  email: Yup.string().required("Required Field"),
  phoneNumber: Yup.string()
    .typeError("Must be a number")
    .min(MAXIMUMPHONENUMBERLENGTH, "Invalid Phone Number")
    .matches(UKNUMBERVALIDATION, "Invalid Phone Number")
    .required("Phone is required"),
  resume: Yup.mixed().required("Required Field"),
  postCode: Yup.string().trim().required(" Post Code is required"),
  buildingName: Yup.string()
    .trim()
    .required("Building Name is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  streetName: Yup.string()
    .trim()
    .required("Street Name is required")
    .matches(
      ALPHANUMERICBUTNOTNUMERICALONEVALIDATION,
      "only alphabets are allowed"
    ),
  city: Yup.string()
    .trim()
    .required("Town/City is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  country: Yup.string()
    .trim()
    .required("Country is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
  county: Yup.string()
    .trim()
    .required("County is required")
    .matches(ALLCHARACTERSONLYVALIDATION, "only alphabets are allowed"),
});

//payment scheduler calculator form schema

export const PAYMENTSCHEDULERCALCULATOR = Yup.object({
  loanTerm: Yup.string().required("Required Field"),
  paymentMethod: Yup.string().required("Required Field"),
  interestRate: Yup.number().required("Required Field"),
  paymentSchedule: Yup.string().required("Required Field"),
  fees: Yup.number().required("Required Field"),
});

export const EVIDENCERESEARCGSCHEEMA = Yup.object({
  title: Yup.string().required("Required Field"),
  firstName: Yup.string().required("Required Field"),
  lastName: Yup.string().required("Required Field"),
  // resultToDisplay: Yup.string().required("Required Field"),
  // resultGridTitle: Yup.string().required("Required Field"),
  // resultGridDescription: Yup.string().required("Required Field"),
  // reasonForRecommendation: Yup.string().required("Required Field"),
  // additionalInformation: Yup.string().required("Required Field"),
});
