import { axiosInstance, axiosRefresh } from "../lib/axios";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import { getDataFromLocalStorage } from "../utils/views-utils";
import { refreshTokenThunk } from "../store/auth/auth-thunk-actions";
import { useNavigate } from "react-router-dom";
import { LOCALSTORAGEAUTHUSERINFO } from "../constants/local-storage-constant";

export const useAxiosInterceptors = () => {
  const { auth } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authToken = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config: any) => {
        if (!!authToken?.token) {
          config.headers["Authorization"] = `Bearer ${authToken?.token}`;
        }
        return config;
      },
      (error: any) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          await dispatch(refreshTokenThunk({ navigate, axiosRefresh }));
          prevRequest.headers["Authorization"] = `Bearer ${authToken?.token}`;
          return axiosInstance(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [authToken?.token, axiosInstance]);

  return axiosInstance;
};
