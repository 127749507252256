import { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../lib/axios";

import {
  hideBreadcrumb,
  showBreadcrumb,
} from "../../../store/breadcrumb/breadcrumb-slice";
import { breadcrumbCaseId } from "./breadcrumbs";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { BuyerOptionHeader } from "../common/buyer-option-header";
import { AppTextEditior } from "../../../components/app-text-editor/app-text-editor";
import { tempDataForQuill } from "../sign-contract/text-editior/temp-data";
import { AppButton } from "../../../components/app-button/app-button";
import {
  getContentOfSignleContractThunk,
  updateContractThunk,
} from "../../../store/contracts/contracts-thunk-actions";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import AppLoader from "../../../components/app-loader/app-loader";
import { Reactpdf } from "../../../components/react-pdf/react-pdf";
import { FileUploader } from "./file-uploader";
import { useAppAlert } from "../../../components/app-alert/use-app-alert";

export const UpdateContract = () => {
  const [file, setFile] = useState<File | null>(null);
  const [editorStates, setEditorStates] = useState({
    value: "",
    editOrUpdate: true,
  });

  const { status, singleContractInfoHolder } = useAppSelector(
    (state) => state.contracts
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openAlert } = useAppAlert();
  const { caseId, contractid } = useParams();
  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbCaseId(caseId)));
    dispatch(
      getContentOfSignleContractThunk({
        axiosInstance,
        caseId,
        contractId: contractid,
      })
    );
    return () => {
      dispatch(hideBreadcrumb());
    };
  }, []);

  useEffect(() => {
    if (singleContractInfoHolder?.contractContent) {
      setEditorStates((pre) => ({
        ...pre,
        value: singleContractInfoHolder?.contractContent,
      }));
    }
  }, [status]);

  const editHan = () => {
    setEditorStates((pre) => ({
      ...pre,
      editOrUpdate: !editorStates.editOrUpdate,
    }));
  };
  const updateBtnClickHan = () => {
    setEditorStates((pre) => ({
      ...pre,
      editOrUpdate: !editorStates.editOrUpdate,
    }));
  };
  const updateHand = (updatedVal: string) => {
    setEditorStates((pre) => ({ ...pre, value: updatedVal }));
  };
  // const signatureHandler = (type: string, content: string) => {
  //     console.log({ type, content });
  // };

  const fileHand = (file: File) => {
    setFile(file);
  };
  const resendBtnHan = () => {
    singleContractInfoHolder?.contractType === "seller_contracts" &&
      updateSellerContract();
    singleContractInfoHolder?.contractType === "buyer_contracts" &&
      updateBuyerContract();
  };

  const updateSellerContract = () => {
    if (!file) return;
    const formData = new FormData();
    formData.append("contractContent", "");
    formData.append("contractFile", file ? file : "");
    dispatch(
      updateContractThunk({
        axiosInstance,
        formData,
        caseId,
        contractid,
        navigate,
        openAlert,
      })
    );
  };

  const updateBuyerContract = () => {
    const formData = new FormData();
    formData.append("contractContent", editorStates?.value);
    formData.append("contractFile", "");
    dispatch(
      updateContractThunk({
        axiosInstance,
        formData,
        caseId,
        contractid,
        navigate,
        openAlert,
      })
    );
  };
  return (
    <>
      {status === REQUESTSTATUS.PENDING ? (
        <AppLoader />
      ) : (
        <div className="bg-card-white">
          <div className="padding-top-2 padding-left-3 padding-right-3 padding-bottom-2">
            <div className="primary-text-color secondary-heading font-weight-600">
              Update Contract
            </div>

            {/* Radio Group  */}
            <div>
              <div className="primary-text-color font-weight-500 ">
                Contract Type
              </div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={singleContractInfoHolder?.contractType}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="seller_contracts"
                    control={<Radio />}
                    label="Seller Contracts"
                  />
                  <FormControlLabel
                    value="buyer_contracts"
                    control={<Radio />}
                    label="Buyer Contracts"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Grid container>
              <Grid item sm={12}>
                <BuyerOptionHeader />
              </Grid>
              {/* Text Editor  */}
              <Grid item xs={12}>
                {singleContractInfoHolder?.contractType ===
                  "buyer_contracts" && (
                  <AppTextEditior
                    states={editorStates}
                    editHan={editHan}
                    updateBtnClickHan={updateBtnClickHan}
                    updateHand={updateHand}
                  />
                )}
                {singleContractInfoHolder?.contractType ===
                  "seller_contracts" &&
                  singleContractInfoHolder?.contractFile?.url && (
                    <>
                      <div
                        style={{
                          height: "500px",
                          overflow: "auto",
                          marginBottom: "0.5em",
                        }}
                      >
                        {/* <Reactpdf file="https://pdf-lib.js.org/assets/with_update_sections.pdf" /> */}
                        <iframe
                          src={`${process.env.REACT_APP_BASE_IMAGE_URL}${singleContractInfoHolder?.contractFile?.url}`}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <FileUploader fileHand={fileHand} />
                    </>
                  )}
              </Grid>
              {/* eSignature Of Solicitor  */}
              <Grid
                item
                sm={12}
                container
                sx={{
                  borderBottom: "1px solid black",
                  paddingBottom: "2em",
                  marginBottom: "1em",
                }}
              >
                <Grid item sm={4}>
                  <div
                    className="secondary-text-color secondary-heading font-weight-600"
                    style={{ color: "#366EC2" }}
                  >
                    eSignature of Solicitor
                  </div>
                  <div style={{ maxWidth: "200px" }}>
                    {singleContractInfoHolder?.solicitorSignatureFile?.url && (
                      <img
                        src={`${process.env.REACT_APP_BASE_IMAGE_URL}${singleContractInfoHolder?.solicitorSignatureFile?.url}`}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              {/* suggestion and client eSignature  */}
              <Grid item md={12} container>
                {/* suugetions  */}
                <Grid item md={6} xs={12}>
                  <div className="primary-text-color font-weight-500 margin-bottom-1">
                    Client Suggestion
                  </div>
                  {/* client suggetion input field  */}
                  <Hidden smDown>
                    <textarea
                      rows={6}
                      // defaultValue={updateInfoHolder.clientSuggestion}
                      defaultValue={
                        singleContractInfoHolder?.clientSuggestion || ""
                      }
                      readOnly={true}
                      style={{
                        border: "1px solid #525252",
                        borderRadius: "5px",
                        outline: "none",
                        width: "50%",
                        resize: "none",
                        backgroundColor: "#F0F0F0",
                        color: "transparent",
                        textShadow: "0px 0px 0px black",
                      }}
                    />
                  </Hidden>
                  <Hidden smUp>
                    <textarea
                      rows={6}
                      // defaultValue={updateInfoHolder.clientSuggestion}
                      defaultValue={
                        singleContractInfoHolder?.clientSuggestion || ""
                      }
                      readOnly={true}
                      style={{
                        border: "1px solid #525252",
                        borderRadius: "5px",
                        outline: "none",
                        width: "100%",
                        resize: "none",
                        backgroundColor: "#F0F0F0",
                        color: "transparent",
                        textShadow: "0px 0px 0px black",
                      }}
                    />
                  </Hidden>
                </Grid>
                {/* client signature  */}
              </Grid>
              <Grid
                item
                sm={12}
                container
                justifyContent="flex-end"
                sx={{ marginTop: "0.5em" }}
              >
                <AppButton
                  btnText="Resend"
                  className="btn-blue cursor-pointer tertiary-title add-item-btn padding-left-1 padding-right-1 padding-top-1 padding-bottom-1"
                  btnFunction={resendBtnHan}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
