import { CircularProgress, Grid, LinearProgress, Rating } from "@mui/material";
import thumbIcon from "../../../assets/images/reviews/thumb-rating-icon.png";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import { useAppSelector } from "../../../store/store";

const ratingCardData = {
  rating: 4,
  ratingProgressBar: 90,
  overallRating: "+16",
};

export const RatingCardSolicitorDashboard = () => {
  const { solicitorStatus, solicitorData } =
    useAppSelector((state: any) => state.solicitorDashboardSlice)
  return (
    <Grid
      container
      className="bg-white-color border-radius-16 font-family-monterrat width-100 "
      padding={2}
    >

      {solicitorStatus === REQUESTSTATUS.PENDING ?
        <div className="flex align-center justify-center width-100 padding-2">
          <CircularProgress size={35} />
          <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
        </div>
        :
        solicitorStatus === REQUESTSTATUS.SUCCEEDED ? (
          <>
            <div className=" flex justify-space-between  width-100 margin-bottom-1">
              <div className="flex flex-column justify-space-evenly ">
                <span className="font-weight-600 secondary-title">Rating</span>
                <span className=" tertiary-title ">
                  {solicitorData?.rating?.totalReviews} Reviews
                </span>
              </div>
              <div className="flex flex-column">
                <span className="font-weight-600 secondary-heading ">
                  {solicitorData?.rating?.avgRating?.toFixed(1)}{" "}
                </span>
                <Rating
                  name="half-rating-read"
                  value={solicitorData?.rating?.avgRating}
                  precision={0.5}
                  readOnly
                />
              </div>
            </div>
            <div className="width-100  position-relative">
              <LinearProgress
                variant="determinate"
                value={solicitorData?.rating?.avgRating * 20}
                sx={{ bgcolor: "#DDDDDD", padding: 0.6, borderRadius: "41px" }}
                color={"success"}
              />
              <img
                className="position-absolute"
                style={{
                  bottom: "-1px",
                  transitionDuration: ".7s",
                  left: `${solicitorData?.rating?.avgRating * 20 - 1}%`,
                }}
                src={thumbIcon}
                alt=""
              />
            </div>
          </>
        )
          :
          (solicitorStatus === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
      }
    </Grid>
  );
};