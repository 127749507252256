import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const onboardingUsersThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const onboardingUsersThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.meta = payload.meta;
    state.userDetails = payload.userDetails;
    state.usersOnBoardingRequestData = payload.usersOnBoardingRequestData;
  });
};

export const onboardingUsersThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const changeOnboardingUsersStatusThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.changeStatusStatus = REQUESTSTATUS.PENDING;
  });
};

export const changeOnboardingUsersStatusThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.changeStatusStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const changeOnboardingUsersStatusThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.changeStatusStatus = REQUESTSTATUS.FAILED;
  });
};

export const addOnBoardingThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const addOnBoardingThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addOnBoardingThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};
