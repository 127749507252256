import {
  EMPTYMESSAGEFROMSERVER,
  INVALIDTOKENMESSAGEFROMSERVER,
  NULLMESSAGEFROMSERVER,
  UNAUTHORIZEDSERVERMESSAGE,
  UNDEFINEDPROPERTYERRORMESSAGE,
  TOKENEXPIREDERRORMESSAGE,
  ACCESSTOKENREVOKEDMESSAGE,
} from "../constants/hooks-constant";

export const SERVERRESPONSEMESSAGESTONEGLECT = [
  INVALIDTOKENMESSAGEFROMSERVER,
  EMPTYMESSAGEFROMSERVER,
  NULLMESSAGEFROMSERVER,
  UNAUTHORIZEDSERVERMESSAGE,
  UNDEFINEDPROPERTYERRORMESSAGE,
  TOKENEXPIREDERRORMESSAGE,
  ACCESSTOKENREVOKEDMESSAGE,
];

export const showApiAlert = (
  apiMessage: any,
  alert: any,
  alertType = "alert-error-message"
) => {
  if (SERVERRESPONSEMESSAGESTONEGLECT.includes(apiMessage)) return;
  alert(apiMessage, alertType);
};
