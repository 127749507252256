import {
  CONTRACTPATH,
  CONTRACTSIGNSPATHPARAMS,
  SLASHPATH,
} from "../../../constants/routes-constant";

const BreadcrumbsForContractDetailsClient = [
  {
    id: 1,
    linkName: " Contracts ",
    className: "global-breadcrumb-previous-link",
    linkPath: CONTRACTPATH,
  },
  {
    id: 2,
    linkName: "\\",
    className: "global-breadcrumb-previous-link margin-right-0 margin-left-0",
  },
  {
    id: 3,
    linkName: " Pending Cases ",
    className: "global-breadcrumb-active",
  },
];

const BreadcrumbsForContractDetailsSolicitor = [
  {
    id: 1,
    linkName: " Contracts ",
    className: "global-breadcrumb-previous-link",
    linkPath: CONTRACTPATH,
  },
  {
    id: 2,
    linkName: "\\",
    className: "global-breadcrumb-previous-link margin-right-0 margin-left-0",
  },
  {
    id: 3,
    linkName: " Assign Contracts ",
    className: "global-breadcrumb-active",
  },
];

export const breadcrumbdataForContractDetailsClient = {
  otherBreadcrumbs: BreadcrumbsForContractDetailsClient,
};

export const breadcrumbdataForContractDetailsSolicitor = {
  otherBreadcrumbs: BreadcrumbsForContractDetailsSolicitor,
};
