import { createAsyncThunk } from "@reduxjs/toolkit";
import { CASESAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { SLASHPATH } from "../../constants/routes-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../lib/axios";

// Case Main table Get
export const getCasesThunk = createAsyncThunk(
  "Cases/get-Cases",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(CASESAPI, axiosInstance, params);
      const responseData = {
        message: response?.data?.message,
        CasesLists: response?.data?.data?.cases,
        meta: response?.data?.data?.meta,
      };

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      return rejectWithValue(errorData);
    }
  }
);

// Case Status Patch
export const patchCasesThunk = createAsyncThunk(
  "cases/patch-cases",
  async (
    { axiosInstance, dispatch, openAlert, caseId, formData }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${CASESAPI}${SLASHPATH}${caseId}${SLASHPATH}update-status`,
        axiosInstance,
        formData
      );
      openAlert(response?.data?.message, "success");
      dispatch(getCasesThunk({ axiosInstance }));
      return response?.data?.message;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData?.error, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

// Upload Documents Get
export const getUploadDocumnetsThunk = createAsyncThunk(
  "get-documents",
  async ({ axiosInstance, params, caseId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${CASESAPI}${SLASHPATH}${caseId}${SLASHPATH}documents`,
        axiosInstance,
        params
      );
      const responseData = {
        documents: response?.data?.data?.documents,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Upload Documents Post
export const postUploadDocumnetsThunk = createAsyncThunk(
  "post-documents",
  async (
    {
      axiosInstance,
      caseId,
      formData,
      openAlert,
      dispatch,
      setUploadDocModal,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        `${CASESAPI}${SLASHPATH}${caseId}${SLASHPATH}documents`,
        axiosInstance,
        formData
      );
      openAlert("Document Uploaded Successfully!", "alert-success-message");
      setUploadDocModal(false);

      dispatch(getUploadDocumnetsThunk({ axiosInstance, caseId }));
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

// Uplaod Documnets Patch
export const patchUploadDocumnetsThunk = createAsyncThunk(
  "patch-documents",
  async (
    { axiosInstance, caseId, formData, documentId, openAlert, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${CASESAPI}${SLASHPATH}${caseId}${SLASHPATH}documents${SLASHPATH}${documentId}`,
        axiosInstance,
        formData
      );
      openAlert("Document Updated Successfully!", "alert-success-message");
      dispatch(getUploadDocumnetsThunk({ axiosInstance, caseId }));
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

// Uplaod Documnets Delete
export const deleteUploadDocumnetsThunk = createAsyncThunk(
  "delete-documents",
  async (
    { axiosInstance, caseId, documentId, openAlert, dispatch }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(
        `${CASESAPI}${SLASHPATH}${caseId}${SLASHPATH}documents${SLASHPATH}${documentId}`,
        axiosInstance
      );
      dispatch(getUploadDocumnetsThunk({ axiosInstance, caseId }));
      openAlert("Document has been deleted!", "alert-error-message");
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
