import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ONBOARDINGAPI,
  ONBOARDINGCHANGESTATUSAPI,
} from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { getDataAPI, postDataAPI, putDataAPI } from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";
import { HOMEPATH } from "../../constants/routes-constant";

export const onboardingUsersThunk = createAsyncThunk(
  "onboarding/get-onbaording-users",
  async ({ axiosInstance, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(ONBOARDINGAPI, axiosInstance, params);
      const responseData = {
        message: response?.data?.message,
        meta: response?.data?.meta,
        usersOnBoardingRequestData: response?.data?.data,
      };
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      return rejectWithValue(errorData);
    }
  }
);

export const changeOnboardingUsersStatusThunk = createAsyncThunk(
  "onboarding/change-onbaording-users-status",
  async (
    { axiosInstance, openAlert, body, dispatch, setShowUserDetails }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        ONBOARDINGCHANGESTATUSAPI,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      setShowUserDetails?.(false);
      const alertClass = responseData.message.includes(
        "You have rejected user."
      )
        ? "alert-error-message"
        : "alert-success-message";
      showApiAlert(responseData.message, openAlert, alertClass);
      dispatch(onboardingUsersThunk({ axiosInstance, openAlert }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData?.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

//onboarding Posting
export const postOnboardingResponseThunk = createAsyncThunk(
  "onboarding-post",
  async (
    { axiosInstance, formData, openAlert, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        ONBOARDINGAPI,
        axiosInstance,
        formData
      );
      openAlert(response.data.message, "alert-success-message");
      setTimeout(() => navigate(HOMEPATH), 3005);
      return response;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert(errorData.error, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);
