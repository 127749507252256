import { CircularProgress, Grid } from "@mui/material";
import "./style.scss";
import EChartsReact from "echarts-for-react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { useAppSelector } from "../../../../store/store";

export const DonutChartForAdmin = (props: any) => {
  const { cases } = props
  const { status } = useAppSelector((state: any) => state?.adminDashboardSlice)

  const eChartOption = {
    fontWeight: 600,
    fontSize: 40,
    title: {
      text: cases?.total || 0,
      subtext: "Total",
      fontWeight: 600,
      fontSize: 40,
      left: "center",
      top: 60,
    },
    tooltip: {
      trigger: "item",
    },
    color: ["#FFBF00", "#FA7901", "#366EC2", "#FF3F3F", "#48995D"],

    legend: {
      showInLegend: true,
      height: "250px",
      data: [
        `Completed ${cases?.completed || '0'}`,
        `In Progress ${cases?.inProgress || '0'}`,
        `On Hold ${cases?.onHold || '0'}`,
        `Post Completed ${cases?.postCompleted || '0'}`,
        `Fell Through ${cases?.fellThrough || '0'}`,
      ],
      icon: "circle",
      orient: "horizontal",
      bottom: "bottom",
      left: "center",
    },

    series: [
      {
        type: "pie",
        radius: ["65%", "35%"],
        center: ["50%", "31%"],
        data: [
          {
            value: cases?.inProgress || 0,
            name: `In Progress ${cases?.inProgress || '0'}`
          },

          {
            value: cases?.onHold || 0,
            name: `On Hold ${cases?.onHold || '0'}`
          },

          {
            value: cases?.postCompleted || 0,
            name: `Post Completed ${cases?.postCompleted || '0'}`
          },

          {
            value: cases?.fellThrough || 0,
            name: `Fell Through ${cases?.fellThrough || '0'}`
          },

          {
            value: cases?.completed || 0,
            name: `Completed ${cases?.completed || '0'}`
          },
        ],

        itemStyle: {
          normal: {
            labelLine: {
              show: false,
            },
            borderWidth: 6,
            borderColor: "#fff",
            borderRadius: 8,
          },
        },
        label: {
          show: false,
        },
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 ">
      <Grid padding={2}>
        <div className="flex justify-space-between margin-bottom-1">
          <span className="font-weight-600 secondary-title"> Cases </span>
        </div>
        {status === REQUESTSTATUS.PENDING &&
          <div className="flex align-center justify-center width-100 padding-2">
            <CircularProgress size={35} />
            <span className="primary-color font-weight-600 center-text width-100  secondary-title  padding-left-0">Loading... </span>
          </div>}
        {status === REQUESTSTATUS.SUCCEEDED ?
          <EChartsReact option={eChartOption} className="donut-chart-div" />
          :
          (status === REQUESTSTATUS.FAILED &&
            <div className="flex align-center justify-center width-100 padding-2">
              <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                Server Not Responding
              </span>
            </div>
          )
        }
      </Grid>
    </div>
  );
};
