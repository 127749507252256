import { Grid } from "@mui/material";
import { SignaturePad } from "../../../../components/signature-pad/signature-pad";
import { SIGNATUREPADSINTERFACE } from "./SIGNATUREPADSINTERFACE";

export const Signpads: React.FC<SIGNATUREPADSINTERFACE> = ({
    signatureHandler1,
    signatureHandler2,
    applicationType,
    signFileHandler1,
    signFileHandler2,
}) => {
    return (
        <>
            {/* <div className="primary-text-color font-weight-500 ">
                eSignature of Client
            </div> */}
            {/* for single signature  */}
            {applicationType === "Single" && (
                <Grid item container sm={6} xs={12}>
                    <Grid item sm={12}>
                        <SignaturePad signatureContentHan={signatureHandler1} signFileHandler={signFileHandler1} />
                    </Grid>
                </Grid>
            )}

            {/* for double signature  */}
            {applicationType === "Joint" && signatureHandler2 && (
                <>
                    <Grid item container sm={6} xs={12}>
                        <Grid item sm={12}>
                            <SignaturePad signatureContentHan={signatureHandler1} signFileHandler={signFileHandler1} />
                        </Grid>
                    </Grid>
                    <Grid item container sm={6} xs={12}>
                        <Grid item sm={12}>
                            <SignaturePad signatureContentHan={signatureHandler2} signFileHandler={signFileHandler2} />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
