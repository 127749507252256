import { Tooltip } from "@mui/material";

import { tooltipPopper, tooltipArrow } from "./tooltip-info-mui-style";

export const TooltipInfo = (props: any) => {
  const { showTooltipInfo, tooltipInfoTitle, children } = props;

  return (
    <Tooltip
      title={!!showTooltipInfo && tooltipInfoTitle}
      arrow={true}
      placement={"bottom-start"}
      open={!!showTooltipInfo}
      disableHoverListener={true}
      componentsProps={{
        tooltip: tooltipPopper,
        arrow: tooltipArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};
