import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const emptySingleDelegateReducer = (state: any) => {
  state.singleDelegate = {};
};

export const getDelegatesThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.delegatesLists = payload.delegate;
    state.meta = payload.meta;
  });
};

export const getDelegatesThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getSingleDelegatesThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getSingleDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.singleDelegate = payload.singleDelegate;
  });
};

export const getSingleDelegatesThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const addDelegatesThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const addDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addDelegatesThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const editDelegatesThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const editDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const editDelegatesThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const deleteDelegatesThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const deleteDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const deleteDelegatesThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.FAILED;
  });
};
/////////delegates
export const getClientDelegatesThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getClientDelegatesThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.clientdelegatesLists = payload.delegatesLists;
    state.delegatesinfo=payload.delegatesinfo;
    state.meta = payload.meta;
  });
};

export const getClientDelegatesThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};
