import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addApplyThunkPendingReducer,
  addApplyThunkFulfilledReducer,
  addApplyThunkRejectedReducer,
} from "./resumes-reducers";
import { postApplyNowResponseThunk } from "./resumes-thunk-actions";

const applyNowInitialState: any = {
  applyNowStatus: REQUESTSTATUS.IDLE,
  resumesData: [],
};

export const applyNowSlice = createSlice({
  name: "applyNow",
  initialState: applyNowInitialState,
  reducers: {},
  extraReducers(builder) {
    addApplyThunkPendingReducer(builder, postApplyNowResponseThunk);
    addApplyThunkFulfilledReducer(builder, postApplyNowResponseThunk);
    addApplyThunkRejectedReducer(builder, postApplyNowResponseThunk);
  },
});

export const {} = applyNowSlice.actions;

export default applyNowSlice.reducer;
