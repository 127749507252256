import { createAsyncThunk } from "@reduxjs/toolkit";
import { CLIENTSAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  getDataAPI,
} from "../../lib/axios";

export const getClientThunk = createAsyncThunk(
  "client/get-client",
  async (
    { axiosInstance, params }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(CLIENTSAPI, axiosInstance, params);
      const responseData = {
        message: response?.data?.message,
        list: response?.data?.data?.clients,
        meta: response?.data?.data?.meta,
      };

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
