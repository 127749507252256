import { lazy } from "react";

import { AuthLayout } from "../layout/auth-layout/auth-layout";

import { PrivateRoutes } from "./private-routes";
import { AuthProtect } from "./auth-protect";

import { USERROLES } from "../constants/roles-constant";

import { Navigate } from "react-router-dom";

import {
  ACCOUNTSETTINGSPATH,
  ADDREVIEWSPATH,
  CASEDETAILSPATH,
  CASESLISTSPATH,
  CASESPATH,
  CHANGEEMAILPATH,
  CHANGEPASSWORDPATH,
  CONTRACTLISTSPATH,
  CONTRACTPATH,
  DAYPLANNERPATH,
  DELEGATESLISTSPATH,
  DELEGATESPATH,
  DIPADDPATH,
  DIPAPPLICATIONDETAILSPATH,
  DIPHISTORYPATH,
  DIPPATH,
  IDVERIFICATIONPATH,
  EMPTYPATH,
  FINDASOLICITORPATH,
  FORGOTPASSWORDPATH,
  HELPPATH,
  DASHBOARDPATH,
  PROFILEMANAGEMENTPATH,
  PROFILEPATH,
  QUOTEMANGEMENTPATH,
  QUOTEPATH,
  RESETPASSWORDPATH,
  REVIEWSLISTSPATH,
  REVIEWSOVERALLRATINGSPATH,
  REVIEWSPATH,
  SETTINGSPATH,
  SIGNINPATH,
  SIGNUPPATH,
  SOLICITORDETAILSPATH,
  SOLICITORPATH,
  TICKETSLISTSPATH,
  TICKETSPATH,
  USERMANAGEMENTPATH,
  USERONBOARDINGREQUESTSPATH,
  USERSPATH,
  USERSREQUESTPATH,
  DOCUMENTSPATH,
  DOCUMENTSLISTSPATH,
  HOMEPATH,
  PARTNERWITHUSPATH,
  PARTNERWITHUSASSOLICITORPATH,
  PARTNERWITHUSASMORTGAGEBROKERPATH,
  PARTNERWITHUSASESTATEAGENTPATH,
  EDITPROFILEPATH,
  SENTEMAILPATH,
  SLASHPATH,
  AUTHENTICATIONPATH,
  CONTRACTDETAILSPATH,
  CONTRACTSIGNSPATH,
  ABOUTUSPATH,
  CONTRACTASSIGNPATH,
  CONTRACTCASEASSIGNPATH,
  CONTRACTADDSOLICITIORPATH,
  QUOTEMANGEMENTFORM,
  BLOGLISTSPATH,
  SINGLEBLOGPARAM,
  SINGLEBLOGPATH,
  SALESSITEHELPFAQPATH,
  TERMSOFUSEPATH,
  COOKIESPOLICYPATH,
  PRIVACYPOLICYPATH,
  CONTACTUSPATH,
  CAREERSPATH,
  APPLYNOWFORMPATH,
  ENQUIRYFORMPATH,
  SCHEDULEADEMOPATH,
  CHANGEEMAILVERIFICATIONPATH,
  QUOTEMANGEMENTFORMID,
  FIRSTTIMEBUYERPATH,
  REMORTAGEPATH,
  BUYERTOLETPATH,
  RENTALVALUEPATH,
  DIPAPPLICATIONDETAILSPARAM,
  CLIENTSPATH,
  CONTRACTUPDATEPATH,
  DIPADDPARAMS,
  VIEWQUOTEPATH,
  CASEDOCUMENTSPATHID,
  TICKETSDETAILS,
} from "../constants/routes-constant";

import Dashboards from "../pages/dashboard/dashboards";
import { SalesRoute } from "./sales-route";
import { SalesSiteLayout } from "../layout/sales-site-layout/sales-site-layout";
import { MainLayout } from "../layout/main-layout/main-layout";
import Signcontract from "../pages/sign-contract";
import Assigncontract from "../pages/assign-contract";
import Addcontractsolicitior from "../pages/add-contract-solicitor";
import Updatecontractsolicitor from "../pages/update-contract-solicitor";
import { WebViewRoute } from "./webview-route";
import { PDFEDITOR } from "../components/pdf-editor/pdf-editor";

const Changepassword = lazy(() => import("../pages/change-password"));
const Signin = lazy(() => import("../pages/signin"));
const Signup = lazy(() => import("../pages/signup"));
const Resetpassword = lazy(() => import("../pages/reset-password"));
const Forgotpassword = lazy(() => import("../pages/forgot-password"));
const Emailverification = lazy(() => import("../pages/email-verification"));
const Changeemailverificationpage = lazy(
  () => import("../pages/change-email-verification")
);
const Sentemail = lazy(() => import("../pages/sent-email"));

//sales site route portion

const Homepage = lazy(() => import("../pages/sales-sites-pages/home"));
const Aboutus = lazy(() => import("../pages/sales-sites-pages/about-us"));
const Partnerwithus = lazy(
  () => import("../pages/sales-sites-pages/partner-with-us")
);
const Partnerwithusassolicitor = lazy(
  () => import("../pages/sales-sites-pages/partner-with-us-as-solicitor")
);
const Partnerwithusasmortgagebroker = lazy(
  () => import("../pages/sales-sites-pages/partner-with-us-as-mortgage-broker")
);
const Partnerwithusasestateagent = lazy(
  () => import("../pages/sales-sites-pages/partner-with-us-as-estate-agent")
);
const Aboutusindexpage = lazy(
  () => import("../pages/sales-sites-pages/about-us-index")
);
const Bloglistspage = lazy(
  () => import("../pages/sales-sites-pages/blog-lists")
);
const Singleblogpage = lazy(
  () => import("../pages/sales-sites-pages/single-blog")
);
const Helpfaqpage = lazy(() => import("../pages/sales-sites-pages/help-faq"));
const Termsofusepage = lazy(
  () => import("../pages/sales-sites-pages/terms-of-use")
);
const Privacypolicypage = lazy(
  () => import("../pages/sales-sites-pages/privacy-policy")
);
const Cookiespolicypage = lazy(
  () => import("../pages/sales-sites-pages/cookies-policy")
);
const Contactusindexpage = lazy(
  () => import("../pages/sales-sites-pages/contact-us-index")
);
const Enquiryformpage = lazy(
  () => import("../pages/sales-sites-pages/enquiry-form")
);
const Careerpage = lazy(() => import("../pages/sales-sites-pages/career"));
const Applyformpage = lazy(
  () => import("../pages/sales-sites-pages/apply-form")
);
const Scheduleademopage = lazy(
  () => import("../pages/sales-sites-pages/schedule-a-demo")
);
// const Aboutus = lazy(() => import("../pages/sales-sites-pages/about-us"));

const FirstTimeBuyer = lazy(
  () => import("../pages/sales-sites-pages/first-time")
);
const Remorteage = lazy(() => import("../pages/sales-sites-pages/remort-gage"));
const BUYERTOLET = lazy(() => import("../pages/sales-sites-pages/buy-to"));
const RentalValueForBuyToLet = lazy(
  () => import("../pages/sales-sites-pages/rental-value-for-buy")
);

// sales site route portion

const Dipindex = lazy(() => import("../pages/dip-index"));
const Addreview = lazy(() => import("../pages/add-review"));
const Casesindex = lazy(() => import("../pages/cases-index"));
const Caseslists = lazy(() => import("../pages/cases-lists"));
const Contractindex = lazy(() => import("../pages/contract-index"));
const Contractlists = lazy(() => import("../pages/contract-lists"));
const Contractdetails = lazy(() => import("../pages/contract-details"));
const Dayplanner = lazy(() => import("../pages/day-planner"));
const Delegatesindex = lazy(() => import("../pages/delegates-index"));
const Delegateslists = lazy(() => import("../pages/delegates-lists"));
const Dipapplicationdetails = lazy(
  () => import("../pages/dip-application-details")
);
const Adddip = lazy(() => import("../pages/add-dip"));
const Diphistory = lazy(() => import("../pages/dip-history"));
const Dipproductdetails = lazy(() => import("../pages/dip-product-details"));
const DipDetails = lazy(() => import("../pages/dip-details"));
const Reviewindex = lazy(() => import("../pages/review-index"));
const Reviewoverallratings = lazy(
  () => import("../pages/review-overall-ratings")
);
const Reviewlists = lazy(() => import("../pages/reviews-lists"));
const Solicitorindex = lazy(() => import("../pages/solicitor-index"));
const Findasolicitor = lazy(() => import("../pages/find-a-solicitor"));
const Solicitordetails = lazy(() => import("../pages/solicitor-details"));
const Personalprofile = lazy(() => import("../pages/personal-profile"));
const Upsertpersonalprofile = lazy(
  () => import("../pages/upsert-personal-profile")
);
const Profilemanagementindex = lazy(
  () => import("../pages/profile-management-index")
);
const QuotemanagementProfile = lazy(
  () => import("../pages/quote-management-profile")
);
const Changeemail = lazy(() => import("../pages/change-email"));
const Ticketsindex = lazy(() => import("../pages/tickets-index"));
const Ticketslists = lazy(() => import("../pages/tickets-lists"));

const Usersindex = lazy(() => import("../pages/users-index"));
const Usersmanagement = lazy(() => import("../pages/users-management"));
const Usersrequestindex = lazy(() => import("../pages/users-request-index"));
const Usersonboardingrequest = lazy(
  () => import("../pages/users-onboarding-request")
);

const Quoteindex = lazy(() => import("../pages/quote-index"));
const Quotemanagement = lazy(() => import("../pages/quote-management"));
const QuotemanagementForm = lazy(
  () => import("../pages/quote-management-form")
);

const Settingsindex = lazy(() => import("../pages/settings-index"));
const Accountsettings = lazy(() => import("../pages/account-settings"));

const Documentsindex = lazy(() => import("../pages/documents-index"));
const Documentslists = lazy(() => import("../pages/documents-lists"));
const CaseDocuments = lazy(() => import("../pages/case-document"));
const ClientList = lazy(() => import("../pages/client-list"));

export const routesData = [
  {
    id: 1,
    pathLink: DASHBOARDPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.ESTATEAGENT,
      USERROLES.MORTGAGEBROKER,
      USERROLES.ADMIN,
      USERROLES.SOLICITOR,
    ],
    componentName: <Dashboards />,
  },
  // {
  //   id: 2,
  //   pathLink: CHANGEPASSWORDPATH,
  //   guard: PrivateRoutes,
  //   allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT, USERROLES.ESTATEAGENT, USERROLES.ADMIN, USERROLES.SOLICITOR, USERROLES.MORTGAGEBROKER],
  //   componentName: <Changepassword />,
  // },
  {
    id: 3,
    pathLink: DIPPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.SOLICITOR,
      USERROLES.MORTGAGEBROKER,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
    ],
    componentName: <Dipindex />,
    child: [
      {
        id: 1,
        pathLink: DIPHISTORYPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT],
        componentName: <Diphistory />,
      },
      {
        id: 2,
        pathLink: DIPADDPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT, USERROLES.ESTATEAGENT],
        componentName: <Adddip />,
      },
      {
        id: 3,
        pathLink: DIPADDPARAMS,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ESTATEAGENT],
        componentName: <Adddip />,
      },
      {
        id: 4,
        pathLink: DIPAPPLICATIONDETAILSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT, USERROLES.ESTATEAGENT],
        componentName: <DipDetails />,
      },
      // {
      //   id: 4,
      //   pathLink: DIPAPPLICATIONDETAILSPARAM,
      //   guard: PrivateRoutes,
      //   allowedRoles: [USERROLES.CLIENT],
      //   componentName: <DipDetails />,
      // },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT],
        componentName: <Navigate to={DIPHISTORYPATH} />,
      },
    ],
  },
  {
    id: 4,
    pathLink: REVIEWSPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    componentName: <Reviewindex />,
    child: [
      {
        id: 1,
        pathLink: REVIEWSOVERALLRATINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.SOLICITOR,
        ],
        componentName: <Reviewoverallratings />,
      },

      {
        id: 2,
        pathLink: REVIEWSLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.SOLICITOR,
        ],
        componentName: <Reviewlists />,
      },
      {
        id: 3,
        pathLink: ADDREVIEWSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Addreview />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.SOLICITOR,
        ],
        componentName: <Navigate to={REVIEWSOVERALLRATINGSPATH} />,
      },
    ],
  },
  {
    id: 5,
    pathLink: HELPPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT],
    // componentName: <Helpfaq />,
  },
  {
    id: 6,
    pathLink: DAYPLANNERPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT, USERROLES.SOLICITOR],
    componentName: <Dayplanner />,
  },
  {
    id: 7,
    pathLink: CASESPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
    componentName: <Casesindex />,
    child: [
      {
        id: 1,
        pathLink: CASESLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
        componentName: <Caseslists />,
      },
      {
        id: 2,
        pathLink: CASEDETAILSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
        componentName: <DipDetails />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.SOLICITOR,
        ],
        componentName: <Navigate to={CASESLISTSPATH} />,
      },
    ],
  },
  {
    id: 8,
    pathLink: HELPPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT],
    // componentName: <Helpfaq />,
  },
  {
    id: 9,
    pathLink: CONTRACTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
    componentName: <Contractindex />,
    child: [
      {
        id: 1,
        pathLink: CONTRACTLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT, USERROLES.SOLICITOR],
        componentName: <Contractlists />,
      },
      {
        id: 2,
        pathLink: CONTRACTDETAILSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Contractdetails />,
      },
      {
        id: 3,
        pathLink: CONTRACTSIGNSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Signcontract />,
      },
      {
        id: 4,
        pathLink: CONTRACTASSIGNPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <Assigncontract />,
      },
      {
        id: 6,
        pathLink: CONTRACTADDSOLICITIORPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <Addcontractsolicitior />,
      },
      {
        id: 7,
        pathLink: CONTRACTUPDATEPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <Updatecontractsolicitor />,
      },
      {
        id: 8,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.SOLICITOR,
        ],
        componentName: <Navigate to={CONTRACTLISTSPATH} />,
      },
    ],
  },
  {
    id: 10,
    pathLink: DOCUMENTSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.SOLICITOR],
    componentName: <Documentsindex />,
    child: [
      {
        id: 1,
        pathLink: DOCUMENTSLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <Documentslists />,
      },
      {
        id: 1,
        pathLink: CASEDOCUMENTSPATHID,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <CaseDocuments />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <Navigate to={DOCUMENTSLISTSPATH} />,
      },
    ],
  },
  {
    id: 11,
    pathLink: DELEGATESPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.CLIENT,
      USERROLES.SALESAGENT,
      USERROLES.ESTATEAGENT,
      USERROLES.MORTGAGEBROKER,
      USERROLES.ADMIN,
      USERROLES.SOLICITOR,
    ],
    componentName: <Delegatesindex />,
    child: [
      {
        id: 1,
        pathLink: DELEGATESLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.CLIENT,
          USERROLES.SALESAGENT,
          USERROLES.ESTATEAGENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.ADMIN,
          USERROLES.SOLICITOR,
        ],
        componentName: <Delegateslists />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.CLIENT,
          USERROLES.SALESAGENT,
          USERROLES.ESTATEAGENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.ADMIN,
          USERROLES.SOLICITOR,
        ],
        componentName: <Navigate to={DELEGATESLISTSPATH} />,
      },
    ],
  },
  {
    id: 12,
    pathLink: TICKETSPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.MORTGAGEBROKER,
      USERROLES.ADMIN,
    ],
    componentName: <Ticketsindex />,
    child: [
      {
        id: 1,
        pathLink: TICKETSLISTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.ADMIN,
        ],
        componentName: <Ticketslists />,
      },
      {
        id: 2,
        pathLink: TICKETSDETAILS,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SALESAGENT, USERROLES.MORTGAGEBROKER],
        componentName: <DipDetails />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.MORTGAGEBROKER,
          USERROLES.ADMIN,
        ],
        componentName: <Navigate to={TICKETSLISTSPATH} />,
      },
    ],
  },
  {
    id: 13,
    pathLink: PROFILEMANAGEMENTPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    componentName: <Profilemanagementindex />,
    child: [
      {
        id: 1,
        pathLink: PROFILEPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.ESTATEAGENT,
          USERROLES.ADMIN,
          USERROLES.MORTGAGEBROKER,
          USERROLES.SOLICITOR,
        ],
        componentName: <Personalprofile />,
      },
      {
        id: 2,
        pathLink: EDITPROFILEPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.ESTATEAGENT,
          USERROLES.ADMIN,
          USERROLES.MORTGAGEBROKER,
        ],
        componentName: <Upsertpersonalprofile />,
      },
      {
        id: 3,
        pathLink: CHANGEPASSWORDPATH,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.ESTATEAGENT,
          USERROLES.ADMIN,
          USERROLES.SOLICITOR,
          USERROLES.MORTGAGEBROKER,
        ],
        componentName: <Changepassword />,
      },
      {
        id: 4,
        pathLink: CHANGEEMAILPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Changeemail />,
      },
      {
        id: 5,
        pathLink: VIEWQUOTEPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <QuotemanagementProfile />,
      },
      {
        id: 4,
        pathLink: SENTEMAILPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Sentemail />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [
          USERROLES.SALESAGENT,
          USERROLES.CLIENT,
          USERROLES.ESTATEAGENT,
          USERROLES.ADMIN,
          USERROLES.MORTGAGEBROKER,
          USERROLES.SOLICITOR,
        ],
        componentName: <Navigate to={PROFILEPATH} />,
      },
    ],
  },
  {
    id: 14,
    pathLink: SOLICITORPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.CLIENT],
    componentName: <Solicitorindex />,
    child: [
      {
        id: 1,
        pathLink: FINDASOLICITORPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <Findasolicitor />,
      },
      {
        id: 2,
        pathLink: SOLICITORDETAILSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.CLIENT],
        componentName: <DipDetails />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT],
        componentName: <Navigate to={FINDASOLICITORPATH} />,
      },
    ],
  },
  {
    id: 15,
    pathLink: USERSREQUESTPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ADMIN],
    componentName: <Usersrequestindex />,
    child: [
      {
        id: 1,
        pathLink: USERONBOARDINGREQUESTSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Usersonboardingrequest />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Navigate to={USERONBOARDINGREQUESTSPATH} />,
      },
    ],
  },
  {
    id: 16,
    pathLink: QUOTEPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ADMIN],
    componentName: <Quoteindex />,
    child: [
      {
        id: 1,
        pathLink: QUOTEMANGEMENTPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Quotemanagement />,
      },
      // {
      //   id: 2,
      //   pathLink: QUOTEMANGEMENTFORM,
      //   guard: PrivateRoutes,
      //   allowedRoles: [USERROLES.ADMIN],
      //   componentName: <QuotemanagementForm />,
      // },
      {
        id: 3,
        pathLink: QUOTEMANGEMENTFORMID,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <QuotemanagementForm />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Navigate to={QUOTEMANGEMENTPATH} />,
      },
    ],
  },
  {
    id: 17,
    pathLink: SETTINGSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ADMIN],
    componentName: <Settingsindex />,
    child: [
      {
        id: 1,
        pathLink: ACCOUNTSETTINGSPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Accountsettings />,
      },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Navigate to={ACCOUNTSETTINGSPATH} />,
      },
    ],
  },
  {
    id: 18,
    pathLink: USERSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ADMIN],
    componentName: <Usersindex />,
    child: [
      {
        id: 1,
        pathLink: USERMANAGEMENTPATH,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Usersmanagement />,
      },

      // {
      //   id: 3,
      //   pathLink: DIPAPPLICATIONDETAILSPATH,
      //   guard: PrivateRoutes,
      //   allowedRoles: [USERROLES.CLIENT],
      //   componentName: <Dipapplicationdetails />,
      // },
      {
        id: 10,
        index: true,
        guard: PrivateRoutes,
        allowedRoles: [USERROLES.ADMIN],
        componentName: <Navigate to={USERMANAGEMENTPATH} />,
      },
    ],
  },
  {
    id: 19,
    pathLink: CHANGEEMAILVERIFICATIONPATH,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    componentName: <Changeemailverificationpage />,
  },
  {
    id: 10000,
    index: true,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    componentName: <Navigate to={DASHBOARDPATH} />,
  },
  {
    id: 19,
    pathLink: CLIENTSPATH,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ESTATEAGENT],
    componentName: <ClientList />,
  },
  {
    id: 20,
    pathLink: `${CLIENTSPATH}${SLASHPATH}:clientId`,
    guard: PrivateRoutes,
    allowedRoles: [USERROLES.ESTATEAGENT],
    componentName: <Diphistory forClient={true} />,
  },
  {
    id: 21,
    pathLink: `${AUTHENTICATIONPATH}${SLASHPATH}${SENTEMAILPATH}`,
    guard: AuthProtect,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    componentName: <Sentemail />,
  },

  // in case of sales site
  // {
  //   id: 10001,
  //   pathLink: HOMEPATH,
  //   guard: PrivateRoutes,
  //   allowedRoles: [USERROLES.SALESAGENT, USERROLES.CLIENT, USERROLES.ESTATEAGENT,USERROLES.ADMIN],
  //   componentName: <Navigate to={DASHBOARDPATH} />
  // },
];

export const authRoutes = [
  {
    id: 1,
    pathLink: SIGNINPATH,
    guard: AuthProtect,
    componentName: <Signin />,
  },
  {
    id: 2,
    pathLink: SIGNUPPATH,
    guard: AuthProtect,
    componentName: <Signup />,
  },
  {
    id: 3,
    pathLink: RESETPASSWORDPATH,
    guard: AuthProtect,
    componentName: <Resetpassword />,
  },
  {
    id: 4,
    pathLink: FORGOTPASSWORDPATH,
    guard: AuthProtect,
    componentName: <Forgotpassword />,
  },
  {
    id: 5,
    pathLink: IDVERIFICATIONPATH,
    guard: AuthProtect,
    componentName: <Emailverification />,
  }
  // {
  //   id: 7,
  //   pathLink: CHANGEEMAILVERIFICATIONPATH,
  //   guard: AuthProtect,
  //   componentName: <Changeemailverificationpage />,
  // },
  // {
  //   id: 10000,
  //   index: true,
  //   guard: AuthProtect,
  //   componentName: <Navigate to={HOMEPATH} />
  // },
];

export const salesSiteRoutes = [
  {
    id: 1,
    pathLink: HOMEPATH,
    guard: SalesRoute,
    componentName: <Homepage />,
  },
  // {
  //   id: 1,
  //   pathLink: PARTNERWITHUSPATH,
  //   guard: SalesRoute,
  //   componentName: <Partnerwithus />,
  // },
  {
    id: 2,
    pathLink: PARTNERWITHUSASSOLICITORPATH,
    guard: SalesRoute,
    componentName: <Partnerwithusassolicitor />,
  },
  {
    id: 3,
    pathLink: PARTNERWITHUSASESTATEAGENTPATH,
    guard: SalesRoute,
    componentName: <Partnerwithusasestateagent />,
  },
  {
    id: 4,
    pathLink: PARTNERWITHUSASMORTGAGEBROKERPATH,
    guard: SalesRoute,
    componentName: <Partnerwithusasmortgagebroker />,
  },
  {
    id: 4,
    pathLink: ABOUTUSPATH,
    guard: SalesRoute,
    componentName: <Aboutusindexpage />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: SalesRoute,
        componentName: <Aboutus />,
      },
      {
        id: 2,
        pathLink: BLOGLISTSPATH,
        guard: SalesRoute,
        componentName: <Bloglistspage />,
      },
      {
        id: 3,
        pathLink: SINGLEBLOGPATH,
        guard: SalesRoute,
        componentName: <Singleblogpage />,
      },
      {
        id: 4,
        pathLink: SALESSITEHELPFAQPATH,
        guard: SalesRoute,
        componentName: <Helpfaqpage />,
      },
      {
        id: 5,
        pathLink: TERMSOFUSEPATH,
        guard: SalesRoute,
        componentName: <Termsofusepage />,
      },
      {
        id: 6,
        pathLink: COOKIESPOLICYPATH,
        guard: SalesRoute,
        componentName: <Cookiespolicypage />,
      },
      {
        id: 7,
        pathLink: PRIVACYPOLICYPATH,
        guard: SalesRoute,
        componentName: <Privacypolicypage />,
      },
      // {
      //   id: 10,
      //   index: true,
      //   guard: SalesRoute,
      //   componentName: <Navigate to={SLASHPATH} />
      // },
    ],
  },
  {
    id: 5,
    pathLink: FIRSTTIMEBUYERPATH,
    guard: SalesRoute,
    componentName: <FirstTimeBuyer />,
  },
  {
    id: 6,
    pathLink: REMORTAGEPATH,
    guard: SalesRoute,
    componentName: <Remorteage />,
  },
  {
    id: 7,
    pathLink: RENTALVALUEPATH,
    guard: SalesRoute,
    componentName: <RentalValueForBuyToLet />,
  },
  {
    id: 8,
    pathLink: BUYERTOLETPATH,
    guard: SalesRoute,
    componentName: <BUYERTOLET />,
  },
  {
    id: 18,
    pathLink: CONTACTUSPATH,
    guard: SalesRoute,
    componentName: <Contactusindexpage />,
    child: [
      // {
      //   id: 1,
      //   pathLink: EMPTYPATH,
      //   guard: SalesRoute,
      //   componentName: <>Contact Us</>,
      // },
      {
        id: 2,
        pathLink: CAREERSPATH,
        guard: SalesRoute,
        componentName: <Careerpage />,
      },
      {
        id: 3,
        pathLink: APPLYNOWFORMPATH,
        guard: SalesRoute,
        componentName: <Applyformpage />,
      },
      {
        id: 4,
        pathLink: ENQUIRYFORMPATH,
        guard: SalesRoute,
        componentName: <Enquiryformpage />,
      },
      {
        id: 5,
        pathLink: SCHEDULEADEMOPATH,
        guard: SalesRoute,
        componentName: <Scheduleademopage />,
      },
      {
        id: 10,
        index: true,
        guard: SalesRoute,
        componentName: <Navigate to={CAREERSPATH} />,
      },
    ],
  },

  // {
  //   id: 10000,
  //   index: true,
  //   guard: AuthProtect,
  //   componentName: <Navigate to={HOMEPATH} />
  // },
];

export const routingData = [
  {
    id: 1,
    guard: PrivateRoutes,
    allowedRoles: [
      USERROLES.SALESAGENT,
      USERROLES.CLIENT,
      USERROLES.ESTATEAGENT,
      USERROLES.ADMIN,
      USERROLES.MORTGAGEBROKER,
      USERROLES.SOLICITOR,
    ],
    layout: MainLayout,
    parentRoutes: routesData,
  },
  {
    id: 2,
    guard: AuthProtect,
    layout: AuthLayout,
    parentRoutes: authRoutes,
  },
  {
    id: 3,
    guard: SalesRoute,
    layout: SalesSiteLayout,
    parentRoutes: salesSiteRoutes,
  },
  {
    id: 4,
    parentRoutes: [
      {
        id: 1,
        pathLink: `${CONTRACTPATH}${SLASHPATH}${CONTRACTCASEASSIGNPATH}`,
        layout: SalesSiteLayout,
        guard: WebViewRoute,
        allowedRoles: [USERROLES.SOLICITOR],
        componentName: <PDFEDITOR />,
      }
    ],
    layout: SalesSiteLayout,
    guard: WebViewRoute,
  },
];
