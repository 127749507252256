import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADDSELLERCONTRACT,
  GETLISTOFCONTRACTS,
} from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  CONTRACTASSIGNPATHPARANS,
  CONTRACTDETAILSPARAMS,
  CONTRACTPATH,
  SLASHPATH,
} from "../../constants/routes-constant";
import {
  postDataAPI,
  getDataAPI,
  patchDataAPI,
  putDataAPI,
} from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getContractsThunk = createAsyncThunk(
  "contracts/get-contracts",
  async ({ axiosInstance, dynamicpart }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${GETLISTOFCONTRACTS}/${dynamicpart}`,
        axiosInstance
      );
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const addSellerContractThunk = createAsyncThunk(
  "contracts/add-seller-contract",
  async (
    { axiosInstance, formData, caseId, navigate, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        `${ADDSELLERCONTRACT}/${caseId}`,
        axiosInstance,
        formData
      );
      navigate(
        `${CONTRACTPATH}${SLASHPATH}${CONTRACTASSIGNPATHPARANS}${SLASHPATH}${caseId}`
      );
      showApiAlert(response?.data?.message, openAlert, "alert-success-message");
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const signOrChangeContractThunk = createAsyncThunk(
  "contracts/sign-or-change-contract",
  async (
    { axiosInstance, caseId, contractId, formData, navigate, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await putDataAPI(
        `${GETLISTOFCONTRACTS}/${caseId}/client/${contractId}`,
        axiosInstance,
        formData
      );
      navigate(
        `${CONTRACTPATH}${SLASHPATH}${CONTRACTDETAILSPARAMS}${SLASHPATH}${caseId}`
      );
      showApiAlert(response?.data?.message, openAlert, "alert-success-message");
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const getBuyerFormContentThunk = createAsyncThunk(
  "contracts/get-contract-content",
  async ({ axiosInstance, formType, caseId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${GETLISTOFCONTRACTS}/${caseId}/content/${formType}`,
        axiosInstance
      );
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const sendBuyerContractThunk = createAsyncThunk(
  "contracts/send-buyer-contract",
  async (
    { axiosInstance, formData, caseId, navigate, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        `${GETLISTOFCONTRACTS}/buyer/${caseId}`,
        axiosInstance,
        formData
      );
      navigate(
        `${CONTRACTPATH}${SLASHPATH}${CONTRACTASSIGNPATHPARANS}${SLASHPATH}${caseId}`
      );
      showApiAlert(response?.data?.message, openAlert, "alert-success-message");
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const getContentOfSignleContractThunk = createAsyncThunk(
  "contracts/get-single-contract-content",
  async ({ axiosInstance, caseId, contractId }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        `${GETLISTOFCONTRACTS}/${caseId}/${contractId}`,
        axiosInstance
      );
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const updateContractThunk = createAsyncThunk(
  "contracts/update-contract",
  async (
    { axiosInstance, formData, caseId, contractid, navigate, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${GETLISTOFCONTRACTS}/${contractid}/update-content`,
        axiosInstance,
        formData
      );
      navigate(
        `${CONTRACTPATH}${SLASHPATH}${CONTRACTASSIGNPATHPARANS}${SLASHPATH}${caseId}`
      );
      showApiAlert(response?.data?.message, openAlert, "alert-success-message");
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);

export const embedSignatureContractThunk = createAsyncThunk(
  "contracts/embed-signature-contract",
  async (
    { axiosInstance, formData, contractId, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${GETLISTOFCONTRACTS}/${contractId}/embedded-signature`,
        axiosInstance,
        formData
      );
      // navigate(
      //   `${CONTRACTPATH}${SLASHPATH}${CONTRACTASSIGNPATHPARANS}${SLASHPATH}${caseId}`
      // );
      showApiAlert(response?.data?.message, openAlert, "alert-success-message");
      return response.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      return rejectWithValue(errorData);
    }
  }
);
