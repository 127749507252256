import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DayPlannerCardTickets } from "../../../components/day-planner-card-tickets/day-planner-card-tickets";
import { NotificationAndRemindersCard } from "../../../components/notification-and-reminders-card/notification-and-reminders-card";
import {
  TICKETSLISTSPATH,
  TICKETSPATH,
} from "../../../constants/routes-constant";
import { RecentDetailsTablePiece } from "../../../features/tickets/tickets-lists/tickets-lists-mortgage-broker";
import { axiosInstance } from "../../../lib/axios";
import { getMortgageBrokerDasboardDataThunk } from "../../../store/dashboard/mortgage-broker-dashboard/mortgage-broker-dashboard-thunk";
import { useAppDispatch} from "../../../store/store";
import { getTicketsListThunk } from "../../../store/tickets/tickets-thunk-actions";

import { LineChartForMortgageBroker } from "./chart-for-mortgage-broker/line-chart-for-mortgage-broker";
import { PieChartForMortgageBroker } from "./chart-for-mortgage-broker/pie-chart-for-mortgage-broker";
import { MortgageBrokerDashboardCardData } from "./mortgage-broker-dashboard-data";

export const MortgageBrokerDashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMortgageBrokerDasboardDataThunk({ axiosInstance }));
    dispatch(
      getTicketsListThunk({ axiosInstance, params: { limit: 4, offset: 0 } })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const width = window.innerWidth;
  const navigate = useNavigate();

  return (
    <div>
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          item
          xl={7.5}
          lg={11.9}
          xs={12}
        >
          <MortgageBrokerDashboardCardData />

          <Grid container justifyContent={"space-between"} marginTop={2}>
            <Grid
              item
              xl={6.7}
              xs={12}
              className="bg-card-white rotate-line-chart"
              mb={{ xl: 0, xs: 2 }}
            >
              <LineChartForMortgageBroker />
            </Grid>

            <Grid
              item
              xl={5}
              xs={12}
              className="bg-card-white position-relative"
              py={1}
              px={2}
              mb={{ xl: 0, xs: 2 }}
            >
               <span
                className="position-absolute primary-color tertiary-title font-weight-600 "
                style={{
                  right: 45, top: 10, zIndex: 1,
                  borderBottom: "1px solid #366EC2",
                }}>
                Call
              </span>
              <span className="position-absolute"
                style={{
                  height: 12,  width: 12, zIndex: 1,
                  right: 22, top: 13, backgroundColor: '#fa7901 '
                }}></span>
              <DayPlannerCardTickets  dayPlannerHeight={"330px"} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="bg-card-white"
          justifyContent={"center"}
          xl={4.3}
          xs={12}
          padding={{ xl: 0, xs: 2 }}
        >
          <PieChartForMortgageBroker />
        </Grid>
      </Grid>

      <Grid container marginTop={2} justifyContent={"space-between"}>
        <Grid
          item
          xl={7.5}
          lg={7.3}
          xs={12}
          px={2}
          pb={1}
          mb={{ xl: 0, xs: 2 }}
          sx={{ maxWidth: width - 70 }}
          className="bg-card-white overflow-hidden"
        >
          <div className="flex justify-space-between padding-top-1 padding-bottom-1">
            <span className="secondary-title font-weight-600">
              Recent Tickets
            </span>
            <span
              className="primary-color tertiary-title font-weight-600 cursor-pointer"
              onClick={() => navigate(TICKETSPATH + "/" + TICKETSLISTSPATH)}
              style={{ borderBottom: "1px solid #366EC2" }}
            >
              View All
            </span>
          </div>
          <RecentDetailsTablePiece dashboardView />
        </Grid>
        <Grid
          item
          xl={4.3}
          lg={4.5}
          xs={12}
          padding={2}
          className="bg-card-white border-radius-16 font-family-monterrat width-100"
        >
          <span className="secondary-title font-weight-600 padding-bottom-0">
            Notifications
          </span>
          <Grid
            container
            className="overflow-scroll-y"
            sx={{ maxHeight: 353.5 }}
          >
            <NotificationAndRemindersCard viewDetails={true} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
