import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { REQUESTSTATUS } from "../../../../constants/axios-api-constant";
import { axiosInstance } from "../../../../lib/axios";
import { getSalesAgentYearDashboardDataThunk } from "../../../../store/dashboard/sales-agent-dashboard/sales-agent-dashboard-thunk";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import "./style.scss";
export const LineChartForSalesAgent = () => {

  const { yearStatus, salesAgentUserData } = useAppSelector((state: any) => state?.salesAgentSlice)
  const date = new Date();
  const year = date.getFullYear();
  const [yearData, setYearData] = useState<any>(year);


  const handleChange = (event: SelectChangeEvent) => {
    setYearData(event.target.value as string);
  };
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getSalesAgentYearDashboardDataThunk({ yearData, axiosInstance }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearData])
  const eChartOption = {
    legend: {
      data: ["Estate Agent", "Solictor", "Client", "Mortgage Broker"],
      icon: "circle",
      left: "right",
      orient: "horizontal",
    },
    tooltip: {
      trigger: "item",
    },
    grid: {
      left: "7%",
      right: "3%",
      top: "22%",
    },
    height: "67%",
    xAxis: {
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      minInterval: 1,
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        data: salesAgentUserData?.ESTATE_AGENT,
        type: "line",
        color: "#FF3F3F",
        name: "Estate Agent",
        smooth: true,
      },
      {
        data: salesAgentUserData?.SOLICITOR,
        type: "line",
        color: "#48995D",
        smooth: true,
        name: "Solictor",
      },
      {
        data: salesAgentUserData?.CLIENT,
        type: "line",
        color: "#366EC2",
        smooth: true,
        name: "Client",
      },
      {
        data: salesAgentUserData?.MORTGAGE_BROKER,
        type: "line",
        color: "#FA7901",
        name: "Mortgage Broker",
        smooth: true,
      },
    ],
  };
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 height-100">
      <Grid padding={2}>
        <div className="flex justify-space-between">
          <span className="font-weight-600 secondary-title"> Users </span>

          <div className="margin-bottom-0 flex">
            <span className="tertiary-title font-weight-600 primary-text-color margin-right-1 padding-top-0">
              Year:
            </span>
            <FormControl fullWidth variant="standard" sx={{ minWidth: 70 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={yearData}
                onChange={handleChange}
                sx={{ height: 25, fontSize: 12, border: "none" }}
              >

                <MenuItem value={year}>{year}</MenuItem>
                <MenuItem value={year - 1}>{year - 1}</MenuItem>
                <MenuItem value={year - 2}>{year - 2}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Grid paddingTop={1}>
          {yearStatus === REQUESTSTATUS.PENDING &&
            <div className="flex align-center justify-center width-100 padding-2">
              <CircularProgress size={35} />
              <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">Loading... </span>
            </div>}

          {yearStatus === REQUESTSTATUS.SUCCEEDED ?
            <EChartsReact option={eChartOption} className="line-chart-size" />
            :
            (yearStatus === REQUESTSTATUS.FAILED &&
              <div className="flex align-center justify-center width-100 padding-2">
                <span className="error-color font-weight-600 width-100 center-text secondary-title padding-top-1 padding-bottom-1">
                  Server Not Responding
                </span>
              </div>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};
