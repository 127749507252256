export const casesStatusType = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Signed",
  },
  {
    id: 3,
    name: "Unsigned",
  },
];

export const casesStatusTypeSolicitor = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Signed",
  },
  {
    id: 3,
    name: "Unsigned",
  },
  {
    id: 4,
    name: "Change Request",
  },
  {
    id: 5,
    name: "Embedded Sign",
  },
];
