import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  MYPERSONALPROFILEADDDOCUMENTAPI,
  MYPERSONALPROFILEDELETEDOCUMENTAPI,
  MYPERSONALPROFILEDETAILSAPI,
  MYPERSONALPROFILEEDITAPI,
  MYPERSONALPROFILEEDITDOCUMENTAPI,
  MYPERSONALPROFILEIMAGECHANGEAPI,
} from "../../constants/axios-api-constant";

import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
  putDataAPI,
} from "../../lib/axios";
import { dayjsFormatDate } from "../../lib/dayjs";
import { showApiAlert } from "../../utils/api-utils";

export const myPersonalDetailsThunk = createAsyncThunk(
  "personal-details/my-personal-details",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(
        MYPERSONALPROFILEDETAILSAPI,
        axiosInstance
      );
      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        name: `${formatData?.firstName ?? ""} ${formatData?.middleName ?? ""} ${
          formatData?.lastName ?? ""
        }`,
        profileImage: formatData?.profileImage,
        role: formatData?.defaultRole,
        drn: formatData?.drn,
        documents: formatData?.myDocuments,
        title: formatData?.title,
        personalProfileInfo: [
          {
            title: "Personal Details",
            data: {
              "Date of Birth": formatData.dob
                ? dayjsFormatDate(formatData.dob, "MM/DD/YYYY")
                : "",
              Age: formatData?.age,
              Gender: formatData?.gender,
              "Marital Status": formatData?.maritalStatus,
              "Employment Status": formatData?.employmentStatus,
              Occupation: formatData?.occupation,
              Citizenship: formatData?.citizenship,
            },
          },
          {
            title: "Contact Details",
            data: {
              "Post Code": formatData?.postCode,
              Email: formatData?.email,
              Address: formatData?.address,
              "Mobile Phone": formatData?.phoneNumber,

              flatNo: formatData?.flatNo,
              buildingName: formatData?.buildingName,
              buildingNo: formatData?.buildingNo,
              streetName: formatData?.streetName,
              city: formatData?.city,
              country: formatData?.country,
              county: formatData?.county,
            },
          },
        ],
        solicitorpersonalProfileInfo: {
          title: "Solicitor Details",
          data: {
            "SRA Number": formatData?.identityNo,
            "Company Name": formatData?.companyName ?? "No company",
            "Phone #": formatData?.phoneNumber,
            "Email Address": formatData?.email,
            "Post Code": formatData?.postCode,
            // Address: formatData?.address
            Address:
              formatData?.flatNo +
              " " +
              formatData?.buildingName +
              " " +
              formatData?.buildingNo +
              " " +
              formatData?.streetName +
              " " +
              formatData?.city +
              " " +
              formatData?.country +
              " " +
              formatData?.county,
            "Driving License": formatData?.drivingLicenseFile,
          },
        },
        solicitorFees: formatData?.solicitorFees,
      };

      // !!responseData?.message && openAlert(responseData?.message, 'alert-success-message')
      showApiAlert(responseData.message, openAlert, "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      // openAlert(errorData?.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsUpdateThunk = createAsyncThunk(
  "personal-details/my-personal-details-update",
  async (
    { body, axiosInstance, openAlert, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        MYPERSONALPROFILEEDITAPI,
        axiosInstance,
        body
      );

      const formatData = response?.data;
      const responseData = {
        message: response?.data?.message,
        name: `${formatData?.firstName ?? ""} ${formatData?.middleName ?? ""} ${
          formatData?.lastName ?? ""
        }`,
        profileImage: formatData?.profileImage,
        role: formatData?.defaultRole,
        drn: formatData?.drn,
        documents: formatData?.myDocuments,
        title: formatData?.title,
        personalProfileInfo: [
          {
            title: "Personal Details",
            data: {
              "Date of Birth": formatData?.dob,
              Age: formatData?.age,
              Gender: formatData?.gender,
              "Marital Status": formatData?.maritalStatus,
              "Employment Status": formatData?.employmentStatus,
              Occupation: formatData?.occupation,
              Citizenship: formatData?.citizenship,
            },
          },
          {
            title: "Contact Details",
            data: {
              "Post Code": formatData?.postCode,
              Email: formatData?.email,
              Address: formatData?.address,
              "Mobile Phone": formatData?.phoneNumber,

              flatNo: formatData?.flatNo,
              buildingName: formatData?.buildingName,
              buildingNo: formatData?.buildingNo,
              streetName: formatData?.streetName,
              city: formatData?.city,
              country: formatData?.country,
              county: formatData?.county,
            },
          },
          {
            title: "Solicitor Details",
            data: {
              "SRA Number": formatData.identityNo,
              "Company Name": formatData.companyName,
              "Phone #": formatData.phoneNumber,
              "Email Address": formatData.email,
              "Post Code": formatData.postCode,
              Address: formatData.address,
              "Driving License": formatData.drivingLicenseFile,
            },
          },
        ],
        solicitorpersonalProfileInfo: {
          title: "Solicitor Details",
          data: {
            "SRA Number": formatData?.identityNo,
            "Company Name": formatData?.companyName,
            "Phone #": formatData?.phoneNumber,
            "Email Address": formatData?.email,
            "Post Code": formatData?.postCode,
            Address: formatData?.address,
            "Driving License": formatData?.drivingLicenseFile,
          },
        },
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      navigate("/profile-management/profile");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      // openAlert(errorData?.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsChangeProfileImageThunk = createAsyncThunk(
  "personal-details/my-personal-details-change-profile-image",
  async ({ body, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(
        MYPERSONALPROFILEIMAGECHANGEAPI,
        axiosInstance,
        body
      );

      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        profileImage: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsAddDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-add-document",
  async (
    {
      body,
      openAlert,
      dispatch,
      axiosInstance,
      setFieldData,
      setAddDocument,
      setProfileIndex,
      setFileImage,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        MYPERSONALPROFILEADDDOCUMENTAPI,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setFieldData({ docType: "", remarks: "" });
      setAddDocument(false);
      setProfileIndex(null);
      setFileImage("");

      dispatch(myPersonalDetailsThunk({ axiosInstance, openAlert }));

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsEditDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-edit-document",
  async (
    {
      body,
      axiosInstance,
      openAlert,
      documentId,
      dispatch,
      setAddDocument,
      setProfileIndex,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(
        `${MYPERSONALPROFILEEDITDOCUMENTAPI}/${documentId}`,
        axiosInstance,
        body
      );

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddDocument(false);
      setProfileIndex(null);
      dispatch(myPersonalDetailsThunk({ axiosInstance }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsDeleteDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-delete-document",
  async (
    { documentId, body, openAlert, dispatch, axiosInstance }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(
        `${MYPERSONALPROFILEDELETEDOCUMENTAPI}/${documentId}`,
        axiosInstance,
        body
      );
      // const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        documentId,
      };

      // showApiAlert(responseData.message, openAlert, "alert-success-message");
      showApiAlert(
        "Document deleted successfully",
        openAlert,
        "alert-success-message"
      );
      dispatch(myPersonalDetailsThunk({ axiosInstance, openAlert }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);
