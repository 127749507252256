import { createSlice } from "@reduxjs/toolkit";
import { getSolicitorDataThunkPendingReducer, getSolicitorDataThunkRejectedReducer, getSolicitorDataThunkFulfilledReducer, getSolicitorYearThunkRejectedReducer, getSolicitorYearThunkFulfilledReducer, getSolicitorYearThunkPendingReducer } from "./solicitor-dashboard-reducers";
import { getSolicitorDashboardDataThunk, getSolicitorYearDashboardDataThunk } from "./solicitor-dashboard-thunk-action";

const solicitorDashboardIntialState = {
    solicitorStatus: '',
    solicitorData: {} as any,
    solicitorYearData: {} as any
};

export const solicitorDashboardSlice = createSlice({
    name: "solicitorDashboard",
    initialState: solicitorDashboardIntialState,
    reducers: {},
    extraReducers(builder) {
        getSolicitorDataThunkRejectedReducer(builder, getSolicitorDashboardDataThunk)
        getSolicitorDataThunkPendingReducer(builder, getSolicitorDashboardDataThunk)
        getSolicitorDataThunkFulfilledReducer(builder, getSolicitorDashboardDataThunk)

        getSolicitorYearThunkRejectedReducer(builder, getSolicitorYearDashboardDataThunk)
        getSolicitorYearThunkPendingReducer(builder, getSolicitorYearDashboardDataThunk)
        getSolicitorYearThunkFulfilledReducer(builder, getSolicitorYearDashboardDataThunk)

    },
});
export default solicitorDashboardSlice.reducer;