import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";

//Get Windows size function
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const useFooter = () => {
  const { openDrawer } = useAppSelector((state) => state.layout);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [smallWidth, setSmallWidth] = useState<boolean>();
  const [xSmallWidth, setXSmallWidth] = useState<boolean>();

  function stateReturnTrue() {
    return setSmallWidth(true);
  }

  function stateReturnFalse() {
    return setSmallWidth(false);
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    if (windowSize.innerWidth <= 970) {
      if (windowSize.innerWidth <= 675) {
        setXSmallWidth(true);
      } else {
        setXSmallWidth(false);
      }
      stateReturnTrue();
    } else {
      stateReturnFalse();
    }
  }, [windowSize.innerWidth]);
  return {
    xSmallWidth,
    smallWidth,
    openDrawer,
  };
};
