import {
  MUIBORDERRADIUS5,
  MUIFONTWEIGHT400,
  MUIPRIMARYCOLOR,
  MUISECONDARYTITLE,
  MUIWHITECOLOR,
} from "../../constants/mui-styles-constant";

export const TOOLTIP = (bgColor = MUIPRIMARYCOLOR) => {
  return {
    tooltip: {
      sx: {
        background: bgColor,
        color: MUIWHITECOLOR,
        fontSize: MUISECONDARYTITLE,
        fontWeight: MUIFONTWEIGHT400,
        borderRadius: MUIBORDERRADIUS5,
        marginBottom: "0px",
      },
    },
    arrow: {
      color: MUIPRIMARYCOLOR,
      sx: {
        fontSize: 18,
        width: 20,
        color: MUIPRIMARYCOLOR,
        "&::before": {
          boxSizing: "border-box",
          boxShadow: 1,
        },
      },
    },
  };
};

export const LICENSEINFOICON = {
  color: MUIWHITECOLOR,
  marginLeft: "1rem",
  fontSize: "15px",
  cursor: "pointer",
};
