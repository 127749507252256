export const UpdateStatusArray = [
  {
    id: 1,
    name: "Completed",
  },
  {
    id: 2,
    name: "On Hold",
  },
  {
    id: 3,
    name: "Post Completion",
  },
  {
    id: 4,
    name: "Fell Through",
  },
  {
    id: 5,
    name: "In Progress",
  },
];
