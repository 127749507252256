import { createAsyncThunk } from "@reduxjs/toolkit";
import { REFERENCEDATAAPI } from "../../constants/axios-api-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/hooks-constant";
import { LOCALSTORAGELENDERREFERENCEDATA, LOCALSTORAGEREFERENCEDATA } from "../../constants/local-storage-constant";
import { getDataAPI } from "../../lib/axios";
import { saveDataToLocalStorage } from "../../utils/views-utils";

export const referenceDataThunk = createAsyncThunk(
  "referenceData/reference-data",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(REFERENCEDATAAPI, axiosInstance);
      const formatData = response?.data?.data;
      const responseData = {
        mortgage: formatData?.mortgage, // store access token
        property: formatData?.property, // store refresh token
        otherData: formatData,
      };

      saveDataToLocalStorage(LOCALSTORAGEREFERENCEDATA, responseData);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert?.(errorData?.error);
      return rejectWithValue(errorData);
    }
  }
);



export const getReferenceLenderThunk = createAsyncThunk(
  "get/referenceLender",
  async (
    { axiosInstance}: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await getDataAPI(
        `reference-data/lenders`,
        axiosInstance
      );

      saveDataToLocalStorage(LOCALSTORAGELENDERREFERENCEDATA, response?.data?.data);
      
      
      return response?.data?.data;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };


      return rejectWithValue(errorData);
    }
  }
);
