import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { IMAGEBASEURL } from "../../constants/axios-api-constant";
import {
  MUIWHITECOLOR,
  MUIPRIMARYCOLOR,
  MUIERRORCOLOR,
  MUIBOXSHADOWTERTIARYINSET,
  MUIBORDERRADIUS24,
  MUIDISABLEDCOLOR,
} from "../../constants/mui-styles-constant";
import { CheckBoxButton } from "../checkbox/checkbox-button";
import { TooltipInfo } from "../tooltip-info/tooltip-info";
import { SELECTPAPER } from "./app-input-field-mui-styles";

export const AppInputField = (props: any) => {
  const {
    label,
    name,
    disabled,
    required,
    sx,
    color,
    placeholder,
    type,
    selectProps,
    minRows,
    maxRows,
    renderValue,
    endIconClassName,
    setFormikField,
    endIcon,
    multiline,
    menuProps,
    startIcon,
    size,
    setPopuptitle,
    inputProps,
    value,
    select,
    selectMenudata,
    isPopupOpen,
    labelClassName,
    checkbox,
    menuItemClick,
    onBlur,
    onChange,
    error,
    borderColor,
    iconComponent,
    boxShadow,
    touched,
    fullWidth,
    endIcon2,
    inputRef,
  } = props;

  const MenuProps = {
    PaperProps: {
      sx: SELECTPAPER,
    },
  };

  const startAdornment = startIcon && {
    startAdornment: (
      <InputAdornment position="start">
        <> {startIcon} </>
      </InputAdornment>
    ),
  };

  const endAdornment = endIcon && {
    endAdornment: (
      <InputAdornment position="end" className={`${endIconClassName}`}>
        <> {endIcon} </>
        <> {endIcon2} </>
      </InputAdornment>
    ),
  };

  return (
    <>
      <div className={`primary-title flex margin-bottom-0 align-center ${labelClassName}`}>
        {label}
        <span className="danger-color primary-title margin-left-0-2">
          {(required ?? true) && "  *"}
        </span>
      </div>

      <TooltipInfo showTooltipInfo={!!isPopupOpen} tooltipInfoTitle={setPopuptitle}>
        <TextField
          className={`${touched && !!error && "border-radius-24 border-error"}`}
          fullWidth={fullWidth ?? true}
          select={select || false}
          multiline={multiline || false}
          disabled={disabled}
          placeholder={placeholder}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: MUIBORDERRADIUS24,
              "& > fieldset": {
                borderColor: borderColor || MUIWHITECOLOR,
                boxShadow: boxShadow || MUIBOXSHADOWTERTIARYINSET,
                borderRadius: MUIBORDERRADIUS24,
              },
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: MUIPRIMARYCOLOR,
              },
            },
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: MUIPRIMARYCOLOR,
              },
            },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              backgroundColor: MUIDISABLEDCOLOR,
              "& > fieldset": {
                border: "none",
              },
            },
            "& .MuiOutlinedInput-root.Mui-error": {
              "& > fieldset": {
                borderColor: MUIERRORCOLOR,
              },
            },
            ...sx,
          }}
          minRows={minRows || 3}
          maxRows={maxRows || 3}
          type={type || "text"}
          color={color}
          id={name}
          size={size || "small"}
          name={name}
          value={value}
          inputRef={inputRef}
          onChange={(e: any) => {
            onChange?.(e);
          }}
          onBlur={onBlur}
          SelectProps={{
            MenuProps: menuProps || MenuProps,
            renderValue: renderValue,
            IconComponent: iconComponent,
            ...selectProps,
          }}
          InputProps={{
            startAdornment: startAdornment?.startAdornment,
            endAdornment: endAdornment?.endAdornment,
            ...inputProps,
          }}
        >
          {selectMenudata?.length ? (
            selectMenudata?.map((menuData: any) => {
              return (
                <MenuItem
                  value={
                    (typeof menuData === "string" && menuData) ||
                    menuData?.Value ||
                    menuData?.city_name ||
                    (menuData?.type === "badge" && JSON.stringify(menuData)) ||
                    menuData?.name ||
                    menuData?.code
                  }
                  onClick={(e: any) => {
                    menuItemClick?.(e, menuData, setFormikField);
                  }}
                  key={(typeof menuData === "string" && menuData) || menuData?.id}
                  sx={menuData?.sx}
                >
                  {checkbox && (
                    <CheckBoxButton
                      checked={value?.indexOf(menuData?.Value || menuData?.name) > -1}
                    />
                  )}
                  {(typeof menuData === "string" && menuData) ||
                    menuData?.text ||
                    menuData?.name ||
                    menuData?.city_name ||
                    menuData?.name?.replace?.("_", " ")}
                  {menuData?.icon && (
                    <img
                      src={`${IMAGEBASEURL}${menuData?.icon}`}
                      className="margin-left-1"
                      width="20px"
                      alt="badge-icon"
                    />
                  )}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem>No Data Found</MenuItem>
          )}
        </TextField>
      </TooltipInfo>
      <span className="global-input-error error-color secondary-title">{touched && error}</span>
    </>
  );
};
