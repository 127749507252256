import { Grid } from "@mui/material";
import { useEffect } from "react";
import {
  hideBreadcrumb, showBreadcrumb
} from "../../store/breadcrumb/breadcrumb-slice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { AdminDashboard } from "./admin-dashboard/admin-dashboard";
import { ClientDashboard } from "./client-dashboard/client-dashboard";
import { EstateAgentDashboard } from "./estate-agent-dashboard/estate-agent-dashboard";
import { MortgageBrokerDashboard } from "./mortgage-broker-dashboard/mortgage-broker-dashboard";
import { SaleAgentDashboard } from "./sale-agent-dashboard/sale-agent-dashboard";
import { SolicitorDashboard } from "./solicitor-dashboard/solicitor-dashboard";

const Dashboards = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state: any) => state);

  const renderConditionalyDashboard: any = {
    CLIENT: <ClientDashboard />,
    SOLICITOR: <SolicitorDashboard />,
    SALES_AGENT: <SaleAgentDashboard />,
    MORTGAGE_BROKER: <MortgageBrokerDashboard />,
    ESTATE_AGENT: <EstateAgentDashboard />,
    SUPER_ADMIN: <AdminDashboard />,
  };
  const authRole = auth?.role[0];

  const otherBreadcrumbs = [
    {
      id: 1,
      linkName: "Dashboard",
      className: "global-breadcrumb-active",
    },
  ];

  const breadcrumbdata = {
    otherBreadcrumbs,
  };

  useEffect(() => {
    dispatch(showBreadcrumb(breadcrumbdata));
    return () => {
      dispatch(hideBreadcrumb());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // comment  line 52  before push
  return (
    <>
      <Grid container >
        <Grid item md={12}>
          {renderConditionalyDashboard[authRole]}
        </Grid>
      </Grid>
    </>
    
  );
};
export default Dashboards;