import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const getProductsThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getProductsThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.products = [...state.products, ...payload.data];
  });
};

export const getProductsThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getProductThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getProductThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.product = [payload.data];
  });
};

export const getProductThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getDIPApplicationThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getDIPApplicationThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.application = {
      ...payload.data,
    };
  });
};

export const getDIPApplicationThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};
// Get All Application
export const getDIPApplicationsThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getDIPApplicationsThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.applicationList = {
      ...payload.data,
    };
  });
};

export const getDIPApplicationsThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const createDIPApplicationThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const createDIPApplicationThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.application = { ...state.application, ...payload.data.data };
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const createDIPApplicationThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const updateDIPApplicationThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const updateDIPApplicationThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const updateDIPApplicationThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const confirmApplicationThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const confirmApplicationThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const confirmApplicationThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

// Get Post Code
export const getGetPostCodeThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getGetPostCodeThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.postCodes = { ...payload };
  });
};

export const getGetPostCodeThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

// Get PropertyPost Code
export const getGetPropertyPostCodeThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getGetPropertyPostCodeThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.propertyPostCodes = { ...payload };
  });
};

export const getGetPropertyPostCodeThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

// Get ApplicantPost Code
export const getGetApplicantPostCodeThunkPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getGetApplicantPostCodeThunkFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
    state.applicantPostCodes = { ...payload };
  });
};

export const getGetApplicantPostCodeThunkRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};



// export const deleteDemoThunkPendingReducer = (builder: any, action: any) => {
//   builder.addCase(action.pending, (state: any, { payload }: any) => {
//     state.deleteRequestStatus = REQUESTSTATUS.PENDING;
//   });
// };

// export const deleteDemoThunkFulfilledReducer = (builder: any, action: any) => {
//   builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
//     state.deleteRequestStatus = REQUESTSTATUS.SUCCEEDED;
//   });
// };

// export const deleteDemoThunkRejectedReducer = (builder: any, action: any) => {
//   builder.addCase(action.rejected, (state: any, { payload }: any) => {
//     state.deleteRequestStatus = REQUESTSTATUS.FAILED;
//   });
// };
