import { createAsyncThunk } from "@reduxjs/toolkit";
import { ESTATEAGENTDASHBOARDPATH } from "../../../constants/routes-constant";
import { getDataAPI } from "../../../lib/axios";

export const getEstateAgentDashboardDataThunk = createAsyncThunk("get-estate-agent-dashboard-data",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(ESTATEAGENTDASHBOARDPATH, axiosInstance);
            

            return response.data?.data;
        } catch (error: any) {
            
            const errorData = {

                //   error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
            };
            return rejectWithValue(errorData);
        }
    }
);



export const getEstateAgentYearDashboardDataThunk = createAsyncThunk("get-estate-agent-year-dashboard-data",
    async ({ yearData, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`dashboard/get-user?year=${yearData}`, axiosInstance);
            

            return response.data?.data;
        } catch (error: any) {
            
            const errorData = {

                //   error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
            };
            return rejectWithValue(errorData);
        }
    }
);