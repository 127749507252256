import { Link } from "react-router-dom";

import { useAppSelector } from "../../store/store";

import "./app-breadcrumbs.scss";

export const AppBreadcrumbs = () => {
  const { breadcrumb } = useAppSelector((state: any) => state);

  return (
    <div className="global-breadcrumb padding-left-1">
      {!!breadcrumb?.breadcrumbData?.otherBreadcrumbs?.length &&
        breadcrumb?.breadcrumbData?.otherBreadcrumbs?.map(
          (otherBreadcrumb: any) =>
            otherBreadcrumb.className === "global-breadcrumb-active" ? (
              <span
                key={otherBreadcrumb.id}
                className={otherBreadcrumb?.className}
              >
                {otherBreadcrumb?.linkName}
              </span>
            ) : (
              <Link
                key={otherBreadcrumb.id}
                className={`${otherBreadcrumb?.className} text-transform-capitalize`}
                to={otherBreadcrumb?.linkPath}
              >
                {otherBreadcrumb?.linkName}
              </Link>
            )
        )}
    </div>
  );
};
