import { createSlice } from "@reduxjs/toolkit";
import { getMortgageBrokerThunkFulfilledReducer, getMortgageBrokerThunkPendingReducer, getMortgageBrokerThunkRejectedReducer, getMortgageBrokerYearThunkFulfilledReducer, getMortgageBrokerYearThunkPendingReducer, getMortgageBrokerYearThunkRejectedReducer } from "./mortgage-broker-dashboard-reducer";
import { getMortgageBrokerDasboardDataThunk, getMortgageBrokerYearDashboardDataThunk } from "./mortgage-broker-dashboard-thunk";



const mortgageBrokerDashboardInitialState = {
    status: '',
    mortgageBrokerData: {} as any,

    yearStatus: '' as any,
    mortgageBrokerUsersData: {} as any


};

export const mortgageBrokerSlice = createSlice({
    name: "mortgageBrokerDashboard",
    initialState: mortgageBrokerDashboardInitialState,
    reducers: {},
    extraReducers(builder) {
        getMortgageBrokerThunkRejectedReducer(builder, getMortgageBrokerDasboardDataThunk)
        getMortgageBrokerThunkPendingReducer(builder, getMortgageBrokerDasboardDataThunk)
        getMortgageBrokerThunkFulfilledReducer(builder, getMortgageBrokerDasboardDataThunk)

        getMortgageBrokerYearThunkRejectedReducer(builder, getMortgageBrokerYearDashboardDataThunk)
        getMortgageBrokerYearThunkPendingReducer(builder, getMortgageBrokerYearDashboardDataThunk)
        getMortgageBrokerYearThunkFulfilledReducer(builder, getMortgageBrokerYearDashboardDataThunk)

    },
});


export default mortgageBrokerSlice.reducer;