import { TYPEINTERFACE } from "../OPTIONINTERFACE";

export const Type:React.FC<TYPEINTERFACE> = ({value,valChangeHand}) => {

  return (
    <>
      <div className="primary-text-color font-weight-500 margin-bottom-1">
        Type Your Signature here
      </div>
      {/*  Draw Here  */}
      <div
        style={{ height: "200px", border: "1px solid black",overflow:'hidden' }}
      >
      <textarea style={{ height: "200px",width:'100%',border:'none',outline:'none',resize:'none' }} value={value} onChange={valChangeHand}  />

      
      </div>
      
    </>
  );
};
