import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { RecentMeetings } from "./recent-meetings-cards/recent-meetings-cards";
import { AssignedSolictorCard } from "../../../features/solicitor/solictor-card/assigned-solicitor-card-client";
import { NotificationAndRemindersCard } from "../../../components/notification-and-reminders-card/notification-and-reminders-card";
import { PersonalDetailsSummaryView } from "../../../features/personal-details/personal-details-summary-view/personal-details-summary-view";
import { ClientDataFile } from "./client-dashboard-data";
import { DayPlannerCard } from "../../../components/day-planner-card/day-planner-card";
import {
  DAYPLANNERPATH,
  DIPHISTORYPATH,
  DIPPATH,
} from "../../../constants/routes-constant";
import { DipHistoryTable } from "../../../features/dip/dip-history/dip-history-list/dip-history-list";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getClientDashboardDataThunk } from "../../../store/dashboard/client-dashboard/client-dashboard-thunk";
import { axiosInstance } from "../../../lib/axios";
import { getDIPApplicationsThunk } from "../../../store/dip/dip-thunk-actions";
import { getDipApplications } from "../../../store/dip/dip-slice";

export const ClientDashboard = () => {
  const dispatch = useAppDispatch();

  const dipApplications = useAppSelector(getDipApplications);

  useEffect(() => {
    dispatch(getClientDashboardDataThunk({ axiosInstance }));
    dispatch(getDIPApplicationsThunk({ axiosInstance, params: { limit: 4 } }));
  }, [dispatch]);
  const width = window.innerWidth;
  const navigate = useNavigate();

  return (
    <div>
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          item
          xl={9.2}
          lg={11.9}
          xs={12}
        >
          <ClientDataFile />

          <Grid container justifyContent={"space-between"} marginTop={2}>
            <Grid
              container
              item
              xl={4.4}
              lg={12}
              xs={12}
              className="bg-card-white position-relative"
              py={1}
              px={2}
              mb={{ xl: 0, xs: 2 }}
            >
              <span
                className="position-absolute primary-color tertiary-title font-weight-600 cursor-pointer"
                onClick={() => navigate(DAYPLANNERPATH)}
                style={{
                  right: 10,
                  top: 10,
                  zIndex: 1,
                  borderBottom: "1px solid #366EC2",
                }}
              >
                Open Day Planner
              </span>
              <DayPlannerCard dayPlannerHeight={"280px"} />
            </Grid>

            <Grid
              className="bg-card-white"
              container
              item
              xl={3.2}
              lg={5.9}
              xs={12}
              mb={{ xl: 0, xs: 2 }}
            >
              <RecentMeetings />
            </Grid>

            <Grid
              className="bg-card-white"
              container
              item
              xl={4}
              lg={5.9}
              xs={12}
              mb={{ xl: 0, xs: 2 }}
            >
              <AssignedSolictorCard />
            </Grid>
          </Grid>
        </Grid>

        {/* grid for profile card */}
        <Grid
          item
          container
          justifyContent={"center"}
          xl={2.6}
          xs={12}
          mb={{ xl: 0, xs: 2 }}
        >
          <PersonalDetailsSummaryView />
        </Grid>

        {/* grid for Dip History Card and Notification Card */}
        <Grid container justifyContent={"space-between"} marginTop={2}>
          <Grid
            item
            xl={8.3}
            lg={7.3}
            xs={12}
            px={2}
            className="bg-card-white  position-relative"
            mb={{ lg: 0, xs: 2 }} sx={{ maxWidth: width - 70 }}
          >
            <div className="flex justify-space-between padding-top-1 padding-bottom-1">
              <span className="secondary-title font-weight-600">
                Dip History
              </span>
              <span
                className="primary-color tertiary-title font-weight-600 cursor-pointer"
                onClick={() => navigate(DIPPATH + "/" + DIPHISTORYPATH)}
                style={{ borderBottom: "1px solid #366EC2" }}
              >
                View All
              </span>
            </div>
            <DipHistoryTable dipApplications={dipApplications?.dips} />
          </Grid>

          <Grid item xl={3.5} lg={4.5} xs={12}
            padding={2}
            className="bg-card-white "
          >
            <span className="secondary-title font-weight-600 padding-bottom-0">
              Notifications and Reminders
            </span>

            <Grid container className="overflow-scroll-y"
              sx={{ maxHeight: 363.5 }}>
              <NotificationAndRemindersCard viewDetails={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
