import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import "./day-planner-card.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CircularProgress, Popover, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getRemindersThunk } from "../../store/day-planner/day-planner-thunk-actions";
import { axiosInstance } from "../../lib/axios";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

export const DayPlannerCard = (props: any) => {
  const { dayPlannerHeight } = props;
  const dispatch = useAppDispatch();
  const { reminders, status } = useAppSelector((state: any) => state.dayPlanner);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [date, setDate] = useState<any>(null);
  useEffect(() => {
    dispatch(getRemindersThunk({ axiosInstance }));
  }, []);

  return (
    <>
      {status === REQUESTSTATUS.PENDING &&
        <div className="flex align-center justify-center width-100 padding-2">
          <CircularProgress size={35} />
          <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
            Loading...{" "}
          </span>
        </div>
      }

      {status === REQUESTSTATUS.SUCCEEDED && <>
        <Grid
          container
          textAlign={"center"}
          position={"relative"}
          marginTop={{ xs: 3, sm: 0 }}
          className={`calendar-container-card`}
          height={"auto"}
        >
          <Grid item xs={12}>
            <FullCalendar
              dayHeaderFormat={(date) => {
                return dayjs(date.start.marker).format("ddd");
              }}
              handleWindowResize={true}
              height={dayPlannerHeight}
              timeZone="local"
              headerToolbar={{
                start: "prev,title,next",
                center: "",
                end: "",
              }}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              eventMouseEnter={(info: any) => {
                setAnchorEl(info.el);
                setDate(info.event);
              }}
              initialView="dayGridMonth"
              events={reminders}
              eventClassNames={" events-style cursor-pointer"}
              eventTimeFormat={{
                hour: "numeric",
                omitZeroMinute: true,
                meridiem: "narrow",
              }}
            />
          </Grid>
        </Grid>

        <Popover
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "5px",
            },
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div
            style={{ fontSize: "10px", padding: "5px" }}
            className="primary-text-color"
          >
            {dayjs(date?.start).format("HH:mm")}
            {date?.end ? dayjs(date?.end).format("- HH:mm") : ""}
          </div>
        </Popover>
      </>
      }

      {
        status === REQUESTSTATUS.FAILED &&
        <div className="center-text error-color font-weight-600 width-100 padding-4 secondary-title">
          <span> Server Not Responding </span>
        </div>
      }
    </>
  );
};
