import { Avatar, Box, ClickAwayListener, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../store/store";
import { logoutThunk } from "../../store/auth/auth-thunk-actions";
import { useAppAlert } from "../app-alert/use-app-alert";
import { axiosInstance } from "../../lib/axios";
import {
  CHANGEPASSWORDPATH,
  PROFILEMANAGEMENTPATH,
  PROFILEPATH,
  SLASHPATH,
} from "../../constants/routes-constant";
import NotificationCard from "../../layout/header/notification-card/notification-card";

export const UserDetailCard = (props: any) => {
  const {
    name,
    description,
    userImage,
    userImageClassName,
    descriptionClassName,
    nameClassName,
    userDetailsClassName,
    userInfoClassName,
    imageDivClassName,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openAlert } = useAppAlert();
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);

  const logoutHandler = () => {
    dispatch(logoutThunk({ navigate, axiosInstance, openAlert }));
    setIsDropdownShowing(false);
  };
  const changePasswordHandler = () => {
    navigate(`${PROFILEMANAGEMENTPATH}${SLASHPATH}${CHANGEPASSWORDPATH}`);
    setIsDropdownShowing(false);
  };
  const viewProfileHandler = () => {
    navigate(`${PROFILEMANAGEMENTPATH}${SLASHPATH}${PROFILEPATH}`);
    setIsDropdownShowing(false);
  };

  return (
    <Box
      sx={{
        flexDirection: { xs: "column", sm: "row" },
      }}
      className={`flex align-center ${userDetailsClassName}`}
    >
      <div className={`${userInfoClassName}`}>
        <div className={`${nameClassName}`}>{name}</div>
        <div className={`${descriptionClassName}`}>{description}</div>
      </div>
      <div className={`flex align-center justify-center ${imageDivClassName}`}>
        <ClickAwayListener onClickAway={() => setIsDropdownShowing(false)}>
          <div className="position-relative">
            <Avatar
              src={userImage}
              alt={name}
              className={`${userImageClassName} cursor-pointer`}
              onClick={() => setIsDropdownShowing((p) => !p)}
            >
              {name?.slice(0, 1)?.toUpperCase()}
            </Avatar>

            {isDropdownShowing ? (
              <Grid
                className="position-absolute bg-card-white border-radius-24"
                width="250px"
                height="auto"
                right="0"
                top="110%"
                left={{ xs: "-100%", sm: "auto" }}
                zIndex={1}
                py={2}
                px="5px"
              >
                <Grid
                  gap={2}
                  p={1}
                  px={3}
                  onClick={viewProfileHandler}
                  className="font-weight-500 flex align-center secondary-title cursor-pointer border-radius-10 primary-text-color"
                >
                  <PersonIcon /> <span>Profile Preview</span>{" "}
                </Grid>
                <hr />
                <Grid
                  gap={2}
                  p={1}
                  px={3}
                  onClick={changePasswordHandler}
                  className="font-weight-500 flex align-center secondary-title cursor-pointer border-radius-10 primary-text-color"
                >
                  <KeyIcon /> <span>Change Password</span>{" "}
                </Grid>
                <hr />
                <Grid
                  gap={2}
                  p={1}
                  px={3}
                  onClick={logoutHandler}
                  className="font-weight-500 flex align-center secondary-title cursor-pointer border-radius-10 primary-text-color"
                >
                  <LogoutRoundedIcon />
                  <span>Logout</span>{" "}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </ClickAwayListener>
        {/* Notification Component */}
        <NotificationCard />
      </div>
    </Box>
  );
};
