import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";

import {
  addOnBoardingThunkFulfilledReducer,
  addOnBoardingThunkPendingReducer,
  addOnBoardingThunkRejectedReducer,
  changeOnboardingUsersStatusThunkFulfilledReducer,
  changeOnboardingUsersStatusThunkPendingReducer,
  changeOnboardingUsersStatusThunkRejectedReducer,
  onboardingUsersThunkFulfilledReducer,
  onboardingUsersThunkPendingReducer,
  onboardingUsersThunkRejectedReducer,
} from "./onboarding-reducers";

import {
  changeOnboardingUsersStatusThunk,
  onboardingUsersThunk,
  postOnboardingResponseThunk,
} from "./onboarding-thunk-actions";

const onboardingInitialState = {
  getRequestStatus: REQUESTSTATUS.IDLE,
  changeStatusStatus: REQUESTSTATUS.IDLE,
  postRequestStatus: REQUESTSTATUS.IDLE,
  meta: {} as any,
  userDetails: {} as any,
  usersOnBoardingRequestData: [] as any,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: onboardingInitialState,
  reducers: {},
  extraReducers(builder) {
    onboardingUsersThunkPendingReducer(builder, onboardingUsersThunk);
    onboardingUsersThunkFulfilledReducer(builder, onboardingUsersThunk);
    onboardingUsersThunkRejectedReducer(builder, onboardingUsersThunk);

    changeOnboardingUsersStatusThunkPendingReducer(
      builder,
      changeOnboardingUsersStatusThunk
    );
    changeOnboardingUsersStatusThunkFulfilledReducer(
      builder,
      changeOnboardingUsersStatusThunk
    );
    changeOnboardingUsersStatusThunkRejectedReducer(
      builder,
      changeOnboardingUsersStatusThunk
    );
    addOnBoardingThunkPendingReducer(builder, postOnboardingResponseThunk);
    addOnBoardingThunkFulfilledReducer(builder, postOnboardingResponseThunk);
    addOnBoardingThunkRejectedReducer(builder, postOnboardingResponseThunk);
  },
});

export const {} = onboardingSlice.actions;

export default onboardingSlice.reducer;
