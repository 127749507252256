import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getByIdQuoteManagementFormFulfilledReducer,
  getByIdQuoteManagementFormRejectedReducer,
  getQuoteManagementFulfilledReducer,
  getQuoteManagementPendingReducer,
  getQuoteManagementRejectedReducer,
  getByIdQuoteManagementFormPendingReducer,
  patchByIdQuoteManagementFormRejectedReducer,
  patchByIdQuoteManagementFormFulfilledReducer,
  patchByIdQuoteManagementFormPendingReducer,
} from "./quote-mangement-reducers";
import {
  getQuoteManagementListThunk,
  getByIdQuoteManagementFormThunk,
  patchByIdQuoteManagementFormThunk,
} from "./quote-mangement-thunk-actions";

const quoteManagementInitialState: any = {
  quoteStatus: REQUESTSTATUS.IDLE,
  quoteManagementData: [],
  quoteManagementFormData: [],
};

export const quoteManagementSlice = createSlice({
  name: "quoteManagement",
  initialState: quoteManagementInitialState,
  reducers: {},
  extraReducers(builder) {
    getQuoteManagementRejectedReducer(builder, getQuoteManagementListThunk);
    getQuoteManagementFulfilledReducer(builder, getQuoteManagementListThunk);
    getQuoteManagementPendingReducer(builder, getQuoteManagementListThunk);

    getByIdQuoteManagementFormRejectedReducer(
      builder,
      getByIdQuoteManagementFormThunk
    );
    getByIdQuoteManagementFormFulfilledReducer(
      builder,
      getByIdQuoteManagementFormThunk
    );
    getByIdQuoteManagementFormPendingReducer(
      builder,
      getByIdQuoteManagementFormThunk
    );

    patchByIdQuoteManagementFormRejectedReducer(
      builder,
      patchByIdQuoteManagementFormThunk
    );
    patchByIdQuoteManagementFormFulfilledReducer(
      builder,
      patchByIdQuoteManagementFormThunk
    );
    patchByIdQuoteManagementFormPendingReducer(
      builder,
      patchByIdQuoteManagementFormThunk
    );
  },
});

export const {} = quoteManagementSlice.actions;

export default quoteManagementSlice.reducer;
