import "./app-alert.scss";
import { useAppAlert } from "./use-app-alert";
import markAsDone from "../../assets/icons/mark-as-done.svg";
import markAsUnDone from "../../assets/icons/mark-as-undone.svg";

export const AppAlert = (props: any) => {
  const { alertType, alertMessage } = props;

  const { alert } = useAppAlert(props);

  return (
    <div
      className={`alert-message-wrapper flex align-center 
        border-radius-10 box-shadow-secondary ${
          alert?.alertType || alertType || "alert-error-message"
        }`}
    >
      {
        <img
          className="margin-right-0"
          src={
            alert?.alertType === "alert-error-message"
              ? `${markAsUnDone}`
              : `${markAsDone}`
          }
          alt=""
        />
      }
      <div className="alert-message-wrapper-flex-grow secondary-title text-break">
        {alert?.alertMessage || alertMessage}
      </div>
    </div>
  );
};
