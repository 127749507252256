export const tooltipNotificationCardComponenetsProps = {
  tooltip: {
    sx: {
      maxWidth: "325px",
      marginTop: "30px !important",
      padding: "0 !important",
      bgcolor: "transparent",
    },
  },
  arrow: {
    sx: {
      fontSize: 18,
      width: 20,
      color: "#FFFFFF",
      "&::before": {
        boxSizing: "border-box",
        boxShadow: 1,
      },
    },
  },
};
