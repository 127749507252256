import { Avatar } from "@mui/material";
import { useState } from "react";
import "./app-short-summary-cards.scss";

export const ShortSummaryCards = (props: any) => {
  const {
    bgColor,
    value,
    title,
    imgSrc,
    isCLient,
    isAdmin,
    isSolicitor,
    thisWeekMonthToggler,
    value2,
    firstLabel,
    secondLabel,
  } = props;
  const [tabValue, setTabValue] = useState(0);
  const [thisWeek, setThisWeek] = useState(true);
  const [thisMonth, setThisMonth] = useState(false);

  return (
    <div className="order-cards white-color font-family-montserrat overflow-hidden">
      <div
        className="custom-card-shadow  border-radius-16 flex padding-2 card align-center height-100 justify-space-between"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex flex-column padding-left-0 position-relative">
          <div className="flex">
            <Avatar
              src={imgSrc}
              alt={imgSrc}
              className="order-card-image position-relative border-radius-rounded"
            ></Avatar>
          </div>
          <p
            className="tertiary-title position-absolute font-weight-500 padding-top-0 padding-bottom-0 margin-none "
            style={{ bottom: -18, width: "200px" }}
          >
            {title}
          </p>
        </div>

        <div className="flex flex-column align-end">
          <div
            className="card-content padding-bottom-0 white-color position-relative flex align-center justify-space-between title-8 font-weight-600"
            style={{
              height: 13,
              width: isCLient || isAdmin || isSolicitor ? 100 : 130,
            }}
          >
            {thisWeekMonthToggler && (
              <>
                <span
                  className={`cursor-pointer ${
                    thisWeek ? "border-bottom-for-selected" : "disabled-color"
                  }`}
                  onClick={() => {
                    setThisWeek(true);
                    setThisMonth(false);
                    setTabValue(0);
                  }}
                >
                  {firstLabel}
                </span>
                <span
                  className={`cursor-pointer ${
                    thisMonth ? "border-bottom-for-selected" : "disabled-color"
                  }`}
                  onClick={() => {
                    setThisMonth(true);
                    setThisWeek(false);
                    setTabValue(1);
                  }}
                >
                  {secondLabel}
                </span>
              </>
            )}
          </div>

          <div className="padding-bottom-1">
            <span className="heading-42  font-weight-600 padding-right-0   margin-none ">
              {!tabValue ? value : (tabValue === 1 && value2) || "0"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
