import { createSlice } from "@reduxjs/toolkit";
import { getAdminDataThunkFulfilledReducer, getAdminDataThunkPendingReducer, getAdminDataThunkRejectedReducer } from "./admin-dashboard-reducer";
import { getAdminDashboardDataThunk } from "./admin-dashboard-thunk";

const adminDashboardIntialState = {
    status: '',
    closedTicketsThisMonth: 0,
    closedTicketsThisWeek: 0,
    newTicketsThisMonth: 0,
    newTicketsThisWeek: 0,
    totalTickets: 0,
    adminDashboardData: {} as any
};

export const adminDashboardSlice = createSlice({
    name: "adminDashboard",
    initialState: adminDashboardIntialState,
    reducers: {},
    extraReducers(builder) {
        getAdminDataThunkRejectedReducer(builder, getAdminDashboardDataThunk)
        getAdminDataThunkPendingReducer(builder, getAdminDashboardDataThunk)
        getAdminDataThunkFulfilledReducer(builder, getAdminDashboardDataThunk)
    },
});

export default adminDashboardSlice.reducer;