import { createAsyncThunk } from "@reduxjs/toolkit";
import { ONBOARDINGAPI } from "../../constants/axios-api-constant";
import { postDataAPI } from "../../lib/axios";

export const partnerWithUsAsXThunk = createAsyncThunk(
  "partnerWithUsSalesSite/onbaord-partner",
  async (
    { userCredentials, navigate, axiosInstance, openAlert }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(
        ONBOARDINGAPI,
        axiosInstance,
        userCredentials
      );

      const responseData = {
        user: response.data.user,
        success: response.data.msg,
        userEmail: userCredentials.email,
        message: response?.data?.message,
      };
      openAlert(responseData?.message, "alert-success-message");
      // saveDataToLocalStorage('smd-user-email', response.data.data)
      return responseData;
    } catch (error: any) {
      const errorData = {
        error:
          error?.response?.data?.message ||
          "Server is not responding. Please Try Again Later",
        userEmail: userCredentials.email,
      };
      openAlert(errorData?.error, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);
