import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AppDateField } from "../../../components/app-date-field/app-date-field";
import { AppInputField } from "../../../components/app-input-field/app-input-field";
import { FilterIcon } from "../../../utils/svg-icon-utils";
import { AppPagination } from "../../../components/app-pagination/app-pagination";
import { useNavigate } from "react-router-dom";
import { CASESPATH, SOLICITORPATH } from "../../../constants/routes-constant";
import {
  casesListsSolicitorDashboardTableHead,
  casesListsSolicitorTableHead,
  casesStatusList,
} from "./cases-lists-data";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getCasesThunk } from "../../../store/cases/cases-thunk-actions";
import { axiosInstance } from "../../../lib/axios";
import { AppTableUI } from "../../../components/app-table-ui/app-table-ui";
import { REQUESTSTATUS } from "../../../constants/axios-api-constant";
import AppLoader from "../../../components/app-loader/app-loader";
import {
  MUIBORDERRADIUS10,
  MUIFONTWEIGHT500,
  MUIPRIMARYCOLOR,
  MUISECONDARYTITLE,
  MUIWHITECOLOR,
} from "../../../constants/mui-styles-constant";
import { UpdateStatusModal } from "../common/update-status-modal";

export const CasesListsSolicitor = () => {
  const navigate = useNavigate();
  const [createdDate, setCreatedDate] = useState<any>(null);
  const [searchParam, setSearchParam] = useState<any>("");
  const [caseStatus, setCaseStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({ prevValue: "", id: "" });

  const dispatch = useAppDispatch();

  const handleCaseStatus = (event: any) => {
    event.target.value === "All"
      ? setCaseStatus("")
      : setCaseStatus(event.target.value as string);
  };

  const handleChange = (newValue: any) => {
    setCreatedDate(dayjs(newValue).format("YYYY-MM-DD"));
  };

  const { casesLists, getRequestStatus } = useAppSelector(
    (state) => state.cases
  );

  useEffect(() => {
    const params = {
      status: caseStatus,
      search: searchParam,
      fromDate: createdDate,
      toDate: createdDate,
      limit: 10,
      offset: currentPage,
    };
    const debouce = setTimeout(
      () => dispatch(getCasesThunk({ axiosInstance, params, currentPage })),
      500
    );
    return () => clearTimeout(debouce);
  }, [currentPage, caseStatus, searchParam, createdDate]);

  return (
    <div className="bg-card-white">
      <Box
        className="padding-top-2 padding-bottom-2"
        sx={{ padding: { xs: "0 1rem", sm: "3rem 3rem" } }}
      >
        <div className="primary-text-color secondary-heading font-weight-600">
          Case Tracking
        </div>
        <div className="filter margin-top-2 margin-bottom-3">
          <Grid container spacing={1} className="align-center">
            <Grid item xs={12} md={4} lg={2}>
              <AppInputField
                label="Case status"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                select={true}
                selectMenudata={casesStatusList}
                value={caseStatus}
                onChange={handleCaseStatus}
                iconComponent={FilterIcon}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <AppDateField
                label="Date Created"
                labelClassName="primary-text-color font-weight-500"
                required={false}
                value={createdDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}></Grid>
            <Grid item xs={12} md={4} lg={2}>
              <AppInputField
                label="Search"
                labelClassName="primary-text-color font-weight-500 visibility-hidden"
                placeholder="Search"
                required={false}
                endIcon={<SearchIcon className="primary-text-color" />}
                value={searchParam}
                onChange={(e: any) => {
                  setSearchParam(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>

        <SolicitorTablePiece setOpen={setOpen} setModalData={setModalData} />
        <AppPagination
          currentPage={casesLists?.meta?.page ?? 0}
          totalPages={casesLists?.meta?.pages ?? 0}
          handleChangePage={(a, b) => {
            setCurrentPage((b - 1) * 10);
          }}
        />
      </Box>
      <UpdateStatusModal open={open} setOpen={setOpen} modalData={modalData} />
    </div>
  );
};
export const SolicitorTablePiece = (props: any) => {
  const navigate = useNavigate();
  const { setOpen, setModalData, dashboardView } = props;
  const { casesLists, getRequestStatus } = useAppSelector(
    (state) => state.cases
  );

  return (
    <>
      <AppTableUI
        tableHeadings={
          dashboardView
            ? casesListsSolicitorDashboardTableHead
            : casesListsSolicitorTableHead
        }
      >
        {getRequestStatus === REQUESTSTATUS.PENDING ? (
          !dashboardView ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <AppLoader />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <div className="flex align-center justify-center  padding-2">
                  <CircularProgress size={35} />
                  <span className="primary-color font-weight-600 center-text secondary-title  padding-left-0">
                    Loading...{" "}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          )
        ) : (
          <>
            {casesLists?.CasesLists?.length ? (
              casesLists?.CasesLists?.map((row: any, index: any): any => (
                <TableRow
                  key={row?.["_id"]}
                  className="border-bottom-light-grey"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    className="primary-text-color primary-title font-weight-400"
                    align="left"
                  >
                    {row?.["clientName"] ?? "-"}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell
                      align="left"
                      className="primary-text-color primary-title font-weight-400"
                    >
                      {row?.["clientEmail"] ?? "-"}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {dayjs(row?.["createdAt"]).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell
                    align="left"
                    className="primary-text-color primary-title font-weight-400"
                  >
                    {row?.["applicantType"] ?? "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="primary-text-color tertiary-title font-weight-600 center-text"
                  >
                    {row?.["status"] === "In Progress" ? (
                      <Grid
                        sx={{ width: "140px", p: 1 }}
                        className="light-grey-color bg-tertiary-color border-radius-pill cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                          setModalData({
                            prevValue: row?.["status"],
                            id: row?.["_id"],
                          });
                        }}
                      >
                        In Progress
                      </Grid>
                    ) : row?.["status"] === "On Hold" ? (
                      <Grid
                        sx={{ width: "140px", p: 1 }}
                        className="warning-color bg-tertiary-color border-radius-pill cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                          setModalData({
                            prevValue: row?.["status"],
                            id: row?.["_id"],
                          });
                        }}
                      >
                        On Hold
                      </Grid>
                    ) : row?.["status"] === "Post Completion" ? (
                      <Grid
                        sx={{ width: "140px", p: 1 }}
                        className="primary-color bg-tertiary-color border-radius-pill cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                          setModalData({
                            prevValue: row?.["status"],
                            id: row?.["_id"],
                          });
                        }}
                      >
                        Post Completion
                      </Grid>
                    ) : row?.["status"] === "Fell Through" ? (
                      <Grid
                        sx={{ width: "140px", p: 1 }}
                        className="danger-color bg-tertiary-color border-radius-pill"
                      >
                        Fell Through
                      </Grid>
                    ) : (
                      <Grid
                        sx={{ width: "140px", p: 1 }}
                        className="success-color bg-tertiary-color border-radius-pill"
                      >
                        Completed
                      </Grid>
                    )}
                  </TableCell>
                  {!dashboardView && (
                    <TableCell>
                      <Tooltip
                        title="View"
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              background: MUIPRIMARYCOLOR,
                              fontSize: MUISECONDARYTITLE,
                              fontWeight: MUIFONTWEIGHT500,
                              borderRadius: MUIBORDERRADIUS10,
                              paddingX: "15px",
                            },
                          },
                        }}
                      >
                        <Grid
                          className="cursor-pointer flex align-center justify-center  width-40 border-radius-10"
                          sx={{
                            height: 40,
                            color: MUIPRIMARYCOLOR,
                            "&:hover": {
                              background: MUIPRIMARYCOLOR,
                              color: `${MUIWHITECOLOR} !important`,
                            },
                          }}
                          onClick={() => {
                            navigate(
                              `${CASESPATH}/details/${row?.["dipId"]}?clientId=${row?.["clientId"]}&caseId=${row?.["_id"]}`,
                              {
                                state: {
                                  diptabsData: [
                                    "Application Details",
                                    "Product Details",
                                    "Upload Documents",
                                  ],
                                  diptabsComponent: [
                                    "applicationDetails",
                                    "productDetails",
                                    "uploadDocnment",
                                  ],
                                },
                              }
                            );
                          }}
                        >
                          <RemoveRedEyeIcon />
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <p className="error-color font-weight-600 center-text width-100">No data</p>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </AppTableUI>
    </>
  );
};
