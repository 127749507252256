import { REQUESTSTATUS } from "../../constants/axios-api-constant";

// Quote Management Home Page Get
export const getQuoteManagementRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getQuoteManagementFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.quoteManagementData = payload;
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getQuoteManagementPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

// Quote Management Form Get
export const getByIdQuoteManagementFormRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getByIdQuoteManagementFormFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.quoteManagementFormData = payload;
    state.legalFees = payload?.solicitorFees?.legalFees;
    state.landRegistryFees = payload?.solicitorFees?.landRegistryFees;
    state.stampDuty = payload?.solicitorFees?.stampDuty;
    state.supplements = payload?.solicitorFees?.supplements;
    state.legalFeesVat = payload?.solicitorFees?.legalFeesVat;
    state.mgbCommission = payload?.solicitorFees?.mgbCommission;
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getByIdQuoteManagementFormPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

// Quote Management Form Patch
export const patchByIdQuoteManagementFormRejectedReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const patchByIdQuoteManagementFormFulfilledReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const patchByIdQuoteManagementFormPendingReducer = (
  builder: any,
  action: any
) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};
