import { createSlice } from "@reduxjs/toolkit";

import {
  createNewUserRejectedReducer,
  createNewUserSuccessReducer,
  createNewUserPendingReducer,
  getUsersRejectedReducer,
  getUsersSuccessReducer,
  getUsersPendingReducer,
  getAdminUsersRejectedReducer,
  getAdminUsersSuccessReducer,
  getAdminUsersPendingReducer,
  editUserRejectedReducer,
  editUserSuccessReducer,
  editUserPendingReducer,
} from "./manage-users-reducers";
import {
  createNewUserThunk,
  getAdminUsersThunk,
  getUsersThunk,
  editUserThunk,
  // changeUserStatusThunk, deleteUserThunk,
  // getBookingsByIdThunk,
  // getListingsByUserIdThunk,
  // getMangeUsersThunk, getUserRightsThunk,  postUserRightsThunk
} from "./manage-users-thunk-actions";

const initialState = {
  usersData: [],
  adminUsersData: [],
  status: "idle",
};

const manageSlice = createSlice({
  name: "manage",
  initialState,
  reducers: {},
  extraReducers(builder) {
    createNewUserRejectedReducer(builder, createNewUserThunk);
    createNewUserSuccessReducer(builder, createNewUserThunk);
    createNewUserPendingReducer(builder, createNewUserThunk);

    getUsersRejectedReducer(builder, getUsersThunk);
    getUsersSuccessReducer(builder, getUsersThunk);
    getUsersPendingReducer(builder, getUsersThunk);

    getAdminUsersRejectedReducer(builder, getAdminUsersThunk);
    getAdminUsersSuccessReducer(builder, getAdminUsersThunk);
    getAdminUsersPendingReducer(builder, getAdminUsersThunk);

    editUserRejectedReducer(builder, editUserThunk);
    editUserSuccessReducer(builder, editUserThunk);
    editUserPendingReducer(builder, editUserThunk);

    // getMangeUsersFullfilledReducer(builder, getMangeUsersThunk)
    // getMangeUsersRejectedReducer(builder, getMangeUsersThunk)

    // changeUserStatusRejectedReducer(builder, changeUserStatusThunk)
    // changeUserStatusSuccessReducer(builder, changeUserStatusThunk)
    // changeUserStatusPendingReducer(builder, changeUserStatusThunk)

    // deleteUserRejectedReducer(builder, deleteUserThunk)
    // deleteUserSuccessReducer(builder, deleteUserThunk)
    // deleteUserPendingReducer(builder, deleteUserThunk)

    // getUserRightsRejectedReducer(builder, getUserRightsThunk)
    // getUserRightsSuccessReducer(builder, getUserRightsThunk)
    // getUserRightsPendingReducer(builder, getUserRightsThunk)

    // postUserRightsRejectedReducer(builder, postUserRightsThunk)
    // postUserRightsSuccessReducer(builder, postUserRightsThunk)
    // postUserRightsPendingReducer(builder, postUserRightsThunk)

    // getBookingsByIdRejectedReducer(builder, getBookingsByIdThunk)
    // getBookingsByIdSuccessReducer(builder, getBookingsByIdThunk)
    // getBookingsByIdPendingReducer(builder, getBookingsByIdThunk)

    // getListingsByUserIdRejectedReducer(builder, getListingsByUserIdThunk)
    // getListingsByUserIdSuccessReducer(builder, getListingsByUserIdThunk)
    // getListingsByUserIdPendingReducer(builder, getListingsByUserIdThunk)
  },
});

export const manageUsersActions = manageSlice.actions;
export default manageSlice.reducer;
