import { createAsyncThunk } from "@reduxjs/toolkit";
import { SOLICITORDASHBOARDPATH } from "../../../constants/routes-constant";
import { getDataAPI } from "../../../lib/axios";

export const getSolicitorDashboardDataThunk = createAsyncThunk("getsolicitor-dashboard-data",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(SOLICITORDASHBOARDPATH, axiosInstance);
          

            return response.data.data;
        } catch (error: any) {
            
            const errorData = {

                
            };
            return rejectWithValue(errorData);
        }
    }
);


export const getSolicitorYearDashboardDataThunk = createAsyncThunk("get-solicitor-year-dashboard-data",
    async ({ yearData, axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(`dashboard/get-user?year=${yearData}`, axiosInstance);

            return response.data?.data;
        } catch (error: any) {
            const errorData = {
            };
            return rejectWithValue(errorData);
        }
    }
);