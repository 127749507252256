import totalTicketsIcon from "../../../assets/images/app-short-summary-cards/total-tickets-icons.png";
import newTicketsIcon from "../../../assets/images/app-short-summary-cards/new-tickets-icon.png";
import closedTicketsIcon from "../../../assets/images/app-short-summary-cards/closed-ticket-icon.png";
import { useAppSelector } from "../../../store/store";
import { ShortSummaryCards } from "../../../components/app-short-summary-cards/app-short-summary-cards";
import { Grid } from "@mui/material";

export const AdminDashboardCardData = () => {
  const { closedTicketsThisMonth, closedTicketsThisWeek, newTicketsThisMonth,
    newTicketsThisWeek, totalTickets } = useAppSelector((state: any) => state?.adminDashboardSlice)

  const superAdminCardsData: any = [
    {
      id: 1,
      bgColor: "#366EC2",
      value: totalTickets,
      value2: "0",
      title: "Total Tickets",
      imgSrc: totalTicketsIcon,
      thisWeekMonthToggler: false,
    },
    {
      id: 2,
      bgColor: "#48995D",
      value: newTicketsThisWeek,
      value2: newTicketsThisMonth,
      title: "New Tickets",
      imgSrc: newTicketsIcon,
      thisWeekMonthToggler: true,
    },
    {
      id: 3,
      bgColor: "#FF3F3F",
      value: closedTicketsThisWeek,
      value2: closedTicketsThisMonth,
      title: "Closed Tickets",
      imgSrc: closedTicketsIcon,
      thisWeekMonthToggler: true,
    },
  ];
  return (
    <>
      {superAdminCardsData?.map((ele: any) => (
        <Grid item xl={3.8} lg={5.9} sm={5.9} md={11.9} xs={11.9}
          marginBottom={{ xl: 0, xs: 2 }} key={ele?.id}
        >
          <ShortSummaryCards
            bgColor={ele?.bgColor}
            value={ele?.value || 0}
            title={ele?.title}
            imgSrc={ele?.imgSrc}
            thisWeekMonthToggler={ele?.thisWeekMonthToggler}
            value2={ele?.value2 || 0}
            firstLabel={"This Week"}
            secondLabel={"This Month"}
            isAdmin
          />
        </Grid>
      ))}
    </>
  )
}