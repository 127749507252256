import { createAsyncThunk } from "@reduxjs/toolkit";
import { SALESAGENTDASHBOARDPATH } from "../../../constants/routes-constant";
import { getDataAPI } from "../../../lib/axios";
export const getSalesAgentDashboardDataThunk = createAsyncThunk("get-sales-agent-dashboard-data",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(SALESAGENTDASHBOARDPATH, axiosInstance);
            

            return response?.data;
        } catch (error: any) {
            
            const errorData = {


            };
            return rejectWithValue(errorData);
        }
    }
);

export const getSalesAgentYearDashboardDataThunk = createAsyncThunk("get-sales-agent-year-dashboard-data",
    async ({ yearData, axiosInstance }: any, { rejectWithValue }) => {
        
        try {
            const response = await getDataAPI(`dashboard/get-user?year=${yearData}`, axiosInstance);
            

            return response.data?.data;
        } catch (error: any) {
            
            const errorData = {

                
            };
            return rejectWithValue(errorData);
        }
    }
);