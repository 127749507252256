import { Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./custom-temp-file.scss";

import { EDITTEXTEDITORINTERFACE } from "./app-text-editor-interfaces";

export const EditMood: React.FC<EDITTEXTEDITORINTERFACE> = ({
  value,
  updateHand,
}) => {
  return (
    <Grid container>
      <ReactQuill theme="snow" value={value} onChange={updateHand} />
    </Grid>
  );
};
