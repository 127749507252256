import { Grid } from "@mui/material";
import { NotificationAndRemindersCard } from "../../../components/notification-and-reminders-card/notification-and-reminders-card";

import { PieChartForSalesAgent } from "./charts-for-sales-agent/pie-chart-for-sales-agent";
import { LineChartForSalesAgent } from "./charts-for-sales-agent/line-chart-for-sales-agent";
import SquareIcon from '@mui/icons-material/Square';

// import { DayPlannerCard } from "../../../components/day-planner-card/day-planner-card";
import { SalesAgentCardData } from "./sale-agent-dashboard-data";
import { axiosInstance } from "../../../lib/axios";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect } from "react";
import { getSalesAgentDashboardDataThunk } from "../../../store/dashboard/sales-agent-dashboard/sales-agent-dashboard-thunk";
import { TICKETSLISTSPATH, TICKETSPATH } from "../../../constants/routes-constant";
import { useNavigate } from "react-router-dom";
import { SalesAgentDashboardTablePiece } from "../../../features/tickets/tickets-list-sale-agent/dip-details";
import { getTicketsListThunk } from "../../../store/tickets/tickets-thunk-actions";
import { DayPlannerCardTickets } from "../../../components/day-planner-card-tickets/day-planner-card-tickets";


export const SaleAgentDashboard = () => {
  

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {

    dispatch(getSalesAgentDashboardDataThunk({ axiosInstance }))
    dispatch(getTicketsListThunk({ axiosInstance, params: { limit: 4, offset: 0 } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const width = window.innerWidth;
  return (
    <div>
      <Grid container direction={"row"} justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"} item xl={7.5} lg={11.9} xs={12}>

          <SalesAgentCardData />
          <Grid container justifyContent={"space-between"} marginTop={2}>
            <Grid item xl={6.6} xs={12} mb={{ xl: 0, xs: 2 }} className="bg-card-white"
            >
              <LineChartForSalesAgent />
            </Grid>

            <Grid item xl={5} xs={12} py={1} px={2} mb={{ xl: 0, xs: 2 }} className="bg-card-white position-relative"
            >
              <span
                className="position-absolute primary-color tertiary-title font-weight-600 "
                style={{
                  right: 45, top: 10, zIndex: 1,
                  borderBottom: "1px solid #366EC2",
                }}>
                Call
              </span>
              <span className="position-absolute"
                style={{
                  height: 12,  width: 12, zIndex: 1,
                  right: 22, top: 13, backgroundColor: '#fa7901 '
                }}></span>

              <DayPlannerCardTickets dayPlannerHeight={'305px'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}
          xl={4.3} xs={12} className="bg-card-white" mb={{ xl: 0, xs: 2 }}>
          <PieChartForSalesAgent />
        </Grid>
      </Grid>


      <Grid container marginTop={2} justifyContent={"space-between"}>
        <Grid item xl={8.5} lg={7.3} xs={12} px={2}
          pb={1} mb={{ xl: 0, xs: 2 }} sx={{ maxWidth: width - 70 }}
          className="bg-card-white"
        >
          <div className="flex justify-space-between padding-top-1 padding-bottom-1">
            <span className="secondary-title font-weight-600">Recent Tickets</span>
            <span className="primary-color tertiary-title font-weight-600 cursor-pointer"
              onClick={() => navigate(TICKETSPATH + '/' + TICKETSLISTSPATH)}
              style={{ borderBottom: "1px solid #366EC2" }}
            >
              View All
            </span>
          </div>
          <SalesAgentDashboardTablePiece dashboardView />
        </Grid>
        <Grid item xl={3.3} lg={4.5} xs={12} padding={2}
          className="bg-card-white border-radius-16 font-family-monterrat width-100">
          <span className="secondary-title font-weight-600 padding-bottom-0 ">
            Notifications
          </span>
          <Grid container className="overflow-scroll-y" sx={{ maxHeight: 353.5 }}
          >
            <NotificationAndRemindersCard viewDetails={true} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};