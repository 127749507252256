import { PDFEDITOR } from "../../../../components/pdf-editor/pdf-editor";

export const EmbedSignatureContracts: React.FC<{
  pdfFile: string;
  contractId: string;
  setIsEmbedModalOpen: any;
}> = ({ pdfFile, contractId, setIsEmbedModalOpen }) => {
  return (
    <PDFEDITOR
      pdfFile={pdfFile}
      contractId={contractId}
      setIsEmbedModalOpen={setIsEmbedModalOpen}
    />
  );
};
