import { Avatar, Grid } from "@mui/material";
import "./recent-meetings-cards.scss";

const recentMeetingData: any = [
  { id: 1, personImg: "", name: "William John", role: "Sale Agent" },
  { id: 2, personImg: "", name: "John Doe", role: "Sale Agent" },
  { id: 3, personImg: "", name: "Henry", role: "Sale Agent" },
  { id: 4, personImg: "", name: "William John", role: "Sale Agent" },
  { id: 5, personImg: "", name: "William John", role: "Sale Agent" },
];

export const RecentMeetings = () => {
  return (
    <div className="bg-white-color border-radius-16 font-family-monterrat width-100 recent-meetings">
      <Grid container item xs={12} padding={2}>
        <span className="secondary-title font-weight-600 padding-bottom-0">
          Recent Meetings
        </span>
        <Grid
          className="persons-main-div overflow-scroll-y"
          marginBottom={1}
          item
          xs={12}
        >
          {recentMeetingData?.map((ele: any, index: any) => (
            <div className=" flex align-center padding-top-1 " key={index}>
              <span>
                <Avatar sx={{ height: 45, width: 45 }} />
              </span>
              <div className="flex flex-column justify-space-between  padding-left-1 ">
                <span className="title-10 font-weight-600 padding-bottom-0">
                  {ele?.name}
                </span>
                <span className="title-10"> {ele?.role}</span>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
