import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
// import SmaplePdf from "./sample.pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";

export const Reactpdf = ({ file, width = "", height = "", overflowNone = false }) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
      <div>
        <div className={`${overflowNone ? "o-none ": ""}mt4`} style={{ height: height ||"750px", width: width || '' }}>
          <div>
            <Viewer fileUrl={file} />
          </div>
        </div>
      </div>
    </Worker>
  );
};
