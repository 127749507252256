import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  getEnquiriesFulfilledReducer,
  getEnquiriesPendingReducer,
  getEnquiriesRejectedReducer,
  patchEnquiriesResponseFulfilledReducer,
  patchEnquiriesResponsePendingReducer,
  patchEnquiriesResponseRejectedReducer,
  postEnquiriesFulfilledReducer,
  postEnquiriesPendingReducer,
  postEnquiriesRejectedReducer,
} from "./enquiries-reducers";
import {
  getEnquiriesListThunk,
  patchEnquiriesResponseThunk,
  postEnquiriesResponseThunk,
} from "./enquiries-thunk-actions";

const enquiriesListInitialState: any = {
  enquiriesStatus: REQUESTSTATUS.IDLE,
  enquiriesListData: [],
};

export const enquiriesSlice = createSlice({
  name: "enquiriesList",
  initialState: enquiriesListInitialState,
  reducers: {},
  extraReducers(builder) {
    getEnquiriesRejectedReducer(builder, getEnquiriesListThunk);
    getEnquiriesFulfilledReducer(builder, getEnquiriesListThunk);
    getEnquiriesPendingReducer(builder, getEnquiriesListThunk);

    patchEnquiriesResponseRejectedReducer(builder, patchEnquiriesResponseThunk);
    patchEnquiriesResponseFulfilledReducer(
      builder,
      patchEnquiriesResponseThunk
    );
    patchEnquiriesResponsePendingReducer(builder, patchEnquiriesResponseThunk);

    postEnquiriesRejectedReducer(builder, postEnquiriesResponseThunk);
    postEnquiriesFulfilledReducer(builder, postEnquiriesResponseThunk);
    postEnquiriesPendingReducer(builder, postEnquiriesResponseThunk);
  },
});

export const {} = enquiriesSlice.actions;

export default enquiriesSlice.reducer;
