// Components
import { HeaderMainLayout } from "../header/header-main-layout";
import { FooterMainLayout } from "../footer/footer-main-layout";

// SCSS
import "./main-layout.scss";
import { useMainLayout } from "./use-main-layout";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { BodyMainLayout } from "../body/body-main-layout";
import { useEffect } from "react";
import { useAxiosInterceptors } from "../../hooks/use-axios-interceptors";
import { useAppAlert } from "../../components/app-alert/use-app-alert";
import { myPersonalDetailsThunk } from "../../store/personal-details/personal-details-thunk-actions";
import { getDataFromLocalStorage } from "../../utils/views-utils";
import { LOCALSTORAGEAUTHUSERINFO } from "../../constants/local-storage-constant";

// Component start here
export const MainLayout = () => {
  const { openDrawer }: any = useMainLayout();
  const { layout } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInterceptors();
  const { openAlert } = useAppAlert();

  const isAuth = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);

  useEffect(() => {
    if (!!isAuth?.token) {
      dispatch(myPersonalDetailsThunk({ axiosInstance, openAlert }));
    }
  }, []);

  return (
    <div className="flex flex-column min-height-100vh">
      <div className="header margin-outlet-x">
        <div className="position-absolute side-bar-triangle"></div>
        <HeaderMainLayout />
      </div>
      <div className="main-body flex-grow-1 margin-outlet-x">
        <BodyMainLayout />
      </div>
      <div className="footer">
        <FooterMainLayout />
      </div>
    </div>
  );
};
