import { Button } from "@mui/material";
import { IAPPBUTTONPROPS } from "./app-button-interface";
import "./app-button.scss";

export const AppButton: React.FC<any> = (props: IAPPBUTTONPROPS) => {
  const {
    fullWidth,
    sx,
    id,
    type,
    disabled,
    btnIcon,
    btnText,
    btnFunction,
    className,
    btnName,
    btnValue,
    btnIconRight,
    label,
    labelClassName,
    required,
    btnTextClassName,
    startIcon,
    endIcon,
  } = props;
  return (
    <>
      {label && (
        <div
          className={`primary-title flex margin-bottom-0 align-center ${labelClassName}`}
        >
          {label}
          <span className="error-color primary-title">{required && "*"}</span>
        </div>
      )}
      <Button
        disableElevation
        fullWidth={fullWidth}
        onClick={() => {
          btnFunction?.();
        }}
        sx={sx}
        className={`${className} width-sm-full`}
        id={id}
        type={(type as "submit" | "reset" | "button") || undefined}
        disabled={disabled}
        name={btnName}
        value={btnValue}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {btnIcon}
        <div className={`${btnTextClassName}`}> {btnText}</div>
        {btnIconRight}
      </Button>
    </>
  );
};
