export const dropdownOptionsForBuyers = [
  {
    id: 1,
    name: "Authority Letter",
  },
  {
    id: 2,
    name: "Proof of Funds Form",
  },
  {
    id: 3,
    name: "Joint Application Form",
  },
];

export const dropdownOptionsForSellers = [
  {
    id: 1,
    name: "Last society fittings and content form TA10",
  },
  {
    id: 2,
    name: "Property Information form TA6",
  },
  {
    id: 3,
    name: "HM Land Registry TRI",
  },
  {
    id: 4,
    name: "Contracts",
  },
];
