import { createAsyncThunk } from "@reduxjs/toolkit";
import { userNotificationLatestPath } from "../../constants/routes-constant";
import { getDataAPI } from "../../lib/axios";

export const getLatestNotificationThunk = createAsyncThunk("latest-notification",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(userNotificationLatestPath, axiosInstance, { includeIcon: true });
            return response?.data?.data;
        } catch (error: any) {
            const errorData = {

                //   error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
            };
            return rejectWithValue(errorData);
        }
    }
);