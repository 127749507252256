export const MINIMUMPASSWORDLENGTHVALIDATION = 8;
export const MAXIMUMPASSWORDLENGTHVALIDATION = 30;
export const MINIMUMNAMELENGTH = 3;
export const MAXIMUMNAMELENGTH = 20;
export const MAXIMUMPHONENUMBERLENGTH = 10;
export const MINIMUMSIGNUPFIRSTNAMELENGTH = 3;
export const MAXIMUMSIGNUPFIRSTNAMELENGTH = 15;
export const MAXIMUMSIGNUPLASTNAMELENGTH = 20;
//Menu Validation Constans
export const MAXIMUMMENUTITLELENGTH = 50;
export const MAXIMUMMENUDESCRIPTIONLENGTH = 500;
//Add Food Item Validation Constans
export const MINIMUMITEMCATEGORYSELECTION = 1;
export const MINIMUMINGREDIENTSELECTION = 1;
export const MINIMUMALLERGENSELECTION = 1;
//Listing Validation Constans
export const MINIMUMHOUSERULESELECTION = 1;
export const MINIMUMFOODITEMSELECTION = 1;
export const MINIMUMPRICE = 0;

// characters validations //
export const ATLEASTONEDIGITVALIDATION = /^(?=.{1,300}$)\D*\d/; // eslint-disable-line
export const ATLEASTONEUPPERCASEVALIDATION = /^(?=.*[A-Z])/; // eslint-disable-line
export const ATLEASTONELOWERCASEVALIDATION = /^(?=.*[a-z])/; // eslint-disable-line
// export const ATLEASTONESPECIALCHARACTERVALIDATION = /[ -!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/; // eslint-disable-line
export const ATLEASTONESPECIALCHARACTERVALIDATION =
  /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]/g; // eslint-disable-line
// export const ATLEASTONESPECIALCHARACTERVALIDATION = /^(?=.*[!@#)_(=\$%\^&\*])/; // eslint-disable-line
export const ALLCHARACTERSONLYVALIDATION = /^[aA-zZ\s]+$/; // eslint-disable-line
export const ALLCASECHARACTERSONLYVALIDATION = /^(?=.*[a-z])(?=.*[A-Z])/; // eslint-disable-line
export const ALPHANUMERICVALIDATION = /^.[a-zA-Z0-9]+$/; // eslint-disable-line
export const ALPHANUMERICWITHBASICCHARACTERSVALIDATION = /^.[a-zA-Z0-9\s_-]+$/; // eslint-disable-line

export const BOTHAPLHANUMERICVALIDATION =
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/; // eslint-disable-line
export const ALPHANUMERICBUTNOTNUMERICALONEVALIDATION =
  /^(?!^\d*$)[a-zA-Z\d\s]*$/; // eslint-disable-line
export const ALPHANUMERICANDCHARACTERS =
  /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\s])([a-zA-Z0-9\s]+)$/; //eslint-disable-line
export const ALPHANUMERICANDOTHERSBASICCHARACTERS =
  /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\s])(?=.*[-_])([a-zA-Z0-9\s_-]+)$/; //eslint-disable-line

export const ONLYNUMERICVALUESVALIDATION = /^[0-9]*$/; //eslint-disable-line
// characters validation

export const UKNUMBERVALIDATION = /^\+44\d{10}$/; // eslint-disable-line

export const UTRNUMBERVALIDATION = /\d{5}\s\d{5}/;
export const UTRNUMBERMAXIMUMLENGTH = 11;
export const MAXUPLOADFILESIZE = 1024 * 1024;

export const MINIMUMHOSTSSELECTION = 1;
