import { createAsyncThunk } from "@reduxjs/toolkit";
import { CLIENTDASHBOARDPATH } from "../../../constants/routes-constant";
import { getDataAPI } from "../../../lib/axios";

export const getClientDashboardDataThunk = createAsyncThunk("get-client-dashboard-data",
    async ({ axiosInstance }: any, { rejectWithValue }) => {
        try {
            const response = await getDataAPI(CLIENTDASHBOARDPATH, axiosInstance);

            return response.data;
        } catch (error: any) {
            
            const errorData = {

                //   error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
            };
            return rejectWithValue(errorData);
        }
    }
);