import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

import { IAPPLOADERPROPS } from "./app-loader-interface";
import { MUISECONDARYCOLOR } from "../../constants/mui-styles-constant";
import { GLOBALSERVERREQUESTPENDINGSTATEMESSAGE } from "../../constants/views-constant";

const AppLoader: React.FC<IAPPLOADERPROPS> = (props) => {
  const { message } = props;

  return (
    <div className="center-text padding-top-2 padding-bottom-2 flex  justify-center flex-column">
      <h2 className="secondary-heading secondary-color font-weight-600">
        {message || GLOBALSERVERREQUESTPENDINGSTATEMESSAGE}
      </h2>
      <LinearProgress
        variant="indeterminate"
        className="bg-primary-color"
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: MUISECONDARYCOLOR,
          },
        }}
      />
    </div>
  );
};
export default AppLoader;
