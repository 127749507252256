import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import alertReducer from "./alert/alert-slice";
import breadcrumbReducer from "./breadcrumb/breadcrumb-slice";
import layoutReducer from "./layout/layout-slice";
import referenceDataReducer from "./reference-data/reference-data-slice";
import authReducer from "./auth/auth-slice";
import personalDetailsReducer from "./personal-details/personal-details-slice";
import onboardingReducer from "./onboarding/onboarding-slice";
import PartnerWithUsSalesSiteSliceReducer from "./partner-with-us-sales-site/partner-with-us-sales-site-slice";
import manageUsersReducer from "./manage-users/manage-users-slice";
import dayPlannerReducer from "./day-planner/day-planner-slice";
import jobsReducer from "./jobs/jobs-slice";
import faqReducer from "./faq/faq-slice";
import delegatesReducer from "./delegates/delegates-slice";
import quoteManagementReducer from "./quote-mangement/quote-quote-mangement-slice";
import contractsReducer from "./contracts/contracts-slice";
import dipReducer from "./dip/dip-slice";
import enquiriesReducer from "./enquiries/enquiries-slice";
import solicitorReducer from "./solicitor/solicitor-slice";
import applyNowReducer from "./resumes/resumes-slice";
import ticketsReducer from "./tickets/tickets-slice";
import casesReducer from "./cases/cases-slice";
import clientsReducer from "./client/client-slice";
import solicitorDashboardReducer from "./dashboard/solicitor-dashboard/solicitor-dashboard-slice";
import adminDashboardSliceReducer from "./dashboard/admin-dashboard/admin-dashboard-slice";
import notificationSliceReducer from "./notification-and-reminder/notification-slice";
import clientDashboardSliceReducer from "./dashboard/client-dashboard/client-dashboard-slice";
import estateAgentSliceReducer from "./dashboard/estate-agent-dashboard/estate-agent-dashboard-slice";
import mortgageBrokerSliceReducer from "./dashboard/mortgage-broker-dashboard/mortgage-broker-dashboard-slice"
import salesAgentSliceReducer from "./dashboard/sales-agent-dashboard/sales-agent-dashboard-slice"
import reviewsReducer from "./reviews/reviews-slice";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    breadcrumb: breadcrumbReducer,
    dip: dipReducer,
    layout: layoutReducer,
    referenceData: referenceDataReducer,
    auth: authReducer,
    personalDetails: personalDetailsReducer,
    onboarding: onboardingReducer,
    PartnerWithUsSalesSite: PartnerWithUsSalesSiteSliceReducer,
    manage: manageUsersReducer,
    dayPlanner: dayPlannerReducer,
    jobs: jobsReducer,
    faq: faqReducer,
    delegates: delegatesReducer,
    quoteManagement: quoteManagementReducer,
    contracts: contractsReducer,
    solicitor: solicitorReducer,
    equiriesList: enquiriesReducer,
    applyNow: applyNowReducer,
    tickets: ticketsReducer,
    cases: casesReducer,
    clints: clientsReducer,
    solicitorDashboardSlice: solicitorDashboardReducer,
    notificationSlice: notificationSliceReducer,
    clientDashboardSlice: clientDashboardSliceReducer,
    adminDashboardSlice: adminDashboardSliceReducer,
    estateAgentSlice: estateAgentSliceReducer,
    mortgageBrokerSlice:mortgageBrokerSliceReducer,
    salesAgentSlice:salesAgentSliceReducer,
    reviews: reviewsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
// Export a hook that can be reused to resolve types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export default store;

//TODO: rename slice to reducer
