import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AlertBreadcrumbs } from "../../components/alert-breadcrumbs/alert-breadcrumbs";
import { useAppSelector } from "../../store/store";
import { SidebarMainLayout } from "../sidebar/sidebar-main-layout";

export const BodyMainLayout = () => {
  const { layout } = useAppSelector((state) => state);

  return (
    <>
      <div className="breadcrumbs-alert" >
        <AlertBreadcrumbs />
      </div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={layout.sidebarMd}
          lg={layout.sidebarLg}
          className={`${layout.hideSidebar && "display-none"} `}
        >
          <SidebarMainLayout />
        </Grid>
        <Grid item xs={12} md={layout.mainMd} lg={layout.mainLg}>
          <div
            className="margin-left-0"
            style={{ backgroundImage: `url(${layout?.backgroundImage})` }}
          >
            <div>
              <Outlet />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
