import { MUIPRIMARYCOLOR } from "../../constants/mui-styles-constant";

export const checkBoxSx = (fontSize: any, borderRadius: any, other: any) => {
  return {
    "& .MuiSvgIcon-root": {
      fontSize: { fontSize },
      borderRadius: borderRadius,
      ...other,
    },
    "&.Mui-checked": { color: MUIPRIMARYCOLOR },
  };
};
