import { Navigate, useLocation } from "react-router-dom";
import { LOCALSTORAGEAUTHUSERINFO } from "../constants/local-storage-constant";
import { getDataFromLocalStorage, saveDataToLocalStorage } from "../utils/views-utils";
import { routingData } from "./routes-data";

export const PrivateRoutes: React.FC<any> = (props: any) => {
  const { allowedRoles } = props;
  const auth = getDataFromLocalStorage(LOCALSTORAGEAUTHUSERINFO);
  // const  { auth } = useAppSelector(state => state)
  // in case of sale site     <Navigate to="/home" /> else    <Navigate to="/authentication/signin" />
  const { pathname } = useLocation();

  if (!auth?.token) {
    // <Navigate to={!(routingData[0].parentRoutes as any)?.find((pR: any) => pR.pathLink == '/' + pathname.split('/')[1] || pR.pathLink == pathname) ? "/home": `/authentication/signin?redirect_uri=${pathname}${search}`} />
    return (
      <Navigate
        to={
          !(routingData[0].parentRoutes as any)?.find(
            (pR: any) =>
              pR.pathLink === "/" + pathname.split("/")[1] ||
              pR.pathLink === pathname
          )
            ? "/home"
            : `/authentication/signin`
        }
      />
    );
  } else if (!auth.user.idVerified) {
    saveDataToLocalStorage(LOCALSTORAGEAUTHUSERINFO, {});
    return <Navigate to={"/authentication/verification" + "?user=" + auth.user.userId + "&status=" + auth.user.idVerificationStatus} />;
  } else if (auth?.role?.find((role: any) => allowedRoles?.includes(role))) {
    return <> {props.children} </>;
  }
  return (
    <>
      {" "}
      <Navigate to="/unauthorized" />{" "}
    </>
  );

  //uncomment it if backedn does not work
  // return <>{props.children}</>
};
