import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/axios-api-constant";
import {
  addRemindersThunkFulfilledReducer,
  addRemindersThunkPendingReducer,
  addRemindersThunkRejectedReducer,
  deleteRemindersThunkFulfilledReducer,
  deleteRemindersThunkPendingReducer,
  deleteRemindersThunkRejectedReducer,
  editRemindersThunkFulfilledReducer,
  editRemindersThunkPendingReducer,
  editRemindersThunkRejectedReducer,
  getRemindersThunkFulfilledReducer,
  getRemindersThunkPendingReducer,
  getRemindersThunkRejectedReducer,
} from "./day-planner-reducers";
import {
  addRemindersThunk,
  deleteRemindersThunk,
  editRemindersThunk,
  getRemindersThunk,
} from "./day-planner-thunk-actions";

const dayPlannerInitialState = {
  dayPlannerStatus: REQUESTSTATUS.IDLE,
  status: "idle",
  reminders: [],
  tickets: [] as any,
};

export const dayPlanner = createSlice({
  name: "dayPlanner",
  initialState: dayPlannerInitialState,
  reducers: {},
  extraReducers(builder) {
    getRemindersThunkRejectedReducer(builder, getRemindersThunk);
    getRemindersThunkFulfilledReducer(builder, getRemindersThunk);
    getRemindersThunkPendingReducer(builder, getRemindersThunk);

    addRemindersThunkRejectedReducer(builder, addRemindersThunk);
    addRemindersThunkFulfilledReducer(builder, addRemindersThunk);
    addRemindersThunkPendingReducer(builder, addRemindersThunk);

    editRemindersThunkRejectedReducer(builder, editRemindersThunk);
    editRemindersThunkFulfilledReducer(builder, editRemindersThunk);
    editRemindersThunkPendingReducer(builder, editRemindersThunk);

    deleteRemindersThunkRejectedReducer(builder, deleteRemindersThunk);
    deleteRemindersThunkFulfilledReducer(builder, deleteRemindersThunk);
    deleteRemindersThunkPendingReducer(builder, deleteRemindersThunk);
  },
});

// export const {} = dayPlanner.actions;

export default dayPlanner.reducer;
