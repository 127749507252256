import { Grid } from "@mui/material";
import { AppButton } from "../app-button/app-button";
import { Reactpdf } from "../react-pdf/react-pdf";
import { RENDERPDF } from "./render-pdf-interface";

export const RenderPdf: React.FC<RENDERPDF> = ({
  file,
  clinetSignaturs,
  solicitorSignatureContent,
  solicitorSignatureType,
  viewonly,
}) => {
  const downloadfile = () => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "filename.pdf";
        a.click();
      });
    });
  };
  return (
    <Grid container direction="column">
      <Grid item container sx={{ height: "5vh", overflow: "hidden" }}>
        {!viewonly && (
          <>
            <Grid item sx={{ flexGrow: "1" }}>
              Contract
            </Grid>
            <Grid item>
              <AppButton
                btnText="Download"
                className="btn-gray cursor-pointer tertiary-title add-item-btn "
                btnFunction={() => downloadfile()}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        sx={{
          height: `${viewonly ? "80vh" : "60vh"}`,
          overflow: "auto",
          width: "100%",
        }}
      >
        <Reactpdf file={file} />
        {/* <iframe
          src={file}
          style={{ width: "100%", height: '100%' }}
        /> */}
      </Grid>
      {!viewonly && (
        <Grid item container>
          <Grid item sm={8} xs={12}>
            <p className="primary-text-color font-weight-600 primary-heading primary-title">
              Solicitor Signature
            </p>
            {solicitorSignatureType && solicitorSignatureType !== "type" ? (
              <img
                style={{ maxHeight: "150px", maxWidth: "100px" }}
                src={`${process.env.REACT_APP_BASE_IMAGE_URL}${solicitorSignatureContent}`}
                alt="solicitor signature"
              />
            ) : (
              solicitorSignatureContent
            )}
          </Grid>
          {/* for joint app  */}
          {clinetSignaturs?.length === 2 && (
            <>
              <Grid item sm={4} xs={12}>
                <p className="primary-text-color font-weight-600 primary-heading primary-title">
                  Client Signature
                </p>
                {clinetSignaturs[0]?.clientSignatureType !== "type" && (
                  <img
                    style={{ maxHeight: "150px", maxWidth: "100px" }}
                    src={`${process.env.REACT_APP_BASE_IMAGE_URL}${clinetSignaturs[0]?.clientSignatureFile?.url}`}
                    alt="client signature"
                  />
                )}
                {clinetSignaturs[0]?.clientSignatureType === "type" && (
                  <>
                    {`${clinetSignaturs[0]?.clientSignatureContent}`}
                    <br />
                  </>
                )}
                {clinetSignaturs[1]?.clientSignatureType !== "type" && (
                  <img
                    style={{ maxHeight: "100px", maxWidth: "100px" }}
                    src={`${process.env.REACT_APP_BASE_IMAGE_URL}${clinetSignaturs[1]?.clientSignatureFile?.url}`}
                    alt="client signature"
                  />
                )}
                {clinetSignaturs[1]?.clientSignatureType === "type" &&
                  clinetSignaturs[1]?.clientSignatureContent}
              </Grid>
            </>
          )}
          {clinetSignaturs?.length === 1 && (
            <>
              <Grid item sm={4} xs={12}>
                <p className="primary-text-color font-weight-600 primary-heading primary-title">
                  Client Signature
                </p>
                {clinetSignaturs[0]?.clientSignatureType !== "type" && (
                  <img
                    style={{ maxHeight: "150px", maxWidth: "100px" }}
                    src={`${process.env.REACT_APP_BASE_IMAGE_URL}${clinetSignaturs[0]?.clientSignatureFile?.url}`}
                    alt="client signature"
                  />
                )}
                {clinetSignaturs[0]?.clientSignatureType === "type" && (
                  <>
                    {`${clinetSignaturs[0]?.clientSignatureContent}`}
                    <br />
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};
